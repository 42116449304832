import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import InputLabel from '@material-ui/core/InputLabel';
import Collapse from '@material-ui/core/Collapse';

import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import CloseIcon from '@material-ui/icons/Close';
import ImageIcon from '@material-ui/icons/Image';
import CircularProgress from '@material-ui/core/CircularProgress';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { Box, Switch } from '@material-ui/core';

import { addSubCategory } from '../../slices/categories/categoriesSlice';
import { v4 as uuidv4 } from 'uuid';

function StatusChangeDialog(props: any) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let uploadFileInputRef: any = React.useRef(null);

  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedFileData, setSelectedFileData] = useState('');
  const [selectedMedia, setSelectedMedia] = useState();

  const selectFile = async (event: any) => {
    if (!uploadFileInputRef.files[0]) {
      return;
    }

    setSelectedFileData(uploadFileInputRef.files[0]);

    const fileReader = new FileReader();
    await fileReader.readAsDataURL(uploadFileInputRef.files[0]);

    fileReader.onload = (file: any) => {
      console.log(file);
      setSelectedMedia(file.target.result);
      // setAddMediaDialogOpen(true);
    };
    fileReader.onerror = (error: any) => console.log(error);
  };

  useEffect(() => {
    const image = document.getElementById('image');
  }, [selectedMedia]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Update {props.name} status to {props.statusChangeTo}?
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.handleClose()}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box mt={2}></Box>
            <Typography variant="body1">Message</Typography>
            <TextField
              fullWidth
              autoFocus
              margin="none"
              id="name"
              multiline
              label="Enter a message (Optional)"
              type="text"
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box mt={2}></Box>
          <Typography variant="body1">Image</Typography>
          <Box
            width="125px"
            height="125px"
            marginTop={2}
            border="2px dashed #bbb"
            display="flex"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            className="add-image-container"
            onClick={() => {
              // setCurrentSelectedVariant(index);
              // setOpenSelectMediaDialog(true);
              uploadFileInputRef.click();
            }}
          >
            <ImageIcon style={{ marginBottom: '8px' }} />

            <Typography variant="caption">Add image</Typography>
          </Box>

          <input
            type="file"
            onChange={selectFile}
            style={{ display: 'none' }}
            ref={(ref: HTMLInputElement) => (uploadFileInputRef = ref)}
          />
        </Grid>

        <div
          style={{
            padding: '30px 0 20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            style={{ boxShadow: 'none' }}
            disabled={isSubmitting}
            onClick={() => {
              setIsSubmitting(true);
              props.handleStatusChange(message, selectedMedia);
              setIsSubmitting(false);
            }}
          >
            {isSubmitting && (
              <CircularProgress
                style={{
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-8px',
                }}
                size={16}
              />
            )}
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(StatusChangeDialog);
