import React from 'react';

import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import EditIcon from '@material-ui/icons/Edit';

export default function CustomerAddressPreview(props: any) {
  const renderAddressIcon = (addressType: String) => {
    if (addressType === 'business' || addressType === 'work') {
      return (
        <WorkIcon
          style={{
            paddingLeft: '10px',
            paddingRight: '20px',
            color: '#999',
          }}
        />
      );
    }

    if (addressType === 'home') {
      return (
        <HomeIcon
          style={{
            paddingLeft: '10px',
            paddingRight: '20px',
            color: '#999',
          }}
        />
      );
    }

    if (addressType === 'apartment') {
      return (
        <BusinessIcon
          style={{
            paddingLeft: '10px',
            paddingRight: '20px',
            color: '#999',
          }}
        />
      );
    }
  };

  return (
    <Box display="flex" flexDirection="column" style={{ marginTop: '1em' }}>
      {props.address.primary && (
        <div>
          <Chip
            size="small"
            label="Primary"
            style={{ marginLeft: '50px', marginBottom: '10px' }}
          />
        </div>
      )}
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexDirection="row" display="flex" alignItems="center">
          <div>{renderAddressIcon(props.address.addressType)}</div>
          <div>
            <Typography style={{ textOverflow: 'ellipsis' }}>
              {props.address.organization ? (
                <React.Fragment>
                  {props.address.organization}
                  <br />
                </React.Fragment>
              ) : (
                ''
              )}
              {`${props.address.Line1}`} <br />
              {`${props.address.City}, ${props.address.Province} ${props.address.PostalCode}`}
            </Typography>
            {props.address.buzzer && (
              <Typography color="textSecondary">
                Buzzer: {props.address.buzzer}
              </Typography>
            )}
          </div>
        </Box>
        <div>
          <IconButton
            onClick={() =>
              props.openEditAddressDialog(props.addressType, props.address)
            }
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </div>
      </Box>
    </Box>
  );
}
