import feathers from '../../modules/feathers';

export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS';
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS';
export const REQUEST_CUSTOMER = 'REQUEST_CUSTOMER';
export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER';

export const REQUEST_SECONDARY_CUSTOMERS = 'REQUEST_SECONDARY_CUSTOMERS';
export const RECEIVE_SECONDARY_CUSTOMERS = 'RECEIVE_SECONDARY_CUSTOMERS';

export const REQUEST_PRIMARY_CUSTOMER = 'REQUEST_PRIMARY_CUSTOMER';
export const RECEIVE_PRIMARY_CUSTOMER = 'RECEIVE_PRIMARY_CUSTOMER';

export function requestCustomers() {
  return {
    type: REQUEST_CUSTOMERS,
  };
}

export function requestCustomer() {
  return {
    type: REQUEST_CUSTOMER,
  };
}

export function receivedCustomers(customers: [Object]) {
  return {
    type: RECEIVE_CUSTOMERS,
    payload: customers,
  };
}

export function receivedCustomer(customer: [Object]) {
  return {
    type: RECEIVE_CUSTOMER,
    payload: customer,
  };
}

export function requestSecondaryCustomers() {
  return {
    type: REQUEST_SECONDARY_CUSTOMERS,
  };
}
export function receivedSecondaryCustomers(secondaryCustomers: any) {
  return {
    type: RECEIVE_SECONDARY_CUSTOMERS,
    payload: secondaryCustomers,
  };
}

function requestPrimaryCustomer() {
  return {
    type: REQUEST_PRIMARY_CUSTOMER,
  };
}

async function fetchPrimaryCustomer(primaryAccountId: String) {
  const primaryAccount = await feathers.service('users').get(primaryAccountId);

  console.log(primaryAccount);
  return primaryAccount;
}

function receivedPrimaryCustomer(primaryCustomer: Object) {
  return {
    type: RECEIVE_PRIMARY_CUSTOMER,
    payload: primaryCustomer,
  };
}

export function fetchCustomers(
  filters: [Object] | undefined,
  searchText: String | undefined
) {
  return function (dispatch: Function) {
    // turn fitler array into queryable fields in the api call

    let query = {};
    // asd
    if (searchText) {
      //@ts-ignore
      query = {
        search: searchText,
      };
    }

    dispatch(requestCustomers());
    return feathers
      .service('users')
      .find({
        query,
      })
      .then((res: any) => {
        console.log(res);
        dispatch(receivedCustomers(res.data || res));
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
}

export async function fetchSecondaryCustomers(_id: String) {
  console.log(_id);
  const secondaryAccounts = await feathers.service('users').find({
    query: {
      primaryAccountId: _id,
    },
  });

  console.log(secondaryAccounts);
  return secondaryAccounts.data;
}

export function fetchCustomer(_id: String | undefined) {
  console.log('Calling fetchCustomer');
  return function (dispatch: Function) {
    dispatch(requestCustomer());
    return feathers
      .service('users')
      .get(_id)
      .then(async (res: any) => {
        if (res.secondary) {
          dispatch(requestPrimaryCustomer());
          const primaryCustomer = await fetchPrimaryCustomer(
            res.primaryAccountId
          );
          dispatch(receivedPrimaryCustomer(primaryCustomer));
          dispatch(requestSecondaryCustomers());
          dispatch(receivedSecondaryCustomers([]));
        }

        if (!res.secondary && res.secondaryAccounts.length > 0) {
          console.log(res._id);
          dispatch(requestSecondaryCustomers());
          const secondaryCustomers = await fetchSecondaryCustomers(res._id);
          dispatch(receivedSecondaryCustomers(secondaryCustomers));
        }

        dispatch(receivedCustomer(res));
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
}
