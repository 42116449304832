import { createSlice } from '@reduxjs/toolkit';
import SubscriptionStatusesService from '../../api/subscription-statuses';
import { getSubscription, changePaymentMethod } from '../../api/subscriptions';
import { getPaymentMethods } from '../../api/stripe';

const initialState = {
  subscription: null,
  fetchSubscriptionLoading: true,
  paymentMethods: null,
  paymentMethodsLoading: false,
  paymentMethodError: null,
  error: null,
  subscriptionStatuses: {
    data: [],
    total: 0,
  },
  subscriptionStatusesLoading: true,

  subscriptionStatus: null,
  subscriptionStatusLoading: true,
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptionLoading: (state) => {
      state.fetchSubscriptionLoading = true;
    },

    setSubscriptionStatusesLoading: (state, action) => {
      state.subscriptionStatusesLoading = action.payload;
    },
    fetchSubscriptionStatusesSuccess: (state, action) => {
      state.subscriptionStatuses = action.payload;
      state.subscriptionStatusesLoading = false;
    },
    unloadSubscriptionStatuses: (state) => {
      state.subscriptionStatuses = {
        data: [],
        total: 0,
      };
      state.subscriptionStatusesLoading = true;
    },
    setSubscriptionStatusLoading: (state, action) => {
      state.subscriptionStatusLoading = action.payload;
    },
    fetchSubscriptionStatusSuccess: (state, action) => {
      state.subscriptionStatus = action.payload;
      state.subscriptionStatusLoading = false;
    },
    unloadSubscriptionStatus: (state) => {
      state.subscriptionStatus = null;
      state.subscriptionStatusesLoading = true;
    },
    setPaymentMethodsLoading: (state) => {
      state.paymentMethodsLoading = true;
    },
    getSubscriptionSuccess: (state, action: any) => {
      //@ts-ignore
      state.subscription = action.payload;
      state.fetchSubscriptionLoading = false;
    },

    getSubscriptionFailed: (state, action: any) => {
      state.subscription = null;
      state.fetchSubscriptionLoading = false;
      state.error = action.payload;
    },

    getPaymentMethodsSuccess: (state, action: any) => {
      //@ts-ignore
      state.paymentMethods = action.payload.data;
      state.paymentMethodsLoading = false;
    },
    getPaymentMethodsFailed: (state, action: any) => {
      console.log(action);
      state.paymentMethodsLoading = false;
      state.paymentMethodError = action.payload;
    },
    changePaymenMethodSuccess: (state, action: any) => {},
    changePaymentMethodFailed: (state, action: any) => {},
  },
});

export const {
  setSubscriptionLoading,

  setSubscriptionStatusesLoading,
  fetchSubscriptionStatusesSuccess,
  unloadSubscriptionStatuses,

  setSubscriptionStatusLoading,
  fetchSubscriptionStatusSuccess,
  unloadSubscriptionStatus,

  setPaymentMethodsLoading,
  getSubscriptionSuccess,
  getSubscriptionFailed,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailed,
  changePaymenMethodSuccess,
  changePaymentMethodFailed,
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

export const fetchSubscription =
  (customerId: String) => async (dispatch: Function) => {
    try {
      setSubscriptionLoading();
      const sub = await getSubscription(customerId);
      console.log(sub);
      dispatch(getSubscriptionSuccess(sub.data[0]));

      if (sub.data[0]) {
        if (sub.data[0].paymentMethod === 'card') {
          console.log('Going in here');

          dispatch(fetchStripePaymentMethods(sub.data[0].stripe_customer_id));
        }
      }
    } catch (error) {
      console.log('Error: fetchSubscription');
      console.log(error);
      dispatch(getSubscriptionFailed(error));
    }
  };

export const fetchStripePaymentMethods =
  (stripeCustomerId: String) => async (dispatch: Function) => {
    try {
      console.log(stripeCustomerId);
      setPaymentMethodsLoading();
      const paymentMethods = await getPaymentMethods(stripeCustomerId);
      console.log(paymentMethods);
      dispatch(getPaymentMethodsSuccess(paymentMethods));
    } catch (error) {
      console.log('Error: getPaymentMethods');
      console.log(error);
      dispatch(getPaymentMethodsFailed(error));
    }
  };

export function switchPaymentMethod(
  subscriptionid: String,
  customerId: String,
  paymentMethod: String
) {
  return async function (dispatch: Function) {
    try {
      await changePaymentMethod(subscriptionid, paymentMethod);
      dispatch(fetchSubscription(customerId));
    } catch (error) {
      console.log('change payment method error');
    }
  };
}

export const fetchSubscriptionStatuses =
  (query: object | undefined = undefined) =>
  async (dispatch: any) => {
    dispatch(setSubscriptionStatusesLoading(true));

    try {
      const sub = await SubscriptionStatusesService.find(query);

      console.log(sub);

      dispatch(fetchSubscriptionStatusesSuccess(sub));
    } catch (error) {
      console.log(error);
      // dispatch(
      //   open({
      //     message: error.message || 'There was a problem pausing this week.',
      //   })
      // );
      dispatch(setSubscriptionStatusesLoading(false));
    }
  };

export const fetchSubscriptionStatus =
  (query: object | undefined = undefined) =>
  async (dispatch: any) => {
    dispatch(setSubscriptionStatusLoading(true));

    try {
      const status = await SubscriptionStatusesService.find(query);

      console.log(status);

      if (status.total !== 0) {
        dispatch(fetchSubscriptionStatusSuccess(status.data[0]));
      } else {
        dispatch(fetchSubscriptionStatusSuccess(null));
      }
    } catch (error) {
      console.log(error);
      // dispatch(
      //   open({
      //     message: error.message || 'There was a problem pausing this week.',
      //   })
      // );
      dispatch(setSubscriptionStatusLoading(false));
    }
  };
