import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import PrintIcon from '@material-ui/icons/Print';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default (props: { open: boolean; close: any }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Print QR Code
          </Typography>
        </div>
        <div>
          <IconButton onClick={props.close}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        <DialogContentText>Print 3 of 3 codes</DialogContentText>

        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox checked />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Label</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox checked />
              </TableCell>
              <TableCell>
                Mung beans <br />
                <Typography color="textSecondary" variant="body2">
                  Ingredient
                </Typography>
              </TableCell>
              <TableCell style={{ width: '20%' }}>
                <TextField variant="outlined" size="small" defaultValue="1" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox checked />
              </TableCell>
              <TableCell>
                Lean Chicken
                <br />
                <Typography color="textSecondary" variant="body2">
                  Ingredient
                </Typography>
              </TableCell>
              <TableCell style={{ width: '20%' }}>
                <TextField variant="outlined" size="small" defaultValue="1" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ borderBottom: 0 }} padding="checkbox">
                <Checkbox checked />
              </TableCell>
              <TableCell style={{ borderBottom: 0 }}>
                Apple Cider Vinegar
                <br />
                <Typography color="textSecondary" variant="body2">
                  Ingredient
                </Typography>
              </TableCell>
              <TableCell style={{ width: '20%', borderBottom: 0 }}>
                <TextField variant="outlined" size="small" defaultValue="1" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <div
          style={{
            padding: '16px ',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            onClick={props.close}
            style={{ marginRight: '16px' }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            style={{ boxShadow: 'none' }}
            variant="contained"
            color="secondary"
            onClick={props.close}
          >
            <PrintIcon style={{ marginRight: '8px' }} />
            Print QR Codes
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
