import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import NumberFormat from 'react-number-format';

// import Container from "@material-ui/core/Container";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';

import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import CategoryIcon from '@material-ui/icons/Category';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { Switch } from '@material-ui/core';

import { addSubCategory } from '../../slices/categories/categoriesSlice';
import { v4 as uuidv4 } from 'uuid';

function AddSubCategoryDialog(props: any) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);
  const [productInfoLang, setProductInfoLang] = useState(0);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Add subcategory to {props.selectedCategoryName}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.handleClose()}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>

      <DialogContent>
        <Tabs
          style={{
            marginBottom: '2em',
          }}
          value={productInfoLang}
          onChange={(ev: any, val: any) => setProductInfoLang(val)}
          indicatorColor="primary"
        >
          <Tab label="English" />
          <Tab label="French" />
        </Tabs>
        <Formik
          initialValues={{
            name: '',
            nameFR: '',
          }}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            console.log(values);

            dispatch(
              addSubCategory(
                props.selectedCategoryId,
                {
                  _id: uuidv4(),
                  name: values.name,
                  nameFR: values.nameFR,
                },
                setSubmitting,
                props.handleClose
              )
            );
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Item name is required'),
            nameFR: Yup.string(),
            // available: Yup.boolean(),
          })}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    autoFocus
                    margin="none"
                    id="name"
                    label="Name"
                    type="text"
                    value={productInfoLang === 0 ? values.name : values.nameFR}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      productInfoLang === 0
                        ? errors.name && touched.name
                          ? true
                          : false
                        : errors.nameFR && touched.nameFR
                        ? true
                        : false
                    }
                    helperText={
                      productInfoLang === 0
                        ? errors.name && touched.name && errors.name
                        : errors.nameFR && touched.nameFR && errors.nameFR
                    }
                    InputProps={{
                      name: productInfoLang === 0 ? 'name' : 'nameFR',
                    }}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  padding: '30px 0 20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  style={{ boxShadow: 'none' }}
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <CircularProgress
                      style={{
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-8px',
                      }}
                      size={16}
                    />
                  )}
                  Add Subcategory
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(AddSubCategoryDialog);
