import React, { SyntheticEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { Formik, Field, FieldArray, Form } from 'formik';

// import Container from "@material-ui/core/Container";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';
import { RadioGroup, Radio } from '@material-ui/core';

import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import CategoryIcon from '@material-ui/icons/Category';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import feathers from '../../modules/feathers';

import './Dropzone.scss';
import { uploadMedia } from '../../slices/media/mediaSlice';
import { Slider, Box } from '@material-ui/core';

function AddCategoryDialog(props: any) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const cropperRef = React.useRef<HTMLImageElement>(null);

  const [zoomValue, setZoomValue] = React.useState(0);

  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    // const cropper: any = imageElement?.cropper;
    // console.log(cropper.getCroppedCanvas().toDataURL());
  };

  const getCanvasBlob = (canvas: HTMLCanvasElement) =>
    new Promise((resolve, reject) => {
      canvas.toBlob((blob: any) => {
        console.log(blob);
        resolve(blob);
      });
    });

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={props.open}
      onClose={() => {
        props.handleClose();
        //@ts-ignore
        cropperRef.current.cropper.destroy();
      }}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Upload media
          </Typography>
        </div>
        <div>
          <IconButton
            onClick={() => {
              props.handleClose();
              //@ts-ignore
              cropperRef.current.cropper.destroy();
            }}
          >
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>

      <DialogContent style={{ background: '#000', paddingBottom: 0 }}>
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            setSubmitting(true);

            // const fr = new FileReader();
            // await fr.readAsArrayBuffer(props.file);

            // fr.onload = (asd) => {
            //   //@ts-ignore
            //   console.log(asd.target.result);
            // };

            try {
              let blobData: any = await getCanvasBlob(
                //@ts-ignore
                cropperRef.current.cropper.getCroppedCanvas()
              );

              //@ts-ignore
              console.log(cropperRef.current.cropper.getCropBoxData());
              //@ts-ignore
              console.log(cropperRef.current.cropper.getData());

              const fr = new FileReader();
              await fr.readAsArrayBuffer(props.file);

              fr.onload = (asd) => {
                console.log(asd);
                //@ts-ignore
                console.log(asd.target.result);

                dispatch(
                  uploadMedia(
                    {
                      originalName: props.file.name,
                      mimeType: props.file.type,
                      size: props.file.size,
                      //@ts-ignore
                      buffer: asd.target.result,
                    },
                    //@ts-ignore
                    cropperRef.current.cropper.getData(),
                    () => props.handleClose()
                  )
                );
              };
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Cropper
                    viewMode={1}
                    src={props.imageData}
                    // Cropper.js options
                    minCanvasWidth={310}
                    minCanvasHeight={310}
                    aspectRatio={1}
                    minCropBoxHeight={210}
                    minCropBoxWidth={210}
                    cropBoxResizable={false}
                    cropBoxMovable={false}
                    draggable={false}
                    scalable={false}
                    toggleDragModeOnDblclick={false}
                    guides={true}
                    dragMode="move"
                    crop={onCrop}
                    ref={cropperRef}
                    zoom={(ev: any) => {
                      // console.log(ev.detail);
                      if (ev.detail.ratio >= 5.0) {
                        ev.preventDefault();
                      }

                      setZoomValue(ev.detail.ratio);
                    }}
                    modal={true}
                    center={true}
                    background={false}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  padding: '30px 20px',
                  margin: '0 -24px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: '#fff',
                }}
              >
                <Box
                  width="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Box width="340px">
                    <ZoomOutIcon style={{ marginRight: '10px' }} />
                    <Slider
                      style={{ maxWidth: '220px', marginRight: '10px' }}
                      value={zoomValue}
                      min={0.1}
                      step={0.01}
                      max={5}
                      onChange={(e: any, value: number | any) => {
                        console.log(value);

                        //@ts-ignore
                        cropperRef.current.cropper.zoomTo(value);
                      }}
                    />
                    <ZoomInIcon />
                  </Box>

                  <Button
                    onClick={() => {
                      //@ts-ignore
                      cropperRef.current.cropper.reset();
                    }}
                    style={{ marginLeft: '6px' }}
                  >
                    <RotateLeftIcon style={{ marginRight: '6px' }} />
                    Reset
                  </Button>
                </Box>

                <Box width="50%" display="flex" justifyContent="flex-end">
                  <Button
                    style={{ marginRight: '10px' }}
                    onClick={() => props.handleClose()}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="secondary"
                    style={{ boxShadow: 'none' }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        style={{
                          position: 'absolute',
                          left: '50%',
                          marginLeft: '-8px',
                        }}
                        size={16}
                      />
                    )}
                    Upload media
                  </Button>
                </Box>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(AddCategoryDialog);
