import React, { SyntheticEvent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import Checkbox from '@material-ui/core/Checkbox';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';

import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { lighten, makeStyles, useTheme, alpha } from '@material-ui/core/styles';

import feathers from '../../modules/feathers';
import { DialogActions } from '@material-ui/core';

const useSearchStyles = makeStyles((theme) => ({
  title: {
    flex: '1 1 100%',
  },
  search: {
    marginTop: '24px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: '8px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    width: '100%',
  },
  table: {
    border: '1px solid #eee',
    marginTop: '1em',
    // minWidth?: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'scroll',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  hiddenBelowSm: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

function SelectMediaDialog(props: any) {
  const classes = useSearchStyles();
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');

  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [media, setMedia] = useState({ data: [] });
  const [selectedMediaId, setSelectedMediaId] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState({});

  const currentSelectedVariant =
    props.values.variants[props.currentSelectedVariant];

  const currentSelectedVariantIndex = props.currentSelectedVariant + 1;

  useEffect(() => {
    feathers
      .service('media')
      .find({
        query: {
          $sort: {
            createdAt: -1,
          },
        },
      })
      .then((res: any) => {
        setMedia(res);
      });
  }, []);

  const assignAndClose = () => {
    console.log(
      //@ts-ignore
      selectedMedia.transforms,
      `variants.${props.currentSelectedVariant}.largeImageURL`
    );
    props.setFieldValue(
      `variants.${props.currentSelectedVariant}.largeImageURL`,
      //@ts-ignore
      selectedMedia.transforms[0].path
    );
    props.setFieldValue(
      `variants.${props.currentSelectedVariant}.thumbnailURL`,
      //@ts-ignore
      selectedMedia.transforms[1].path
    );
    props.handleClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Assign media for{' '}
            {currentSelectedVariant.name !== ''
              ? currentSelectedVariant.name
              : currentSelectedVariant.value !== '' &&
                currentSelectedVariant.unit !== ''
              ? `${currentSelectedVariant.value} ${currentSelectedVariant.unit}`
              : `Variant ${currentSelectedVariantIndex}`}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.handleClose()}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>

      <DialogContent>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search media"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            style={{
              width: '100%',
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>

        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size="small"
        >
          <TableBody>
            {media.data &&
              media.data.map((row: any, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    onClick={() => {
                      setSelectedMediaId(row._id);
                      setSelectedMedia(row);
                    }}
                    hover
                    tabIndex={-1}
                    key={row._id}
                    style={{
                      background:
                        selectedMediaId === row._id ? '#2196f3' : '#fff',
                    }}
                  >
                    <TableCell
                      padding="normal"
                      style={{
                        width: '15%',
                        padding: '12px',
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <img
                          style={{
                            borderRadius: '4px',
                            maxWidth: '100%',
                          }}
                          src={`https://s3.amazonaws.com/vittle-new/${row.transforms[1].path}`}
                        />
                      </Box>
                    </TableCell>
                    <TableCell
                      scope="row"
                      style={{
                        cursor: 'pointer',
                        width: '80%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            fontSize: '1rem',
                            marginTop: '4px',
                            color:
                              selectedMediaId === row._id ? '#fff' : 'inherit',
                          }}
                        >
                          {row.name.split('.')[0]}
                        </Typography>

                        <DoneIcon
                          style={{
                            opacity: selectedMediaId === row._id ? 1 : 0,
                            color: '#fff',
                          }}
                        />
                      </div>
                    </TableCell>

                    <TableCell
                      className={classes.hiddenBelowSm}
                      style={{ cursor: 'pointer', width: '20%' }}
                      align="left"
                      onClick={(event) =>
                        props.history.push(`/product/${row._id}/edit`)
                      }
                    >
                      {/* Starting at ${row.variants[0].price} */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>

        {/* <Grid container>
          {media.data &&
            media.data.map((e: any, i: number) => (
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  marginTop: '1em',
                  cursor: 'pointer',
                  borderBottom:
                    i == media.data.length - 1 ? 0 : '1px solid #eee',
                  background: selectedMedia === e._id ? '#2196f3' : '#fff',
                }}
                onClick={() => setSelectedMedia(e._id)}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingX={1}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    paddingY={1}
                  >
                    <img
                      width="64"
                      style={{
                        marginRight: '20px',
                      }}
                      src={`https://s3.amazonaws.com/vittle-new/${e.transforms[1].path}`}
                    />
                    <Typography
                      style={{
                        color: selectedMedia === e._id ? '#fff' : '#000',
                      }}
                      gutterBottom
                      variant="body1"
                      component="h2"
                    >
                      {e.name.split('.')[0]}
                    </Typography>
                  </Box>

                </Box>
              </Grid>
            ))} 
        </Grid>*/}
      </DialogContent>

      <DialogActions style={{ padding: '30px 24px' }}>
        <Button
          style={{ marginRight: '10px' }}
          onClick={() => props.handleClose()}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          style={{ boxShadow: 'none' }}
          onClick={() => assignAndClose()}
          // disabled={selectedMedia == false}
        >
          {/* {isSubmitting && (
              <CircularProgress
                style={{
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-8px',
                }}
                size={16}
              />
            )} */}
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(SelectMediaDialog);
