import { combineReducers } from '@reduxjs/toolkit';

import activitiesReducer from './slices/activities/activitiesSlice';
import customersReducer from './slices/customers/customersSlice';
import customerReducer from './slices/customer/customerSlice';
import subscriptionReducer from './slices/subscription/subscriptionSlice';
import categoriesReducer from './slices/categories/categoriesSlice';
import ingredientsReducer from './slices/ingredients/ingredientsSlice';
import productsReducer from './slices/products/productsSlice';
import organizationReducer from './slices/organization/organizationSlice';
import mediaReducer from './slices/media/mediaSlice';
import ordersSlice from './slices/orders/ordersSlice';
import postalCodesSlice from './slices/postal-codes/postalCodesSlice';
import usersSlice from './slices/users/usersSlice';
import authSlice from './slices/auth/authReducer';

const rootReducer = combineReducers({
  activities: activitiesReducer,
  customers: customersReducer,
  customer: customerReducer,
  subscription: subscriptionReducer,
  categories: categoriesReducer,
  ingredients: ingredientsReducer,
  products: productsReducer,
  organization: organizationReducer,
  media: mediaReducer,
  orders: ordersSlice,
  postalCodes: postalCodesSlice,
  users: usersSlice,
  auth: authSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
