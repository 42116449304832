/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { result } from 'lodash';
import feathers from '../../modules/feathers';

export default {
  create: () => {},

  find: (query: object | null = null) => {
    if (query) {
      return feathers.service('orders').find({
        query: query,
      });
    }

    return feathers.service('orders').find();
  },

  get: (id: string | null, params: any = undefined) => {
    console.log('Pram.ssssss', params, id);
    if (params) {
      return feathers.service('orders').get(id, params);
    }

    return feathers.service('orders').get(id);
  },

  patch: (id: string, data: any) => {
    return feathers.service('orders').patch(id, data);
  },

  // confirmPayment: async (data: any) => {
  //   const result = await axios.post(
  //     process.env.REACT_APP_API_URL + '/confirmPayment',
  //     data
  //   );
  //   return result;
  // },
};
