import feathers from '../../modules/feathers';

export function create(customer: Object) {
  return feathers.service('users').create(customer);
}

export function getCustomer(customerId?: String) {
  return feathers.service('users').get(customerId);
}

export function getSecondaryCustomers(primaryAccountId: String) {
  return feathers.service('users').find({
    query: {
      primaryAccountId,
    },
  });
}

export function getCustomersTable(
  filters: [Object] | undefined,
  searchText: String | undefined
) {
  let query = {};
  // asd
  if (searchText) {
    //@ts-ignore
    query = {
      search: searchText,
    };
  }
  return feathers.service('users').find({ query });
}

export function updateNote(customerId: String, notes: String) {
  return feathers.service('users').patch(customerId, {
    notes,
  });
}

export function updateAddress(
  id: string,
  address: [Object] | any[],
  queryData: object
) {
  return feathers.service('users').patch(
    id,
    {
      address,
    },
    {
      query: {
        ...queryData,
      },
    }
  );
}

export function updateContactDetails(
  customerId: String,
  contactDetails: Object
) {
  return feathers.service('users').patch(customerId, {
    ...contactDetails,
  });
}

export function updateCustomerSecondaryAccountsId(
  primaryAccountId: String,
  idToPush: String
) {
  return feathers.service('users').patch(primaryAccountId, {
    $push: {
      secondaryAccounts: idToPush,
    },
  });
}

export async function fetchSecondaryCustomers(_id: String) {
  console.log(_id);
  const secondaryAccounts = await feathers.service('users').find({
    query: {
      primaryAccountId: _id,
    },
  });

  console.log(secondaryAccounts);
  return secondaryAccounts.data;
}
