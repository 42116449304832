import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Formik, FieldArray, FastField, ErrorMessage } from 'formik';
import { VariantType, useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import ImageIcon from '@material-ui/icons/Image';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import EditIcon from '@material-ui/icons/Edit';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';

import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchCategories } from '../../slices/categories/categoriesSlice';

import './Ingredients.css';
import Tooltip from '@material-ui/core/Tooltip';
import { addIngredient } from '../../slices/ingredients/ingredientsSlice';
import { useDispatch, useSelector } from 'react-redux';
import IngredientSubIngredient from './IngredientSubIngredient';
import { RootState } from '../../rootReducer';

export default function NewIngredient(props: any) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [productInfoLang, setProductInfoLang] = useState(0);
  const [pricingLang, setPricingLang] = useState(0);

  const categories: any = useSelector(
    (state: RootStateOrAny) => state.categories.categories
  );

  const categoriesLoading: any = useSelector(
    (state: RootStateOrAny) => state.categories.categoriesLoading
  );

  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
            }}
          >
            <Link style={{ textDecoration: 'none' }} to="/">
              <Button style={{ textTransform: 'capitalize' }}>
                <KeyboardArrowLeftIcon /> <Typography>Dashboard</Typography>
              </Button>
            </Link>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          title: '',
          titleFR: '',
          FoodDescription: '',
          FoodDescriptionFR: '',
          type: 'Custom',
          category: '',
          subingredients: [],
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title is required'),
          titleFR: Yup.string(),
          FoodDescription: Yup.string(),
          FoodDescriptionFR: Yup.string(),
          type: Yup.string().required('Type is required'),
          category: Yup.string(),
          subingredients: Yup.array().of(
            Yup.object().shape({
              _id: Yup.string().required(),
              title: Yup.string(),
              FoodDescription: Yup.string(),
              unit: Yup.string(),
              value: Yup.string(),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);

          dispatch(
            addIngredient(
              values,
              () => {
                setSubmitting(false);
                enqueueSnackbar(values.title + ' added successfully', {
                  variant: 'success',
                });
              },
              (error: any) => {
                console.log('update ingredient failed');
                console.log(error);
                setSubmitting(false);
                enqueueSnackbar('There was an error updating ' + values.title);
              },
              props.history
            )
          );
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {console.log(errors)}
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: '20px 0 40px' }}
            >
              <Box
                fontWeight="fontWeightMedium"
                fontSize="h4.fontSize"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  mb={2}
                  flex={1}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    mb={2}
                    flex={'1 0 100%'}
                  >
                    <Box display="flex" flexDirection="column" flex={1}>
                      {values.title.length > 0
                        ? values.title
                        : 'Ingredient name'}
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    style={{ marginRight: '1em' }}
                  >
                    Save
                    <CloudIcon
                      style={{ fill: '#9b9b9b', marginLeft: '10px' }}
                    />
                  </Button>
                </Box>
              </Box>

              <Box></Box>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Paper style={{ padding: '0 24px 0', marginBottom: '2em' }}>
                  <Tabs
                    style={{
                      marginBottom: '2em',
                    }}
                    value={productInfoLang}
                    onChange={(ev: any, val: any) => setProductInfoLang(val)}
                    indicatorColor="primary"
                  >
                    <Tab label="English" />
                    <Tab label="French" />
                  </Tabs>
                  <Typography variant="h6">Ingredient information</Typography>

                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Product name"
                    label="Title"
                    margin="normal"
                    value={
                      productInfoLang === 0 ? values.title : values.titleFR
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      productInfoLang === 0
                        ? errors.title && touched.title
                          ? true
                          : false
                        : errors.titleFR && touched.titleFR
                        ? true
                        : false
                    }
                    helperText={
                      productInfoLang === 0
                        ? errors.title && touched.title && errors.title
                        : errors.titleFR && touched.titleFR && errors.titleFR
                    }
                    InputProps={{
                      name: productInfoLang === 0 ? 'title' : 'titleFR',
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{ textAlign: 'right', color: '#9b9b9b' }}
                  >
                    {values.title.length > 2 &&
                      values.title.length + ' characters'}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name={
                      productInfoLang === 0
                        ? 'FoodDescription'
                        : 'FoodDescriptionFR'
                    }
                    placeholder="Food description"
                    label="Food description"
                    margin="normal"
                    value={
                      productInfoLang === 0
                        ? values.FoodDescription
                        : values.FoodDescriptionFR
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      productInfoLang === 0
                        ? errors.FoodDescription && touched.FoodDescription
                          ? true
                          : false
                        : errors.FoodDescriptionFR && touched.FoodDescriptionFR
                        ? true
                        : false
                    }
                    helperText={
                      productInfoLang === 0
                        ? errors.FoodDescription &&
                          touched.FoodDescription &&
                          errors.FoodDescription
                        : errors.FoodDescriptionFR &&
                          touched.FoodDescriptionFR &&
                          errors.FoodDescriptionFR
                    }
                    InputProps={{
                      name:
                        productInfoLang === 0
                          ? 'FoodDescription'
                          : 'FoodDescriptionFR',
                    }}
                  />
                  {/* <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Cooking losses</Typography>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Refuse amount
                  </Typography>

                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    42%
                  </Typography>
                </Box>
                <div
                  style={{
                    borderTop: '1px solid rgba(0,0,0,0.12)',
                    margin: '8px -0 8px',
                  }}
                ></div>
                <Box
                  display="flex"
                  borderBottom="1px solid rgba(0,0,0,0.12)"
                  width="100%"
                  paddingBottom="8px"
                  marginBottom="8px"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">Bone</Typography>

                  <Typography variant="body1">37%</Typography>
                </Box>

                <Box display="flex" width="100%" justifyContent="space-between">
                  <Typography variant="body1">Skin</Typography>

                  <Typography variant="body1">5%</Typography>
                </Box>
              </Grid>
            </Grid> */}

                  {/* <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px -24px 24px',
                    }}
                  ></div>

                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Inventory</Typography>
                  </Grid>

                  <Grid container style={{ marginBottom: '16px' }}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={<Checkbox name="trackQty" />}
                        label="Track quantity"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={<Checkbox name="sellWhenOutOfStock" />}
                        label="Continue selling when out of stock"
                      />
                    </Grid>
                  </Grid>
*/}
                  <div
                    style={{
                      borderTop: '1px solid transparent',
                      margin: '24px -24px 24px',
                    }}
                  ></div>
                </Paper>
                {/* 
                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px -24px 24px',
                    }}
                  ></div>
                </Paper> */}

                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <FieldArray
                    name="subingredients"
                    render={(arrayHelpers) => (
                      <>
                        <Grid
                          style={{ marginBottom: '1em' }}
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h6">Sub ingredients</Typography>
                          <Button
                            onClick={() => {
                              arrayHelpers.push({
                                _id: '',
                                title: '',
                                ingredientObject: null,
                                FoodDescription: '',
                                value: [0],
                                unit: '',
                              });
                            }}
                          >
                            <AddBoxIcon
                              style={{
                                marginRight: '8px',
                                color: 'rgba(0, 0, 0, 0.54)',
                              }}
                            />
                            Add sub ingredient
                          </Button>
                        </Grid>

                        {values.subingredients.length === 0 && (
                          <Typography style={{ marginTop: '30px' }}>
                            No sub ingredients added
                          </Typography>
                        )}

                        {values.subingredients.map(
                          (ingredient: any, index: number) => (
                            <IngredientSubIngredient
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              ingredient={ingredient}
                              setFieldValue={setFieldValue}
                              values={values}
                              arrayHelpers={arrayHelpers}
                              index={index}
                            />
                          )
                        )}
                      </>
                    )}
                  />

                  <div
                    style={{
                      borderTop: '1px solid transparent',
                      margin: '16px 0 0',
                    }}
                  ></div>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Category</Typography>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      {!categoriesLoading && categories && (
                        <TextField
                          margin="normal"
                          fullWidth
                          select
                          variant="outlined"
                          label="Select a  category"
                          value={values.category}
                          name="Category"
                          onChange={(ev: any) =>
                            setFieldValue('category', ev.target.value)
                          }
                          onBlur={handleBlur}
                          error={
                            errors.category && touched.category ? true : false
                          }
                          helperText={
                            errors.category &&
                            touched.category &&
                            errors.category
                          }
                        >
                          {categories.data
                            .filter((e: any) => e.type === 'Ingredients')
                            .map((e: any) => (
                              <MenuItem value={e.name}>{e.name}</MenuItem>
                            ))}
                        </TextField>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
                {/*
            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6">Tags</Typography>
                <Typography
                  variant="body2"
                  style={{ color: '#999', marginTop: 0 }}
                >
                  1 auto tag
                </Typography>
              </div>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Chip
                  label="Gluten free"
                  style={{ marginTop: '10px' }}
                  onDelete={() => null}
                />
              </Grid>
            </Grid>
          </Paper> */}

                {/* <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '0' }}
              container
              direction="row"
              alignItems="flex-start"
            >
              <div>
                <Typography variant="h6">Nutrition Facts</Typography>
              </div>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="Select unit"
                  placeholder="Volume"
                  value="g"
                >
                  <ListSubheader>Volume</ListSubheader>
                  <MenuItem value="tsp">Teaspoon (tsp)</MenuItem>
                  <MenuItem value="tbsp">Tablespoon (tbsp)</MenuItem>
                  <MenuItem value="floz">Fluid ounce (fl oz)</MenuItem>
                  <MenuItem value="mL">Milliliter (mL)</MenuItem>
                  <MenuItem value="L">Liter (L)</MenuItem>
                  <ListSubheader>Mass and weight</ListSubheader>
                  <MenuItem value="lb">Pound (lb)</MenuItem>
                  <MenuItem value="oz">Ounce (oz)</MenuItem>
                  <MenuItem value="mg">Milligram (mg)</MenuItem>
                  <MenuItem value="g">Gram (g)</MenuItem>
                  <MenuItem value="kg">Kilogram (kg)</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">g</InputAdornment>
                    ),
                  }}
                  margin="normal"
                  fullWidth
                  label="Value"
                  value="100"
                />
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: '10px', marginBottom: '10px' }}>
              <Grid xs={12} md={6}>
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  Amount
                </Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <Typography
                  variant="body1"
                  align="right"
                  style={{ fontWeight: 'bold' }}
                >
                  % Daily Value
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 6px',
              }}
            ></div>

            <Grid container>
              <Grid xs={12} md={6}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Calories</span> 160
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 6px',
              }}
            ></div>

            <Grid container style={{ marginTop: '6px' }}>
              <Grid xs={12} md={6}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Fat</span> 2.5 g
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 6px',
              }}
            ></div>

            <Grid container>
              <Grid xs={12} md={6}>
                <Typography variant="body1" style={{ textIndent: '1em' }}>
                  Saturated 1.5 g
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 6px',
              }}
            ></div>

            <Grid container>
              <Grid xs={12} md={6}>
                <Typography variant="body1" style={{ textIndent: '1em' }}>
                  + Trans 0 g
                </Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <Typography variant="body1" align="right">
                  8%
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 6px',
              }}
            ></div>

            <Grid container style={{ marginTop: '6px' }}>
              <Grid xs={12} md={6}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Cholesterol</span> 10 mg
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 4px',
              }}
            ></div>

            <Grid container style={{ marginTop: '6px' }}>
              <Grid xs={12} md={6}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Sodium</span> 75 mg
                </Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <Typography variant="body1" align="right">
                  3%
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 4px',
              }}
            ></div>

            <Grid container style={{ marginTop: '6px' }}>
              <Grid xs={12} md={6}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Carbohydrate</span> 25 g
                </Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <Typography variant="body1" align="right">
                  8%
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 6px',
              }}
            ></div>

            <Grid container>
              <Grid xs={12} md={6}>
                <Typography variant="body1" style={{ textIndent: '1em' }}>
                  Fibre 0 g
                </Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <Typography variant="body1" align="right">
                  0%
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 6px',
              }}
            ></div>

            <Grid container>
              <Grid xs={12} md={6}>
                <Typography variant="body1" style={{ textIndent: '1em' }}>
                  Sugars 24 g
                </Typography>
              </Grid>

              <Grid xs={12} md={6}>
                <Typography variant="body1" align="right">
                  0%
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 6px',
              }}
            ></div>

            <Grid container style={{ marginTop: '6px' }}>
              <Grid xs={12} md={6}>
                <Typography variant="body1">
                  <span style={{ fontWeight: 'bold' }}>Protein</span> 8 g
                </Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 4px',
              }}
            ></div>

            <Grid container style={{ marginTop: '6px' }}>
              <Grid xs={12} md={6}>
                <Grid container>
                  <Grid xs={12} md={6}>
                    <Typography variant="body1">Vitamin A</Typography>
                  </Grid>

                  <Grid xs={12} md={6}>
                    <Typography variant="body1" align="right">
                      2%
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12} md={6}>
                <Grid container>
                  <Grid xs={12} md={8}>
                    <Typography variant="body1" style={{ paddingLeft: '16px' }}>
                      Vitamin C
                    </Typography>
                  </Grid>
                  <Grid xs={12} md={4}>
                    <Typography variant="body1" align="right">
                      0%
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid #ddd',
                margin: '6px 0 6px',
              }}
            ></div>

            <Grid container>
              <Grid xs={12} md={6}>
                <Grid container>
                  <Grid xs={12} md={6}>
                    <Typography variant="body1">Calcium</Typography>
                  </Grid>

                  <Grid xs={12} md={6}>
                    <Typography variant="body1" align="right">
                      20%
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12} md={6}>
                <Grid container>
                  <Grid xs={12} md={8}>
                    <Typography variant="body1" style={{ paddingLeft: '16px' }}>
                      Iron
                    </Typography>
                  </Grid>
                  <Grid xs={12} md={4}>
                    <Typography variant="body1" align="right">
                      0%
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper> */}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
}
