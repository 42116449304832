import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, FieldArray, FastField, ErrorMessage } from 'formik';
import { VariantType, useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import ImageIcon from '@material-ui/icons/Image';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import EditIcon from '@material-ui/icons/Edit';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';

import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  fetchIngredient,
  patchIngredient,
  unloadIngredient,
} from '../../slices/ingredients/ingredientsSlice';

import './Ingredients.css';
import Tooltip from '@material-ui/core/Tooltip';
import { RootState } from '../../rootReducer';
import { CircularProgress } from '@material-ui/core';
import IngredientSubIngredient from './IngredientSubIngredient';
import { fetchCategories } from '../../slices/categories/categoriesSlice';

export default function Ingredients(props: any) {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const ingredient: any = useSelector(
    (state: RootStateOrAny) => state.ingredients.ingredient
  );

  const ingredientLoading = useSelector(
    (state: RootStateOrAny) => state.ingredients.ingredientLoading
  );

  const categories: any = useSelector(
    (state: RootStateOrAny) => state.categories.categories
  );

  const categoriesLoading: any = useSelector(
    (state: RootStateOrAny) => state.categories.categoriesLoading
  );

  const [productInfoLang, setProductInfoLang] = useState(0);
  const [pricingLang, setPricingLang] = useState(0);

  const [selectedUnit, setSelectedUnit] = useState('g');
  const [value, setValue] = useState('100');

  const [additionalNutrientsSectionIn, setAdditionalNutrientsSectionIn] =
    useState(true);

  const id = props.match.params.id;

  useEffect(() => {
    console.log(id);
    dispatch(fetchIngredient(id));
    dispatch(fetchCategories());

    return () => {
      dispatch(unloadIngredient());
    };
  }, []);

  if (ingredientLoading) {
    return <CircularProgress />;
  }

  const renderUnit = (unit: string) => {
    if (unit === 'ml') {
      return <MenuItem value={unit}>Millilitre (ml)</MenuItem>;
    }

    if (unit === 'g') {
      return <MenuItem value={unit}>Gram (g)</MenuItem>;
    }

    if (unit === 'quantity') {
      return <MenuItem value={unit}>Quantity</MenuItem>;
    }
  };

  return (
    <Container>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          title: ingredient.title || '',
          titleFR: ingredient.titleFR || '',
          FoodDescription: ingredient.FoodDescription || '',
          FoodDescriptionFR: ingredient.FoodDescriptionFR || '',
          category: ingredient.category || '',
          subCategory: ingredient.subCategory || '',
          subingredients: ingredient.subingredients || [],
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title is required'),
          titleFR: Yup.string(),
          FoodDescription: Yup.string(),
          FoodDescriptionFR: Yup.string(),
          category: Yup.string(),
          subingredients: Yup.array().of(
            Yup.object().shape({
              _id: Yup.string().required(),
              title: Yup.string(),
              FoodDescription: Yup.string(),
              unit: Yup.string(),
              value: Yup.string(),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);
          console.log('In values');

          dispatch(
            patchIngredient(
              props.match.params.id,
              {
                title: values.title,
                titleFR: values.titleFR,
                FoodDescription: values.FoodDescription,
                FoodDescriptionFR: values.FoodDescriptionFR,
                category: values.category,
                subingredients: values.subingredients,
              },
              () => {
                setSubmitting(false);
                enqueueSnackbar(values.title + ' updated successfully');
              },
              (error: any) => {
                console.log('update ingredient failed');
                console.log(error);
                setSubmitting(false);
                enqueueSnackbar('There was an error updating ' + values.title);
              },
              props.history
            )
          );
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            {console.log(values)}
            <Grid container>
              {console.log(errors)}
              <Grid item xs={12} md={8}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{
                    marginTop: '1em',
                  }}
                >
                  <Link style={{ textDecoration: 'none' }} to="/">
                    <Button style={{ textTransform: 'capitalize' }}>
                      <Typography>Dashboard</Typography>
                    </Button>
                  </Link>

                  <Link style={{ textDecoration: 'none' }} to="/ingredients">
                    <Button style={{ textTransform: 'capitalize' }}>
                      <Typography>Ingredients</Typography>
                    </Button>
                  </Link>

                  <Typography>
                    {ingredient.title || ingredient.FoodDescription}
                  </Typography>
                </Breadcrumbs>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: '20px 0 40px' }}
            >
              <Box
                fontWeight="fontWeightMedium"
                fontSize="h4.fontSize"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  mb={2}
                  flex={1}
                >
                  <Grid item xs={8}>
                    <Box
                      display="flex"
                      alignItems="start"
                      flexDirection="column"
                      mb={2}
                    >
                      <Box display="flex" flexDirection="column" flex={1}>
                        {values.title.length > 0
                          ? values.title
                          : 'Ingredient name'}
                        <br />
                        <Typography> {values.FoodDescription || ''}</Typography>
                      </Box>

                      {ingredient.hasOwnProperty('FoodCode') &&
                        ingredient.type !== 'Custom' && (
                          <Typography
                            variant="body1"
                            style={{ marginTop: '8px' }}
                            color="textSecondary"
                          >
                            Food Code: {ingredient.FoodCode}
                          </Typography>
                        )}
                    </Box>
                  </Grid>
                </Box>

                <Box>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    style={{ marginRight: '1em' }}
                  >
                    Save
                    <CloudIcon
                      style={{ fill: '#9b9b9b', marginLeft: '10px' }}
                    />
                  </Button>
                </Box>
              </Box>

              <Box>
                <Button size="small" style={{ fontWeight: 400 }}>
                  <DeleteIcon style={{ fill: '#9b9b9b', marginRight: '5px' }} />{' '}
                  Delete
                </Button>
              </Box>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Paper style={{ padding: '0 24px 0', marginBottom: '2em' }}>
                  <Tabs
                    style={{
                      marginBottom: '2em',
                    }}
                    value={productInfoLang}
                    onChange={(ev: any, val: any) => setProductInfoLang(val)}
                    indicatorColor="primary"
                  >
                    <Tab label="English" />
                    <Tab label="French" />
                  </Tabs>
                  <Typography variant="h6">Ingredient information</Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Product name"
                    label="Title"
                    margin="normal"
                    value={
                      productInfoLang === 0 ? values.title : values.titleFR
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      productInfoLang === 0
                        ? errors.title && touched.title
                          ? true
                          : false
                        : errors.titleFR && touched.titleFR
                        ? true
                        : false
                    }
                    helperText={
                      productInfoLang === 0
                        ? errors.title && touched.title && errors.title
                        : errors.titleFR && touched.titleFR && errors.titleFR
                    }
                    InputProps={{
                      name: productInfoLang === 0 ? 'title' : 'titleFR',
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{ textAlign: 'right', color: '#9b9b9b' }}
                  >
                    {values.title.length > 2 &&
                      values.title.length + ' characters'}
                  </Typography>

                  <TextField
                    variant="outlined"
                    fullWidth
                    name={
                      productInfoLang === 0
                        ? 'FoodDescription'
                        : 'FoodDescriptionFR'
                    }
                    placeholder="Food description"
                    label="Food description"
                    margin="normal"
                    value={
                      productInfoLang === 0
                        ? values.FoodDescription
                        : values.FoodDescriptionFR
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      productInfoLang === 0
                        ? errors.FoodDescription && touched.FoodDescription
                          ? true
                          : false
                        : errors.FoodDescriptionFR && touched.FoodDescriptionFR
                        ? true
                        : false
                    }
                    helperText={
                      productInfoLang === 0
                        ? errors.FoodDescription &&
                          touched.FoodDescription &&
                          errors.FoodDescription
                        : errors.FoodDescriptionFR &&
                          touched.FoodDescriptionFR &&
                          errors.FoodDescriptionFR
                    }
                    InputProps={{
                      name:
                        productInfoLang === 0
                          ? 'FoodDescription'
                          : 'FoodDescriptionFR',
                    }}
                  />
                  {/* <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Cooking losses</Typography>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Refuse amount
                  </Typography>

                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    42%
                  </Typography>
                </Box>
                <div
                  style={{
                    borderTop: '1px solid rgba(0,0,0,0.12)',
                    margin: '8px -0 8px',
                  }}
                ></div>
                <Box
                  display="flex"
                  borderBottom="1px solid rgba(0,0,0,0.12)"
                  width="100%"
                  paddingBottom="8px"
                  marginBottom="8px"
                  justifyContent="space-between"
                >
                  <Typography variant="body1">Bone</Typography>

                  <Typography variant="body1">37%</Typography>
                </Box>

                <Box display="flex" width="100%" justifyContent="space-between">
                  <Typography variant="body1">Skin</Typography>

                  <Typography variant="body1">5%</Typography>
                </Box>
              </Grid>
            </Grid> */}

                  {/* <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px -24px 24px',
                    }}
                  ></div>

                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Inventory</Typography>
                  </Grid>

                  <Grid container style={{ marginBottom: '16px' }}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={<Checkbox name="trackQty" />}
                        label="Track quantity"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={<Checkbox name="sellWhenOutOfStock" />}
                        label="Continue selling when out of stock"
                      />
                    </Grid>
                  </Grid>
*/}
                  <div
                    style={{
                      borderTop: '1px solid transparent',
                      margin: '24px -24px 24px',
                    }}
                  ></div>
                </Paper>

                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <FieldArray
                    name="subingredients"
                    render={(arrayHelpers) => (
                      <>
                        <Grid
                          style={{ marginBottom: '1em' }}
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h6">Sub ingredients</Typography>
                          <Button
                            onClick={() => {
                              arrayHelpers.push({
                                _id: '',
                                title: '',
                                ingredientObject: null,
                                FoodDescription: '',
                                value: [0],
                                unit: '',
                              });
                            }}
                          >
                            <AddBoxIcon
                              style={{
                                marginRight: '8px',
                                color: 'rgba(0, 0, 0, 0.54)',
                              }}
                            />
                            Add sub ingredient
                          </Button>
                        </Grid>

                        {values.subingredients.length === 0 && (
                          <Typography style={{ marginTop: '30px' }}>
                            No sub ingredients added
                          </Typography>
                        )}

                        {values.subingredients.map(
                          (ingredient: any, index: number) => (
                            <IngredientSubIngredient
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              ingredient={ingredient}
                              setFieldValue={setFieldValue}
                              values={values}
                              arrayHelpers={arrayHelpers}
                              index={index}
                            />
                          )
                        )}
                      </>
                    )}
                  />

                  <div
                    style={{
                      borderTop: '1px solid transparent',
                      margin: '16px 0 0',
                    }}
                  ></div>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Category</Typography>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      {!categoriesLoading &&
                        categories &&
                        ingredient.type === 'Custom' && (
                          <TextField
                            margin="normal"
                            fullWidth
                            select
                            variant="outlined"
                            label="Select a  category"
                            value={values.category}
                            defaultValue={values.category}
                            name="category"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.category && touched.category ? true : false
                            }
                            helperText={
                              errors.category &&
                              touched.category &&
                              errors.category
                            }
                          >
                            {categories.data
                              .filter((e: any) => e.type === 'Ingredients')
                              .map((e: any) => (
                                <MenuItem value={e.name}>{e.name}</MenuItem>
                              ))}
                          </TextField>
                        )}

                      {ingredient.type === 'CNF' && (
                        <Typography variant="body1">
                          {ingredient.category}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Paper>

                {/*
              <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6">Tags</Typography>
                <Typography
                  variant="body2"
                  style={{ color: '#999', marginTop: 0 }}
                >
                  1 auto tag
                </Typography>
              </div>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Chip
                  label="Gluten free"
                  style={{ marginTop: '10px' }}
                  onDelete={() => null}
                />
              </Grid>
            </Grid>
          </Paper> */}
                {ingredient.hasOwnProperty('nutrientInfo') &&
                  ingredient.type !== 'Custom' && (
                    <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                      <Grid
                        style={{ marginBottom: '0' }}
                        container
                        direction="row"
                        alignItems="flex-start"
                      >
                        <div>
                          <Typography variant="h6">Nutrition Facts</Typography>
                        </div>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            margin="normal"
                            fullWidth
                            select
                            label="Select unit"
                            placeholder="Volume"
                            // defaultValue={renderUnit(
                            //   ingredient.units.sort()[0]
                            // )}
                            value={selectedUnit}
                            onChange={(e: any) => {
                              setSelectedUnit(e.target.value);
                            }}
                          >
                            {ingredient.units
                              .sort()
                              .map((e: string) => renderUnit(e))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <TextField
                            // InputProps={{
                            //   endAdornment: (
                            //     // <InputAdornment position="start">g</InputAdornment>
                            //   ),
                            // }}
                            margin="normal"
                            fullWidth
                            label="Value"
                            onChange={(e: any) => setValue(e.target.value)}
                            value={value}
                            type="number"
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        <Grid xs={12} md={6}>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 'bold' }}
                          >
                            Amount
                          </Typography>
                        </Grid>

                        <Grid xs={12} md={6}>
                          <Typography
                            variant="body1"
                            align="right"
                            style={{ fontWeight: 'bold' }}
                          >
                            % Daily Value
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 6px',
                        }}
                      ></div>

                      <Grid container>
                        <Grid xs={12} md={6}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>Calories</span>{' '}
                            {(
                              ingredient.nutrientInfo.energy.NutrientValue *
                              (parseFloat(value) / 100)
                            ).toFixed(2)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 6px',
                        }}
                      ></div>

                      <Grid container style={{ marginTop: '6px' }}>
                        <Grid xs={12} md={6}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>Fat</span>{' '}
                            {(
                              ingredient.nutrientInfo.fat.NutrientValue *
                              (parseFloat(value) / 100)
                            ).toFixed(2)}{' '}
                            g
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 6px',
                        }}
                      ></div>

                      <Grid container>
                        <Grid xs={12} md={6}>
                          <Typography
                            variant="body1"
                            style={{ textIndent: '1em' }}
                          >
                            Saturated{' '}
                            {(
                              ingredient.nutrientInfo.saturatedFat
                                .NutrientValue *
                              (parseFloat(value) / 100)
                            ).toFixed(2)}{' '}
                            g
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 6px',
                        }}
                      ></div>

                      <Grid container>
                        <Grid xs={12} md={6}>
                          <Typography
                            variant="body1"
                            style={{ textIndent: '1em' }}
                          >
                            {ingredient.nutrientInfo.hasOwnProperty(
                              'transFat'
                            ) ? (
                              <>
                                {console.log(ingredient.nutrientInfo.transFat)}+
                                Trans{' '}
                                {(
                                  ingredient.nutrientInfo.transFat
                                    .NutrientValue *
                                  (parseFloat(value) / 100)
                                ).toFixed(2)}
                              </>
                            ) : (
                              '0'
                            )}
                            g
                          </Typography>
                        </Grid>

                        <Grid xs={12} md={6}>
                          <Typography variant="body1" align="right">
                            N/A
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 6px',
                        }}
                      ></div>

                      <Grid container style={{ marginTop: '6px' }}>
                        <Grid xs={12} md={6}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>
                              Cholesterol
                            </span>{' '}
                            {(
                              ingredient.nutrientInfo.cholesterol
                                .NutrientValue *
                              (parseFloat(value) / 100)
                            ).toFixed(2)}{' '}
                            mg
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 4px',
                        }}
                      ></div>

                      <Grid container style={{ marginTop: '6px' }}>
                        <Grid xs={12} md={6}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>Sodium</span>{' '}
                            {(
                              ingredient.nutrientInfo.sodium.NutrientValue *
                              (parseFloat(value) / 100)
                            ).toFixed(2)}{' '}
                            mg
                          </Typography>
                        </Grid>

                        <Grid xs={12} md={6}>
                          <Typography variant="body1" align="right">
                            3%
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 4px',
                        }}
                      ></div>

                      <Grid container style={{ marginTop: '6px' }}>
                        <Grid xs={12} md={6}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>
                              Carbohydrate
                            </span>{' '}
                            {(
                              ingredient.nutrientInfo.carbohydrate
                                .NutrientValue *
                              (parseFloat(value) / 100)
                            ).toFixed(2)}{' '}
                            g
                          </Typography>
                        </Grid>

                        <Grid xs={12} md={6}>
                          <Typography variant="body1" align="right">
                            -
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 6px',
                        }}
                      ></div>

                      <Grid container>
                        <Grid xs={12} md={6}>
                          <Typography
                            variant="body1"
                            style={{ textIndent: '1em' }}
                          >
                            Fibre{' '}
                            {(
                              ingredient.nutrientInfo.fibre.NutrientValue *
                              (parseFloat(value) / 100)
                            ).toFixed(2)}{' '}
                            g
                          </Typography>
                        </Grid>

                        <Grid xs={12} md={6}>
                          <Typography variant="body1" align="right">
                            0%
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 6px',
                        }}
                      ></div>

                      <Grid container>
                        <Grid xs={12} md={6}>
                          <Typography
                            variant="body1"
                            style={{ textIndent: '1em' }}
                          >
                            Sugars{' '}
                            {(
                              ingredient.nutrientInfo.sugars.NutrientValue *
                              (parseFloat(value) / 100)
                            ).toFixed(2)}{' '}
                            g
                          </Typography>
                        </Grid>

                        <Grid xs={12} md={6}>
                          <Typography variant="body1" align="right">
                            0%
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 6px',
                        }}
                      ></div>

                      <Grid container style={{ marginTop: '6px' }}>
                        <Grid xs={12} md={6}>
                          <Typography variant="body1">
                            <span style={{ fontWeight: 'bold' }}>Protein</span>{' '}
                            {(
                              ingredient.nutrientInfo.protein.NutrientValue *
                              (parseFloat(value) / 100)
                            ).toFixed(2)}{' '}
                            g
                          </Typography>
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid #ddd',
                          margin: '6px 0 4px',
                        }}
                      ></div>

                      {/* <Grid container style={{ marginTop: '6px' }}>
                      <Grid xs={12} md={6}>
                        <Grid container>
                          <Grid xs={12} md={6}>
                            <Typography variant="body1">Vitamin A</Typography>
                          </Grid>

                          <Grid xs={12} md={6}>
                            <Typography variant="body1" align="right">
                              2%
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid xs={12} md={6}>
                        <Grid container>
                          <Grid xs={12} md={8}>
                            <Typography
                              variant="body1"
                              style={{ paddingLeft: '16px' }}
                            >
                              Vitamin C
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={4}>
                            <Typography variant="body1" align="right">
                              0%
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <div
                      style={{
                        borderTop: '1px solid #ddd',
                        margin: '6px 0 6px',
                      }}
                    ></div>

                    <Grid container>
                      <Grid xs={12} md={6}>
                        <Grid container>
                          <Grid xs={12} md={6}>
                            <Typography variant="body1">Calcium</Typography>
                          </Grid>

                          <Grid xs={12} md={6}>
                            <Typography variant="body1" align="right">
                              20%
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid xs={12} md={6}>
                        <Grid container>
                          <Grid xs={12} md={8}>
                            <Typography
                              variant="body1"
                              style={{ paddingLeft: '16px' }}
                            >
                              Iron
                            </Typography>
                          </Grid>
                          <Grid xs={12} md={4}>
                            <Typography variant="body1" align="right">
                              0%
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid> */}

                      {/* <Box
              display="flex"
              alignItems="center"
              marginTop={4}
              justifyContent="space-between"
              onClick={() =>
                setAdditionalNutrientsSectionIn(!additionalNutrientsSectionIn)
              }
            >
              <Typography
                variant="body1"
                style={{
                  fontWeight: 'bold',
                }}
              >
                Additional nutrients
              </Typography>

              {additionalNutrientsSectionIn ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </Box>

            <Collapse in={additionalNutrientsSectionIn}>
              <Typography>FRANK</Typography>
            </Collapse> */}
                    </Paper>
                  )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
}
