import { createSlice } from '@reduxjs/toolkit';
import {
  getIngredientsTable,
  getIngredient,
  patch,
  create,
  search,
} from '../../api/ingredients';

const initialState = {
  error: null,

  ingredient: null,
  ingredientLoading: true,
  ingredientError: null,

  ingredientsLoading: true,
  ingredients: {
    data: [],
    total: 0,
    limit: 0,
    skip: 0,
  },
  // addCustomerLoading: false,
  // addCustomerContactDetailsLoading: false
};

const ingredientsSlice = createSlice({
  name: 'ingredients',
  initialState,
  reducers: {
    fetchIngredientsSuccess: (state, action) => {
      console.log(action);

      state.ingredients.data = action.payload.data
        ? action.payload.data
        : action.payload;

      state.ingredientsLoading = false;
    },
    fetchIngredientsFailed: (state, action) => {
      console.log('Failed fetchingredients');
      console.log(action);
      state.error = action.payload;
    },

    setIngredientLoading: (state, action) => {
      state.ingredientLoading = action.payload;
    },

    setIngredientsLoading: (state, action) => {
      state.ingredientsLoading = action.payload;
    },

    fetchIngredientSuccess: (state, action) => {
      console.log(action);
      state.ingredient = action.payload;
      state.ingredientLoading = false;
    },

    unloadIngredient: (state) => {
      state.ingredient = null;
      state.ingredientLoading = true;
      state.ingredientError = null;
    },
  },
});

export const {
  fetchIngredientsSuccess,
  fetchIngredientsFailed,
  setIngredientLoading,
  fetchIngredientSuccess,
  unloadIngredient,
  setIngredientsLoading,
} = ingredientsSlice.actions;

export default ingredientsSlice.reducer;

export const fetchIngredients =
  (filters: Object | undefined = undefined, searchText: string | undefined) =>
  async (dispatch: Function) => {
    console.log('fetchIngredients');
    try {
      dispatch(setIngredientsLoading(true));
      const i = await getIngredientsTable(filters, searchText);
      console.log(i);
      dispatch(fetchIngredientsSuccess(i));
    } catch (error) {
      console.log('Catch failed Ingredients');
      console.log(error);
      // dispatch(fetchIngredientsFailed(error));
      dispatch(setIngredientsLoading(false));
    }
  };

export const searchIngredients =
  (filters: Object | undefined, searchText: string) =>
  async (dispatch: Function) => {
    console.log('searchIngredient');
    try {
      dispatch(setIngredientsLoading(true));
      const i = await getIngredientsTable(filters, searchText);
      console.log(i);
      dispatch(fetchIngredientsSuccess(i));
    } catch (error) {
      console.log('Catch failed search ingredients');
      console.log(error);
      // dispatch(fetchIngredientsFailed(error));
      dispatch(setIngredientsLoading(false));
    }
  };

export const fetchIngredient = (id: string) => async (dispatch: Function) => {
  console.log('fetchIngredient');
  try {
    dispatch(setIngredientLoading(true));
    const i = await getIngredient(id);
    console.log(i);
    dispatch(fetchIngredientSuccess(i));
  } catch (error) {
    console.log('Catch faild Ingredients');
    console.log(error);
    dispatch(fetchIngredientsFailed(error));
  } finally {
    dispatch(setIngredientLoading(false));
  }
};

export const patchIngredient =
  (
    id: string,
    data: any,
    successFunction: Function,
    errorFunction: Function,
    history: any
  ) =>
  async (dispatch: Function) => {
    console.log('patchIngredient');
    try {
      console.log('before patch');
      
      console.log(data);
      const i = await patch(id, data);
      console.log('after patch');

      console.log(i);
      dispatch(fetchIngredientSuccess(i));

      if (successFunction) {
        successFunction();
      }

      history.push('/ingredients');
    } catch (error) {
      // dispatch(fetchIngredientsFailed(error));

      if (errorFunction) {
        errorFunction(error);
      }
    }
  };

type AddIngredientData = {
  title: string;
  titleFR?: string;
  category?: string;
  subCategory?: string;
  FoodDescription: string;
  FoodDescriptionFR: string;
  subingredients?: any;
};

export const addIngredient =
  (
    data: any,
    successFunction: Function,
    errorFunction: Function,
    history: any
  ) =>
  async (dispatch: Function) => {
    console.log('fetchIngredients');
    try {
      dispatch(setIngredientLoading(true));
      const i = await create(data);
      console.log(i);
      dispatch(fetchIngredientSuccess(i));

      if (successFunction) {
        successFunction();
      }

      history.push('/ingredients');
    } catch (error) {
      dispatch(fetchIngredientsFailed(error));

      if (errorFunction) {
        errorFunction(error);
      }
    } finally {
      dispatch(setIngredientLoading(false));
    }
  };
