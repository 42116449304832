import React, { Ref, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Breadcrumbs,
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ImageIcon from '@material-ui/icons/Image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CropIcon from '@material-ui/icons/Crop';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { lighten, makeStyles, useTheme, alpha } from '@material-ui/core/styles';

import feathers from '../../modules/feathers';
// import { findMedia } from '../../slices/media/mediaSlice';
import { RootState } from '../../rootReducer';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    width: '100%',
  },
  tabs: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: '1px',
  },
  filterChips: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root2: {
    width: '100%',
  },
  media: {
    height: 200,
  },
}));

export default function (props: any) {
  const classes = useToolbarStyles();
  const dispatch = useDispatch();

  const media: any = useSelector((state: RootStateOrAny) => state.media.media);

  const [addMediaDialogOpen, setAddMediaDialogOpen] = useState(false);
  const [deleteMediaDialogOpen, setDeleteMediaDialogOpen] = useState(false);
  const [mediaToDelete, setMediaToDelete] = useState(null);

  const [selectedMedia, setSelectedMedia] = useState();
  const [selectedFileData, setSelectedFileData] = useState('');

  const onChangeSearchText = (event: any) => {
    const searchText = event.target.value;

    // dispatch();
    // findMedia({
    //   name: {
    //     $regex: new RegExp(`${searchText}`, 'ig'),
    //   },
    // })
  };

  // const selectFile = async (event: any) => {
  //   if (!uploadFileInputRef.files[0]) {
  //     return;
  //   }

  //   setSelectedFileData(uploadFileInputRef.files[0]);

  //   const fileReader = new FileReader();
  //   await fileReader.readAsDataURL(uploadFileInputRef.files[0]);

  //   fileReader.onload = (file: any) => {
  //     console.log(file);
  //     setSelectedMedia(file.target.result);
  //     setAddMediaDialogOpen(true);
  //   };
  //   fileReader.onerror = (error: any) => console.log(error);
  // };

  // useEffect(() => {
  //   const image = document.getElementById('image');
  // }, [selectedMedia]);

  useEffect(() => {
    // dispatch(findMedia());
  }, []);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <Link
              to="/"
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Button style={{ textTransform: 'capitalize' }}>
                <Typography>Dashboard</Typography>
              </Button>
            </Link>
            <Typography variant="body1">Labels</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: '40px' }}
      >
        <Grid item>
          <Box fontWeight="fontWeightMedium" fontSize="h4.fontSize" m={1}>
            Labels
          </Box>
          <Box></Box>
        </Grid>

        <Grid item>
          <Button
            style={{
              boxShadow: 'none',
            }}
            variant="contained"
            color="secondary"
            href="/labels/new"
          >
            <AddIcon style={{ marginRight: '6px', marginTop: '-2px' }} />
            New Label
          </Button>
        </Grid>
      </Grid>

      <Paper>
        <Box padding={2}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              onChange={onChangeSearchText}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{
                width: '100%',
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </Box>
      </Paper>

      <Grid container spacing={3} style={{ marginTop: '30px' }}>
        {media.data &&
          media.data.map(
            (e: any) =>
              null && (
                <Grid item xs={6} sm={6} md={3} lg={3}>
                  {console.log(e)}{' '}
                  <Card className={classes.root2}>
                    <CardMedia
                      className={classes.media}
                      image={
                        e.transforms &&
                        `https://s3.amazonaws.com/vittle-new/${e.transforms[1].path}`
                      }
                      title="Contemplative Reptile"
                    />
                    <CardContent style={{ padding: '8px' }}>
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          overflow="hidden"
                        >
                          <ImageIcon style={{ marginRight: '6px' }} />
                          <Typography
                            variant="body2"
                            component="h2"
                            style={{
                              fontWeight: 500,
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              position: 'relative',
                              top: '2px',
                            }}
                          >
                            {e.name.split('.')[0]}
                          </Typography>
                        </Box>

                        <PopupState variant="popover" popupId="demo-popup-menu">
                          {(popupState: any) => (
                            <React.Fragment>
                              <IconButton
                                style={{ padding: '8px', marginRight: '-8px' }}
                                {...bindTrigger(popupState)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                              <Menu {...bindMenu(popupState)}>
                                <MenuItem onClick={popupState.close}>
                                  <CropIcon
                                    fontSize="small"
                                    style={{ marginRight: '4px' }}
                                  />{' '}
                                  Crop
                                </MenuItem>
                                <MenuItem onClick={popupState.close}>
                                  <EditIcon
                                    fontSize="small"
                                    style={{ marginRight: '4px' }}
                                  />
                                  Rename
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setMediaToDelete(e);
                                    popupState.close();
                                  }}
                                >
                                  <DeleteIcon
                                    fontSize="small"
                                    style={{ marginRight: '4px' }}
                                  />
                                  Delete
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              )
          )}
      </Grid>
    </Container>
  );
}
