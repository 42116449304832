import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import EditIcon from '@material-ui/icons/Edit';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';

import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddProductDialog from './AddProductDialog';

import './AddTransfer.css';
import Tooltip from '@material-ui/core/Tooltip';

export default function Dashboard(props: any) {
  // const classes = useStyles();

  const [productInfoLang, setProductInfoLang] = useState(0);
  const [pricingLang, setPricingLang] = useState(0);

  // const [
  //   productAvailabilityDialogOpen,
  //   setProductAvailabilityDialogOpen,
  // ] = useState(false);
  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false);
  // const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);

  const [title, setTitle] = useState('');

  const [variants, setVariants] = useState([{ name: '' }]);

  const reorder = (
    list: Array<object>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: 8,
    width: '100%',
    display: 'flex',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      variants,
      result.source.index,
      result.destination.index
    );

    //@ts-ignore
    setVariants(items);
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
            }}
          >
            <Link style={{ textDecoration: 'none' }} to="/">
              <Button style={{ textTransform: 'capitalize' }}>
                <KeyboardArrowLeftIcon /> <Typography>Dashboard</Typography>
              </Button>
            </Link>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: '20px 0 40px' }}
      >
        <Box
          fontWeight="fontWeightMedium"
          fontSize="h4.fontSize"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <Box display="flex" flexDirection="column">
                Add transfer
              </Box>
            </Box>
          </Box>

          <Box>
            <Button style={{ marginRight: '1em' }}>
              Save
              <CloudIcon style={{ fill: '#9b9b9b', marginLeft: '10px' }} />
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Products</Typography>
              <Button onClick={() => setAddProductDialogOpen(true)}>
                <AddBoxIcon
                  style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }}
                />
                Add product
              </Button>
            </Grid>

            <Grid item xs={12} md={12} sm={12}>
              <TextField
                margin="normal"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <SearchIcon style={{ fill: '#9b9b9b' }} />,
                }}
                label="Search product or ingredient"
              />
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px 24px',
              }}
            ></div>

            <Table aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Qty.</TableCell>
                  <TableCell>Yield</TableCell>
                  <TableCell>Exp.</TableCell>
                  <TableCell>Cost</TableCell>
                  <TableCell>Subtotal</TableCell>
                  <TableCell style={{ borderBottom: 0 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                  // style={
                  //   {
                  //     // overflow: 'hidden',
                  //     // textOverflow: 'ellipsis',
                  //     // // whiteSpace: 'wrap',
                  //   }
                  // }
                  >
                    Beans, legumes, mung (green gram), raw
                    <br />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: '4px' }}
                    >
                      Ingredient
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: '13%' }}>25 lbs.</TableCell>
                  <TableCell style={{ width: '8%' }}>1</TableCell>
                  <TableCell>0%</TableCell>
                  <TableCell>12/20/2020</TableCell>
                  <TableCell>$56.06</TableCell>
                  <TableCell style={{ width: '12%' }}>$56.06</TableCell>
                  <TableCell align="right" style={{ borderBottom: 0 }}>
                    <IconButton style={{ marginRight: '-24px' }}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    Chicken, ground, lean, raw
                    <br />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: '4px' }}
                    >
                      Ingredient
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: '13%' }}>22.27 Kg</TableCell>
                  <TableCell style={{ width: '8%' }}>3</TableCell>
                  <TableCell>0%</TableCell>
                  <TableCell>12/20/2020</TableCell>
                  <TableCell>$29.18</TableCell>
                  <TableCell style={{ width: '12%' }}>$87.54</TableCell>
                  <TableCell align="right" style={{ borderBottom: 0 }}>
                    <IconButton style={{ marginRight: '-24px' }}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      borderBottom: 0,
                    }}
                  >
                    Vinegar Apple Cider
                    <br />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: '4px' }}
                    >
                      Allens
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: '13%', borderBottom: 0 }}>
                    25 L
                  </TableCell>
                  <TableCell style={{ width: '8%', borderBottom: 0 }}>
                    3
                  </TableCell>
                  <TableCell style={{ borderBottom: 0 }}>0%</TableCell>
                  <TableCell style={{ borderBottom: 0 }}></TableCell>
                  <TableCell style={{ borderBottom: 0 }}>$19.98</TableCell>
                  <TableCell style={{ width: '12%', borderBottom: 0 }}>
                    $19.98
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: 0 }}>
                    <IconButton style={{ marginRight: '-24px' }}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Miscellaneous</Typography>
              <Button onClick={() => setAddProductDialogOpen(true)}>
                <AddBoxIcon
                  style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }}
                />
                Add line item
              </Button>
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchIcon style={{ fill: '#9b9b9b' }} />,
                  }}
                  label="Search line item"
                />
              </Grid>
              <Grid item xs={12} md={3} sm={3}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  margin="normal"
                  fullWidth
                  type="number"
                  label="Amount"
                />
              </Grid>
              <Grid item xs={12} md={3} sm={3}>
                <Box display="flex" justifyContent="space-between">
                  <FormControlLabel
                    control={<Checkbox name="taxExempt" />}
                    label="Tax exempt"
                    style={{ marginRight: 0 }}
                  />

                  <IconButton style={{ position: 'relative', right: '-8px' }}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Summary</Typography>
            </Grid>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid container item xs={6}>
                <Grid item xs={12}>
                  <Box justifyContent="space-between" display="flex">
                    <Typography variant="body1">Subtotal</Typography>
                    <Typography>$100</Typography>
                  </Box>
                </Grid>

                <Box
                  borderTop="1px solid #ddd"
                  margin="6px 0 6px"
                  width="100%"
                ></Box>

                <Grid item xs={12}>
                  <Box justifyContent="space-between" display="flex">
                    <Typography variant="body1">Miscellaneous</Typography>
                    <Typography>$0</Typography>
                  </Box>
                </Grid>

                <Box
                  borderTop="1px solid #ddd"
                  margin="6px 0 6px"
                  width="100%"
                ></Box>

                <Grid item xs={12}>
                  <Box justifyContent="space-between" display="flex">
                    <Typography variant="body1">Tax</Typography>
                    <Typography>$13</Typography>
                  </Box>
                </Grid>

                <Box
                  borderTop="1px solid #ddd"
                  margin="6px 0 6px"
                  width="100%"
                ></Box>

                <Grid item xs={12}>
                  <Box justifyContent="space-between" display="flex">
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Total
                    </Typography>
                    <Typography style={{ fontWeight: 'bold' }}>$113</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography variant="h6">Supplier</Typography>
              <div style={{ marginRight: '-8px' }}>
                <Button disabled size="small">
                  Add
                </Button>
              </div>
            </Grid>
            {/* 
            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px 24px',
              }}
            ></div> */}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Avatar style={{ marginRight: '8px' }}>S</Avatar>
                    <Typography>Sysco</Typography>
                  </Box>
                  <Box>
                    <IconButton style={{ marginRight: '-8px' }}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6">Additional details</Typography>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  type="date"
                  value="2020-10-09"
                  label="Invoice date"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  label="Expected arrival"
                  type="date"
                  value="2020-10-09"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  label="Reference number"
                  style={{ marginBottom: '22px' }}
                />
              </Grid>
            </Grid>
            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px 24px',
              }}
            ></div>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography variant="h6">Attachments</Typography>
              <Button size="small" disabled>
                Add
              </Button>
            </Grid>
            {/* <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <InsertDriveFileIcon style={{ marginRight: '8px' }} />{' '}
                <Typography>Sysco Invoice</Typography>
              </Box>
              <Box style={{ marginRight: '-16px' }}>
                <IconButton>
                  <VisibilityIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box> */}
            <Box
              width="100%"
              height="125px"
              marginTop={2}
              border="2px dashed #bbb"
              display="flex"
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="add-image-container"
            >
              <AttachFileIcon style={{ marginBottom: '8px' }} />

              <Typography variant="caption">Add attachment</Typography>
            </Box>{' '}
          </Paper>
        </Grid>
      </Grid>

      <AddProductDialog
        open={addProductDialogOpen}
        close={() => setAddProductDialogOpen(false)}
      />
    </Container>
  );
}
