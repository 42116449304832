import React, { useState } from 'react';
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import { fetchStripePaymentMethods } from '../../slices/subscription/subscriptionSlice';
import stripePromise from '../../modules/stripe';
import { Button, Box, Typography } from '@material-ui/core';
import feathers from '../../modules/feathers';

const CheckoutForm = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (event: any) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setSubmitting(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    //@ts-ignore
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.log('[error]', error);
    }

    feathers
      .service('/stripe/payment-method')
      .patch(paymentMethod.id, {
        attach: true,
        customer: props.stripeCustomerId,
      })
      .then((res: any) => {
        console.log('success');
        console.log(res);

        setSubmitting(false);
        props.toggleOpen(false);
        fetchStripePaymentMethods(props.stripeCustomerId);
      })
      .catch((err: any) => {
        console.log('err');
        console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" style={{ marginBottom: '1em' }}>
        Add a credit card
      </Typography>
      <CardElement
        options={{
          hidePostalCode: false,
          style: {
            base: {
              fontFamily: 'Roboto, sans-serif',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.87)',
              '::placeholder': {
                color: 'rgba(0, 0, 0, 0.87)',
              },
            },
            invalid: {
              color: '#9e2146',
              iconColor: '#fa755a',
            },
          },
        }}
        onChange={handleChange}
      />
      <Typography
        style={{ marginTop: '10px', color: '#9e2146' }}
        variant="body1"
      >
        {error}
      </Typography>

      <Box paddingY={2}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={Boolean(submitting || error)}
          style={{ marginTop: '1em' }}
        >
          {props.save ? 'Save Card' : 'Add'}
        </Button>
      </Box>
    </form>
  );
};

export default function CardAddExistingCustomerForm(props: any) {
  console.log(props);
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        stripeCustomerId={props.stripeCustomerId}
        firstName={props.firstName}
        lastName={props.lastName}
        customerId={props.customerId}
        subscriptionId={props.subscriptionId}
        email={props.email}
        toggleOpen={props.toggleOpen}
      />
    </Elements>
  );
}
