import React from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { CircularProgress, Box } from '@material-ui/core';

import CardSaveForm from '../../components/CardSaveForm/CardSaveForm';
import { VariantType, useSnackbar } from 'notistack';

export default function CustomerBillingDialog(props: any) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const [submitting, setSubmitting] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState(
    props.paymentMethod === '' ? 'card' : props.paymentMethod
  );

  const submitPaymentMethodChange = async () => {
    setSubmitting(true);

    try {
      const change = await props.changePaymentMethod(
        props.subscriptionId,
        props.customerId,
        paymentMethod
      );

      enqueueSnackbar('Billing method changed to ' + paymentMethod);
      props.toggleOpen(false);
    } catch (err) {
      console.log(err);
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Add billing
            {/* {props.paymentMethod === "" ? "Add" : "Edit"} billing */}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.toggleOpen(false)}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        <FormControl component="fieldset">
          <Typography variant="body1">Payment method</Typography>
          <RadioGroup
            defaultValue={props.paymentMethod}
            value={paymentMethod}
            aria-label="payment method"
            name="customized-radios"
            onChange={(e: any, v: any) => {
              setPaymentMethod(v);
            }}
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <FormControlLabel
              value="card"
              control={<Radio color="primary" />}
              label="Credit Card"
            />
            <FormControlLabel
              value="cash"
              control={<Radio color="primary" />}
              label="Cash"
            />
            <FormControlLabel
              value="interac"
              control={<Radio color="primary" />}
              label="Interac"
            />
          </RadioGroup>
        </FormControl>
        {paymentMethod == 'card' && !props.subscription.stripe_customer_id ? (
          <Box marginTop={4}>
            <CardSaveForm
              stripeCustomerId={props.stripeCustomerId}
              paymentMethod={props.paymentMethod}
              customerId={props.customerId}
              subscriptionId={props.subscriptionId}
              firstName={props.firstName}
              lastName={props.lastName}
              email={props.email}
              toggleOpen={props.toggleOpen}
            />
          </Box>
        ) : (
          <Box paddingY={2}>
            <DialogActions>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                style={{
                  boxShadow: 'none',
                }}
                onClick={submitPaymentMethodChange}
                disabled={submitting || paymentMethod == props.paymentMethod}
              >
                {submitting && (
                  <CircularProgress
                    style={{
                      position: 'absolute',
                      left: '50%',
                      marginLeft: '-8px',
                    }}
                    size={16}
                  />
                )}
                Add
              </Button>
            </DialogActions>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
