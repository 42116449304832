import { createSlice } from '@reduxjs/toolkit';
import {
  create,
  getCustomer,
  getSecondaryCustomers,
  updateNote,
  updateAddress,
  updateContactDetails,
  updateCustomerSecondaryAccountsId,
} from '../../api/customers';

import feathers from '../../modules/feathers';

import { createActivity } from '../../api/activities';

const initialState = {
  customer: null,
  fetchCustomerLoading: true,
  fetchCustomerError: null,
  secondaryCustomers: [],
  fetchSecondaryCustomersLoading: true,
  primaryCustomer: null,
  primaryCustomerFetchLoading: true,
};

const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    startCustomerLoading: (state) => {
      //@ts-ignore
      state.fetchCustomerLoading = true;
    },
    startSecondaryCustomersLoading: (state) => {
      //@ts-ignore
      state.fetchSecondaryCustomersLoading = true;
    },
    fetchCustomerSuccess: (state, action) => {
      console.log(action);
      state.fetchCustomerLoading = false;
      state.customer = action.payload;
    },
    fetchCustomerFailed: (state, action) => {
      console.log('Failed fetchCustomer');
      console.log(action);
      state.fetchCustomerLoading = false;
      state.fetchCustomerError = action.payload;
    },
    fetchPrimaryAccountSuccess: (state, action) => {
      state.primaryCustomer = action.payload;
      state.primaryCustomerFetchLoading = false;
    },
    fetchSecondaryAccountsSuccess: (state, action) => {
      console.log(action);
      state.secondaryCustomers = action.payload.data;
      state.fetchSecondaryCustomersLoading = false;
    },
    updateAddressSuccess: (state, action) => {
      console.log(state);
      //@ts-ignore
      state.customer.address = action.payload;
    },
    updateAddressFailed: (state, action) => {},
    updateCustomerContactSuccess: (state, action) => {
      console.log(action.payload);
      //@ts-ignore
      if (state.customer._id === action.payload._id) {
        state.customer = action.payload;
      } else {
      }
    },
    updateCustomerContactFailed: (state, action) => {},
  },
});

export const {
  startCustomerLoading,
  startSecondaryCustomersLoading,
  fetchCustomerSuccess,
  fetchCustomerFailed,
  fetchPrimaryAccountSuccess,
  fetchSecondaryAccountsSuccess,
  updateAddressSuccess,
  updateAddressFailed,
  updateCustomerContactSuccess,
  updateCustomerContactFailed,
} = customerSlice.actions;

export default customerSlice.reducer;

export function createCustomer(customer: Object, history: any) {
  return async function (dispatch: Function) {
    try {
      const createdCustomer = await create(customer);
      console.log(createdCustomer);
      //@ts-ignore
      if (!customer.secondary) {
        await createActivity({
          actionOn: createdCustomer._id,
          actionBy: ' ',
          action: 'create-primary-customer',
          data: {
            firstName: createdCustomer.firstName,
            lastName: createdCustomer.lastName,
          },
        });
      } else {
        // await updateCustomerSecondaryAccountsId(
        //   createdCustomer.primaryAccountId,
        //   createdCustomer._id
        // );

        dispatch(startSecondaryCustomersLoading());

        const secondaryCustomers = await getSecondaryCustomers(
          createdCustomer.primaryAccountId
        );

        dispatch(fetchSecondaryAccountsSuccess(secondaryCustomers));

        await createActivity({
          actionOn: createdCustomer._id,
          actionBy: 'RANDOM_ID',
          action: 'create-secondary-customer',
          data: {
            primaryAccountId: createdCustomer.primaryAccountId,
            firstName: createdCustomer.firstName,
            lastName: createdCustomer.lastName,
          },
        });

        await createActivity({
          actionOn: createdCustomer.primaryAccountId,
          actionBy: 'RANDOM_ID',
          action: 'add-secondary-contact',
          data: {
            firstName: createdCustomer.firstName,
            lastName: createdCustomer.lastName,
          },
        });
      }

      // await dispatch(addCustomerLoading(false));
      //@ts-ignore
      if (!customer.secondary) {
        history.push(`/customer/${createdCustomer._id}`);
      }
    } catch (err) {
      console.log('ADD CUSTOMER FAILED');
      console.log(err);
      // dispatch(addCustomerLoading(false));
    }
  };
}

export function fetchCustomer(id: String) {
  console.log('Asd');
  return async function (dispatch: Function) {
    console.log('Fetch Customer');

    try {
      dispatch(startCustomerLoading());
      const customer = await getCustomer(id);
      console.log(customer);

      if (!customer) {
        throw new Error('Customer not found');
      }
      dispatch(startSecondaryCustomersLoading());

      dispatch(fetchSecondaryAccountsSuccess([]));
      dispatch(fetchCustomerSuccess(customer));

      if (customer.secondary) {
        console.log('Customer is secondary');
        const primaryCustomer = await getCustomer(customer.primaryAccountId);
        dispatch(fetchPrimaryAccountSuccess(primaryCustomer));
      }

      if (!customer.secondary && customer.secondaryAccounts) {
        console.log('Fetching secondaries');

        dispatch(startSecondaryCustomersLoading());
        if (customer.secondaryAccounts.length > 0) {
          console.log('Customer is primary');
          const secondaryCustomers = await getSecondaryCustomers(id);
          dispatch(fetchSecondaryAccountsSuccess(secondaryCustomers));
        }
      }
    } catch (error) {
      console.log('Fetch Customer: Error');
      console.log(error);
      dispatch(fetchCustomerFailed(error));
    }
  };
}

export function updateCustomerNote(
  customerId: string,
  notes: string,
  enqueueSnackbar: Function,
  setNoteSaving: Function
) {
  return async function (dispatch: Function) {
    try {
      setNoteSaving(true);
      const noteUpdated = await updateNote(customerId, notes);
      console.log(noteUpdated);
      enqueueSnackbar('Updated note successfully.');
    } catch (error) {
      setNoteSaving(false);
      enqueueSnackbar('There was a problem updating note.', {
        variant: 'error',
      });
    }
  };
}

export const updateCustomerAddress =
  (
    customerId: string,
    addresses: [Object],
    type: object,
    errorAction: Function,
    successAction: Function
  ): any =>
  async (dispatch: any) => {
    console.log('Calling change password');
    try {
      console.log('Going in address');
      await updateAddress(customerId, addresses, type);
      dispatch(updateAddressSuccess(addresses));
      successAction();
    } catch (error) {
      console.log(error);
      errorAction(error);
    }
  };

export function updateCustomerContact(
  customerId: string,
  contactDetails: Object
) {
  return async function (dispatch: Function) {
    try {
      const updatedContact = await updateContactDetails(
        customerId,
        contactDetails
      );

      dispatch(updateCustomerContactSuccess(updatedContact));
    } catch (error) {
      console.log(error);
      updateCustomerContactFailed(error);
    }
  };
}

export const sendResetPasswordEmail =
  (customerEmail: String) => async (dispatch: Function) => {
    try {
      const sendResetPassword = await feathers
        .service('authManagement')
        .create({
          action: 'sendResetPwd',
          value: {email: customerEmail},
        });
    } catch (err) {
      console.log(err);
    }
  };
