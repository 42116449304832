import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Formik, FieldArray, FastField, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import CloudIcon from '@material-ui/icons/Cloud';

import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPostalCode,
  patchPostalCode,
  unsetPostalCode,
} from '../../slices/postal-codes/postalCodesSlice';
import { RootState } from '../../rootReducer';

export default function EditPostalCode(props: any) {
  // const classes = useStyles();

  const dispatch = useDispatch();
  const postalCodeLoading = useSelector(
    (state: RootStateOrAny) => state.postalCodes.postalCodeLoading
  );

  const postalCode: any = useSelector(
    (state: RootStateOrAny) => state.postalCodes.postalCode
  );

  useEffect(() => {
    dispatch(fetchPostalCode(props.match.params.id));

    return () => {
      dispatch(unsetPostalCode());
    };
  }, []);

  if (postalCodeLoading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Formik
        initialValues={{
          code: postalCode.code || '',
          routeId: postalCode.routeId || '',
        }}
        validationSchema={Yup.object().shape({
          code: Yup.string()
            .matches(
              /^[a-zA-Z][0-9][a-zA-Z]$/,
              'Enter first three characters of a postal code'
            )
            .required('Postal code is required'),

          routeId: Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);

          let data: any = {
            code: values.code,
          };

          if (values.routeId != '') {
            data.routeId = values.routeId;
          }

          dispatch(
            patchPostalCode(
              props.match.params.id,
              data,
              setSubmitting,
              props.history
            )
          );
        }}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{
                    marginTop: '1em',
                  }}
                >
                  <Link
                    style={{ textDecoration: 'none' }}
                    to="/settings/postal-codes"
                  >
                    <Button style={{ textTransform: 'capitalize' }}>
                      <KeyboardArrowLeftIcon />
                      <Typography>Postal Codes</Typography>
                    </Button>
                  </Link>
                </Breadcrumbs>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: '20px 0 40px' }}
            >
              <Box
                fontWeight="fontWeightMedium"
                fontSize="h4.fontSize"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    mb={2}
                  >
                    <Box display="flex" flexDirection="column">
                      {values.code.length > 0
                        ? values.code.toUpperCase()
                        : 'New Postal code'}
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    style={{ marginRight: '1em' }}
                  >
                    Save
                    <CloudIcon
                      style={{ fill: '#9b9b9b', marginLeft: '10px' }}
                    />
                  </Button>
                </Box>
              </Box>

              <Box></Box>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Paper style={{ padding: '24px 24px 0', marginBottom: '2em' }}>
                  <Typography variant="h6">Postal code</Typography>

                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Postal code"
                    label="Title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    name="code"
                    id="code"
                    value={values.code.toUpperCase()}
                    helperText={errors.code && touched.code && errors.code}
                  />

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px -24px 24px',
                    }}
                  ></div>
                </Paper>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
}
