import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { login } from '../../slices/auth/authReducer';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
const logoImage = require('../../assets/images/vittle-logo-teal.svg');

export default function Login(props: any) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  // console.log('OIN LOGIN PAGE');
  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item></Grid>
      </Grid>

      <Paper
        elevation={1}
        style={{
          padding: '20px',
          maxWidth: '380px',
          width: '100%',
          margin: '40px auto 0',
        }}
        variant="elevation"
      >
        <Box marginTop={2}>
          <img
            src={logoImage}
            style={{ display: 'block', width: '120px', margin: '0 auto 30px' }}
          />
        </Box>

        <Formik
          initialValues={{
            emailAddress: '',
            password: '',
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            console.log(values);
            console.log(props.history);

            dispatch(
              login(
                values.emailAddress,
                values.password,
                setSubmitting,
                enqueueSnackbar,
                history
              )
            );
          }}
          validationSchema={Yup.object().shape({
            emailAddress: Yup.string()
              .email()
              .required('Enter your email address'),
            password: Yup.string().required('Enter your password'),
            // available: Yup.boolean(),
          })}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="emailAddress"
                    label="Email address"
                    type="text"
                    name="emailAddress"
                    value={values.emailAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.emailAddress && touched.emailAddress ? true : false
                    }
                    helperText={
                      errors.emailAddress &&
                      touched.emailAddress &&
                      errors.emailAddress
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="password"
                    name="password"
                    label="Password"
                    type={passwordVisible ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password && touched.password ? true : false}
                    helperText={
                      errors.password && touched.password && errors.password
                    }
                    // helperText={
                    //   errors.password && touched.password && errors.password
                    // }
                    InputProps={{
                      endAdornment: passwordVisible ? (
                        <IconButton
                          style={{
                            position: 'absolute',
                            right: '8px',
                          }}
                          size="small"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          <VisibilityIcon
                            style={{ cursor: 'pointer', color: '#b2b9b6' }}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          style={{
                            position: 'absolute',
                            right: '8px',
                          }}
                          size="small"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          <VisibilityOffIcon
                            style={{ cursor: 'pointer', color: '#b2b9b6' }}
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  padding: '30px 0 20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  size="large"
                  style={{ boxShadow: 'none' }}
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <CircularProgress
                      style={{
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-8px',
                      }}
                      size={16}
                    />
                  )}
                  Sign in
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Paper>
    </Container>
  );
}
