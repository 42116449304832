import feathers from '../../modules/feathers';

export function create(ingredient: any) {
  return feathers.service('ingredients').create(ingredient);
}

export function getIngredient(id?: String) {
  return feathers.service('ingredients').get(id);
}

export function getSecondaryCustomers(primaryAccountId: String) {
  return feathers.service('ingredients').find({
    query: {
      primaryAccountId,
    },
  });
}

export function getIngredientsTable(
  filters: Object | undefined | any,
  searchText: string | undefined
) {
  console.log('ingredients table ');

  let query: any = {};
  // asd
  if (searchText) {
    //@ts-ignore
    query.searchTerm = searchText;
  }

  if (filters) {
    query['$sort'] = filters;

    if (filters.hasOwnProperty('category')) {
      query.category = filters.category;
      delete query.$sort.category;
      delete filters.category;
    }

    if (filters.hasOwnProperty('type')) {
      query.type = filters.type;
      delete query.$sort.type;
      delete filters.type;
    }

  }

  query['$limit'] = 250;

  console.log(query);

  return feathers.service('ingredients').find({ query });
}

export function search(searchText: String | undefined) {
  return feathers.service('ingredients').find({
    query: {
      searchTerm: searchText,
    },
  });
}

export function patch(id: String, data: any) {
  console.log("In ingredient PATCH");
  console.log(data);
  return feathers.service('ingredients').patch(id, {
    ...data,
  });
}

export function addIngredient(id: String, data: any) {
  return feathers.service('ingredients').patch(id, {
    data,
  });
}

export function editIngredient(id: String, data: any) {
  return feathers.service('ingredients').patch(id, {
    data,
  });
}

export function searchIngredients(search: string) {
  return feathers.service('ingredients').find({
    query: {
      search,
    },
  });
}
