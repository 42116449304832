import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';

import { alpha, makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import BugReportIcon from '@material-ui/icons/BugReport';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import PersonAdd from '@material-ui/icons/PersonAdd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Button from '@material-ui/core/Button';
import { logout } from '../../slices/auth/authReducer';
import { useHistory } from 'react-router-dom';

// const styles2 = require('./NavBar.scss');
const logoImage = require('../../assets/images/Vittle Wordmark (White).svg');

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#33474C',
  },
  logo: { width: '80px' },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchIconRight: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    color: '#FFF',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  notificationFilter: {
    fontWeight: 'bold',
  },
  notificationFilterSelected: {
    backgroundColor: 'rgba(0,0,0,0.09)',
  },
  notiificationButton: {
    marginRight: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
}));

export default function NavBar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    console.log('calling');
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <div
        style={{
          minWidth: '300px',
          textAlign: 'center',
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px 0',
          borderBottom: '1px solid rgba(0,0,0,.12)',
          marginBottom: '6px',
        }}
      >
        {user.firstName === 'Omar' ? (
          <Avatar
            style={{ width: 72, height: 72, marginBottom: '10px' }}
            alt="Omar Radwan"
            src="/omar-avatar.png"
          />
        ) : (
          <Avatar className="fulfillment-item__avatar color-1">
            {user.firstName.charAt(0)}
            {user.lastName.charAt(0)}
          </Avatar>
        )}
        <Typography style={{ fontWeight: 'bold' }}>
          {`${user.firstName} ${user.lastName}`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Owner
        </Typography>
      </div>
      <MenuItem
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={handleMenuClose}
      >
        <PersonIcon style={{ marginRight: '25px', color: '#999' }} /> Profile
      </MenuItem>
      <MenuItem
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={handleMenuClose}
      >
        <SettingsIcon style={{ marginRight: '25px', color: '#999' }} />
        Settings
      </MenuItem>
      <MenuItem
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
        onClick={() => {
          dispatch(logout(history));
        }}
      >
        <ArrowRightAltIcon style={{ marginRight: '25px', color: '#999' }} />
        Sign out
      </MenuItem>
    </Menu>
  );

  const renderNotificationMenu = (
    <Menu
      anchorEl={notificationAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id="notification-menu"
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={Boolean(notificationAnchorEl)}
      onClose={() => setNotificationAnchorEl(null)}
    >
      <div
        style={{
          textAlign: 'left',
          flexDirection: 'column',
          padding: '10px 15px 15px',
        }}
      >
        <Typography variant="h6" style={{ fontWeight: 400 }}>
          Notifications
        </Typography>

        <div style={{ marginTop: '12px' }}>
          <Button
            className={`${classes.notificationFilterSelected} ${classes.notificationFilter}`}
            size="small"
            style={{
              textTransform: 'capitalize',
              marginRight: '5px',
              minWidth: '48px',
            }}
          >
            <Typography variant="body2" style={{ top: '3px' }}>
              All
            </Typography>
          </Button>
          <Button
            size="small"
            className={`${classes.notificationFilter}`}
            style={{ textTransform: 'capitalize', marginRight: '5px' }}
          >
            <Typography variant="body2">Account</Typography>
          </Button>
          <Button
            size="small"
            className={`${classes.notificationFilter}`}
            style={{ textTransform: 'capitalize', marginRight: '5px' }}
          >
            <Typography variant="body2">Billing</Typography>
          </Button>
          <Button
            size="small"
            style={{ textTransform: 'capitalize', marginRight: '5px' }}
          >
            <Typography variant="body2">Delivery</Typography>
          </Button>
        </div>
      </div>

      <MenuItem
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
        onClick={handleMenuClose}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '12px' }}>
              <Avatar />
            </div>

            <div>
              <Typography variant="body2">
                <strong>John Smith</strong> subscribed for $100.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                30 mins ago
              </Typography>
            </div>
          </div>
        </div>
      </MenuItem>
      <MenuItem
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
        onClick={handleMenuClose}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '12px' }}>
              <Avatar />
            </div>

            <div>
              <Typography variant="body2">
                <strong>John Smith</strong> created an account.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Just now
              </Typography>
            </div>
          </div>
        </div>
      </MenuItem>
      <MenuItem
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
        onClick={handleMenuClose}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '12px' }}>
              <Avatar />
            </div>

            <div>
              <Typography variant="body2">
                <strong>Jane Doe's</strong> credit card ending in 3221 was
                declined.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Just now
              </Typography>
            </div>
          </div>
        </div>
      </MenuItem>

      <div
        style={{
          padding: '10px 10px 4px',
          textAlign: 'center',
          borderTop: '1px solid rgba(0,0,0,.12)',
        }}
      >
        <Button>See all</Button>
      </div>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <MoreIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        position="static"
        style={{ boxShadow: 'none', backgroundColor: '#33474C' }}
      >
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <div>
            <Tooltip title="Menu">
              <IconButton
                edge="start"
                onClick={props.toggleDrawer(true)}
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Dashboard">
              <IconButton style={{ borderRadius: '4px' }}>
                <img style={{ width: '80px' }} src={logoImage} alt="" />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <Tooltip title="Search" className={classes.searchIconRight}>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Notifications">
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={(e) => setNotificationAnchorEl(e.currentTarget)}
              >
                <Badge overlap="circular" variant="dot" color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Profile">
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                // style={{ borderRadius: 0, padding: "10" }}
              >
                {user.firstName === 'Omar' && user.lastName === 'Radwan' ? (
                  <Avatar
                    style={{ width: 35, height: 35 }}
                    alt="Omar Radwan"
                    src="/omar-avatar.png"
                  />
                ) : (
                  <Avatar style={{ width: '35px', height: '35px' }}>
                    {user.firstName.charAt(0)}
                    {user.lastName.charAt(0)}
                  </Avatar>
                )}
              </IconButton>
            </Tooltip>
          </div>
          {renderMenu}
          {renderNotificationMenu}
        </Toolbar>
      </AppBar>
    </div>
  );
}
