import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Input, Select, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import CloudIcon from '@material-ui/icons/Cloud';

import Button from '@material-ui/core/Button';

import { createStaff } from '../../slices/users/usersSlice';
import { fetchPostalCodes } from '../../slices/postal-codes/postalCodesSlice';
import { RootState } from '../../rootReducer';
import { retrieveById, findPlace } from '../../api/canada-post';

async function fetchPlaces(
  setOptions: Function,
  searchTerm: String,
  lastId: String,
  setLoading: Function,
  setOpen: Function
) {
  setLoading(true);

  const response = await findPlace(lastId, searchTerm);

  setLoading(false);
  // await sleep(1e3); // For demo purposes.
  console.log(response.data.Items);

  if (response.data.Items.length > 0) {
    setOptions(response.data.Items);
    setOpen(true);
  } else {
    setOptions([]);
  }
  // Object.keys(response).map(key => response[key].item[0]) as CountryType[]
}

async function fetchFinalAddress(Id: String, setDescriptiveAddress: Function) {
  const response = await retrieveById(Id);

  console.log(response);
  if (response.data.Items) {
    if (response.data.Items.length > 0) {
      // setAddressCorrect(true);
      setDescriptiveAddress(
        response.data.Items[1] ? response.data.Items[1] : response.data.Items[0]
      );
    }
  }
}

export default function NewStaff(props: any) {
  // const classes = useStyles();

  const dispatch = useDispatch();

  const postalCodes: any = useSelector(
    (state: RootStateOrAny) => state.postalCodes.postalCodes
  );
  const postalCodesLoading: boolean = useSelector(
    (state: RootStateOrAny) => state.postalCodes.postalCodesLoading
  );

  const addressComplete = require('../../assets/images/address-complete-en.png');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [lastId, setLastId] = React.useState('');
  const [descriptiveAddress, setDescriptiveAddress] = React.useState({} as any);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    dispatch(fetchPostalCodes({ code: 1 }, undefined));
  }, []);

  React.useEffect(() => {
    if (searchTerm.length > 0 || lastId.length > 0) {
      fetchPlaces(setOptions, searchTerm, lastId, setLoading, setOpen);
    }
  }, [searchTerm, lastId]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const [address, setAddress] = React.useState('');

  const handleAutoCompleteSelected = (e: any, v: any) => {
    if (!v) {
      return;
    }

    if (!v.hasOwnProperty('Next')) {
      return;
    }

    if (v.Next === 'Find') {
      console.log('Going Find');
      setLastId(v.Id);
    }

    if (v.Next === 'Retrieve') {
      console.log('Going Retrieve');
      setLastId('');
      setSearchTerm('');
      setAddress(v.Text + ' ' + v.Description);
      fetchFinalAddress(v.Id, setDescriptiveAddress);
    }
  };

  const handleAutoCompleteBlur = () => {
    if (lastId.length > 0) {
      setLastId('');
      setSearchTerm('');
      setOpen(false);
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          colorCode: '',
          postalCodes: [],
          role: '',
          address: {},
        }}
        validationSchema={Yup.object().shape({
          colorCode: Yup.string().required('Color code is required'),
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: Yup.string().required('Email address is required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);

          let data: any = {
            colorCode: values.colorCode,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            postalCodes: values.postalCodes,
            role: values.role,
            address: values.address,
          };

          dispatch(createStaff(values, setSubmitting, props.history));
        }}
      >
        {({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{
                    marginTop: '1em',
                  }}
                >
                  <Link style={{ textDecoration: 'none' }} to="/staff">
                    <Button style={{ textTransform: 'capitalize' }}>
                      <KeyboardArrowLeftIcon /> <Typography>Staff</Typography>
                    </Button>
                  </Link>
                </Breadcrumbs>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: '20px 0 40px' }}
            >
              <Box
                fontWeight="fontWeightMedium"
                fontSize="h4.fontSize"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                      New staff
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    style={{ marginRight: '1em' }}
                  >
                    Save
                    <CloudIcon
                      style={{ fill: '#9b9b9b', marginLeft: '10px' }}
                    />
                  </Button>
                </Box>
              </Box>

              <Box></Box>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Paper style={{ padding: '24px 24px 0', marginBottom: '2em' }}>
                  <Typography variant="h6">Contact details</Typography>

                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="First name"
                        label="First name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        name="firstName"
                        id="firstName"
                        value={values.firstName.toUpperCase()}
                        helperText={
                          errors.firstName &&
                          touched.firstName &&
                          errors.firstName
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Last name"
                        label="Last name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        name="lastName"
                        id="lastName"
                        value={values.lastName.toUpperCase()}
                        helperText={
                          errors.lastName && touched.lastName && errors.lastName
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Email address"
                        label="Email address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        name="email"
                        id="email"
                        value={values.email.toUpperCase()}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Password"
                        label="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="normal"
                        name="password"
                        id="password"
                        value={values.password.toUpperCase()}
                        helperText={
                          errors.password && touched.password && errors.password
                        }
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px -24px 24px',
                    }}
                  ></div>

                  <Typography variant="h6">Role</Typography>

                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Role"
                    label="Role"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    margin="normal"
                    name="role"
                    id="role"
                    value={values.role}
                    helperText={errors.role && touched.role && errors.role}
                    select
                  >
                    {['Super admin', 'Delivery driver', 'Chef'].map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </TextField>

                  {values.role === 'Delivery driver' && (
                    <div>
                      <div
                        style={{
                          borderTop: '1px solid rgba(0,0,0,0.12)',
                          margin: '32px -24px 24px',
                        }}
                      ></div>

                      <Typography variant="h6">Postal codes</Typography>

                      {console.log(postalCodes)}

                      <FieldArray
                        name="postalCodes"
                        render={(arrayHelpers) => (
                          <>
                            {!postalCodesLoading && (
                              <Select
                                multiple
                                value={values.postalCodes}
                                onChange={(e: any) => {
                                  console.log(e.target.value);

                                  setFieldValue('postalCodes', e.target.value);
                                }}
                                input={<Input />}
                                fullWidth
                                variant="outlined"
                              >
                                {postalCodes.map((code: any) => (
                                  <MenuItem
                                    key={code._id}
                                    value={code.code}
                                    selected={
                                      values.postalCodes.findIndex(
                                        (e) => e === code.code
                                      ) >= 0
                                    }
                                  >
                                    {code.code}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </>
                        )}
                      />
                      <div
                        style={{
                          borderTop: '1px solid rgba(0,0,0,0.12)',
                          margin: '32px -24px 24px',
                        }}
                      ></div>

                      <Typography variant="h6">Final destination</Typography>
                      <Grid container>
                        <Autocomplete
                          freeSolo
                          style={{ width: '100%' }}
                          open={open}
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          noOptionsText="No address found"
                          getOptionSelected={(option: any, value: any) =>
                            option.Text === value.Text
                          }
                          getOptionLabel={(option: any) =>
                            option.Text + ' ' + option.Description
                          }
                          onChange={(e: any, v: any) => {
                            handleAutoCompleteSelected(e, v);
                            handleChange(e);
                          }}
                          options={options}
                          loading={loading}
                          onBlur={(e: any) => {
                            handleAutoCompleteBlur();
                            handleBlur(e);
                          }}
                          clearOnEscape={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={
                                errors.address && touched.address ? true : false
                              }
                              fullWidth
                              required
                              margin="normal"
                              label="Address"
                              name="address"
                              variant="standard"
                              onChange={(e: any) => {
                                setSearchTerm(e.target.value);
                                handleChange(e);
                              }}
                              value={values.address}
                              helperText={
                                errors.address &&
                                touched.address &&
                                errors.address
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {/* {!loading && addressCorrect ? <DoneIcon /> : null} */}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                            />
                          )}
                        />

                        <Grid item xs={12}>
                          <img
                            style={{ marginTop: '10px' }}
                            alt="Address complete"
                            src={addressComplete}
                          />
                        </Grid>
                      </Grid>

                      <div
                        style={{
                          borderTop: '1px solid rgba(0,0,0,0.12)',
                          margin: '32px -24px 24px',
                        }}
                      ></div>

                      <Typography variant="h6">Color code</Typography>

                      <Typography>
                        Select a color for this delviery driver to be used on
                        map markers and fulfillment elements
                      </Typography>

                      <input
                        type="color"
                        style={{
                          border: 0,
                          padding: 0,
                          margin: '32px 0',
                        }}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFieldValue('colorCode', e.target.value);
                        }}
                      />

                      <div
                        style={{
                          borderTop: '1px solid rgba(0,0,0,0.12)',
                          margin: '32px 0',
                        }}
                      ></div>
                    </div>
                  )}

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px 0',
                    }}
                  ></div>
                </Paper>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
}
