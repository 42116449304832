import React from 'react';

import {
  fetchCustomer,
  updateCustomerAddress,
  sendResetPasswordEmail,
} from '../../slices/customer/customerSlice';

import {
  fetchSubscription,
  fetchStripePaymentMethods,
} from '../../slices/subscription/subscriptionSlice';

import { fetchCustomerActivity } from '../../slices/activities/activitiesSlice';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Skeleton from '@material-ui/lab/Skeleton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RepeatIcon from '@material-ui/icons/Repeat';

import LinkIcon from '@material-ui/icons/Link';

import CustomerBillingDialog from './CustomerBillingDialog';
import CustomerCardDialog from './CustomerCardDialog';
import CustomerDeleteDialog from './CustomerDeleteDialog';
import CustomerStatusChangeDialog from './CustomerStatusChangeDialog';
import CustomerAddNewCardDialog from './CustomerAddNewCardDialog';
import StatusIcon from '../../components/StatusIcon/StatusIcon';

import CustomerOrders from './CustomerOrders';
import CustomerSubscription from './CustomerSubscription';
import CustomerSettings from './CustomerSettings';
import CustomerOverview from './CustomerOverview';

import './Customer.css';

interface CustomerProps {
  fetchCustomerLoading: Boolean;
  secondaryCustomersFetchLoading: Boolean;
  primaryCustomerFetchLoading: Boolean;
  customerActivityLoading: Boolean;
  paymentMethodsLoading: Boolean;
  fetchSubscriptionLoading: Boolean;
  fetchStripePaymentMethods: Function;
  sendResetPasswordEmail: Function;

  switchPaymentMethod: Function;
  createCustomer: Function;
  activity: Array<Object> | undefined;
  fetchCustomer: Function;
  fetchCustomerActivity: Function;
  fetchSubscription: Function;
  updateCustomerContact: Function;
  updateCustomerAddress: Function;
  history: any;
  match: any;
  customer: Object | any;
  primaryCustomer: Object | any;
  subscription: Object | any;
  paymentMethods: Array<Object>;
  secondaryCustomers: [Object] | any;
}

interface CustomerState {
  currentUserId: String | null;
  value: Number;
  open: Boolean;
  status: String;
  anchorEl: any;
  billingDialogOpen: Boolean;
  cardDialogOpen: Boolean;
  customerDeleteDialogOpen: Boolean;
  customerStatusChangeDialog: Boolean;
  addCustomerDialogOpen: Boolean;
  addNewCardDialogOpen: Boolean;
}

function CustomerLoading() {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
        </Grid>
      </Grid>
    </Container>
  );
}

class Customer extends React.Component<CustomerProps, CustomerState> {
  state: CustomerState = {
    value: 0,
    open: false,
    billingDialogOpen: false,
    cardDialogOpen: false,
    status: 'Active',
    anchorEl: null,
    customerDeleteDialogOpen: false,
    customerStatusChangeDialog: false,

    addNewCardDialogOpen: false,
    addCustomerDialogOpen: false,

    currentUserId: null,
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      currentUserId: this.props.match.params.id,
    });

    try {
      this.props.fetchCustomer(this.props.match.params.id);
      this.props.fetchCustomerActivity(this.props.match.params.id);
      this.props.fetchSubscription(this.props.match.params.id);
    } catch (error) {
      console.log(error);
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    //@ts-ignore
    if (prevState.currentUserId !== null) {
      if (nextProps.match.params.id !== prevState.currentUserId) {
        //@ts-ignore
        nextProps.fetchCustomer(nextProps.match.params.id);
        //@ts-ignore
        nextProps.fetchCustomerActivity(nextProps.match.params.id);
        //@ts-ignore
        nextProps.fetchSubscription(nextProps.match.params.id);

        return {
          currentUserId: nextProps.match.params.id,
        };
      }
    }

    return null;
  }

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ value: newValue });
  };

  handleStatusChange = (status: String) => {
    this.setState({
      status,
    });
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseOnly = () => {
    this.setState({ anchorEl: null });
  };

  handleClose = (event: any, status: String) => {
    this.setState({ anchorEl: null, customerStatusChangeDialog: true });
  };

  sendResetPasswordEmail = () => {
    this.props.sendResetPasswordEmail(this.props.customer.email);
  };

  render() {
    if (
      this.props.fetchCustomerLoading ||
      this.props.fetchSubscriptionLoading ||
      this.props.customer == null ||
      this.props.subscription == null
    ) {
      return <CustomerLoading />;
    }

    return (
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              style={{
                marginTop: '1em',
              }}
            >
              <Link style={{ textDecoration: 'none' }} to="/customers">
                <Button style={{ textTransform: 'capitalize' }}>
                  <KeyboardArrowLeftIcon /> <Typography>Contacts</Typography>
                </Button>
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ margin: '20px 0 40px' }}
        >
          <Box
            fontWeight="fontWeightMedium"
            fontSize="h4.fontSize"
            m={1}
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              mb={2}
            >
              <Box display="flex" alignItems="center" flexDirection="row">
                <Avatar
                  style={{
                    width: '58px',
                    height: '58px',
                    marginRight: '10px',
                  }}
                  alt={`${this.props.customer.firstName} ${this.props.customer.lastName}`}
                >
                  {this.props.customer.firstName.charAt(0).toUpperCase()}
                  {this.props.customer.lastName.charAt(0).toUpperCase()}
                </Avatar>
                <Box display="flex" flexDirection="column">
                  {`${this.props.customer.firstName} ${this.props.customer.lastName}`}

                  {this.props.customer.secondary &&
                    this.props.primaryCustomer !== null &&
                    !this.props.fetchCustomerLoading && (
                      <Chip
                        style={{ fontWeight: 'normal' }}
                        size="small"
                        label={`Managed by ${this.props.primaryCustomer.firstName} ${this.props.primaryCustomer.lastName}`}
                        clickable
                        onClick={() => {
                          this.props.history.push(
                            '/customer/' + this.props.primaryCustomer._id
                          );
                        }}
                      />
                    )}
                </Box>
              </Box>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <StatusIcon
                  styles={{ marginRight: '5px' }}
                  status={
                    this.props.subscription && this.props.subscription.status
                  }
                />
                <KeyboardArrowDownIcon />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleCloseOnly}
              >
                <MenuItem onClick={(e) => this.handleClose(e, 'Active')}>
                  <StatusIcon styles={{ marginRight: '5px' }} status="active" />
                  Activate
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClose(e, 'Paused')}>
                  <StatusIcon styles={{ marginRight: '5px' }} status="paused" />
                  Pause
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClose(e, 'Cancelled')}>
                  <StatusIcon
                    styles={{ marginRight: '5px' }}
                    status="cancelled"
                  />
                  Cancel
                </MenuItem>
              </Menu>
            </Box>
            <div>
              <Button
                style={{ marginRight: '15px' }}
                onClick={() => this.sendResetPasswordEmail()}
                size="small"
              >
                <RepeatIcon style={{ marginRight: '5px' }} />{' '}
                <Typography variant="body2">Reset password</Typography>
              </Button>
              {!this.props.customer.secondary && (
                <Button disabled style={{ marginRight: '15px' }} size="small">
                  <LinkIcon style={{ marginRight: '5px' }} />{' '}
                  <Typography variant="body2">Link Customer</Typography>
                </Button>
              )}
            </div>
          </Box>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Tabs
              style={{
                marginBottom: '1em',
              }}
              // value={this.state.value}
              value={2}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Overview" />
              <Tab label="Orders" />
              <Tab label="Subscriptions" />
              <Tab label="Settings" />
            </Tabs>
          </Grid>
        </Grid>

        {this.state.value === 0 && (
          <CustomerOverview
            customer={this.props.customer}
            customerActivityLoading={this.props.customerActivityLoading}
            activity={this.props.activity}
            subscription={this.props.subscription}
            paymentMethodsLoading={this.props.paymentMethodsLoading}
            fetchSubscriptionLoading={this.props.fetchSubscriptionLoading}
            secondaryCustomersFetchLoading={
              this.props.secondaryCustomersFetchLoading
            }
            fetchCustomerLoading={this.props.fetchCustomerLoading}
            secondaryCustomers={this.props.secondaryCustomers}
            primaryCustomerFetchLoading={this.props.primaryCustomerFetchLoading}
            primaryCustomer={this.props.primaryCustomer}
            paymentMethods={this.props.paymentMethods}
          />
        )}

        {this.state.value === 1 && <CustomerOrders />}

        {this.state.value === 2 && (
          <CustomerSubscription
            customer={this.props.customer}
            subscription={this.props.subscription}
          />
        )}

        {this.state.value === 3 && (
          <CustomerSettings customer={this.props.customer} />
        )}

        <CustomerAddNewCardDialog
          open={this.state.addNewCardDialogOpen}
          toggleOpen={(open: Boolean) => {
            this.setState({ addNewCardDialogOpen: open });
          }}
          stripeCustomerId={this.props.subscription.stripe_customer_id || null}
          subscriptionId={this.props.subscription._id}
          customerId={this.props.customer._id}
          firstName={this.props.customer.firstName}
          lastName={this.props.customer.lastName}
          email={this.props.customer.email}
        />

        <CustomerCardDialog
          open={this.state.cardDialogOpen}
          handleCardOpen={(open: Boolean) => {
            this.setState({ cardDialogOpen: open });
          }}
        />

        <CustomerDeleteDialog
          customer={this.props.customer}
          open={this.state.customerDeleteDialogOpen}
          toggleOpen={(open: Boolean) => {
            this.setState({ customerDeleteDialogOpen: open });
          }}
        />

        <CustomerStatusChangeDialog
          handleStatusChange={this.handleStatusChange}
          customer={this.props.customer}
          open={this.state.customerStatusChangeDialog}
          toggleOpen={(open: Boolean) => {
            this.setState({ customerStatusChangeDialog: open });
          }}
          statusChangeTo={this.state.status}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: any) => ({
  fetchCustomerLoading: state.customer.fetchCustomerLoading,
  secondaryCustomersFetchLoading: state.customer.secondaryCustomersFetchLoading,
  primaryCustomerFetchLoading: state.customer.primaryCustomerFetchLoading,
  customer: state.customer.customer,
  activity: state.activities.customer.activities,
  customerActivityLoading: state.activities.customer.customerActivityLoading,
  secondaryCustomers: state.customer.secondaryCustomers,
  subscription: state.subscription.subscription,
  fetchSubscriptionLoading: state.subscription.fetchSubscriptionLoading,
  paymentMethodsLoading: state.subscription.paymentMethodsLoading,
  primaryCustomer: state.customer.primaryCustomer,
  paymentMethods: state.subscription.paymentMethods,
});

const mapDispatchToProps = {
  fetchCustomer,
  fetchCustomerActivity,
  fetchSubscription,
  updateCustomerAddress,
  fetchStripePaymentMethods,
  sendResetPasswordEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
