import React, { useEffect, useState, useCallback } from 'react';

import {
  Grid,
  Box,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  ListSubheader,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';

import { FieldArray } from 'formik';
import { search } from '../../api/ingredients';

export default (props: any) => {
  const { extras, setFieldValue, values, index, arrayHelpers, extra } = props;

  const [searchText, setSearchText] = useState('');

  console.log(extras);

  return (
    <Grid container spacing={3} style={{ marginBottom: '1em' }}>
      <Grid item xs={12} md={5} sm={5}>
        <Box display="flex" alignItems="center">
          <AutoComplete
            id={index + 'autocomplete' + index}
            onChange={(event: any, newValue: any) => {
              if (newValue === undefined) {
                return;
              }

              if (!newValue) {
                return;
              }

              console.log('going autocompelte onChange');
              console.log(newValue);

              setFieldValue(`extras.${index}.title`, newValue.title);
              setFieldValue(`extras.${index}._id`, newValue._id);
              setFieldValue(`extras.${index}.price`, newValue.price);
              setFieldValue(`extras.${index}.extra`, newValue);
            }}
            // onInputChange={(event: any, newInputValue: any) => {
            //   // debouncedSearch(newInputValue);
            //   setSearchText(newInputValue);
            // }}
            options={extras}
            //@ts-ignore
            getOptionLabel={(option: any) => option.title}
            value={
              //@ts-ignore
              values.extras[
                index
                //@ts-ignore
              ]._id === ''
                ? {
                    _id: '',
                    title: '',
                  }
                : values.extras[
                    index
                    //@ts-ignore
                  ].extra
            }
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField fullWidth {...params} label="Ingredient" />
            )}
          />
        </Box>
      </Grid>

      <Grid item xs={12} md={1} sm={1}>
        <Box display="flex" alignItems="flex-end">
          <IconButton
            style={{
              position: 'relative',
              right: '-8px',
            }}
            onClick={() => arrayHelpers.remove(index)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};
