import feathers from '../../modules/feathers';

export function createActivity(activity: Object) {
  return feathers.service('activities').create(activity);
}

export function findRecentByCustomer(customerId: String) {
  return feathers.service('activities').find({
    query: {
      actionOn: customerId
    }
  });
}
