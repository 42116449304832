import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import config from 'config';
// import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Avatar from '@material-ui/core/Avatar';
import SettingsIcon from '@material-ui/icons/Settings';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';

import clsx from 'clsx';
import { lighten, makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';

import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { connect } from 'react-redux';
import { fetchIngredients } from '../../slices/ingredients/ingredientsSlice';
import { createCustomer } from '../../actions/customers/add';

import StatusIcon from '../../components/StatusIcon/StatusIcon';

import './IngredientsTable.css';
import Skeleton from '@material-ui/lab/Skeleton';
import { debounce } from 'lodash';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  {
    id: 'code',
    numeric: true,
    disablePadding: true,
    label: 'Code',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Category',
  },
  {
    id: 'refuse',
    numeric: false,
    disablePadding: false,
    label: 'Refuse amount',
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all customers' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={
              headCell.label === 'Address' ||
              headCell.label === 'Last Order' ||
              headCell.label === 'Total Spent'
                ? classes.hiddenBelowSm
                : null
            }
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    width: '100%',
  },
  tabs: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: '1px',
  },
  filterChips: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  const [value, setValue] = React.useState('All');
  const [category, setCategory] = React.useState('All');
  const [searchText, setSearchText] = React.useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);

    props.fetchIngredients(
      {
        FoodCode: 1,
        //prettier-ignore
        type: newValue,
        category,
      },
      searchText
    );
  };

  // useEffect(() => {
  //   props.fetchIngredients(
  //     {
  //       FoodCode: 1,
  //       //prettier-ignore
  //       type: value,
  //       category,
  //     },
  //     searchText
  //   );
  // }, [category]);

  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);

  const openFilterMenu = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const closeFilterMenu = () => {
    setFilterAnchorEl(null);
  };

  // Popover
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);

  const openPopover = (target) => {
    setPopoverAnchorEl(target);
  };

  const closePopover = () => {
    setPopoverAnchorEl(null);
  };

  const addFilter = (event, type) => {
    console.log(event);

    if (type === 'status') {
      openPopover(event.currentTarget);
    }
  };

  const [filterStatusCheckboxes, setStatusFilterState] = React.useState({
    active: false,
    abandoned: false,
    paused: false,
    cancelled: false,
  });

  const handleStatusFilterChange = (name) => (event) => {
    setStatusFilterState({
      ...filterStatusCheckboxes,
      [name]: event.target.checked,
    });
  };

  const onChangeSearchText = (searchText) => {
    console.log('onChangeSearchText');

    props.fetchIngredients(
      {
        FoodCode: 1,
        type: value,
        category,
      },
      searchText
    );
  };

  const debouncedSearchFunc = debounce(onChangeSearchText, 400);

  const debouncedSearch = (value) => {
    debouncedSearchFunc(value);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={(ev, newValue) => {
          console.log(ev);
          console.log(newValue);
          handleChange(ev, newValue);
        }}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab value="All" label="All" />
        <Tab value="Custom" label="Custom" />
        <Tab value="CNF" label="Canada Nutrient File" />

        {/* 
        
        <Tab value="Dairy and Egg Products" label="Dairy and Egg Products" />
        <Tab value="Spices and Herbs" label="Spices and Herbs" />
        <Tab value="Babyfoods" label="Babyfoods" />
        <Tab value="Fats and Oils" label="Fats and Oils" />
        <Tab value="Poultry Products" label="Poultry Products" />
        <Tab
          value="Soups, Sauces and Gravies"
          label="Soups, Sauces and Gravies"
        />
        <Tab
          value="Sausages and Luncheon meats"
          label="Sausages and Luncheon meats"
        />
        <Tab value="Breakfast cereals" label="Breakfast cereals" />
        <Tab value="Fruits and fruit juices" label="Fruits and fruit juices" />
        <Tab value="Pork Products" label="Pork Products" />
        <Tab
          value="Vegetables and Vegetable Products"
          label="Vegetables and Vegetable Products"
        />
        <Tab value="Nuts and Seeds" label="Nuts and Seeds" />
        <Tab value="Beef Products" label="Beef Products" />
        <Tab value="Beverages" label="Beverages" />
        <Tab
          value="Finfish and Shellfish Products"
          label="Finfish and Shellfish Products"
        />
        <Tab
          value="Legumes and Legume Products"
          label="Legumes and Legume Products"
        />
        <Tab value="Lamb, Veal and Game" label="Lamb, Veal and Game" />
        <Tab value="Baked Products" label="Baked Products" />
        <Tab value="Sweets" label="Sweets" />
        <Tab
          value="Cereals, Grains and Pasta"
          label="Cereals, Grains and Pasta"
        />
        <Tab value="Fast Foods" label="Fast Foods" />
        <Tab value="Mixed Dishes" label="Mixed Dishes" />
        <Tab value="Snacks" label="Snacks" /> 
        
        */}
      </Tabs>

      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              onChange={(ev) => {
                setSearchText(ev.target.value);
                debouncedSearch(ev.target.value);
              }}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{
                width: '100%',
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton
              onClick={() => props.openDeleteCustomersDialog()}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter">
            <IconButton onClick={openFilterMenu} aria-label="filter">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}

        <Menu
          id="filter-menu"
          anchorEl={filterAnchorEl}
          keepMounted
          open={Boolean(filterAnchorEl)}
          onClose={closeFilterMenu}
        >
          <MenuItem onClick={() => setCategory('Dairy and Egg Products')}>
            Dairy and Egg Products
          </MenuItem>
          <MenuItem onClick={() => setCategory('Spices and Herbs')}>
            Spices and Herbs
          </MenuItem>
          <MenuItem onClick={() => setCategory('Babyfoods')}>
            Babyfoods
          </MenuItem>
          <MenuItem onClick={() => setCategory('Fats and Oils')}>
            Fats and Oils
          </MenuItem>
          <MenuItem onClick={() => setCategory('Poultry Products')}>
            Poultry Products
          </MenuItem>
          <MenuItem onClick={() => setCategory('Soups, Sauces and Gravies')}>
            Soups, Sauces and Gravies
          </MenuItem>
          <MenuItem onClick={() => setCategory('Sausages and Luncheon meats')}>
            Sausages and Luncheon meats
          </MenuItem>
          <MenuItem onClick={() => setCategory('Breakfast cereals')}>
            Breakfast cereals
          </MenuItem>
          <MenuItem onClick={() => setCategory('Fruits and fruit juices')}>
            Fruits and fruit juices
          </MenuItem>
          <MenuItem onClick={() => setCategory('Pork Products')}>
            Pork Products
          </MenuItem>
          <MenuItem
            onClick={() => setCategory('Vegetables and Vegetable Products')}
          >
            Vegetables and Vegetable Products
          </MenuItem>
          <MenuItem onClick={() => setCategory('Nuts and Seeds')}>
            Nuts and Seeds
          </MenuItem>
          <MenuItem onClick={() => setCategory('Beef Products')}>
            Beef Products
          </MenuItem>
          <MenuItem onClick={() => setCategory('Beverages')}>
            Beverages
          </MenuItem>
          <MenuItem
            onClick={() => setCategory('Finfish and Shellfish Products')}
          >
            Finfish and Shellfish Products
          </MenuItem>
          <MenuItem onClick={() => setCategory('Legumes and Legume Products')}>
            Legumes and Legume Products
          </MenuItem>
          <MenuItem onClick={() => setCategory('Lamb, Veal and Game')}>
            Lamb, Veal and Game
          </MenuItem>
          <MenuItem onClick={() => setCategory('Baked Products')}>
            Baked Products
          </MenuItem>
          <MenuItem onClick={() => setCategory('Sweets')}>Sweets</MenuItem>
          <MenuItem onClick={() => setCategory('Cereals, Grains and Pasta')}>
            Cereals, Grains and Pasta
          </MenuItem>
          <MenuItem onClick={() => setCategory('Fast Foods')}>
            Fast Foods
          </MenuItem>
          <MenuItem onClick={() => setCategory('Mixed Dishes')}>
            Mixed Dishes
          </MenuItem>
          <MenuItem onClick={() => setCategory('Snacks')}>Snacks</MenuItem>
        </Menu>

        <Popover
          id={Boolean(popoverAnchorEl) ? 'filter-popover' : undefined}
          open={Boolean(popoverAnchorEl)}
          anchorEl={popoverAnchorEl}
          onClose={closePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div
            style={{
              padding: '8px 16px',
              background: '#33474C',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography>Status</Typography>
            <IconButton
              style={{ color: '#FFF', position: 'relative', right: '-12px' }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div
            style={{
              minWidth: '200px',
              flexDirection: 'column',
              padding: '16px',
            }}
          >
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterStatusCheckboxes.active}
                      onChange={handleStatusFilterChange('active')}
                      value="active"
                    />
                  }
                  label="Active"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterStatusCheckboxes.paused}
                      onChange={handleStatusFilterChange('paused')}
                      value="paused"
                    />
                  }
                  label="Paused"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterStatusCheckboxes.cancelled}
                      onChange={handleStatusFilterChange('cancelled')}
                      value="cancelled"
                    />
                  }
                  label="Cancelled"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filterStatusCheckboxes.abandoned}
                      onChange={handleStatusFilterChange('abandoned')}
                      value="abandoned"
                    />
                  }
                  label="Abandoned"
                />
              </FormGroup>
            </FormControl>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                color="secondary"
                disabled={
                  !filterStatusCheckboxes.active &&
                  !filterStatusCheckboxes.paused &&
                  !filterStatusCheckboxes.cancelled &&
                  !filterStatusCheckboxes.abandoned
                }
              >
                Apply
              </Button>
            </div>
          </div>
        </Popover>
      </Toolbar>

      {/* <div className={classes.filterChips}>
        <Chip
          size="small"
          label="Status: Active"
          onDelete={() => {
            console.log('DELETE ME!');
          }}
        />
      </div> */}
    </div>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(2.5),
  },
  hiddenBelowSm: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <Tooltip title="First page" className={classes.hiddenBelowSm}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
      </Tooltip>

      <Tooltip title="Previous page">
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
      </Tooltip>

      <Tooltip title="Next page">
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Last page" className={classes.hiddenBelowSm}>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Tooltip>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
    maxHeight: 'calc(100vh - 450px)',
    minHeight: 'calc(100vh - 450px)',
    height: 'calc(100vh-450px)',
    overflowY: 'scroll',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  hiddenBelowSm: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  console.log(props.customers);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.customers.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.customers.length - page * rowsPerPage);

  const loadingTableRow = () => (
    <TableRow style={{ paddingLeft: '16px', paddingRight: '16px' }}>
      <TableCell padding="checkbox">
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell style={{ width: '20%' }}>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Skeleton variant="circle" />
          <Skeleton animation="wave" />
        </Box>
      </TableCell>
      <TableCell style={{ width: '30%' }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell style={{ width: '30%' }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell style={{ width: '15%' }}>
        <Skeleton animation="wave" />
      </TableCell>
    </TableRow>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          fetchIngredients={props.fetchIngredients}
          openDeleteCustomersDialog={props.openDeleteCustomersDialog}
          numSelected={selected.length}
        />

        {props.loading && (
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            {loadingTableRow()}
            {loadingTableRow()}
            {loadingTableRow()}
            {loadingTableRow()}
            {loadingTableRow()}
            {loadingTableRow()}
          </Table>
        )}

        {!props.loading && props.customers.length > 0 && (
          <div className={classes.tableWrapper}>
            <Table
              stickyHeader
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              size="small"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={props.customers.length}
              />
              <TableBody>
                {stableSort(props.customers, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    // console.log(row);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          onClick={(event) => handleClick(event, row._id)}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </TableCell>
                        <TableCell
                          id={labelId}
                          scope="row"
                          padding="none"
                          style={{ cursor: 'pointer', width: '10%' }}
                          onClick={(event) =>
                            props.history.push(`/ingredient/${row._id}`)
                          }
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {row.FoodCode}
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.hiddenBelowSm}
                          style={{ cursor: 'pointer', width: '55%' }}
                          onClick={(event) =>
                            props.history.push(`/ingredient/${row._id}`)
                          }
                        >
                          {!row.title ? row.FoodDescription : row.title}
                          {row.hasOwnProperty('type') && (
                            <Chip
                              style={{ marginLeft: '8px' }}
                              size="small"
                              label={row.type}
                            />
                          )}
                        </TableCell>

                        <TableCell
                          className={classes.hiddenBelowSm}
                          style={{ cursor: 'pointer', width: '20%' }}
                          onClick={(event) =>
                            props.history.push(`/ingredient/${row._id}`)
                          }
                        >
                          {row.hasOwnProperty('category') ? row.category : ''}
                        </TableCell>

                        <TableCell
                          className={classes.hiddenBelowSm}
                          style={{ cursor: 'pointer', width: '20%' }}
                          onClick={(event) =>
                            props.history.push(`/ingredient/${row._id}`)
                          }
                        >
                          {/* {row.hasOwnProperty('refuseAmounts') ? (
                            <>
                              {Object.keys(row.refuseAmounts).length > 1
                                ? row.refuseAmounts[
                                    Object.keys(row.refuseAmounts)[1]
                                  ] +
                                  `${Object.keys(row.refuseAmounts)[1]} ${
                                    row.refuseAmounts[
                                      Object.keys(row.refuseAmounts)[1]
                                    ].RefuseAmount
                                  }`
                                : 0}
                            </>
                          ) : (
                            ''
                          )} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={props.customers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </div>
        )}

        {!props.loading && props.customers.length === 0 && (
          <Box padding={2}>
            <Typography>No ingredients found</Typography>
          </Box>
        )}
      </Paper>
    </div>
  );
}

const customerPageStyles = makeStyles((theme) => ({
  fabIcon: {},
  exportButton: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  addCustomerButton: {},
}));

class Ingredients extends React.Component {
  state = {
    deleteCustomersDialogOpen: false,
    open: false,
    filter: {
      firstName: 1,
      lastName: 1,
      status: '',
      totalSpent: 0,
    },
    activeFilters: [],
    loading: true,
  };

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    try {
      await this.props.fetchIngredients({
        FoodCode: 1,
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  handleClickOpen = () => {
    console.log('yes');
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  addCustomer(customer) {
    this.props.addCustomer(customer);
  }

  openDeleteCustomersDialog = () => {
    this.setState({
      deleteCustomersDialogOpen: true,
    });
  };

  render() {
    return (
      <div>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{
                  marginTop: '1em',
                  marginBottom: '1em',
                }}
              >
                <Link
                  to="/"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'none' },
                  }}
                >
                  <Button style={{ textTransform: 'capitalize' }}>
                    <Typography>Dashboard</Typography>
                  </Button>
                </Link>
                <Typography variant="body1">Ingredients</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Box fontWeight="fontWeightMedium" fontSize="h4.fontSize" m={1}>
                Ingredients
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    console.log('Y?AY');
                  }}
                  size="small"
                  style={{
                    ...customerPageStyles.exportButton,
                  }}
                >
                  <VerticalAlignBottomIcon style={{ marginRight: '16px' }} />
                  Export
                </Button>
              </Box>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.props.history.push('/ingredients/new')}
                style={{
                  boxShadow: 'none',
                  '&:hover': { boxShadow: 'none' },
                }}
                className="add-class-button"
              >
                <PersonAddIcon style={{ marginRight: '16px' }} />
                Add Ingredient
              </Button>
            </Grid>
          </Grid>

          <Grid container>
            <EnhancedTable
              loading={this.state.loading}
              fetchIngredients={this.props.fetchIngredients}
              openDeleteCustomersDialog={this.openDeleteCustomersDialog}
              history={this.props.history}
              customers={
                !this.state.loading ? this.props.customers.data || [] : []
              }
            />
          </Grid>
        </Container>

        {/* <Fab className="fab-icon" color="primary" aria-label="add customer">
          <AddIcon />
        </Fab> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.ingredients.ingredients,
});

const mapDispatchToProps = { fetchIngredients, createCustomer };

export default connect(mapStateToProps, mapDispatchToProps)(Ingredients);
