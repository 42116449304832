import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Formik, FieldArray, FastField, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import ImageIcon from '@material-ui/icons/Image';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import EditIcon from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';

import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Tooltip from '@material-ui/core/Tooltip';
import { addProduct, fetchExtras } from '../../slices/products/productsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../slices/categories/categoriesSlice';
import { RootState } from '../../rootReducer';
import { DateRange } from '@material-ui/icons';

import './EditPlan.css';

export default function EditPlan(props: any) {
  // const classes = useStyles();
  const dispatch = useDispatch();

  const [productInfoLang, setProductInfoLang] = useState(0);
  const [pricingLang, setPricingLang] = useState(0);

  const [selectedExtraIndex, setSelectedExtraIndex] = useState(-1);

  const [currentSelectedVariant, setCurrentSelectedVariant] = useState(0);

  const [variants, setVariants] = useState([{ name: '' }]);

  const reorder = (
    list: Array<object>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: 8,
    width: '100%',
    display: 'flex',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      variants,
      result.source.index,
      result.destination.index
    );

    //@ts-ignore
    setVariants(items);
  };

  const categories: any = useSelector(
    (state: RootStateOrAny) => state.categories.categories
  );

  const categoriesLoading: any = useSelector(
    (state: RootStateOrAny) => state.categories.categoriesLoading
  );

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchExtras());
  }, []);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
            }}
          >
            <Link style={{ textDecoration: 'none' }} to="/">
              <Button style={{ textTransform: 'capitalize' }}>
                <Typography>Dashboard</Typography>
              </Button>
            </Link>
            <Button style={{ textTransform: 'capitalize' }}>
              <Typography>Settings</Typography>
            </Button>
            <Typography>Plan</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          title: '',
          titleFR: '',
          description: '',
          descriptionFR: '',
          type: 'Meals',
          category: '',
          subCategory: '',
          brand: '',
          variants: [
            {
              name: '',
              nameFR: '',
              price: '',
              thumbnailURL: '',
              largeImageURL: '',
              unit: '',
              value: '',
              default: true,
              quantity: '',
              upc: '',
              nutritionalFacts: {
                calories: 0,
                carbs: 0,
                protein: 0,
                fat: 0,
              },
            },
          ],
          extras: [],
          trackQuantity: false,
          maxQuantityInCart: '',
          continueSellingWhenOutOfStock: false,
          taxExempt: false,
          ingredients: [],
          salesChannels: [
            // {
            //   name: 'Online menu',
            //   schedule: [],
            //   available: false,
            //   enabled: false,
            // },
            // {
            //   name: 'Dining menu',
            //   schedule: [],
            //   available: true,
            //   enabled: false,
            // },
            // {
            //   name: 'Room service',
            //   schedule: [],
            //   available: false,
            //   enabled: false,
            // },
          ],
          availability: [],
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title is required'),
          description: Yup.string(),
          type: Yup.string().required('Type is required'),
          category: Yup.string(),
          // Fix this later, for extras type category is not required
          // Yup.string().required('Category is required'),
          subCategory: Yup.string(),
          brand: Yup.string(),
          maxQuantityInCart: Yup.number().min(1),

          variants: Yup.array()
            .of(
              Yup.object().shape(
                {
                  // name: Yup.string(),
                  name: Yup.string().when('value', {
                    is: (value: any) => !value || value === '',
                    then: Yup.string().required('Variant name is required'),
                    otherwise: Yup.string(),
                  }),
                  thumbnailURL: Yup.string(),
                  largeImageURL: Yup.string(),
                  price: Yup.number().required('Price is required'),
                  unit: Yup.string()
                    .when('name', {
                      is: (name: any) => !name || name === '',
                      then: Yup.string().required('Unit is required'),
                      otherwise: Yup.string(),
                    })
                    .when('value', {
                      is: (value: any) => !value || value == '',
                      then: Yup.string(),
                      otherwise: Yup.string().required('Unit is required'),
                    }),
                  value: Yup.string().when('name', {
                    is: (name: any) => !name || name == '',
                    then: Yup.string().required('Value is required'),
                    otherwise: Yup.string(),
                  }),
                  default: Yup.boolean(),
                  quantity: Yup.string(),
                  upc: Yup.string(),
                  nutritionalFacts: Yup.object().shape({
                    calories: Yup.number(),
                    carbs: Yup.number(),
                    protein: Yup.number(),
                    fat: Yup.number(),
                  }),
                },
                [['name', 'value']]
              )
            )
            .required('At least one variant is required')
            .min(1, 'At least one variant is required'),
          ingredients: Yup.array().of(
            Yup.object().shape({
              _id: Yup.string().required(),
              name: Yup.string().required('Item is required'),
            })
          ),

          salesChannels: Yup.array().of(Yup.object()),
          availability: Yup.array().of(
            Yup.object().shape({
              startDate: Yup.date().required(),
              endDate: Yup.date(),
              noEndDate: Yup.boolean().required(),
              frequency: Yup.string(),
              frequencyValue: Yup.string(),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);

          dispatch(addProduct(values, setSubmitting, props.history));
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: '20px 0 40px' }}
            >
              <Box
                fontWeight="fontWeightMedium"
                fontSize="h4.fontSize"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  mb={2}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    mb={2}
                  >
                    <Box display="flex" flexDirection="column">
                      {values.title.length > 0 ? values.title : 'Product name'}
                    </Box>
                  </Box>

                  <Box>
                    <Button
                      size="small"
                      style={{ marginRight: '1em', fontWeight: 400 }}
                    >
                      <LaunchIcon
                        style={{
                          fill: '#9b9b9b',
                          marginRight: '5px',
                          fontWeight: 400,
                        }}
                      />{' '}
                      View
                    </Button>
                    <Button size="small" style={{ fontWeight: 400 }}>
                      <DeleteIcon
                        style={{ fill: '#9b9b9b', marginRight: '5px' }}
                      />{' '}
                      Delete
                    </Button>
                  </Box>
                </Box>

                <Box>
                  <Button
                    style={{ marginRight: '1em' }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                    <CloudIcon
                      style={{ fill: '#9b9b9b', marginLeft: '10px' }}
                    />
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Paper style={{ padding: '0 24px 24px', marginBottom: '2em' }}>
                  {/* <Tabs
                    style={{
                      marginBottom: '2em',
                    }}
                    value={productInfoLang}
                    onChange={(ev: any, val: any) => setProductInfoLang(val)}
                    indicatorColor="primary"
                  >
                    <Tab label="English" />
                    <Tab label="French" />
                  </Tabs> */}
                  <Typography variant="h6">Categories</Typography>

                  {!categoriesLoading &&
                    categories.map((e: any, i: number) => (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography>{e.name}</Typography>
                          </Grid>
                        </Grid>
                      </>
                    ))}
                </Paper>

                <Paper style={{ padding: '0 24px 0', marginBottom: '2em' }}>
                  <FieldArray
                    name="variants"
                    render={(arrayHelpers) => (
                      <>
                        <Grid
                          style={{ marginBottom: '1em', paddingTop: '24px' }}
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="h6">Pricing</Typography>

                          {values.type !== 'Extras' && (
                            <Button
                              onClick={() =>
                                arrayHelpers.push({
                                  thumbnailURL: '',
                                  largeImageURL: '',
                                  name: '',
                                  nameFR: '',
                                  price: '',
                                  unit: '',
                                  value: '',
                                  default: false,
                                  quantity: '',
                                  upc: '',
                                  nutritionalFacts: {
                                    calories: 0,
                                    carbs: 0,
                                    protein: 0,
                                    fat: 0,
                                  },
                                })
                              }
                            >
                              <AddToPhotosIcon
                                style={{
                                  marginRight: '8px',
                                  color: 'rgba(0, 0, 0, 0.54)',
                                }}
                              />
                              Add variant
                            </Button>
                          )}
                        </Grid>

                        {values.variants && values.variants.length > 0
                          ? values.variants.map((variant, index) => (
                              <>
                                <Grid container spacing={3}>
                                  {values.type !== 'Extras' && (
                                    <Grid item style={{ flex: 1 }}>
                                      {variant.thumbnailURL ? (
                                        <Box
                                          width="125px"
                                          className="image-added-container"
                                        >
                                          <img
                                            src={`https://s3.amazonaws.com/vittle-new/${variant.thumbnailURL}`}
                                            width="100%"
                                          />

                                          <div>
                                            <Tooltip title="Remove image">
                                              <IconButton
                                                onClick={() =>
                                                  setFieldValue(
                                                    `variants.${index}.thumbnailURL`,
                                                    ''
                                                  )
                                                }
                                              >
                                                <DeleteIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        </Box>
                                      ) : (
                                        <Box
                                          width="125px"
                                          height="125px"
                                          marginTop={2}
                                          border="2px dashed #bbb"
                                          display="flex"
                                          textAlign="center"
                                          justifyContent="center"
                                          alignItems="center"
                                          flexDirection="column"
                                          className="add-image-container"
                                          onClick={() => {
                                            setCurrentSelectedVariant(index);
                                          }}
                                        >
                                          <ImageIcon
                                            style={{ marginBottom: '8px' }}
                                          />

                                          <Typography variant="caption">
                                            Add image
                                          </Typography>
                                        </Box>
                                      )}
                                    </Grid>
                                  )}
                                  <Grid item style={{ flex: 4 }}>
                                    <Grid container spacing={3}>
                                      <Grid item xs={12} md={4} sm={4}>
                                        <TextField
                                          label="Variant name"
                                          fullWidth
                                          margin="normal"
                                          name={
                                            productInfoLang === 0
                                              ? `variants.${index}.name`
                                              : `variants.${index}.nameFR`
                                          }
                                          value={
                                            productInfoLang === 0
                                              ? values.variants[index].name
                                              : values.variants[index].nameFR ||
                                                ''
                                          }
                                          error={
                                            touched.variants &&
                                            //@ts-ignore
                                            errors.variants &&
                                            //@ts-ignore
                                            //@ts-ignore
                                            errors.variants[index] &&
                                            //@ts-ignore
                                            //@ts-ignore
                                            (productInfoLang === 0
                                              ? //@ts-ignore
                                                //@ts-ignore
                                                errors.variants[index].name
                                              : //@ts-ignore
                                                //@ts-ignore
                                                errors.variants[index].nameFR)
                                          }
                                          helperText={
                                            <ErrorMessage
                                              name={
                                                productInfoLang === 0
                                                  ? `variants.${index}.name`
                                                  : `variants.${index}.nameFR`
                                              }
                                            />
                                          }
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4} sm={4}>
                                        <TextField
                                          margin="normal"
                                          fullWidth
                                          select
                                          label="Select unit"
                                          placeholder="Volume"
                                          name={`variants.${index}.unit`}
                                          value={values.variants[index].unit}
                                          onChange={(ev: any) => {
                                            console.log(ev.target.value);
                                            setFieldValue(
                                              `variants.${index}.unit`,
                                              ev.target.value
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          error={
                                            touched.variants &&
                                            errors.variants &&
                                            errors.variants[index] &&
                                            //@ts-ignore
                                            errors.variants[index].unit
                                          }
                                          helperText={
                                            <ErrorMessage
                                              name={`variants[${index}].unit`}
                                            />
                                          }
                                        >
                                          <ListSubheader>Volume</ListSubheader>
                                          <MenuItem value="tsp">
                                            Teaspoon (tsp)
                                          </MenuItem>
                                          <MenuItem value="tbsp">
                                            Tablespoon (tbsp)
                                          </MenuItem>
                                          <MenuItem value="floz">
                                            Fluid ounce (fl oz)
                                          </MenuItem>
                                          <MenuItem value="mL">
                                            Milliliter (mL)
                                          </MenuItem>
                                          <MenuItem value="L">
                                            Liter (L)
                                          </MenuItem>
                                          <ListSubheader>
                                            Mass and weight
                                          </ListSubheader>
                                          <MenuItem value="lb">
                                            Pound (lb)
                                          </MenuItem>
                                          <MenuItem value="oz">
                                            Ounce (oz)
                                          </MenuItem>
                                          <MenuItem value="mg">
                                            Milligram (mg)
                                          </MenuItem>
                                          <MenuItem value="g">
                                            Gram (g)
                                          </MenuItem>
                                          <MenuItem value="kg">
                                            Kilogram (kg)
                                          </MenuItem>
                                        </TextField>
                                      </Grid>
                                      <Grid item xs={12} md={4} sm={4}>
                                        <Box
                                          display="flex"
                                          alignItems="flex-end"
                                        >
                                          <TextField
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name={`variants.${index}.value`}
                                            value={values.variants[index].value}
                                            error={
                                              touched.variants &&
                                              errors.variants &&
                                              errors.variants[index] &&
                                              //@ts-ignore
                                              errors.variants[index].value
                                            }
                                            helperText={
                                              <ErrorMessage
                                                name={`variants[${index}].value`}
                                              />
                                            }
                                            InputProps={{
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  {values.variants[index].unit}
                                                </InputAdornment>
                                              ),
                                            }}
                                            margin="normal"
                                            fullWidth
                                            label="Value"
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12} md={4}>
                                        <TextField
                                          label="Price"
                                          name={`variants.${index}.price`}
                                          value={values.variants[index].price}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          error={
                                            touched.variants &&
                                            errors.variants &&
                                            errors.variants[index] &&
                                            //@ts-ignore
                                            errors.variants[index].price
                                          }
                                          helperText={
                                            <ErrorMessage
                                              name={`variants[${index}].price`}
                                            />
                                          }
                                          fullWidth
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                $
                                              </InputAdornment>
                                            ),
                                          }}
                                          margin="normal"
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <TextField
                                          label="Quantity"
                                          fullWidth
                                          margin="normal"
                                          type="number"
                                          name={`variants.${index}.quantity`}
                                          value={
                                            values.variants[index].quantity
                                          }
                                          error={
                                            touched.variants &&
                                            errors.variants &&
                                            errors.variants[index] &&
                                            //@ts-ignore
                                            errors.variants[index].quantity
                                          }
                                          helperText={
                                            <ErrorMessage
                                              name={`variants[${index}].quantity`}
                                            />
                                          }
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={4}>
                                        <TextField
                                          label="UPC"
                                          name={`variants.${index}.upc`}
                                          value={values.variants[index].upc}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          fullWidth
                                          margin="normal"
                                          error={
                                            touched.variants &&
                                            errors.variants &&
                                            errors.variants[index] &&
                                            //@ts-ignore
                                            errors.variants[index].upc
                                          }
                                          helperText={
                                            <ErrorMessage
                                              name={`variants[${index}].upc`}
                                            />
                                          }
                                        />
                                      </Grid>

                                      {values.type !== 'Extras' && (
                                        <Grid
                                          item
                                          xs={12}
                                          md={12}
                                          style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                          }}
                                        >
                                          <Box
                                            display="flex"
                                            justifyContent="space-between"
                                          >
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  name="makeDefault"
                                                  checked={index === 0}
                                                />
                                              }
                                              checked={index === 0}
                                              onChange={(ev: any) => {
                                                console.log(ev.target.value);
                                              }}
                                              label="Make default variant"
                                            />

                                            {index > 0 && (
                                              <Tooltip
                                                title="Delete variant"
                                                onClick={() => {
                                                  setCurrentSelectedVariant(0);
                                                  arrayHelpers.remove(index);
                                                }}
                                              >
                                                <IconButton
                                                  style={{
                                                    marginRight: '-16px',
                                                  }}
                                                >
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          </Box>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>

                                {values.variants.length !== index + 1 && (
                                  <div
                                    style={{
                                      borderTop: '1px solid rgba(0,0,0,0.12)',
                                      margin: '24px 0 24px',
                                    }}
                                  ></div>
                                )}
                              </>
                            ))
                          : ''}
                      </>
                    )}
                  />

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px -24px 24px',
                    }}
                  ></div>

                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Inventory</Typography>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={<Checkbox name="trackQty" />}
                        label="Track quantity"
                        value={values.trackQuantity}
                        onChange={(ev: any) => {
                          console.log(ev.target.value);
                          setFieldValue('trackQuantity', !values.trackQuantity);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox name="continueSellingWhenOutOfStock" />
                        }
                        label="Continue selling when out of stock"
                        onChange={(ev: any) => {
                          console.log(ev.target.value);
                          setFieldValue(
                            'continueSellingWhenOutOfStock',
                            !values.continueSellingWhenOutOfStock
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        label="Max quantity in cart"
                        name={`maxQuantityInCart`}
                        value={values.maxQuantityInCart}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        margin="normal"
                        error={Boolean(
                          touched.maxQuantityInCart && errors.maxQuantityInCart
                        )}
                        helperText={<ErrorMessage name={`maxQuantityInCart`} />}
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px -24px 24px',
                    }}
                  ></div>

                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Tax</Typography>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12} style={{ paddingBottom: '1.5em' }}>
                      <FormControlLabel
                        control={<Checkbox name="taxExempt" />}
                        label="Product is tax exempt"
                        onChange={(ev: any) => {
                          console.log(ev.target.value);
                          setFieldValue('taxExempt', !values.taxExempt);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </Paper>

                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Ingredients</Typography>
                    <Button disabled={pricingLang === 1}>
                      <AddBoxIcon
                        style={{
                          marginRight: '8px',
                          color: 'rgba(0, 0, 0, 0.54)',
                        }}
                      />
                      Add ingredient
                    </Button>
                  </Grid>

                  {values.ingredients.length === 0 && (
                    <Typography style={{ marginTop: '30px' }}>
                      No ingredients added
                    </Typography>
                  )}

                  <FieldArray
                    name="ingredients"
                    render={(arrayHelpers) =>
                      values.ingredients.map((ing, index) => (
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <TextField
                              margin="normal"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <SearchIcon style={{ fill: '#9b9b9b' }} />
                                ),
                              }}
                              //@ts-ignore
                              value={values.ingredients[index].name}
                              label="Search ingredient"
                            />
                          </Grid>
                        </Grid>
                      ))
                    }
                  />
                </Paper>

                {values.type !== 'Extras' && (
                  <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                    <Grid
                      style={{ marginBottom: '1em' }}
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h6">Extras</Typography>
                      <Button disabled={pricingLang === 1} onClick={() => {}}>
                        <AddBoxIcon
                          style={{
                            marginRight: '8px',
                            color: 'rgba(0, 0, 0, 0.54)',
                          }}
                        />
                        Add
                      </Button>
                    </Grid>

                    {values.extras.length === 0 && (
                      <Typography style={{ marginTop: '30px' }}>
                        No extras added
                      </Typography>
                    )}

                    <FieldArray
                      name="extras"
                      render={(arrayHelpers) => {
                        return (
                          <div>
                            {values.extras.map((extra, index) => (
                              <Grid container spacing={3}>
                                <Grid item xs={12}>
                                  <TextField
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                      startAdornment: (
                                        <SearchIcon
                                          style={{ fill: '#9b9b9b' }}
                                        />
                                      ),
                                    }}
                                    //@ts-ignore
                                    value={values.extras[index].name}
                                    label="Search ingredient"
                                  />
                                </Grid>
                              </Grid>
                            ))}
                          </div>
                        );
                      }}
                    />
                  </Paper>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <div>
                      <Typography variant="h6">Product Status</Typography>
                      {/* <Typography
                        variant="body2"
                        style={{ color: '#999', marginBottom: '8px' }}
                      >
                        Available on 3 of 3 channels
                      </Typography> */}
                    </div>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center">
                          <div
                            style={{
                              width: '12px',
                              height: '12px',
                              marginLeft: '8px',
                              marginRight: '24px',
                              borderRadius: '50%',
                              backgroundColor: '#4caf50',
                            }}
                          ></div>
                          Active
                        </Box>

                        <FormControlLabel
                          labelPlacement="start"
                          control={
                            <Switch
                              checked
                              // checked={values.salesChannels[2].available}
                            />
                          }
                          name="status"
                          label=""
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '24px -24px 24px',
                    }}
                  ></div>

                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <div>
                      <Typography variant="h6">Fulfillment</Typography>
                      {/* <Typography
                        variant="body2"
                        style={{ color: '#999', marginBottom: '8px' }}
                      >
                        Available on 3 of 3 channels
                      </Typography> */}
                    </div>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center">
                          <LocalShippingIcon
                            style={{
                              marginLeft: '4px',
                              marginRight: '16px',
                              fill: 'rgb(153, 153, 153)',
                            }}
                          />
                          Delivery
                        </Box>

                        <FormControlLabel
                          labelPlacement="start"
                          // onChange={handleChange}
                          control={
                            <Switch
                              checked
                              // checked={values.salesChannels[2].available}
                            />
                          }
                          name="salesChannels.2.available"
                          label={
                            ''
                            // values.salesChannels[2].available ? 'Available' : 'Sold out'
                          }
                        />
                      </Box>
                    </Grid>

                    {/* 
                    
                    <Grid item xs={12} style={{ paddingTop: '0' }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center">
                          <CalendarTodayIcon
                            fontSize="small"
                            style={{
                              fontSize: '16px',
                              marginLeft: '8px',
                              marginRight: '20px',
                              fill: 'rgb(153, 153, 153)',
                            }}
                          />
                          <Typography
                            style={{ marginTop: '1px' }}
                            variant="body2"
                            color="textSecondary"
                          >
                            All times
                          </Typography>
                        </Box>
                        <IconButton
                          // onClick={() => setScheduleDialogOpen(true)}
                          style={{ position: 'relative', right: '-11px' }}
                        >
                          <EditIcon
                            style={{
                              fontSize: '16px',
                              fill: 'rgb(153, 153, 153)',
                            }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Box>
                    </Grid> 
                    
                    */}

                    <Grid item xs={12}>
                      <div
                        style={{
                          borderTop: '1px solid rgba(0,0,0,0.12)',
                          margin: '0x 0 0',
                        }}
                      ></div>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center">
                          <DirectionsWalkIcon
                            style={{
                              marginLeft: '4px',
                              marginRight: '16px',
                              fill: 'rgb(153, 153, 153)',
                            }}
                          />
                          Pickup
                        </Box>

                        <FormControlLabel
                          labelPlacement="start"
                          // onChange={handleChange}
                          control={
                            <Switch
                            // checked={values.salesChannels[2].available}
                            />
                          }
                          name="salesChannels.2.available"
                          label={
                            ''
                            // values.salesChannels[2].available ? 'Available' : 'Sold out'
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '24px -24px 24px',
                    }}
                  ></div>
                  <FieldArray
                    name="availability"
                    render={(arrayHelpers) => (
                      <div>
                        <Grid
                          style={{ marginBottom: '1em' }}
                          container
                          direction="row"
                          alignItems="flex-start"
                          justifyContent="space-between"
                        >
                          {/* <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    > */}
                          <Typography variant="h6">Availability</Typography>

                          <Button onClick={() => {}}>
                            <AddBoxIcon
                              style={{
                                marginRight: '8px',
                                color: 'rgba(0,0,0,.54)',
                              }}
                            />{' '}
                            Add
                          </Button>
                          {/* </Box> */}
                        </Grid>

                        {values.availability &&
                          values.availability.length === 0 && (
                            <Grid xs={12} md={12} style={{ marginTop: '20px' }}>
                              <Box>
                                <Typography>No availability added</Typography>
                              </Box>
                            </Grid>
                          )}

                        {/* <Grid xs={12} md={12} style={{ marginTop: '20px' }}>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                            >
                              <DateRange style={{ marginRight: '12px' }} />

                              <Box display="flex" flexDirection="column">
                                <Typography variant="body1">
                                  Monday, July 1, 2021 to
                                  <br />
                                  Wednesday, December 30, 2021
                                </Typography>

                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                >
                                  Every weekday
                                </Typography>
                              </Box>
                            </Box>

                            <IconButton>
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Grid> */}

                        {values.availability &&
                          values.availability.length > 0 &&
                          values.availability.map(
                            (availability: any, index: number) => (
                              <>
                                <Grid
                                  xs={12}
                                  md={12}
                                  style={{ marginTop: '20px' }}
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                    >
                                      {availability.noEndDate ? (
                                        <EventIcon
                                          style={{ marginRight: '12px' }}
                                        />
                                      ) : (
                                        <DateRange
                                          style={{ marginRight: '12px' }}
                                        />
                                      )}

                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                      >
                                        <Typography variant="body1">
                                          {moment(
                                            availability.startDate
                                          ).format('dddd[,] MMMM D, YYYY')}

                                          {!availability.noEndDate && `to`}

                                          {!availability.noEndDate && <br />}

                                          {!availability.noEndDate &&
                                            moment(availability.endDate).format(
                                              'dddd[,] MMMM D, YYYY'
                                            )}
                                        </Typography>
                                        {availability.frequency !== '' && (
                                          <Typography
                                            variant="body1"
                                            color="textSecondary"
                                          >
                                            {availability.frequency}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>

                                    <IconButton>
                                      <EditIcon fontSize="small" />
                                    </IconButton>
                                  </Box>
                                </Grid>

                                {index >= 1 &&
                                  index !== values.availability.length && (
                                    <Grid
                                      xs={12}
                                      md={12}
                                      style={{ marginTop: '20px' }}
                                    >
                                      <Divider />
                                    </Grid>
                                  )}
                              </>
                            )
                          )}
                      </div>
                    )}
                  />
                </Paper>

                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6">Category</Typography>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        select
                        variant="outlined"
                        label="Select type"
                        value={values.type}
                        name="type"
                        onChange={(ev: any) =>
                          setFieldValue('type', ev.target.value)
                        }
                        onBlur={handleBlur}
                      >
                        <MenuItem value="Meals">Meals</MenuItem>
                        <MenuItem value="Grocery">Grocery</MenuItem>
                        <MenuItem value="Extras">Extras</MenuItem>
                        <MenuItem value="Ingredient">Ingredient</MenuItem>
                      </TextField>

                      {!categoriesLoading && categories && (
                        <TextField
                          margin="normal"
                          fullWidth
                          select
                          variant="outlined"
                          label="Select a category"
                          value={values.category}
                          name="category"
                          onChange={(ev: any) =>
                            setFieldValue('category', ev.target.value)
                          }
                          onBlur={handleBlur}
                          error={
                            errors.category && touched.category ? true : false
                          }
                          helperText={
                            errors.category &&
                            touched.category &&
                            errors.category
                          }
                        >
                          {categories.data
                            .filter((e: any) => e.type === values.type)
                            .map((e: any) => (
                              <MenuItem value={e.name}>{e.name}</MenuItem>
                            ))}
                        </TextField>
                      )}

                      {values.category &&
                      categories.data.find(
                        (e: any) => e.name == values.category
                      ).subCats.length !== 0 ? (
                        <>
                          <TextField
                            margin="normal"
                            fullWidth
                            select
                            variant="outlined"
                            label="Select a subcategory"
                            value={values.subCategory}
                            name="subCategory"
                            onChange={(ev: any) =>
                              setFieldValue('subCategory', ev.target.value)
                            }
                            onBlur={handleBlur}
                            error={
                              errors.subCategory && touched.subCategory
                                ? true
                                : false
                            }
                            helperText={
                              errors.subCategory &&
                              touched.subCategory &&
                              errors.subCategory
                            }
                          >
                            {categories.data
                              .find((e: any) => e.name == values.category)
                              .subCats.map((e: any) => (
                                <MenuItem value={e.name}>{e.name}</MenuItem>
                              ))}
                          </TextField>
                        </>
                      ) : (
                        ''
                      )}

                      {values.type === 'Grocery' && (
                        <TextField
                          margin="normal"
                          name="brand"
                          label="Brand"
                          variant="outlined"
                          fullWidth
                          value={values.brand}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={errors.brand && touched.brand ? true : false}
                          helperText={
                            errors.brand && touched.brand && errors.brand
                          }
                        />
                      )}
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px -24px 24px',
                    }}
                  ></div>

                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <div>
                      <Typography variant="h6">Tags</Typography>
                    </div>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        variant="outlined"
                        label="Add a tag"
                        style={{ marginBottom: '32px' }}
                      />

                      <Typography>No tags added</Typography>
                      {/* <Typography variant="body2" style={{ color: '#999' }}>
                        2 custom tags
                      </Typography>
                      <Chip
                        label="Vegetarian"
                        style={{
                          marginTop: '10px',
                          marginBottom: '10px',
                          marginRight: '8px',
                        }}
                        onDelete={() => null}
                      />
                      <Chip
                        label="Vegan"
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                        onDelete={() => null}
                      />
                      <div
                        style={{
                          borderTop: '1px solid rgba(0,0,0,0.12)',
                          margin: '16px 0 24px',
                        }}
                      ></div>

                      <Typography
                        variant="body2"
                        style={{ color: '#999', marginTop: 0 }}
                      >
                        1 auto tag
                      </Typography>
                      <Chip
                        label="Gluten free"
                        style={{ marginTop: '10px' }}
                        onDelete={() => null}
                      /> */}
                    </Grid>
                  </Grid>
                </Paper>

                <Paper style={{ padding: '0 24px 48px', marginBottom: '2em' }}>
                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    {values.variants.length > 1 && (
                      <Tabs
                        scrollButtons="on"
                        style={{
                          marginBottom: '2em',
                        }}
                        value={currentSelectedVariant}
                        onChange={(ev: any, val: any) => {
                          console.log(val);
                          setCurrentSelectedVariant(val);
                        }}
                        indicatorColor="primary"
                      >
                        {values.variants.map((e: any, ind: number) => (
                          <Tab
                            label={
                              e.name !== ''
                                ? e.name
                                : e.value !== '' && e.unit !== ''
                                ? `${e.value} ${e.unit}`
                                : `Variant ${ind + 1}`
                            }
                          />
                        ))}
                      </Tabs>
                    )}

                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      style={{
                        marginTop: values.variants.length == 1 ? '32px' : 0,
                      }}
                    >
                      <div>
                        <Typography variant="h6">Nutritional Facts</Typography>
                      </div>

                      <Button>
                        <EditIcon
                          style={{
                            marginRight: '8px',
                            color: 'rgba(0,0,0,.54)',
                          }}
                        />{' '}
                        Edit
                      </Button>
                    </Box>
                  </Grid>

                  <Grid
                    container
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <Grid xs={12} md={6}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Amount
                      </Typography>
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography
                        variant="body1"
                        align="right"
                        style={{ fontWeight: 'bold' }}
                      >
                        % Daily Value
                      </Typography>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid #ddd',
                      margin: '6px 0 6px',
                    }}
                  ></div>

                  <Grid container>
                    <Grid xs={12} md={6}>
                      <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>Calories</span>{' '}
                        {values.variants[0].nutritionalFacts.calories}
                      </Typography>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid #ddd',
                      margin: '6px 0 6px',
                    }}
                  ></div>

                  <Grid container style={{ marginTop: '6px' }}>
                    <Grid xs={12} md={6}>
                      <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>Fat</span>{' '}
                        {values.variants[0].nutritionalFacts.fat} g
                      </Typography>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid #ddd',
                      margin: '6px 0 6px',
                    }}
                  ></div>

                  <Grid container style={{ marginTop: '6px' }}>
                    <Grid xs={12} md={6}>
                      <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>
                          Carbohyrdates
                        </span>{' '}
                        {values.variants[0].nutritionalFacts.carbs} g
                      </Typography>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid #ddd',
                      margin: '6px 0 6px',
                    }}
                  ></div>

                  <Grid container style={{ marginTop: '6px' }}>
                    <Grid xs={12} md={6}>
                      <Typography variant="body1">
                        <span style={{ fontWeight: 'bold' }}>Protein</span>{' '}
                        {values.variants[0].nutritionalFacts.protein} g
                      </Typography>
                    </Grid>
                  </Grid>

                  {/* <div
                    style={{
                      borderTop: '1px solid #ddd',
                      margin: '6px 0 6px',
                    }}
                  ></div>

                  <Grid container>
                    <Grid xs={12} md={6}>
                      <Typography variant="body1" style={{ textIndent: '1em' }}>
                        Saturated 1.5 g
                      </Typography>
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid #ddd',
                      margin: '6px 0 6px',
                    }}
                  ></div>

                  <Grid container>
                    <Grid xs={12} md={6}>
                      <Typography variant="body1" style={{ textIndent: '1em' }}>
                        + Trans 0 g
                      </Typography>
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="body1" align="right">
                        8%
                      </Typography>
                    </Grid>
                  </Grid> */}

                  <Grid container spacing={2}></Grid>
                </Paper>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
}
