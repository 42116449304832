import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Menu from '@material-ui/core/Menu';

import EditIcon from '@material-ui/icons/Edit';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import StatusIcon from '../../components/StatusIcon/StatusIcon';
import PrintIcon from '@material-ui/icons/Print';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';

import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DoneIcon from '@material-ui/icons/Done';

import AddProductDialog from './AddProductDialog';
import PrintQRCodeDialog from './PrintQRCodeDialog';
import { ActivityItem } from '../Customer/CustomerActivityList';

import './AddTransfer.css';
import Tooltip from '@material-ui/core/Tooltip';

export default function Dashboard(props: any) {
  // const classes = useStyles();

  const [productInfoLang, setProductInfoLang] = useState(0);
  const [pricingLang, setPricingLang] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [printQRCodeDialogOpen, setPrintQRCodeDialogOpen] = useState(false);

  const handleCloseOnly = () => {
    setAnchorEl(null);
  };

  const handleClose = (event: any, status: String) => {
    // this.setState({ anchorEl: null, customerStatusChangeDialog: true });
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //@ts-ignore
    setAnchorEl(event.currentTarget);
  };

  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false);

  const [title, setTitle] = useState('');

  const [variants, setVariants] = useState([{ name: '' }]);

  const reorder = (
    list: Array<object>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: 8,
    width: '100%',
    display: 'flex',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      variants,
      result.source.index,
      result.destination.index
    );

    //@ts-ignore
    setVariants(items);
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
            }}
          >
            <Link style={{ textDecoration: 'none' }} to="/">
              <Button style={{ textTransform: 'capitalize' }}>
                <Typography>Dashboard</Typography>
              </Button>
            </Link>

            <Link style={{ textDecoration: 'none' }} to="/transfers">
              <Button style={{ textTransform: 'capitalize' }}>
                <Typography>Transfers</Typography>
              </Button>
            </Link>

            <Typography>#A2q642fws</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: '20px 0 40px' }}
      >
        <Box
          fontWeight="fontWeightMedium"
          fontSize="h4.fontSize"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <Box display="flex" flexDirection="column">
                #A2q642fws
              </Box>
            </Box>

            <Box mt={2} flexDirection="row">
              <Button
                size="small"
                onClick={() => setPrintQRCodeDialogOpen(true)}
                style={{ marginRight: '1em', fontWeight: 400 }}
              >
                <PrintIcon
                  style={{ marginRight: '6px', fill: 'rgb(155, 155, 155)' }}
                />{' '}
                Generate QR Code
              </Button>

              <Button size="small" style={{ fontWeight: 400 }}>
                <DeleteIcon style={{ fill: '#9b9b9b', marginRight: '5px' }} />{' '}
                Delete
              </Button>
            </Box>
          </Box>

          <Box>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              // onClick={handleClick}
            >
              <StatusIcon styles={{ marginRight: '5px' }} status={'active'} />
              Completed
              {/* <KeyboardArrowDownIcon /> */}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseOnly}
            >
              <MenuItem onClick={(e) => handleClose(e, 'Active')}>
                <StatusIcon styles={{ marginRight: '5px' }} status="active" />
                Completed
              </MenuItem>
              <MenuItem onClick={(e) => handleClose(e, 'Paused')}>
                <StatusIcon styles={{ marginRight: '5px' }} status="paused" />
                Pending
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Products</Typography>
            </Grid>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>Qty.</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                  // style={
                  //   {
                  //     // overflow: 'hidden',
                  //     // textOverflow: 'ellipsis',
                  //     // // whiteSpace: 'wrap',
                  //   }
                  // }
                  >
                    Beans, legumes, mung (green gram), raw
                    <br />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: '4px' }}
                    >
                      Ingredient
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>25 lbs.</TableCell>
                  <TableCell style={{ width: '20%' }}>1</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    Chicken, ground, lean, raw
                    <br />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: '4px' }}
                    >
                      Ingredient
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>22.27 Kg</TableCell>
                  <TableCell style={{ width: '20%' }}>1</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    style={{
                      borderBottom: 0,
                    }}
                  >
                    Vinegar Apple Cider
                    <br />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginTop: '4px' }}
                    >
                      Allens
                    </Typography>
                  </TableCell>
                  <TableCell style={{ width: '20%', borderBottom: 0 }}>
                    25 L
                  </TableCell>

                  <TableCell style={{ width: '20%', borderBottom: 0 }}>
                    1
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>

          <Paper style={{ padding: '24px' }}>
            <Typography variant="h6">Notes</Typography>

            <TextField
              // min="2"
              variant="outlined"
              rows="1"
              rowsMax="20"
              margin="normal"
              fullWidth
              multiline
              // defaultValue={this.props.customer.notes || ''}
              // onChange={(e: any) => {
              //   this.setState({
              //     note: e.target.value,
              //   });
              // }}
            />

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px 24px',
              }}
            ></div>

            <Typography variant="h6" style={{ marginBottom: '1em' }}>
              Activity
            </Typography>

            <div style={{ marginTop: '1em', marginBottom: '1.5em' }}>
              <Button
                size="small"
                style={{
                  textTransform: 'capitalize',
                  marginRight: '5px',
                  minWidth: '48px',
                  backgroundColor: 'rgba(0,0,0,0.09)',

                  // ...styles.notificationFilterSelected,
                }}
              >
                <Typography variant="body1" style={{ top: '3px' }}>
                  All
                </Typography>
              </Button>
              {/* <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Account</Typography>
              </Button>
              <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Billing</Typography>
              </Button>
              <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Delivery</Typography>
              </Button> */}
            </div>
            {/* 
            {this.props.customerActivityLoading &&
              this.props.activity === [] && <Grid container></Grid>} */}

            {/* {!this.props.customerActivityLoading && this.props.activity && ( */}
            <ActivityItem author="RANDOM_ID" action="completed-transfer" />
            {/* )} */}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography variant="h6">Supplier</Typography>
              <div style={{ marginRight: '-8px' }}>
                <Button disabled size="small">
                  Add
                </Button>
              </div>
            </Grid>
            {/* 
            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px 24px',
              }}
            ></div> */}

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Avatar style={{ marginRight: '8px' }}>S</Avatar>
                    <Typography>Sysco</Typography>
                  </Box>
                  <Box>
                    <IconButton style={{ marginRight: '-8px' }}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6">Additional details</Typography>
              </div>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  type="date"
                  value="2020-10-09"
                  label="Invoice date"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  label="Expected arrival"
                  type="date"
                  value="2020-10-09"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  label="Reference number"
                  style={{ marginBottom: '22px' }}
                />
              </Grid>
            </Grid>
            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px 24px',
              }}
            ></div>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography variant="h6">Attachments</Typography>
              <Button size="small" disabled>
                Add
              </Button>
            </Grid>
            {/* <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <InsertDriveFileIcon style={{ marginRight: '8px' }} />{' '}
                <Typography>Sysco Invoice</Typography>
              </Box>
              <Box style={{ marginRight: '-16px' }}>
                <IconButton>
                  <VisibilityIcon />
                </IconButton>
                <IconButton>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box> */}
            <Box
              width="100%"
              height="125px"
              marginTop={2}
              border="2px dashed #bbb"
              display="flex"
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className="add-image-container"
            >
              <AttachFileIcon style={{ marginBottom: '8px' }} />

              <Typography variant="caption">Add attachment</Typography>
            </Box>{' '}
          </Paper>
        </Grid>
      </Grid>

      <AddProductDialog
        open={addProductDialogOpen}
        close={() => setAddProductDialogOpen(false)}
      />

      <PrintQRCodeDialog
        open={printQRCodeDialogOpen}
        close={() => setPrintQRCodeDialogOpen(false)}
      />
    </Container>
  );
}
