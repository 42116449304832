import React from 'react';

import moment from 'moment';

import Container from '@material-ui/core/Container';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import CardBrandImage from '../../components/CardBrandImage/CardBrandImage';

export default function CustomerCardPaymentMethods(props: any) {
  const checkCardExpiring = (month: String, year: String) => {
    const currentMonth = moment().format('M');
    const currentYear = moment().format('YYYY');

    if (year != currentYear) {
      return '';
    }

    //@ts-ignore
    if (parseInt(month) < parseInt(currentMonth)) {
      return;
    }
    //@ts-ignore
    const monthDifference = parseInt(month) - parseInt(currentMonth);

    if (monthDifference == 1) {
      return (
        <Typography variant="body2" color="error">
          Card expires next month
        </Typography>
      );
    } else if (monthDifference > 1 && monthDifference <= 3) {
      return (
        <Typography variant="body2" color="error">
          Card expires in {monthDifference} months
        </Typography>
      );
    }
  };

  return (
    <React.Fragment>
      {props.paymentMethods &&
        props.paymentMethods.map((e: any, i: Number) => {
          //   console.log(e);
          return (
            <div style={{ width: '100%' }}>
              {i == 0 && (
                <Chip
                  size="small"
                  label="Primary"
                  style={{ marginBottom: '5px', marginLeft: '45px' }}
                />
              )}
              <Box width="100%" display="flex" justifyContent="space-between">
                <Box width="100%" display="flex" flexDirection="column">
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <CardBrandImage brandName={e.card.brand} />
                      <Typography variant="body2">
                        •••• {e.card.last4}
                        {'  '}
                        {e.card.exp_month > 10
                          ? e.card.exp_month
                          : `0${e.card.exp_month}`}
                        /{e.card.exp_year}
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                  <div>
                    {checkCardExpiring(e.card.exp_month, e.card.exp_year)}
                  </div>
                </Box>
              </Box>
            </div>
          );
        })}
    </React.Fragment>
  );
}
