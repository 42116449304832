import React from 'react';

import { withRouter } from 'react-router-dom';

import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import NumberFormat from 'react-number-format';

// import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';

import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { retrieveById, findPlace } from '../../api/canada-post';

import './AddCustomerDialog.css';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}

async function fetchPlaces(
  setOptions: Function,
  searchTerm: String,
  lastId: String,
  setLoading: Function,
  setOpen: Function
) {
  setLoading(true);

  const response = await findPlace(lastId, searchTerm);

  setLoading(false);
  console.log(response.data.Items);

  if (response.data.Items.length > 0) {
    setOptions(response.data.Items);
    setOpen(true);
  } else {
    setOptions([]);
  }
  // Object.keys(response).map(key => response[key].item[0]) as CountryType[]
}

async function fetchFinalAddress(Id: String, setDescriptiveAddress: Function) {
  const response = await retrieveById(Id);

  console.log(response);
  if (response.data.Items) {
    if (response.data.Items.length > 0) {
      // setAddressCorrect(true);
      setDescriptiveAddress(
        response.data.Items[1] ? response.data.Items[1] : response.data.Items[0]
      );
    }
  }
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            //@ts-ignore
            name: 'phone',
            //@ts-ignore
            id: 'phone',
            value: values.value,
          },
        });
      }}
      format="(###) ###-####"
      isNumericString
    />
  );
}

function AddCustomerDialog(props: any) {
  const addressComplete = require('../../assets/images/address-complete-en.png');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [lastId, setLastId] = React.useState('');
  const [addressCorrect, setAddressCorrect] = React.useState(false);
  const [descriptiveAddress, setDescriptiveAddress] = React.useState({} as any);
  const [finalAddressId, setFinalAddressId] = React.useState('');
  const [settingsOpen, setSettingsOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (searchTerm.length > 0 || lastId.length > 0) {
      fetchPlaces(setOptions, searchTerm, lastId, setLoading, setOpen);
    }
  }, [searchTerm, lastId]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const [address, setAddress] = React.useState('');

  const handleAutoCompleteSelected = (e: any, v: any) => {
    if (!v) {
      return;
    }

    if (!v.hasOwnProperty('Next')) {
      return;
    }

    if (v.Next === 'Find') {
      console.log('Going Find');
      setLastId(v.Id);
    }

    if (v.Next === 'Retrieve') {
      console.log('Going Retrieve');
      setLastId('');
      setSearchTerm('');
      setAddress(v.Text + ' ' + v.Description);
      setFinalAddressId(v.Id);
      fetchFinalAddress(v.Id, setDescriptiveAddress);
    }
  };

  const handleAutoCompleteBlur = () => {
    if (lastId.length > 0) {
      setLastId('');
      setSearchTerm('');
      setOpen(false);
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Add {props.secondary ? 'contact' : 'customer'}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.handleClose()}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>

      <DialogContent>
        <Grid container>
          <Grid item style={{ marginBottom: '10px' }}>
            <Typography style={{ display: 'flex', alignItems: 'center' }}>
              <AccountCircleIcon style={{ marginRight: '8px' }} /> Contact
              information
            </Typography>
          </Grid>
        </Grid>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            emailAddress: '',
            phone: '',
            address: '',
            customerAcceptsMarketing: true,
            settings: {
              language: 'EN',
            },
          }}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            console.log(values);

            let customer = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.emailAddress,
              phone: values.phone,
              acceptsMarketing: values.customerAcceptsMarketing,
              notes: '',
              secondary: false,
              settings: {
                language: values.settings.language,
              },
            };

            if (props.secondary) {
              //@ts-ignore
              customer.secondary = true;
              //@ts-ignore
              customer.primaryAccountId = props.primaryAccount._id;
              //@ts-ignore
              customer.subscriptionId = props.subscription._id;
            } else {
              //@ts-ignore
              customer.secondaryAccounts = [];
              //@ts-ignore
              customer.address = [
                {
                  canadaPostId: finalAddressId,
                  addressType: 'home',
                  Label: descriptiveAddress.Label,
                  SubBuilding: descriptiveAddress.SubBuilding,
                  BuildingNumber: descriptiveAddress.BuildingNumber,
                  BuildingName: descriptiveAddress.BuildingName,
                  Street: descriptiveAddress.Street,
                  City: descriptiveAddress.City,
                  Line1: descriptiveAddress.Line1,
                  Line2: descriptiveAddress.Line2,
                  Line3: descriptiveAddress.Line3,
                  Province: descriptiveAddress.Province,
                  PostalCode: descriptiveAddress.PostalCode,
                  Type: descriptiveAddress.Type || '',
                  primary: true,
                  buzzer: '',
                  notes: '',
                  deliveryOption: 'leaveAtDoor',
                  organization: '',
                },
              ];
            }

            console.log(descriptiveAddress);
            console.log(customer);

            props.createCustomer(customer, props.history);
            setSubmitting(false);

            if (props.secondary) {
              props.handleClose();
            }
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            emailAddress: props.secondary
              ? Yup.string().email()
              : Yup.string().email().required('Email address is required'),
            phone: props.secondary
              ? Yup.string()
              : Yup.string().required('Phone is required'),
            address: props.secondary
              ? Yup.string()
              : Yup.string().required('Address is required'),
          })}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    autoFocus
                    margin="none"
                    id="firstName"
                    name="firstName"
                    label="First name"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName ? true : false}
                    helperText={
                      errors.firstName && touched.firstName && errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    margin="none"
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName ? true : false}
                    helperText={
                      errors.lastName && touched.lastName && errors.lastName
                    }
                  />
                </Grid>
              </Grid>

              <TextField
                margin="normal"
                id="emailAddress"
                name="emailAddress"
                label="Email address"
                type="email"
                fullWidth
                value={values.emailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.emailAddress && touched.emailAddress ? true : false
                }
                helperText={
                  errors.emailAddress &&
                  touched.emailAddress &&
                  errors.emailAddress
                }
              />

              <FormControlLabel
                control={
                  <Checkbox
                    value="yes"
                    name="customerAcceptsMarketing"
                    checked={values.customerAcceptsMarketing}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                }
                label="Customer accepts marketing"
              />

              {errors.customerAcceptsMarketing &&
                touched.customerAcceptsMarketing &&
                errors.customerAcceptsMarketing}

              <Grid item xs={12} md={6}>
                <TextField
                  id="phone"
                  name="phone"
                  label="Phone number"
                  type="tel"
                  fullWidth
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.phone && touched.phone && errors.phone}
                  error={errors.phone && touched.phone ? true : false}
                  InputProps={{
                    inputComponent: NumberFormatCustom as any,
                  }}
                />
              </Grid>

              {!props.secondary && (
                <React.Fragment>
                  <Grid container style={{ marginTop: '30px' }}>
                    <Grid item>
                      <Typography
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <LocalShippingIcon style={{ marginRight: '8px' }} />
                        Delivery
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Autocomplete
                      freeSolo
                      style={{ width: '100%' }}
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      noOptionsText="No address found"
                      getOptionSelected={(option, value) =>
                        option.Text === value.Text
                      }
                      getOptionLabel={(option) =>
                        option.Text + ' ' + option.Description
                      }
                      onChange={(e: any, v: any) => {
                        handleAutoCompleteSelected(e, v);
                        handleChange(e);
                      }}
                      options={options}
                      loading={loading}
                      onBlur={(e: any) => {
                        handleAutoCompleteBlur();
                        handleBlur(e);
                      }}
                      clearOnEscape={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            errors.address && touched.address ? true : false
                          }
                          fullWidth
                          margin="normal"
                          label="Address"
                          name="address"
                          variant="standard"
                          onChange={(e: any) => {
                            setSearchTerm(e.target.value);
                            handleChange(e);
                          }}
                          value={values.address}
                          helperText={
                            errors.address && touched.address && errors.address
                          }
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {/* {!loading && addressCorrect ? <DoneIcon /> : null} */}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />

                    <Grid item xs={12}>
                      <img
                        style={{ marginTop: '10px' }}
                        alt="Address complete"
                        src={addressComplete}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}

              <Grid container style={{ marginTop: '30px' }} alignItems="center">
                <Grid item xs={6}>
                  <Typography style={{ display: 'flex', alignItems: 'center' }}>
                    <SettingsIcon style={{ marginRight: '8px' }} />
                    Settings
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <ExpandMoreIcon
                    onClick={() => setSettingsOpen(!settingsOpen)}
                  />
                </Grid>

                <Collapse
                  style={{ width: '100%', marginTop: '25px' }}
                  in={settingsOpen}
                >
                  <Grid xs={12} md={6}>
                    <Select
                      fullWidth
                      labelId="language"
                      id="language"
                      value={values.settings.language}
                      onChange={(ev) =>
                        setFieldValue('settings', {
                          language: ev.target.value,
                        })
                      }
                    >
                      <MenuItem value="EN">English</MenuItem>
                      <MenuItem value="FR">French</MenuItem>
                    </Select>
                  </Grid>
                </Collapse>
              </Grid>
              <div
                style={{
                  padding: '30px 0 20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={() => props.handleClose()}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  style={{ boxShadow: 'none' }}
                  disabled={isSubmitting || !dirty}
                >
                  {isSubmitting && (
                    <CircularProgress
                      style={{
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-8px',
                      }}
                      size={16}
                    />
                  )}

                  {props.secondary ? 'Add Contact' : 'Add Customer'}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(AddCustomerDialog);
