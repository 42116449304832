import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { RootState } from '../../rootReducer';

import { fetchExtras } from '../../slices/products/productsSlice';

export default (props: {
  open: boolean;
  close: any;
  arrayHelpers?: object;
  values?: any; //formik
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const extrasLoading: any = useSelector(
    (state: RootStateOrAny) => state.products.extrasLoading
  );

  const extras: any = useSelector((state: RootStateOrAny) => state.products.extras);

  useEffect(() => {
    dispatch(fetchExtras());
  }, []);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Add extra
          </Typography>
        </div>
        <div>
          <IconButton onClick={props.close}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>

      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Add extra
            </Typography>
          </Grid>

          {/* <Grid item xs={12}>
            <TextField
              variant="outlined"
              placeholder="Enter an extra name"
              fullWidth
            />
          </Grid> */}
        </Grid>
        <Grid container>
          {extrasLoading && (
            <div>
              <Skeleton variant="rect" width="100%" height={100} />
              <Skeleton variant="rect" width="100%" height={100} />
              <Skeleton variant="rect" width="100%" height={100} />
              <Skeleton variant="rect" width="100%" height={100} />
              <Skeleton variant="rect" width="100%" height={100} />
              <Skeleton variant="rect" width="100%" height={100} />
              <Skeleton variant="rect" width="100%" height={100} />
              <Skeleton variant="rect" width="100%" height={100} />
              <Skeleton variant="rect" width="100%" height={100} />
            </div>
          )}

          {!extrasLoading &&
            extras.data.map((extra: any, index: number) => (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  width="100%"
                  paddingY={3}
                  style={{
                    borderBottom:
                      index === extras.data.length - 1 ? 0 : '1px solid #ccc',
                  }}
                  justifyContent="space-between"
                >
                  <Typography>
                    {extra.title} {`$${extra.variants[0].price || ''}`}
                  </Typography>

                  <Button
                    variant="outlined"
                    onClick={() => {
                      props.values.extras.findIndex(
                        (e: any) => e.title === extra.title
                      ) >= 0
                        ? //@ts-ignore
                          //@ts-ignore
                          props.arrayHelpers.remove(
                            props.values.extras.findIndex(
                              (e: any) => e.title === extra.title
                            )
                          )
                        : //@ts-ignore
                          //@ts-ignore
                          props.arrayHelpers.push(extra);
                    }}
                  >
                    {props.values.extras.findIndex(
                      (e: any) => e.title === extra.title
                    ) >= 0
                      ? 'Remove'
                      : 'Add'}
                  </Button>
                </Box>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '24px 16px 16px' }}>
        <Button onClick={props.close} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
