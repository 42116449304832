import React, { Ref, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Breadcrumbs,
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ImageIcon from '@material-ui/icons/Image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CropIcon from '@material-ui/icons/Crop';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { lighten, makeStyles, useTheme, alpha } from '@material-ui/core/styles';
// import AddMediaDialog from './AddMediaDialog';

import feathers from '../../modules/feathers';
// import DeleteMediaDialog from './DeleteMediaDialog';
import { findMedia } from '../../slices/media/mediaSlice';
import { RootState } from '../../rootReducer';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    width: '100%',
  },
  tabs: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: '1px',
  },
  filterChips: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root2: {
    width: '100%',
  },
  media: {
    height: 200,
  },
}));

export default function (props: any) {
  const classes = useToolbarStyles();
  const dispatch = useDispatch();

  const media: any = useSelector((state: RootStateOrAny) => state.media.media);

  const [addMediaDialogOpen, setAddMediaDialogOpen] = useState(false);
  const [deleteMediaDialogOpen, setDeleteMediaDialogOpen] = useState(false);
  const [mediaToDelete, setMediaToDelete] = useState(null);

  const [selectedMedia, setSelectedMedia] = useState();
  const [selectedFileData, setSelectedFileData] = useState('');

  const onChangeSearchText = (event: any) => {
    const searchText = event.target.value;

    dispatch(
      findMedia({
        name: {
          $regex: new RegExp(`${searchText}`, 'ig'),
        },
      })
    );
  };

  let uploadFileInputRef: any = React.useRef(null);

  const selectFile = async (event: any) => {
    if (!uploadFileInputRef.files[0]) {
      return;
    }

    setSelectedFileData(uploadFileInputRef.files[0]);

    const fileReader = new FileReader();
    await fileReader.readAsDataURL(uploadFileInputRef.files[0]);

    fileReader.onload = (file: any) => {
      console.log(file);
      setSelectedMedia(file.target.result);
      setAddMediaDialogOpen(true);
    };
    fileReader.onerror = (error: any) => console.log(error);
  };

  useEffect(() => {
    const image = document.getElementById('image');
  }, [selectedMedia]);

  useEffect(() => {
    dispatch(findMedia());
  }, []);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <Link
              to="/"
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Button style={{ textTransform: 'capitalize' }}>
                <Typography>Dashboard</Typography>
              </Button>
            </Link>
            <Typography variant="body1">Settings</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: '40px' }}
      >
        <Grid item xs={12}>
          <Box fontWeight="fontWeightMedium" fontSize="h4.fontSize" m={1}>
            Settings
          </Box>
          <Box></Box>
        </Grid>

        {/* <Grid item>
          <Button
            style={{
              boxShadow: 'none',
            }}
            variant="contained"
            color="secondary"
            onClick={() => uploadFileInputRef.click()}
          >
            <AddPhotoAlternateIcon
              style={{ marginRight: '6px', marginTop: '-2px' }}
            />
            Upload
          </Button>
          <input
            type="file"
            onChange={selectFile}
            style={{ display: 'none' }}
            ref={(ref: HTMLInputElement) => (uploadFileInputRef = ref)}
          />
        </Grid> */}
      </Grid>

      {/* <Paper>
        <Box padding={2}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              onChange={onChangeSearchText}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{
                width: '100%',
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </Box>
      </Paper> */}

      <Grid container spacing={3} style={{ marginTop: '30px', margin: '8px' }}>
        <Grid item xs={12}>
          <Typography variant="h5">Taxes</Typography>
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant="body1">Ontario</Typography>
          <TextField
            fullWidth
            defaultValue="13.00"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="body1">Quebec</Typography>
          <TextField
            fullWidth
            type="number"
            defaultValue="14.75"
            InputProps={{
              type: 'number',
              //@ts-ignore
              //@ts-ignore
              step: '0.05',
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Button
            variant="contained"
            color="secondary"
            style={{
              boxShadow: 'none',
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      {/* <AddMediaDialog
        open={addMediaDialogOpen}
        handleClose={() => setAddMediaDialogOpen(false)}
        imageData={selectedMedia}
        file={selectedFileData}
      />

      {mediaToDelete !== null && (
        <DeleteMediaDialog
          open={deleteMediaDialogOpen}
          handleClose={() => setDeleteMediaDialogOpen(false)}
          media={mediaToDelete}
        />
      )} */}
    </Container>
  );
}
