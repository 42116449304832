export default function getDeliveryStatusNiceName(status: string) {
  return status === 'in-transit'
    ? 'In transit'
    : status === 'delivered'
    ? 'Delivered'
    : status === 'not-delivered'
    ? 'Not delivered'
    : status === 'delayed'
    ? 'Delayed'
    : '';
}
