import React from 'react';

interface CardBrandImageProps {
  brandName: String;
}

export default function CardBrandImage(props: CardBrandImageProps) {
  switch (props.brandName) {
    case 'visa':
      return (
        <img
          style={{ marginRight: '12px', width: '32px' }}
          src="/card-icons/visa.svg"
        />
      );
    case 'amex':
      return (
        <img
          style={{ marginRight: '12px', width: '32px' }}
          src="/card-icons/amex.svg"
        />
      );

    case 'diners':
      return (
        <img
          style={{ marginRight: '12px', width: '32px' }}
          src="/card-icons/dinersclub.svg"
        />
      );

    case 'jcb':
      return (
        <img
          style={{ marginRight: '12px', width: '32px' }}
          src="/card-icons/jcb.svg"
        />
      );

    case 'discover':
      return (
        <img
          style={{ marginRight: '12px', width: '32px' }}
          src="/card-icons/discover.svg"
        />
      );

    case 'mastercard':
      return (
        <img
          style={{ marginRight: '12px', width: '32px' }}
          src="/card-icons/mastercard.svg"
        />
      );

    default:
      return (
        <img
          style={{ marginRight: '12px', width: '32px' }}
          src="/card-icons/visa.svg"
        />
      );
  }
}
