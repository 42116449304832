// RTK;
import { configureStore } from '@reduxjs/toolkit';
// import { createLogger } from 'redux-logger';
import reducers from '../rootReducer';

// const logger = createLogger({});

export default configureStore({
  reducer: reducers,
  // middleware: [logger, ...getDefaultMiddleware()]
});
