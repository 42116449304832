import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@material-ui/core';

export default (props: { open: boolean; close: any }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Manage availability
          </Typography>
        </div>
        <div>
          <IconButton onClick={props.close}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        {/* <DialogContentText>Lorem ipsum</DialogContentText> */}

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              autoFocus
              id="startDate"
              label="Start date"
              type="date"
              fullWidth
              defaultValue="2021-06-18"
            />
            <FormControlLabel
              style={{ marginTop: '8px' }}
              control={
                <Checkbox
                  value="yes"
                  name="customerAcceptsMarketing"
                  // checked={values.customerAcceptsMarketing}
                  // onChange={handleChange}
                  // onBlur={handleBlur}
                />
              }
              label="No end date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="endDate"
              label="End date"
              type="date"
              defaultValue="2021-06-18"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Select fullWidth value="Every weekday">
              <MenuItem value="Every weekday">Every weekday</MenuItem>
              <MenuItem value={'18th every month'}>18th every month</MenuItem>
              <MenuItem value={'20'}>Anually on June 18</MenuItem>
              <MenuItem value={'30'}>Weekly on Friday</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          Cancel
        </Button>
        <Button onClick={props.close} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
