import React, { useState, useEffect } from 'react';

import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  ExpansionPanelSummary,
  CardContent,
  CardMedia,
  CardActionArea,
  Card,
  Divider,
} from '@material-ui/core';
import moment from 'moment';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from '../../slices/orders/ordersSlice';
import {
  fetchSubscriptionStatuses,
  fetchSubscription,
} from '../../slices/subscription/subscriptionSlice';
import { RootState } from '../../rootReducer';

interface CustomerSubscriptionProps {
  customer: any;
  subscription: object;
}

export default (props: CustomerSubscriptionProps) => {
  const dispatch = useDispatch();

  const [collapseWeekOpen, setCollapseWeekOpen] = React.useState(-1);

  console.log(props);
  console.log(props.subscription);

  useEffect(() => {
    dispatch(
      fetchOrders({
        customerId: props.customer._id,
        $limit: 10,
        $sort: {
          createdAt: -1,
        },
      })
    );

    dispatch(fetchSubscriptionStatuses());

    // dispatch(fetchSubscription(props.match.params.id));

    return () => {
      //cleanup later
    };
  }, []);

  const ordersLoading: boolean = useSelector(
    (state: RootStateOrAny) => state.orders.ordersLoading
  );

  const orders: any = useSelector(
    (state: RootStateOrAny) => state.orders.orders
  );

  const subscriptionStatuses: any = useSelector(
    (state: RootStateOrAny) => state.subscription.subscriptionStatuses
  );

  const subscriptionStatusesLoading: boolean = useSelector(
    (state: RootStateOrAny) => state.subscription.subscriptionStatusesLoading
  );

  const subscription: any = useSelector(
    (state: RootStateOrAny) => state.subscription.subscription
  );

  const subscriptionLoading: boolean = useSelector(
    (state: RootStateOrAny) => state.subscription.fetchSubscriptionLoading
  );

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <>
            <Grid container>
              {[0, 1, 2, 3, 4].map((weekNumber: number, index: number) => {
                const orderPresentIndex = orders.data.findIndex(
                  (order: any) =>
                    order.selectedWeekStartDate ===
                    moment()
                      .add(weekNumber, 'w')
                      .startOf('isoWeek')
                      .format('YYYY-MM-DD')
                );

                const subscriptionStatusIndexPresent =
                  subscriptionStatuses.data.findIndex(
                    (status: any) =>
                      status.weekOf ===
                        moment()
                          .add(weekNumber, 'w')
                          .startOf('isoWeek')
                          .format('YYYY-MM-DD') &&
                      status.customerId === props.customer._id
                  );

                return (
                  <>
                    <Typography
                      variant="h3"
                      style={{
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        marginTop: '1.25em',
                      }}
                    >
                      {weekNumber === 0
                        ? 'This week'
                        : weekNumber === 1
                        ? 'Next week'
                        : weekNumber === 2
                        ? 'Upcoming'
                        : ''}
                    </Typography>

                    <ExpansionPanel
                      expanded={collapseWeekOpen === index}
                      onChange={() => {
                        console.log('asd');
                        setCollapseWeekOpen(
                          collapseWeekOpen === index ? -1 : index
                        );
                      }}
                    >
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1.1rem', position: 'relative' }}
                        >
                          {`${moment()
                            .add(weekNumber, 'w')
                            .startOf('isoWeek')
                            .format('MMMM D')} - ${moment()
                            .add(weekNumber, 'w')
                            .startOf('isoWeek')
                            .add(5, 'd')
                            .format('MMMM D, YYYY')}`}
                          <span
                            style={{
                              position: 'absolute',
                              // right: '0',
                              top: '5px',
                              display: 'inline-block',
                              width: '8px',
                              height: '8px',
                              borderRadius: '50%',
                              backgroundColor: '#2196f3',
                              // backgroundColor: '#4caf50',
                              //  backgroundColor: '#ffc107',
                            }}
                          ></span>
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid
                          container
                          style={{ flexDirection: 'column' }}
                          spacing={2}
                        >
                          <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: '1.1rem',
                                  marginBottom: '1rem',
                                }}
                                color="textSecondary"
                              >
                                Monday, January 1
                              </Typography>
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: '1.1rem',
                                  marginBottom: '1rem',
                                }}
                                color="textSecondary"
                              >
                                $34.90
                              </Typography>
                            </Box>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={4}>
                                <Card
                                  style={{
                                    height: '100%',
                                    boxShadow: '0 0 1px 1px #eee',
                                  }}
                                >
                                  <CardActionArea
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                      alignItems: 'flex-start',
                                      height: '100%',
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      alt="Contemplative Reptile"
                                      height="140"
                                      image="https://s3.amazonaws.com/vittle-new/images/TgrCt4QDR2JBcDWzE.jpg"
                                    />
                                    <CardContent
                                      style={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        width: '88%',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{
                                          fontSize: '1rem',
                                          lineHeight: '1.4',
                                        }}
                                      >
                                        Beyond Meat Swedish Meatballs
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        Variant
                                      </Typography>

                                      <Box
                                        mt={1}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        width="100%"
                                      >
                                        {/* <Chip label="Vegetarian" size="small" /> */}
                                        <Typography variant="body2">
                                          $11.99
                                        </Typography>

                                        <Typography variant="body2">
                                          Qty: 3
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Card
                                  style={{
                                    height: '100%',
                                    boxShadow: '0 0 1px 1px #eee',
                                  }}
                                >
                                  <CardActionArea
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                      alignItems: 'flex-start',
                                      height: '100%',
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      alt="Contemplative Reptile"
                                      height="140"
                                      image="https://s3.amazonaws.com/vittle-new/images/n6y4KhBEDWcezuqxL.jpg"
                                    />
                                    <CardContent
                                      style={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        width: '88%',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{
                                          fontSize: '1rem',
                                          lineHeight: '1.4',
                                        }}
                                      >
                                        Steak and Eggs Benny
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        Variant
                                      </Typography>

                                      <Box
                                        mt={1}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        width="100%"
                                      >
                                        {/* <Chip label="Vegetarian" size="small" /> */}
                                        <Typography variant="body2">
                                          $11.99
                                        </Typography>

                                        <Typography variant="body2">
                                          Qty: 3
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Grid>

                              <Grid item xs={12} md={4}>
                                <Card
                                  style={{
                                    height: '100%',
                                    boxShadow: '0 0 1px 1px #eee',
                                  }}
                                >
                                  <CardActionArea
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                      alignItems: 'flex-start',
                                      height: '100%',
                                    }}
                                  >
                                    <CardMedia
                                      component="img"
                                      alt="Contemplative Reptile"
                                      height="140"
                                      image="https://s3.amazonaws.com/vittle-new/images/b5hBca9v4PhhgvxFm.jpg"
                                    />
                                    <CardContent
                                      style={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        width: '88%',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        style={{
                                          fontSize: '1rem',
                                          lineHeight: '1.4',
                                        }}
                                      >
                                        BBQ Chicken
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        Variant
                                      </Typography>

                                      <Box
                                        mt={1}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        width="100%"
                                      >
                                        {/* <Chip label="Vegetarian" size="small" /> */}
                                        <Typography variant="body2">
                                          $11.99
                                        </Typography>

                                        <Typography variant="body2">
                                          Qty: 3
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </CardActionArea>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* 
                      <Grid item xs={12} style={{ margin: '24px 0 0' }}>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginBottom: 0 }}
                          color="textSecondary"
                        >
                          Monday, January 2
                        </Typography>
                      </Grid> */}
                        </Grid>
                      </ExpansionPanelDetails>

                      <ExpansionPanelActions>
                        <Box display="flex" justifyContent="flex-start">
                          {/* <Button size="small">Cancel</Button> */}
                          <Button size="small">Order History</Button>
                        </Box>
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  </>
                );
              })}
            </Grid>
          </>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Preferences</Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
