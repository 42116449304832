import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  IconButton,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelActions,
  ExpansionPanelSummary,
  CardContent,
  CardMedia,
  CardActionArea,
  Card,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Radio,
  Checkbox,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import CloseIcon from '@material-ui/icons/Close';
import NoteIcon from '@material-ui/icons/Note';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsIcon from '@material-ui/icons/Directions';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';

export default function CustomerEditFulfillmentDialog(props: any) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { subscription } = props;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Edit fulfillment
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.toggleOpen(false)}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        <Formik
          initialValues={{
            deliveryType: subscription.deliveryType,
            deliveryTime: subscription.deliveryTime,
          }}
          validationSchema={Yup.object().shape({
            deliveryType: Yup.string().required(),
            deliveryTime: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);

            setSubmitting(true);

            // dispatch(.nguageSubmitting(true));
            // dispatch(
            //   updateDeliveryPreferences(
            //     subscription._id,
            //     values.deliveryTime,
            //     values.deliveryType,
            //     setSubmitting,
            //     () => {
            //       setSubmitting(false);
            //       //   dispatch(
            //       //     open({
            //       //       message: `Delivery preferences updated`,
            //       //       severity: 'success',
            //       //     })
            //       //   );
            //       props.toggleOPen();
            //     }
            //   )
            // );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <form className="form-login" onSubmit={handleSubmit}>
              <p className="offscreen-menu__content__section-heading">
                Delivery type
              </p>
              <ExpansionPanel
                expanded={values.deliveryType === 'delivery'}
                onClick={() => setFieldValue('deliveryType', 'delivery')}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                >
                  <Box width="100%" display="flex" alignItems="center">
                    <LocalShippingIcon />

                    <Box width="100%" ml={1}>
                      <p
                        className="offscreen-menu__content__section-heading fw-400 mb-0"
                        style={{
                          marginTop: '0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        Delivery
                      </p>
                      {/* <p
                style={{
                  marginTop: 0,
                  marginBottom: 0,
                  fontSize: '14px',
                  color: '#b9b2b6',
                }}
              >
                2:30-6:30 p.m.
              </p> */}
                    </Box>
                  </Box>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="flex-start"
                      onClick={() => setFieldValue('deliveryTime', 'dayOf')}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                        >
                          <WbSunnyIcon style={{ marginRight: '8px' }} />
                          <Box flexDirection="column" display="flex">
                            <p
                              className="offscreen-menu__content__section-heading fw-400 mb-0"
                              style={{
                                marginTop: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingRight: '36px',
                              }}
                            >
                              <span>Day of</span>
                            </p>
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: '14px',
                                color: '#b9b2b6',
                              }}
                            >
                              7:00-11:00 a.m.
                            </p>
                          </Box>
                        </Box>
                        <Box style={{ position: 'relative', left: '8px' }}>
                          <Radio
                            color="secondary"
                            onChange={(e: any) => {
                              setFieldValue('deliveryTime', 'dayOf');
                            }}
                            checked={values.deliveryTime === 'dayOf'}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      alignItems="flex-start"
                      mt={2}
                      onClick={() =>
                        setFieldValue('deliveryTime', 'nightBefore')
                      }
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box display="flex" alignItems="center">
                          <NightsStayIcon style={{ marginRight: '8px' }} />
                          <Box display="flex" flexDirection="column">
                            <p
                              className="offscreen-menu__content__section-heading fw-400 mb-0"
                              style={{
                                marginTop: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingRight: '36px',
                              }}
                            >
                              <span>Night before</span>
                            </p>
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: '14px',
                                color: '#b9b2b6',
                              }}
                            >
                              <span>2:30-6:30 p.m.</span>
                            </p>
                          </Box>
                        </Box>
                        <Box style={{ position: 'relative', left: '8px' }}>
                          <Radio
                            color="secondary"
                            onChange={(e: any) => {
                              setFieldValue('deliveryTime', 'nightBefore');
                            }}
                            checked={values.deliveryTime === 'nightBefore'}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </ExpansionPanelDetails>
              </ExpansionPanel>

              <Divider />

              <ExpansionPanel expanded={values.deliveryType === 'pickup'}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
                  onClick={() => setFieldValue('deliveryType', 'pickup')}
                >
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    onClick={() => {
                      setFieldValue('deliveryType', 'pickup');
                    }}
                  >
                    <DirectionsWalkIcon />

                    <Box width="100%" ml={1}>
                      <p
                        className="offscreen-menu__content__section-heading fw-400 mb-0"
                        style={{
                          marginTop: '0',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          paddingRight: '36px',
                        }}
                      >
                        <span>Pick up</span>
                      </p>
                      <p
                        style={{
                          marginTop: 0,
                          marginBottom: 0,
                          fontSize: '14px',
                          color: '#b9b2b6',
                        }}
                      >
                        Vittle
                      </p>
                    </Box>
                  </Box>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                  <>
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="flex-start"
                      onClick={() => setFieldValue('deliveryTime', 'dayOf')}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                        >
                          <WbSunnyIcon style={{ marginRight: '8px' }} />
                          <Box flexDirection="column" display="flex">
                            <p
                              className="offscreen-menu__content__section-heading fw-400 mb-0"
                              style={{
                                marginTop: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingRight: '36px',
                              }}
                            >
                              <span>Day of</span>
                            </p>
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: '14px',
                                color: '#b9b2b6',
                              }}
                            >
                              7:00-11:00 a.m.
                            </p>
                          </Box>
                        </Box>
                        <Box style={{ position: 'relative', left: '8px' }}>
                          <span className="color-primary-dark">Free</span>
                          <Radio
                            style={{ color: '#4caf50' }}
                            color="primary"
                            onChange={(e: any) => {
                              setFieldValue('deliveryTime', 'dayOf');
                            }}
                            checked={values.deliveryTime === 'dayOf'}
                          />
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      alignItems="flex-start"
                      mt={2}
                      onClick={() =>
                        setFieldValue('deliveryTime', 'nightBefore')
                      }
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box display="flex" alignItems="center">
                          <NightsStayIcon style={{ marginRight: '8px' }} />
                          <Box display="flex" flexDirection="column">
                            <p
                              className="offscreen-menu__content__section-heading fw-400 mb-0"
                              style={{
                                marginTop: '0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingRight: '36px',
                              }}
                            >
                              <span>Night before</span>
                            </p>
                            <p
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                fontSize: '14px',
                                color: '#b9b2b6',
                              }}
                            >
                              <span>2:30-4:00 p.m.</span>
                            </p>
                          </Box>
                        </Box>
                        <Box style={{ position: 'relative', left: '8px' }}>
                          <span className="color-primary-dark">Free</span>
                          <Radio
                            style={{ color: '#4caf50' }}
                            color="primary"
                            onChange={(e: any) => {
                              setFieldValue('deliveryTime', 'nightBefore');
                            }}
                            checked={values.deliveryTime === 'nightBefore'}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
