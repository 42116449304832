import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import CardSaveForm from "../../components/CardSaveForm/CardSaveForm";

export default function CustomerCardDialog(props: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: "15px 15px 15px 25px",
          backgroundColor: "#33474C",
          color: "#FFF",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400
            }}
          >
            Edit card details
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.handleCardOpen(false)}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        <CardSaveForm />

        {/* <div
          style={{
            padding: "30px 0 20px",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <Button
            style={{ marginRight: "10px" }}
            onClick={() => props.handleCardOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            // onClick={() => submitAddCustomerForm()}
            color="secondary"
            style={{ boxShadow: "none" }}
            // disabled={props.addCustomerLoading}
          >
            {/* {props.addCustomerLoading && (
              <CircularProgress
                style={{
                  position: "absolute",
                  left: "50%",
                  marginLeft: "-8px"
                }}
                size={16}
              />
            )} *            Save
          </Button>
        </div> */}
      </DialogContent>
    </Dialog>
  );
}
