import React, { SyntheticEvent } from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';

import axios from 'axios';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';

import NumberFormat from 'react-number-format';

// import Container from "@material-ui/core/Container";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';
import { RadioGroup, Radio } from '@material-ui/core';

import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';

import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import CategoryIcon from '@material-ui/icons/Category';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

function NutritionalDialog(props: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const currentSelectedVariant =
    props.values.variants[props.currentSelectedVariant];

  const currentSelectedVariantIndex = props.currentSelectedVariant + 1;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Nutritional facts for{' '}
            {currentSelectedVariant.name !== ''
              ? currentSelectedVariant.name
              : currentSelectedVariant.value !== '' &&
                currentSelectedVariant.unit !== ''
              ? `${currentSelectedVariant.value} ${currentSelectedVariant.unit}`
              : `Variant ${currentSelectedVariantIndex}`}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.handleClose()}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>

      <DialogContent>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              autoFocus
              margin="none"
              label="Calories"
              type="text"
              name={`variants.${props.currentSelectedVariant}.nutritionalFacts.calories`}
              value={`${
                props.values.variants[props.currentSelectedVariant]
                  .nutritionalFacts.calories
              }`}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={
                props.touched.variants &&
                props.errors.variants &&
                //@ts-ignore
                props.errors.variants[props.currentSelectedVariant] &&
                //@ts-ignore
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts &&
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts.calories &&
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts.calories
              }
              helperText={
                <ErrorMessage
                  name={`variants[${props.currentSelectedVariant}].nutritionalFacts.calories`}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              autoFocus
              margin="none"
              label="Fat"
              type="text"
              name={`variants.${props.currentSelectedVariant}.nutritionalFacts.fat`}
              value={`${
                props.values.variants[props.currentSelectedVariant]
                  .nutritionalFacts.fat
              }`}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={
                props.touched.variants &&
                props.errors.variants &&
                //@ts-ignore
                props.errors.variants[props.currentSelectedVariant] &&
                //@ts-ignore
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts &&
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts.fat &&
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts.fat
              }
              helperText={
                <ErrorMessage
                  name={`variants[${props.currentSelectedVariant}].nutritionalFacts.fat`}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              autoFocus
              margin="none"
              label="Carbohydrates"
              type="text"
              name={`variants.${props.currentSelectedVariant}.nutritionalFacts.carbs`}
              value={`${
                props.values.variants[props.currentSelectedVariant]
                  .nutritionalFacts.carbs
              }`}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={
                props.touched.variants &&
                props.errors.variants &&
                //@ts-ignore
                props.errors.variants[props.currentSelectedVariant] &&
                //@ts-ignore
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts &&
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts.carbs &&
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts.carbs
              }
              helperText={
                <ErrorMessage
                  name={`variants[${props.currentSelectedVariant}].nutritionalFacts.carbs`}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              autoFocus
              margin="none"
              label="Protein"
              type="text"
              name={`variants.${props.currentSelectedVariant}.nutritionalFacts.protein`}
              value={`${
                props.values.variants[props.currentSelectedVariant]
                  .nutritionalFacts.protein
              }`}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={
                props.touched.variants &&
                props.errors.variants &&
                //@ts-ignore
                props.errors.variants[props.currentSelectedVariant] &&
                //@ts-ignore
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts &&
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts.protein &&
                props.errors.variants[props.currentSelectedVariant]
                  .nutritionalFacts.protein
              }
              helperText={
                <ErrorMessage
                  name={`variants[${props.currentSelectedVariant}].nutritionalFacts.protein`}
                />
              }
            />
          </Grid>
        </Grid>
        <div
          style={{
            padding: '30px 0 20px',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            style={{ marginRight: '10px' }}
            onClick={() => props.handleClose()}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            style={{ boxShadow: 'none' }}
            onClick={() => props.handleClose()}
          >
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(NutritionalDialog);
