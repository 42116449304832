import { createSlice } from '@reduxjs/toolkit';

import {
  createProduct,
  getProducts,
  patchProduct,
  removeProduct,
  getProduct,
  getExtras,
} from '../../api/products';

const initialState = {
  products: {
    data: [],
    total: 0,
    limit: 0,
    skip: 0,
  },
  extrasLoading: true,
  extras: {
    data: [],
    total: 0,
    limit: 0,
    skip: 0,
  },
  productsLoading: true,
  product: null,
  productLoading: true,
  error: null,
};

export interface Product {
  title: String;
  description: String;
  type: String;
  category?: String;
  subSubCats?: String;
  variants: Array<Object>;
  select?: Array<Object>;
  choice?: Array<Object>;
  extras?: Array<Object>;
  salesChannels: Array<Object>;
  images?: Array<Object>;
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    productLoading: (state, action: any) => {
      //@ts-ignore
      state.productLoading = action.payload;
    },

    productsLoading: (state, action: any) => {
      //@ts-ignore
      state.productsLoading = action.payload;
    },

    setExtrasLoading: (state, action: any) => {
      //@ts-ignore
      state.extrasLoading = action.payload;
    },

    getProductSuccess: (state, action: any) => {
      //@ts-ignore
      state.product = action.payload;
      state.productLoading = false;
    },

    getProductsSuccess: (state, action: any) => {
      //@ts-ignore
      state.products.data = action.payload.data
        ? action.payload.data
        : action.payload;
      state.productsLoading = false;
    },

    getExtrasSuccess: (state, action: any) => {
      //@ts-ignore
      state.extras = action.payload;
      state.extrasLoading = false;
    },

    getProductsFailed: (state, action: any) => {
      state.products.data = [];
      state.productsLoading = false;
      state.error = action.payload;
    },

    getExtrasFailed: (state, action: any) => {
      state.extras.data = [];
      state.extrasLoading = false;
      state.error = action.payload;
    },

    createProductSuccess: (state, action: any) => {
      //@ts-ignore
      console.log(action.payload);
      //@ts-ignore
      state.products.data.push(action.payload);
    },

    updateProductAvailabilitySuccess: (state, action: any) => {
      const index = state.products.data.findIndex(
        //@ts-ignore
        (e) => e._id === action.payload._id
      );

      if (index >= 0) {
        //@ts-ignore
        state.products.data[index].available = action.payload.available;
      }
    },

    updateProductSuccess: (state, action: any) => {
      const index = state.products.data.findIndex(
        //@ts-ignore
        (e) => e._id === action.payload._id
      );

      if (index >= 0) {
        //@ts-ignore
        state.products.data[index] = action.payload;
      }
    },

    addSubSubProductSuccess: (state, action: any) => {
      const index = state.products.data.findIndex(
        //@ts-ignore
        (e) => e._id === action.payload._id
      );

      if (index >= 0) {
        //@ts-ignore
        state.categories.data[index] = action.payload;
      }
    },

    deleteProductSuccess: (state, action: any) => {
      const index = state.products.data.findIndex(
        //@ts-ignore
        (e) => e._id === action.payload._id
      );

      if (index >= 0) {
        //@ts-ignore
        state.products.data.splice(index, 1);
      }
    },

    unsetProduct: (state) => {
      state.product = null;
      state.productLoading = true;
    },
  },
});

export const {
  productLoading,
  productsLoading,
  setExtrasLoading,
  getProductSuccess,
  getProductsSuccess,
  getProductsFailed,
  getExtrasSuccess,
  getExtrasFailed,
  createProductSuccess,
  addSubSubProductSuccess,
  updateProductSuccess,
  deleteProductSuccess,
  unsetProduct,
} = productsSlice.actions;

export function fetchProducts(query: object | undefined = undefined) {
  return async function (dispatch: Function) {
    dispatch(productsLoading(true));
    try {
      console.log(query);
      const products: Object = await getProducts(query);

      console.log(products);
      //@ts-ignore
      dispatch(getProductsSuccess(products));
    } catch (error) {
      console.log(error);
      dispatch(getProductsFailed(error));
    }
  };
}

export function fetchExtras() {
  return async function (dispatch: Function) {
    console.log('extras inside');
    dispatch(setExtrasLoading(true));
    try {
      const extras: Object = await getExtras();

      console.log(extras);
      //@ts-ignore
      dispatch(getExtrasSuccess(extras));
    } catch (error) {
      console.log(error);
      dispatch(getExtrasFailed(error));
    }
  };
}

export function fetchProduct(
  id: string,
  query: object | undefined = undefined
) {
  return async function (dispatch: Function) {
    dispatch(productLoading(true));
    try {
      const product: Object = await getProduct(id, query);

      console.log(product);
      //@ts-ignore
      dispatch(getProductSuccess(product));
    } catch (error) {
      console.log(error);
      // dispatch(getProductsFailed(error));
    }
  };
}

export function addProduct(
  product: Product | any,
  successFunction: Function,
  errorFunction: Function
) {
  return async function (dispatch: Function) {
    try {
      const p = await createProduct(product);

      console.log(p);
      dispatch(createProductSuccess(p));

      successFunction();
    } catch (error) {
      console.log(error);
      errorFunction(error);
      dispatch(getProductsFailed(error));
    }
  };
}

export function updateProduct(
  id: String,
  product: any,
  successFunction: Function,
  errorFunction: Function,
  history: any
) {
  return async function (dispatch: Function) {
    try {
      console.log('PROUDCT IN PRODUCT UPDATE');

      console.log(product);

      const i = await patchProduct(id, product);
      console.log(i);
      dispatch(updateProductSuccess(i));
      // return true;
      if (successFunction) {
        successFunction();
      }

      history.push('/products');
    } catch (error) {
      errorFunction(error);

      //   dispatch(getProductsFailed(error));
    } finally {
    }
  };
}

export function deleteProduct(
  id: String
  // setSubmitting: Function,
  // closeDialog: Function
) {
  console.log('deleteProduct------', id);
  return async function (dispatch: Function) {
    try {
      console.log('second===');
      const p = await removeProduct(id);

      console.log(p);
      dispatch(deleteProductSuccess(p));

      // closeDialog(false);
    } catch (error) {
      console.log(error);
    } finally {
      // setSubmitting(false);
    }
  };
}

export default productsSlice.reducer;
