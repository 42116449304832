import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

export default function CustomersDeleteDialog(props: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={() => props.toggleOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Delete selected customers?
          </Typography>
        </div>
        <div>
          <IconButton>
            <CloseIcon
              style={{ color: '#fff' }}
              onClick={() => props.toggleOpen(false)}
            />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description"> */}
        <Typography variant="body1">
          Deleting a customer will also remove any associated secondary accounts
          and billing receipts.
        </Typography>
        {/* </DialogContentText> */}
      </DialogContent>

      <Box paddingX="24px" paddingY="15px">
        <DialogActions>
          <Button onClick={() => props.toggleOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.toggleOpen(false)}
            style={{
              boxShadow: 'none',
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
