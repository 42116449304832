import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { CircularProgress } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { searchInstructions } from '../../api/instructions';

const filter = createFilterOptions();

export default (props: { open: boolean; close: any }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [instructionsLoading, setInstructionsLoading] = React.useState(false);
  const [instructionsOpen, setInstructionsOpen] = React.useState(false);
  const [instructionsOptions, setInstructionsOptions] = React.useState([]);
  const [instructionsSearchTerm, setInstructionsSearchTerm] =
    React.useState('');

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Manage instructions
          </Typography>
        </div>
        <div>
          <IconButton onClick={props.close}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        <Autocomplete
          // value={instructionsSearchTerm}
          blurOnSelect
          clearOnEscape
          freeSolo
          filterOptions={(options: any, params: any) => {
            const filtered = filter(options, params);

            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          onOpen={() => {
            setInstructionsOpen(true);
          }}
          onClose={() => {
            setInstructionsOpen(false);
          }}
          onChange={(ev: any, val: any) => {
            if (typeof val === 'object') {
              console.log(val.title);

              if (val.ingredients.length > 0) {
              }
            } else {
              setInstructionsSearchTerm(val);
            }
          }}
          options={instructionsOptions}
          loading={instructionsLoading}
          getOptionLabel={(option: any) => option.title}
          open={instructionsOpen}
          multiple
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              margin="normal"
              label="Select or add instruction"
              variant="outlined"
              value={instructionsSearchTerm}
              onChange={(e: any) => {
                setInstructionsSearchTerm(e.target.value);
                searchInstructions(e.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {instructionsLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          Cancel
        </Button>
        <Button onClick={props.close} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
