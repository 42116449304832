import React, { useEffect, useState, useCallback } from 'react';

import {
  Grid,
  Box,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  ListSubheader,
  IconButton,
  Typography,
  Chip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';

import { FieldArray } from 'formik';
import { search } from '../../api/ingredients';

export default (props: any) => {
  const {
    currentSelectedVariant,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    index,
    subIngredient,
    subIngredientIndex,
    arrayHelpersSubs,
  } = props;

  console.log(subIngredient);

  const [searchText, setSearchText] = useState('');
  const [ingredientsSearchResults, setIngredientsSearchResults] = useState([]);
  const [ingredientsLoading, setIngredientsLoading] = useState(false);

  const searchHandler = debounce(async (searchText: string) => {
    console.log('going in here');
    console.log(searchText);
    setIngredientsLoading(true);

    const results = await search(searchText);
    console.log(results);

    //@ts-ignore
    setIngredientsSearchResults(results);
    setIngredientsLoading(false);
  }, 500);

  return (
    <>
      <Grid
        container
        spacing={3}
        alignItems="flex-start"
        justifyContent="flex-end"
        style={{ marginBottom: '0.5em' }}
      >
        <Grid item xs={12} md={4} sm={4}>
          <Box display="flex" alignItems="center" flexDirection="row">
            {subIngredientIndex == 0 ? (
              <Box
                marginRight={2}
                borderLeft="2px solid #999"
                borderBottom="2px solid #999"
                height="20px"
                width="20px"
              ></Box>
            ) : (
              <Box width="36px"></Box>
            )}

            <Box
              display="flex"
              alignItems="start"
              flexDirection="column"
              flex="1"
            >
              <Autocomplete
                id={subIngredientIndex + 'sub-ingredient-autocomplete' + index}
                autoComplete
                includeInputInList
                filterSelectedOptions
                selectOnFocus
                noOptionsText={'No ingredients found'}
                onChange={(event: any, newValue: any) => {
                  console.log('autocomplete onChange', newValue);

                  if (newValue === undefined || newValue === null) {
                    return;
                  }

                  if (!newValue) {
                    return;
                  }

                  console.log('autocomplete onChange DONE', newValue);

                  setFieldValue(
                    `substitutions.${index}.substitutes.${subIngredientIndex}.ingredientId`,
                    newValue._id
                  );
                  setFieldValue(
                    `substitutions.${index}.substitutes.${subIngredientIndex}.type`,
                    newValue.type
                  );
                  setFieldValue(
                    `substitutions.${index}.substitutes.${subIngredientIndex}._id`,
                    newValue._id
                  );

                  setFieldValue(
                    `substitutions.${index}.substitutes.${subIngredientIndex}.ingredientObject`,
                    newValue
                  );

                  setFieldValue(
                    `substitutions.${index}.substitutes.${subIngredientIndex}.title`,
                    newValue.title
                  );

                  setFieldValue(
                    `substitutions.${index}.substitutes.${subIngredientIndex}.FoodDescription`,
                    newValue.FoodDescription
                  );

                  // setSearchText(newValue.title || newValue.FoodDescription);
                }}
                onInputChange={(event, newInputValue) => {
                  searchHandler(newInputValue);
                }}
                loading={ingredientsLoading}
                filterOptions={(x: any) => x}
                options={ingredientsSearchResults}
                renderOption={(option: any) => {
                  return (
                    <Box width="100%" display="flex" flexDirection="column">
                      <Box display="flex" ml={3} flexDirection="column">
                        <Typography color="textPrimary">
                          {option.title !== ''
                            ? option.title
                            : option.FoodDescription}{' '}
                          <Chip size="small" label={option.type} />
                        </Typography>
                        <Typography color="textSecondary">
                          {option.category}
                        </Typography>
                      </Box>
                    </Box>
                  );
                }}
                //@ts-ignore
                getOptionLabel={(option: {
                  title: string;
                  FoodDescription: string;
                }) =>
                  option.title === '' ? option.FoodDescription : option.title
                }
                style={{
                  width: '100%',
                }}
                value={
                  values.substitutions[index].substitutes[subIngredientIndex]
                    .ingredientId !== null
                    ? values.substitutions[index].substitutes[
                        subIngredientIndex
                      ].ingredientObject
                    : {
                        title: '',
                        FoodDescription: '',
                      }
                }
                renderInput={(params) => (
                  <TextField {...params} label="Ingredient" />
                )}
              />
              {values.substitutions.length > 0 && (
                <>
                  {values.substitutions[index].substitutes[
                    subIngredientIndex
                  ].hasOwnProperty('type') && (
                    <Chip
                      style={{ marginTop: '4px' }}
                      size="small"
                      label={
                        values.substitutions[index].substitutes[
                          subIngredientIndex
                        ].type
                      }
                    />
                  )}
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} sm={3}>
          <TextField
            fullWidth
            select
            label="Select unit"
            placeholder="Volume"
            value={
              values.substitutions[
                index
                //@ts-ignore
              ].substitutes[subIngredientIndex].unit
            }
            onChange={(ev: any) => {
              console.log(ev.target.value);
              setFieldValue(
                `substitutions.${index}.substitutes.${subIngredientIndex}.unit`,
                ev.target.value
              );
            }}
          >
            <ListSubheader>Volume</ListSubheader>
            <MenuItem value="tsp">Teaspoon (tsp)</MenuItem>
            <MenuItem value="tbsp">Tablespoon (tbsp)</MenuItem>
            <MenuItem value="floz">Fluid ounce (fl oz)</MenuItem>
            <MenuItem value="mL">Milliliter (mL)</MenuItem>
            <MenuItem value="L">Liter (L)</MenuItem>
            <ListSubheader>Mass and weight</ListSubheader>
            <MenuItem value="lb">Pound (lb)</MenuItem>
            <MenuItem value="oz">Ounce (oz)</MenuItem>
            <MenuItem value="mg">Milligram (mg)</MenuItem>
            <MenuItem value="g">Gram (g)</MenuItem>
            <MenuItem value="kg">Kilogram (kg)</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={12} md={2} sm={2}>
          <TextField
            onChange={(e: any) => {
              console.log(e.currentTarget.value);
              setFieldValue(
                `substitutions.${index}.substitutes.${subIngredientIndex}.value.${currentSelectedVariant}`,
                e.currentTarget.value
              );
            }}
            name={`substitutions.${index}.substitutes.${subIngredientIndex}.value.${currentSelectedVariant}`}
            value={
              values.substitutions[index].substitutes[subIngredientIndex].value[
                currentSelectedVariant
              ]
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {
                    values.substitutions[index].substitutes[subIngredientIndex]
                      .unit
                  }
                </InputAdornment>
              ),
            }}
            fullWidth
            label="Value"
          />
        </Grid>

        <Grid item xs={12} md={3} sm={3}>
          <Box display="flex" alignItems="flex-end">
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={
                values.substitutions[
                  index
                  //@ts-ignore
                ].substitutes[subIngredientIndex].price
              }
              name={`substitutions.${index}.substitutes.${subIngredientIndex}.price`}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              label="Price"
            />

            {subIngredientIndex >= 1 && (
              <IconButton
                style={{
                  position: 'relative',
                  right: '-8px',
                }}
                onClick={() => arrayHelpersSubs.remove(subIngredientIndex)}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
