import React, { useEffect, useRef, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';

import {
  Grid,
  Box,
  Button,
  Typography,
  Paper,
  IconButton,
  TextField,
  Checkbox,
  InputAdornment,
  Divider,
  Avatar,
  Menu,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withMobileDialog,
} from '@material-ui/core';
import NestedMenuItem from 'material-ui-nested-menu-item';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsIcon from '@material-ui/icons/Directions';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

// import './Fulfillment.scss';

const MultipleStopIcon = require('../../assets/images/multiple_stop.svg');
const vittleImage = require('../../assets/images/vittle-profile-admin.png');
const FilterIcon = require('../../assets/images/filter_icon.svg');

//@ts-ignore
//@ts-ignore
function MyMapComponent() {
  const ref = useRef();

  useEffect(() => {
    try {
      //@ts-ignore
      //@ts-ignore
      const map = new window.google.maps.Map(ref.current, {
        zoom: 17,
        center: { lat: 45.3780708, lng: -75.672641 },
      });

      //@ts-ignore
      //@ts-ignore

      const cityCircle = new window.google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map,
        center: { lat: 45.3780708, lng: -75.672641 },
        radius: 25 * 1000,
      });

      //@ts-ignore
      const cityCircle2 = new window.google.maps.Circle({
        strokeColor: '#f5a241',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#f5a241',
        fillOpacity: 0.35,
        map,
        center: { lat: 45.3780708, lng: -75.672641 },
        radius: 30 * 1000,
      });
    } catch (error) {
      console.log('In here');
      console.log(error);
    }
  }, []);

  //@ts-ignore
  return <div ref={ref} id="map" />;
}

export default function (props: any) {
  const [menuPosition, setMenuPosition] = useState<any>(null);

  const handleRightClick = (event: React.MouseEvent) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleItemClick = (event: React.MouseEvent) => {
    setMenuPosition(null);
  };

  const [streets, setStreets] = React.useState([
    { street: '123 Elm Street', completed: true, note: '', items: 5 },
    {
      street: '799 Lorem Street',
      completed: true,
      note: 'Ring door bell',
      items: 5,
    },
    {
      street: 'Hill Drive',
      completed: true,
      note: 'Call before coming',
      items: 5,
    },
    {
      street: 'Tuna Street',
      completed: true,
      note: 'Do not call, do not text, do not ring the bell. Leave the package and go.',
      items: 5,
    },
    { street: '331 Bacon Dr', completed: true, note: '', items: 5 },

    { street: '123 Elm Street', completed: false, note: '', items: 5 },
    { street: '799 Test Street', completed: false, note: '', items: 5 },
    { street: '799 Lm Street', completed: false, note: 'Meet', items: 5 },
    {
      street: '799 23rd Street',
      completed: false,
      note: 'Call first',
      items: 5,
    },
    { street: '799 Test Street', completed: false, note: '', items: 5 },
  ]);

  const reorder = (list: any, startIndex: any, endIndex: any): Array<any> => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);

    return list;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'inherit',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      streets,
      result.source.index,
      result.destination.index
    );

    //@ts-ignore
    setStreets(items);
  };

  const [checkboxSelectedNumber, setCheckboxSelectedNumber] = useState(0);
  const [massNotifyDialogOpen, setMassNotifyDialogOpen] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);

  const [currentListBeingEdited, setCurrentListBeingEdited] = useState('glenn');
  const [currentListOpen, setCurrentListOpen] = useState('glenn');

  const deliveryDrivers = [
    {
      glenn: {
        listOpen: false,
        listBeingEdited: false,
      },
      alistair: {
        listOpen: false,
      },
      wayne: {
        listOpen: false,
      },
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <Wrapper apiKey={'AIzaSyCMe7tVcdgHT_WzzfpcNwT8P9kMJFR2xfo'}>
        <MyMapComponent />
      </Wrapper>

      <Paper elevation={2} className="fulfillment-sidebar">
        <Box paddingX={2} paddingY={3}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <Box fontWeight="fontWeightMedium" fontSize="h5.fontSize">
              Fulfillment
            </Box>
            <IconButton onClick={() => {}}>
              <img src={FilterIcon} />
            </IconButton>
          </Box>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ fontWeight: 'normal' }}
          >
            Monday, November 22, 2022
          </Typography>

          <Box display="flex" width="100%" mt={2} flexWrap={'wrap'}>
            <Box
              display="flex"
              alignItems="center"
              mr={1}
              style={{ fontSize: '14px' }}
            >
              <DirectionsIcon
                color="primary"
                style={{ marginRight: '4px', fontSize: '18px' }}
              />
              3 routes
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mr={1}
              style={{ fontSize: '14px' }}
            >
              <img
                src={MultipleStopIcon}
                className="icon-primary"
                style={{ marginRight: '4px', fontSize: '18px' }}
              />{' '}
              30 deliveries
            </Box>
            <Box
              display="flex"
              alignItems="center"
              style={{ fontSize: '14px' }}
              mr={1}
            >
              <DirectionsCarIcon
                color="primary"
                style={{ marginRight: '4px', fontSize: '18px' }}
              />{' '}
              3 drivers
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mr={1}
              style={{ fontSize: '14px' }}
            >
              <DirectionsWalkIcon
                color="primary"
                style={{ marginRight: '4px', fontSize: '18px' }}
              />
              10 Pick ups
            </Box>
          </Box>
        </Box>

        <Divider />
        <Box paddingX={2} paddingY={2}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                autoFocus
                id="name"
                margin="normal"
                label="Search fulfillment"
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <ExpansionPanel
          defaultExpanded={false}
          expanded={currentListOpen === 'vittle'}
          onClick={(e: any) => setCurrentListOpen('vittle')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="fulfillment-delivery-driver"
          >
            <Box className="fulfillment-item">
              <div className="fulfillment-item__info">
                <Avatar
                  src={vittleImage}
                  className="fulfillment-item__avatar color-1"
                >
                  V
                </Avatar>

                <div className="fulfillment-item__text">
                  <Typography>Vittle</Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>5</strong> of <strong>5</strong> pickups
                  </Typography>
                </div>
              </div>
              <IconButton
                onClick={(e: any) => {
                  setCurrentListOpen('vittle');
                  setCurrentListBeingEdited('vittle');
                  e.stopPropagation();
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="fulfillment-route-list">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {streets.map((street: any, index: number) => (
                        <Draggable
                          key={`item-${index}`}
                          draggableId={`item-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className={`fulfillment-route-item ${
                                street.complete
                                  ? 'fulfillment-route-item--complete'
                                  : 'fulfillment-route-item--incomplete'
                              }`}
                            >
                              <div>
                                <div className="fulfillment-route-item__icon color-pickup">
                                  {currentListBeingEdited === 'vittle' ? (
                                    <div className="fulfillment-route-item__checkbox-area">
                                      <DragIndicatorIcon className="fulfillment-route-item__drag-handle" />
                                      <Checkbox
                                        onChange={(event: any) => {
                                          console.log(event.target.checked);

                                          if (checkboxSelectedNumber === 1) {
                                            setCheckboxSelectedNumber(0);
                                          } else if (1) {
                                          }

                                          setCheckboxSelectedNumber(
                                            checkboxSelectedNumber + 1
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : street.completed ? (
                                    <CheckIcon />
                                  ) : index + 1 < 10 ? (
                                    <span>{'0' + (index + 1)}</span>
                                  ) : (
                                    <span>{index + 1}</span>
                                  )}
                                </div>

                                <Box>
                                  <Typography
                                    variant="body1"
                                    className="fulfillment-route__name"
                                  >
                                    {street.street}
                                  </Typography>
                                  <Box display="flex" mt={1}>
                                    <a href="#">
                                      <Typography variant="body2">
                                        Order No. 182edjeuce68cc1
                                      </Typography>
                                    </a>
                                    <span style={{ margin: '0 5px' }}>•</span>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {street.items} items
                                    </Typography>
                                  </Box>

                                  <Box></Box>
                                </Box>
                              </div>
                              <div className="fulfillment-route-item__more-options-area">
                                {currentListBeingEdited === 'vittle' && (
                                  <>
                                    <IconButton onClick={handleRightClick}>
                                      <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                      open={!!menuPosition}
                                      onClose={() => setMenuPosition(null)}
                                      anchorReference="anchorPosition"
                                      anchorPosition={menuPosition}
                                    >
                                      <NestedMenuItem
                                        label="Status"
                                        parentMenuOpen={!!menuPosition}
                                        onClick={handleItemClick}
                                      >
                                        <MenuItem onClick={handleItemClick}>
                                          In-transit
                                        </MenuItem>
                                        <MenuItem onClick={handleItemClick}>
                                          Delivered
                                        </MenuItem>

                                        <MenuItem onClick={handleItemClick}>
                                          Not delivered
                                        </MenuItem>

                                        <MenuItem onClick={handleItemClick}>
                                          Delayed
                                        </MenuItem>
                                      </NestedMenuItem>

                                      <NestedMenuItem
                                        label="Re-assign"
                                        parentMenuOpen={!!menuPosition}
                                        onClick={handleItemClick}
                                      >
                                        <MenuItem onClick={handleItemClick}>
                                          Alistair
                                        </MenuItem>
                                        <MenuItem onClick={handleItemClick}>
                                          Test
                                        </MenuItem>
                                      </NestedMenuItem>
                                    </Menu>
                                  </>
                                )}
                              </div>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          defaultExpanded={false}
          expanded={currentListOpen === 'glenn'}
          onClick={(e: any) => setCurrentListOpen('glenn')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="fulfillment-delivery-driver"
          >
            <Box className="fulfillment-item">
              <div className="fulfillment-item__info">
                <Avatar className="fulfillment-item__avatar color-1">GS</Avatar>

                <div className="fulfillment-item__text">
                  <Typography>Glenn Smith</Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>5</strong> of <strong>10</strong> deliveries
                  </Typography>
                </div>
              </div>
              <IconButton
                onClick={(e: any) => {
                  setCurrentListOpen('glenn');
                  setCurrentListBeingEdited('glenn');
                  e.stopPropagation();
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="fulfillment-route-list">
              {streets.map((street: any, index: number) => (
                <li
                  className={`fulfillment-route-item ${
                    street.complete
                      ? 'fulfillment-route-item--complete'
                      : 'fulfillment-route-item--incomplete'
                  }`}
                >
                  <div className="fulfillment-route-item__icon color-1">
                    {street.completed ? (
                      <CheckIcon />
                    ) : index + 1 < 10 ? (
                      <span>{'0' + (index + 1)}</span>
                    ) : (
                      <span>{index + 1}</span>
                    )}
                  </div>

                  <Box>
                    <Typography
                      variant="body1"
                      className="fulfillment-route__name"
                    >
                      {street.street}
                    </Typography>
                    <Box display="flex" mt={1}>
                      <a href="#">
                        <Typography variant="body2">
                          Order No. 182edjeuce68cc1
                        </Typography>
                      </a>
                      <span style={{ margin: '0 5px' }}>•</span>
                      <Typography variant="body2" color="textSecondary">
                        {Math.floor(Math.random() * 10) + 2} items
                      </Typography>
                    </Box>

                    <Box></Box>
                  </Box>
                </li>
              ))}
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          expanded={currentListOpen === 'alistair'}
          onClick={() => setCurrentListOpen('alistair')}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="fulfillment-delivery-driver"
          >
            <Box className="fulfillment-item">
              <div className="fulfillment-item__info">
                <Avatar className="fulfillment-item__avatar color-2">AF</Avatar>

                <div className="fulfillment-item__text">
                  <Typography color="textSecondary">
                    Alistair Fawcett
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>10</strong> deliveries • Completed at 9:30 PM
                  </Typography>
                </div>
              </div>

              <IconButton
                onClick={(e: any) => {
                  setCurrentListOpen('alistair');
                  setCurrentListBeingEdited('alistair');
                  e.stopPropagation();
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="fulfillment-route-list fulfillment-route-list--color-2">
              {streets.map((street: any, index: number) => (
                <li
                  className={`fulfillment-route-item fulfillment-route-item--complete`}
                >
                  <div className="fulfillment-route-item__icon color-2">
                    <CheckIcon />
                  </div>

                  <Box>
                    <Typography
                      variant="body1"
                      className="fulfillment-route__name"
                    >
                      {street.street}
                    </Typography>
                    <Box display="flex" mt={1}>
                      <a href="#">
                        <Typography variant="body2">
                          Order No. 182edjeuce68cc1
                        </Typography>
                      </a>
                      <span style={{ margin: '0 5px' }}>•</span>
                      <Typography variant="body2" color="textSecondary">
                        {Math.floor(Math.random() * 10) + 2} items
                      </Typography>
                    </Box>

                    <Box></Box>
                  </Box>
                </li>
              ))}
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          onClick={() => setCurrentListOpen('wayne')}
          expanded={currentListOpen === 'wayne'}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="fulfillment-delivery-driver"
          >
            <Box className="fulfillment-item">
              <div className="fulfillment-item__info">
                <Avatar className="fulfillment-item__avatar color-3">WL</Avatar>

                <div className="fulfillment-item__text">
                  <Typography color="textSecondary">Wayne Leblond</Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>10</strong> deliveries • Completed at 9:05 PM
                  </Typography>
                </div>
              </div>

              <IconButton
                onClick={(e: any) => {
                  setCurrentListOpen('wayne');
                  setCurrentListBeingEdited('wayne');
                  e.stopPropagation();
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ul className="fulfillment-route-list fulfillment-route-list--color-3">
              {streets.map((street: any, index: number) => (
                <li
                  className={`fulfillment-route-item fulfillment-route-item--complete`}
                >
                  <div className="fulfillment-route-item__icon color-3">
                    <CheckIcon />
                  </div>

                  <Box>
                    <Typography
                      variant="body1"
                      className="fulfillment-route__name"
                    >
                      {street.street}
                    </Typography>
                    <Box display="flex" mt={1}>
                      <a href="#">
                        <Typography variant="body2">
                          Order No. 182edjeuce68cc1
                        </Typography>
                      </a>
                      <span style={{ margin: '0 5px' }}>•</span>
                      <Typography variant="body2" color="textSecondary">
                        {Math.floor(Math.random() * 10) + 2} items
                      </Typography>
                    </Box>

                    <Box></Box>
                  </Box>
                </li>
              ))}
            </ul>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Paper>
      {checkboxSelectedNumber > 0 && (
        <div className="fulfillment-items-selected">
          <p>3 items selected</p>

          <Button>Edit</Button>
        </div>
      )}

      <Dialog
        fullScreen={false}
        open={massNotifyDialogOpen}
        onClose={() => setMassNotifyDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Mass notify deliveries
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {checkboxSelectedNumber} deliver
            {checkboxSelectedNumber > 1 ? 'ies' : 'y'} selected
          </DialogContentText>
          <List style={{ marginTop: '1em' }}>
            <ListItem
              style={{ cursor: 'pointer' }}
              className="status--in-transit"
              button
              // onClick={() => this.handleStatusChange('In-Transit', null, true)}
            >
              <span className="status-circle" />
              <ListItemText primary="In-Transit" />
            </ListItem>
            <Divider />
            <ListItem
              style={{ cursor: 'pointer' }}
              className="status--delayed"
              button
            >
              <span className="status-circle" />
              <ListItemText primary="Delayed" />
            </ListItem>
            <Divider />
            <ListItem
              style={{ cursor: 'pointer' }}
              className="status--not-delivered"
              button
            >
              <span className="status-circle" />
              <ListItemText primary="Not delivered" />
            </ListItem>
            <Divider />
            <ListItem
              style={{ cursor: 'pointer' }}
              className="status--delivered"
              button
            >
              <span className="status-circle" />
              <ListItemText primary="Delivered" />
            </ListItem>
            <Divider />
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setMassNotifyDialogOpen(false)}
            color="default"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={false}
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Assign selected delivery
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {checkboxSelectedNumber} deliver
            {checkboxSelectedNumber > 1 ? 'ies' : 'y'} selected
          </DialogContentText>
          <List style={{ marginTop: '1em' }}>
            <ListItem style={{ cursor: 'pointer' }} button key="2137">
              <ListItemText primary={'Unassigned'} />
            </ListItem>
            <Divider />

            <ListItem
              style={{ cursor: 'pointer' }}
              // key={e._id}
              button
              // onClick={() => this.handleDeliveryAssign(e._id)}
            >
              <ListItemText primary={'Glenn'} />
            </ListItem>
            <Divider />

            <ListItem
              style={{ cursor: 'pointer' }}
              // key={e._id}
              button
              // onClick={() => this.handleDeliveryAssign(e._id)}
            >
              <ListItemText primary={'Tyler'} />
            </ListItem>
            <Divider />

            <ListItem
              style={{ cursor: 'pointer' }}
              // key={e._id}
              button
              // onClick={() => this.handleDeliveryAssign(e._id)}
            >
              <ListItemText primary={'Chris'} />
            </ListItem>
            <Divider />
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssignDialogOpen(false)} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
