import { createSlice } from '@reduxjs/toolkit';
import {
  patchOrganizationDetails,
  getOrganizationDetails,
} from '../../api/organization';

const initialState = {
  organization: null,
  organizationLoading: true,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    organizationLoading: (state, action: any) => {
      //@ts-ignore
      state.organizationLoading = action.payload;
    },

    getOrganizationSuccess: (state, action: any) => {
      //@ts-ignore
      state.organization = action.payload.data[0];
      state.organizationLoading = false;
    },

    updateOrganizationSuccess: (state, action: any) => {
      state.organization = action.payload;
    },
  },
});

export const {
  organizationLoading,
  getOrganizationSuccess,
  updateOrganizationSuccess,
} = organizationSlice.actions;

export function getOrganization() {
  return async function (dispatch: Function) {
    dispatch(organizationLoading(true));
    console.log('getting here');
    try {
      const org: Object = await getOrganizationDetails();
      console.log(org);
      //@ts-ignore
      dispatch(getOrganizationSuccess(org));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateOrganizationDetails(
  data: any,
  setSubmitting: Function | null = null
) {
  return async function (dispatch: Function) {
    try {
      const i = await patchOrganizationDetails(data);
      console.log(i);
      dispatch(updateOrganizationSuccess(i));
    } catch (error) {
      console.log('Update organization failed');
      console.log(error);
    } finally {
      if (setSubmitting !== null) setSubmitting(false);
    }
  };
}

export default organizationSlice.reducer;
