import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import ImageIcon from '@material-ui/icons/Image';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import EditIcon from '@material-ui/icons/Edit';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import BlockIcon from '@material-ui/icons/Block';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';

// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import './Discounts.css';

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
  price: number
) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: 'Ingredit.', customerId: '159', amount: '3g' },
      { date: 'Ingredit.', customerId: '159', amount: '1g' },
    ],
  };
}

function Row(props: {
  row: ReturnType<typeof createData>;
  noBorder?: Boolean;
}) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Grid
        container
        style={{
          marginTop: '8px',
          paddingTop: '8px',
          borderTop: props.noBorder ? 0 : '1px solid #ccc',
        }}
        alignItems="center"
      >
        <Grid item xs={1}>
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ marginLeft: '-8px' }}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Grid>
        <Grid item xs={3}>
          {row.name}
        </Grid>
        <Grid item style={{ textAlign: 'center' }} xs={2}>
          {row.calories}
        </Grid>
        <Grid item style={{ textAlign: 'center' }} xs={2}>
          {row.fat}g
        </Grid>
        <Grid item style={{ textAlign: 'center' }} xs={2}>
          {row.carbs}g
        </Grid>
        <Grid item style={{ textAlign: 'center' }} xs={2}>
          {row.protein}g
        </Grid>
      </Grid>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container style={{ paddingTop: '4px' }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={3} style={{ color: '#999' }}>
            Ingredient
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', color: '#999' }}>
            {row.calories}
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', color: '#999' }}>
            {row.fat}g
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', color: '#999' }}>
            {row.carbs}g
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center', color: '#999' }}>
            {row.protein}g
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}

export default function Dashboard(props: any) {
  // const classes = useStyles();

  const [productInfoLang, setProductInfoLang] = useState(0);
  const [pricingLang, setPricingLang] = useState(0);

  const [title, setTitle] = useState('');

  const [variants, setVariants] = useState([{ name: '' }]);

  const reorder = (
    list: Array<object>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: 8,
    width: '100%',
    display: 'flex',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      variants,
      result.source.index,
      result.destination.index
    );

    //@ts-ignore
    setVariants(items);
  };

  const rows = [
    createData('Variant 1', 159, 6.0, 24, 4.0, 3.99),
    createData('Variant 2', 237, 9.0, 37, 4.3, 4.99),
  ];

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
            }}
          >
            <Link style={{ textDecoration: 'none' }} to="/">
              <Button style={{ textTransform: 'capitalize' }}>
                <KeyboardArrowLeftIcon /> <Typography>Dashboard</Typography>
              </Button>
            </Link>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: '20px 0 40px' }}
      >
        <Box
          fontWeight="fontWeightMedium"
          fontSize="h4.fontSize"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            mb={2}
          >
            <Box display="flex" alignItems="center" flexDirection="row" mb={2}>
              <Box display="flex" flexDirection="column">
                {title.length > 0 ? title : 'Discount code'}
              </Box>
            </Box>

            <Box>
              <Button size="small" style={{ fontWeight: 400 }}>
                <BlockIcon style={{ fill: '#9b9b9b', marginRight: '5px' }} />{' '}
                Disable
              </Button>
            </Box>
          </Box>

          <Box>
            <Button style={{ marginRight: '1em' }}>
              Save
              <CloudIcon style={{ fill: '#9b9b9b', marginLeft: '10px' }} />
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: '24px 24px 0', marginBottom: '2em' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Discount code</Typography>

              <a className="link">Generate code</a>
            </Box>

            <TextField
              variant="outlined"
              fullWidth
              label="Discount code"
              margin="normal"
              onChange={(e) => setTitle(e.currentTarget.value)}
            />

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
            >
              <Typography variant="h6">Types</Typography>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value="Percentage"
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Percentage"
                      control={<Radio />}
                      label="Percentage"
                    />
                    <FormControlLabel
                      value="Fixed amount"
                      control={<Radio />}
                      label="Fixed amount"
                    />
                    <FormControlLabel
                      value="Free delivery"
                      control={<Radio />}
                      label="Free delivery"
                    />

                    <FormControlLabel
                      value="Buy X Get Y"
                      control={<Radio />}
                      label="Buy X Get Y"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px 0 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Value</Typography>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  InputProps={{
                    endAdornment: '%',
                  }}
                  label="Discount value"
                />
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Applies to</Typography>
            </Grid>

            <Grid container spacing={3} style={{ paddingBottom: '16px' }}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value="Entire Order"
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Entire Order"
                      control={<Radio />}
                      label="Entire Order"
                    />
                    <FormControlLabel
                      value="Specific categories"
                      control={<Radio />}
                      label="Specific categories"
                    />
                    <FormControlLabel
                      value="Specific products"
                      control={<Radio />}
                      label="Specific products"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Customer spends</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value="Minimum quantity of items"
                  // onChange={handleChange}
                >
                  <FormControlLabel
                    value="Minimum quantity of items"
                    control={<Radio />}
                    label="Minimum quantity of items"
                  />
                  <FormControlLabel
                    value="Minimum purchase amount"
                    control={<Radio />}
                    label="Minimum purchase amount"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: '$ ',
                  }}
                  label="Amount"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="Any items from"
                  value="tsp"
                >
                  <MenuItem value="tsp">Specific products</MenuItem>
                  <MenuItem value="tbsp">Specific category</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Autocomplete
                  // options={inventories}
                  options={[]}
                  getOptionLabel={(option: any) => option.name}
                  clearOnEscape
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      margin="normal"
                      // name={`select[${index}].name`}
                      label="Search products"
                      InputProps={{
                        startAdornment: (
                          <SearchIcon style={{ fill: '#9b9b9b' }} />
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid style={{ marginBottom: '1em' }} container>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6">Customer gets</Typography>

                <Typography variant="body2" color="textSecondary">
                  Customers must add the quantity of items specified below to
                  their cart.
                </Typography>
              </Box>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField margin="normal" fullWidth label="Quantity" />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  select
                  label="Any items from"
                  value="tsp"
                >
                  <MenuItem value="tsp">Specific products</MenuItem>
                  <MenuItem value="tbsp">Specific category</MenuItem>
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchIcon style={{ fill: '#9b9b9b' }} />,
                  }}
                  label="Search products"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: '20px' }}>
              <Grid item xs={12}>
                <Typography>At a discounted value</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value="Percentage"
                    // onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Percentage"
                      control={<Radio />}
                      label="Percentage"
                    />

                    <FormControlLabel
                      value="Free"
                      control={<Radio />}
                      label="Free"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  fullWidth
                  InputProps={{
                    endAdornment: '%',
                  }}
                  label="Discount value"
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Usage limits</Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} sm={12}>
                <FormControlLabel
                  control={<Checkbox name="gilad" checked />}
                  label="Limit the number of times this discount can be used in total"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  type="number"
                  margin="normal"
                  placeholder="Limit"
                />
              </Grid>

              <Grid item xs={12} md={12} sm={12}>
                <FormControlLabel
                  control={<Checkbox name="jason" />}
                  label="Limit one use per customer"
                />
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Customer eligibility</Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12} sm={12}>
                <FormControlLabel
                  control={<Checkbox name="gilad" checked />}
                  label="Everyone"
                />
              </Grid>

              <Grid item xs={12} md={12} sm={12}>
                <FormControlLabel
                  control={<Checkbox checked name="jason" />}
                  label="Specific customers"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Grid item xs={12} md={12}>
                  <Autocomplete
                    // options={inventories}
                    options={[]}
                    getOptionLabel={(option: any) => option.name}
                    // clearOnBlur
                    clearOnEscape
                    // onChange={(e, value) =>
                    //   value
                    //     ? setFieldValue(`select[${index}].name`, value.name)
                    //     : null
                    // }
                    renderInput={(params) => (
                      <TextField
                        // {...params}
                        fullWidth
                        margin="normal"
                        // name={`select[${index}].name`}
                        label="Search customers"
                        InputProps={{
                          startAdornment: (
                            <SearchIcon style={{ fill: '#9b9b9b' }} />
                          ),
                        }}
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        // error={
                        //   touched.select &&
                        //   errors.select &&
                        //   errors.select[index] &&
                        //   //@ts-ignore
                        //   errors.select[index].name
                        // }
                        // helperText={
                        //   <ErrorMessage name={`select[${index}].name`} />
                        // }
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Active dates</Typography>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  margin="normal"
                  label="Start date"
                />
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  fullWidth
                  type="time"
                  margin="normal"
                  placeholder="Limit"
                  label="Start time"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} sm={12} style={{ marginTop: '2em' }}>
              <FormControlLabel
                control={<Checkbox checked name="jason" />}
                label="Set end date"
              />
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  fullWidth
                  type="date"
                  margin="normal"
                  label="End date"
                />
              </Grid>

              <Grid item xs={12} md={6} sm={6}>
                <TextField
                  fullWidth
                  type="time"
                  margin="normal"
                  placeholder="Limit"
                  label="End time"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6">Summary</Typography>
              </div>
            </Grid>

            <Grid
              style={{ marginBottom: '2em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6">JN7ZK2ZB8S3F</Typography>
              </div>

              <Chip label="Active" />
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>25% off entire order</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>For everyone</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>Active from August 26, 2020</Typography>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6">Performance</Typography>
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography>10 used</Typography>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '1em' }}>
              <Typography>
                View the <a className="link">sales by discount report</a>
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
