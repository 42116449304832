import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function CustomerContactEditDialog(props: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth
      open={props.open}
      onClose={() => props.toggleOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Edit contact
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.toggleOpen(false)}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        <Formik
          initialValues={{
            firstName: props.customer.firstName,
            lastName: props.customer.lastName,
            email: props.customer.email,
            phone: props.customer.phone,
            customerAcceptsMarketing: props.customer.acceptsMarketing,
          }}
          onSubmit={(values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            props
              .updateCustomerContact(props.customer._id, {
                firstName: values.firstName.trim(),
                lastName: values.lastName.trim(),
                email: values.email.trim(),
                phone: values.phone.trim(),
                acceptsMarketing: values.customerAcceptsMarketing,
              })
              .then((updatedCustomer: any) => {
                // console.log(updateCustomerContactDetails);
                setSubmitting(false);
                props.toggleOpen(false);
                enqueueSnackbar('Contact details updated successfully');
              })
              .catch((err: any) => {
                console.log(err);
                setSubmitting(false);
                enqueueSnackbar(
                  'There was a problem updating contact details',
                  { variant: 'error' }
                );
              });
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required('First name is required'),
            lastName: Yup.string().required('Last name is required'),
            email: Yup.string().email().required('Email address is required'),
            phone: Yup.string().required('Phone is required'),
          })}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
          }) => (
            <form autoComplete="false" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    autoFocus
                    margin="none"
                    id="firstName"
                    name="firstName"
                    label="First name"
                    type="text"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName && touched.firstName ? true : false}
                    helperText={
                      errors.firstName && touched.firstName && errors.firstName
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    margin="none"
                    id="lastName"
                    name="lastName"
                    label="Last name"
                    type="text"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName && touched.lastName ? true : false}
                    helperText={
                      errors.lastName && touched.lastName && errors.lastName
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    margin="none"
                    id="email"
                    name="email"
                    label="Email"
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email ? true : false}
                    helperText={errors.email && touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    margin="none"
                    id="phone"
                    name="phone"
                    label="Phone"
                    type="text"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phone && touched.phone ? true : false}
                    helperText={errors.phone && touched.phone && errors.phone}
                  />
                </Grid>
              </Grid>

              <Box mt={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="yes"
                      name="customerAcceptsMarketing"
                      checked={values.customerAcceptsMarketing}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  }
                  label="Customer accepts marketing"
                />
                {errors.customerAcceptsMarketing &&
                  touched.customerAcceptsMarketing &&
                  errors.customerAcceptsMarketing}
              </Box>

              <Box paddingY="15px">
                <DialogActions>
                  <Button
                    onClick={() => props.toggleOpen(false)}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    style={{
                      boxShadow: 'none',
                    }}
                    disabled={!dirty || isSubmitting}
                  >
                    {isSubmitting && (
                      <CircularProgress
                        style={{
                          position: 'absolute',
                          left: '50%',
                          marginLeft: '-8px',
                        }}
                        size={16}
                      />
                    )}
                    Save
                  </Button>
                </DialogActions>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
