import currency from 'currency.js';

interface calculateOrderLineItemsProps {
  lineItems: Array<Object>;
  deliveryType: string;
}

export default function calculateOrderLineItems(
  data: calculateOrderLineItemsProps
) {
  let deliveryFeeApplicable = false;
  let deliveryFeeOff = true;
  let tax = 0;
  let total = 0;

  if (data.deliveryType === 'delivery') {
    deliveryFeeApplicable = true;
  }

  let subTotal = data.lineItems.reduce(
    (acc: any, curr: any) =>
      (acc += curr.quantity * currency(curr.price).value),
    0
  );

  if (subTotal < 100 && deliveryFeeApplicable) {
    total += currency(subTotal).add(5).multiply(1.13).value;
    tax += currency(subTotal).add(5).multiply(0.13).value;
    deliveryFeeOff = false;
  } else {
    total += currency(subTotal).multiply(1.13).value;
    tax += currency(subTotal).multiply(0.13).value;
    deliveryFeeApplicable = false;
    deliveryFeeOff = true;
  }

  // const productsToTax = data.lineItems.filter((e: any) => {
  //   if (e.hasOwnProperty('taxExempt')) {
  //   }
  // });

  // console.log(productsToTax);

  return {
    subTotal,
    total,
    displayDeliveryFee: deliveryFeeApplicable && !deliveryFeeOff,
    deliveryFeeOff,
    deliveryFee: 5,
    tax,
  };
}
