import React from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { VariantType, useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { CircularProgress, Chip, Checkbox } from '@material-ui/core';

import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

import { retrieveById, findPlace } from '../../api/canada-post';
import { updateCustomerAddress } from '../../slices/customer/customerSlice';

async function fetchPlaces(
  setOptions: Function,
  searchTerm: String,
  lastId: String,
  setLoading: Function,
  setOpen: Function
) {
  setLoading(true);

  const response = await findPlace(lastId, searchTerm);

  setLoading(false);
  console.log(response.data.Items);

  if (response.data.Items.length > 0) {
    setOptions(response.data.Items);
    setOpen(true);
  } else {
    setOptions([]);
  }
  // Object.keys(response).map(key => response[key].item[0]) as CountryType[]
}

async function fetchFinalAddress(Id: String, setDescriptiveAddress: Function) {
  const response = await retrieveById(Id);

  console.log(response);
  if (response.data.Items) {
    if (response.data.Items.length > 0) {
      // setAddressCorrect(true);
      setDescriptiveAddress(
        response.data.Items[1] ? response.data.Items[1] : response.data.Items[0]
      );
    }
  }
}

export default function CustomerEditAddressDialog(props: any) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const addressComplete = require('../../assets/images/address-complete-en.png');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(
    props.address.Line1 +
      ' ' +
      props.address.City +
      ' ' +
      props.address.Province +
      ' ' +
      props.address.PostalCode
  );
  const [lastId, setLastId] = React.useState('');
  const [descriptiveAddress, setDescriptiveAddress] = React.useState({} as any);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (searchTerm.length > 0 || lastId.length > 0) {
      fetchPlaces(setOptions, searchTerm, lastId, setLoading, setOpen);
    }
  }, [searchTerm, lastId]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const [address, setAddress] = React.useState(
    props.address.Line1 +
      ' ' +
      props.address.City +
      ' ' +
      props.address.Province +
      ' ' +
      props.address.PostalCode
  );

  const [removeLoading, setRemoveLoading] = React.useState(false);

  const [openSplitButton, setOpenSplitButton] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpenSplitButton(false);
  };

  const handleToggle = () => {
    setOpenSplitButton((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenSplitButton(false);
  };

  const handleAutoCompleteSelected = (e: any, v: any) => {
    if (!v) {
      return;
    }

    if (!v.hasOwnProperty('Next')) {
      return;
    }

    if (v.Next === 'Find') {
      console.log('Going Find');
      setLastId(v.Id);
    }

    if (v.Next === 'Retrieve') {
      console.log('Going Retrieve');
      setLastId('');
      setSearchTerm('');
      setAddress(v.Text + ' ' + v.Description);
      fetchFinalAddress(v.Id, setDescriptiveAddress);
    }
  };

  const handleAutoCompleteBlur = () => {
    if (lastId.length > 0) {
      setLastId('');
      setSearchTerm('');
      setOpen(false);
    }
  };

  const removeAddress = (addressIndex: Number) => {
    setRemoveLoading(true);
    //@ts-ignore
    let addresses: any = [...props.customer.address];

    console.log(addresses);
    // return;

    const removedAddress = addresses.splice(addressIndex, 1);
    console.log(addresses);

    dispatch(
      updateCustomerAddress(
        props.customer._id,
        addresses,
        { type: 'remove', currentAddress: removedAddress },
        (error: any) => {
          console.log(error);
          enqueueSnackbar('There was a problem removing address', {
            variant: 'error',
          });
          setRemoveLoading(false);
        },
        () => {
          enqueueSnackbar('Address removed', {
            variant: 'error',
          });
          props.toggleOpen(false);
          setRemoveLoading(false);
        }
      )
    );
  };

  console.log(props);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Edit address
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => props.toggleOpen(false)}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        <Formik
          initialValues={{
            address:
              props.address.Line1 +
              ' ' +
              props.address.City +
              ' ' +
              props.address.Province +
              ' ' +
              props.address.PostalCode,
            addressType: props.addressType,
            buzzer: props.address.buzzer || '',
            deliveryOption: props.address.deliveryOption || 'leaveAtDoor',
            notes: props.address.notes || '',
            organization: props.address.organization || '',
            primary: props.address.primary || false,
          }}
          onSubmit={async (values, { setSubmitting, setStatus }) => {
            setSubmitting(true);
            console.log(values);

            console.log(descriptiveAddress);

            const address: any = {
              Label: descriptiveAddress.Label,
              SubBuilding: descriptiveAddress.SubBuilding,
              BuildingNumber: descriptiveAddress.BuildingNumber,
              BuildingName: descriptiveAddress.BuildingName,
              Street: descriptiveAddress.Street,
              City: descriptiveAddress.City,
              Line1: descriptiveAddress.Line1,
              Line2: descriptiveAddress.Line2,
              Line3: descriptiveAddress.Line3,
              Province: descriptiveAddress.Province,
              PostalCode: descriptiveAddress.PostalCode,
              Type: descriptiveAddress.Type || '',
              buzzer: values.buzzer,
              notes: values.notes,
              organization: values.organization,
              primary: false,
              addressType: values.addressType,
              deliveryOption: values.deliveryOption,
            };

            // const currentAddresses = props.customer.address;
            // currentAddresses[props.selectedAddressIndex] = address;

            // console.log(currentAddresses);
            // return;

            let addresses = [...props.customer.address];

            addresses[props.selectedAddressIndex] = address;

            if (values.primary) {
              console.log('is primary');

              addresses = addresses.map((e: any) => {
                let copy = { ...e };

                copy.primary = false;
                // e.primary = false;

                return copy;
              });

              addresses[props.selectedAddressIndex].primary = true;
            }

            console.log('till herer');

            console.log(addresses);

            console.log('going here');
            console.log('going here');

            try {
              const addressUpdated: any = dispatch(
                props.updateCustomerAddress(
                  props.customer._id,
                  addresses,
                  {
                    type: values.primary ? 'editPrimary' : 'edit',
                    currentAddress: address,
                  },
                  (error: any) => {
                    console.log(error);
                    enqueueSnackbar(
                      'There was a problem updating the address',
                      {
                        variant: 'error',
                      }
                    );

                    props.toggleOpen(false);
                  },
                  () => {
                    enqueueSnackbar('Address updated');
                    props.fetchCustomer(props.customer._id);
                    props.toggleOpen(false);
                  }
                )
              );
              console.log(addressUpdated);
            } catch (error) {
              console.log(error);
            }

            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            address: Yup.string().required('Address is required'),
            addressType: Yup.string().required('Address type is required'),
            buzzer: Yup.string(),
            organization: Yup.string(),
            deliveryOption: Yup.string(),
            notes: Yup.string(),
          })}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <FormControl component="fieldset">
                  <FormLabel required component="legend">
                    Type
                  </FormLabel>
                  <RadioGroup
                    aria-label="address type"
                    name="addressType"
                    value={values.addressType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel
                      value="home"
                      control={<Radio color="primary" />}
                      label="Home"
                    />
                    <FormControlLabel
                      value="apartment"
                      control={<Radio color="primary" />}
                      label="Apartment"
                    />
                    <FormControlLabel
                      value="business"
                      control={<Radio color="primary" />}
                      label="Business"
                    />
                  </RadioGroup>
                  <Typography color="error">
                    {errors.addressType &&
                      touched.addressType &&
                      errors.addressType}
                  </Typography>
                </FormControl>
              </Grid>
              <Grid container>
                <Autocomplete
                  freeSolo
                  style={{ width: '100%' }}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  noOptionsText="No address found"
                  //@ts-ignore
                  getOptionSelected={(option, value) =>
                    option.Text === value.Text
                  }
                  //@ts-ignore
                  getOptionLabel={(option) =>
                    option.Text + ' ' + option.Description
                  }
                  onChange={(e: any, v: any) => {
                    handleAutoCompleteSelected(e, v);
                    handleChange(e);
                  }}
                  options={options}
                  loading={loading}
                  onBlur={(e: any) => {
                    handleAutoCompleteBlur();
                    handleBlur(e);
                  }}
                  clearOnEscape={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.address && touched.address ? true : false}
                      fullWidth
                      required
                      margin="normal"
                      label="Address"
                      name="address"
                      variant="standard"
                      onChange={(e: any) => {
                        setSearchTerm(e.target.value);
                        handleChange(e);
                      }}
                      value={values.address}
                      helperText={
                        errors.address && touched.address && errors.address
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {/* {!loading && addressCorrect ? <DoneIcon /> : null} */}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />

                <Grid item xs={12}>
                  <img
                    style={{ marginTop: '10px' }}
                    alt="Address complete"
                    src={addressComplete}
                  />
                </Grid>

                <Grid xs={6} md={6}>
                  {values.addressType != 'home' &&
                    values.addressType !== 'partner' && (
                      <TextField
                        fullWidth
                        error={errors.buzzer && touched.buzzer ? true : false}
                        margin="normal"
                        style={{ width: '90%' }}
                        id="buzzer"
                        name="buzzer"
                        label="Buzzer"
                        type="text"
                        value={values.buzzer}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          errors.buzzer && touched.buzzer && errors.buzzer
                        }
                      />
                    )}
                </Grid>
                <Grid xs={6} md={6}>
                  {values.addressType == 'business' && (
                    <TextField
                      error={
                        errors.organization && touched.organization
                          ? true
                          : false
                      }
                      fullWidth
                      margin="normal"
                      id="organization"
                      name="organization"
                      label="Organization"
                      type="text"
                      value={values.organization}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={
                        errors.organization &&
                        touched.organization &&
                        errors.organization
                      }
                    />
                  )}
                </Grid>

                <Grid container style={{ marginTop: '1em' }}>
                  <Grid item xs={12}>
                    <Typography variant="body2">Delivery options</Typography>
                  </Grid>

                  <Grid item xs={12} style={{ marginTop: '5px' }}>
                    <Chip
                      icon={<MeetingRoomIcon />}
                      color={
                        values.deliveryOption === 'leaveAtDoor'
                          ? 'secondary'
                          : 'default'
                      }
                      onClick={() =>
                        setFieldValue('deliveryOption', 'leaveAtDoor')
                      }
                      label="Leave at door"
                      style={{ marginRight: '10px' }}
                    />
                    <Chip
                      icon={<DirectionsWalkIcon />}
                      color={
                        values.deliveryOption === 'meetAtDoor'
                          ? 'secondary'
                          : 'default'
                      }
                      onClick={() =>
                        setFieldValue('deliveryOption', 'meetAtDoor')
                      }
                      label="Meet at door"
                      style={{ marginRight: '10px' }}
                    />

                    <Chip
                      icon={<DriveEtaIcon />}
                      color={
                        values.deliveryOption === 'meetOutside'
                          ? 'secondary'
                          : 'default'
                      }
                      onClick={() =>
                        setFieldValue('deliveryOption', 'meetOutside')
                      }
                      label="Meet outside"
                    />
                  </Grid>
                </Grid>

                <TextField
                  error={errors.notes && touched.notes ? true : false}
                  margin="normal"
                  id="notes"
                  name="notes"
                  label="Notes"
                  type="text"
                  multiline
                  fullWidth
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={errors.notes && touched.notes && errors.notes}
                />

                <FormControlLabel
                  onChange={(e: any) => {
                    setFieldValue('primary', !values.primary);
                  }}
                  control={
                    <Checkbox
                      checked={values.primary}
                      //   onChange={handleChange}
                      name="deliveryAddressPrimary"
                    />
                  }
                  label="Make this their primary delivery address"
                />
              </Grid>

              <div
                style={{
                  padding: '30px 0 20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderWidth: '1px',
                    borderColor: '#f44336',
                  }}
                  disabled={removeLoading}
                  onClick={() => removeAddress(props.selectedAddressIndex)}
                >
                  {removeLoading && (
                    <CircularProgress
                      style={{
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-8px',
                      }}
                      size={16}
                    />
                  )}
                  <Typography style={{ color: '#f44336' }}>Delete</Typography>
                </Button>

                {/* <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  style={{ boxShadow: 'none' }}
                  disabled={
                    !dirty ||
                    isSubmitting ||
                    Boolean(errors.address && touched.address)
                  }
                >
                  {isSubmitting && (
                    <CircularProgress
                      style={{
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-8px'
                      }}
                      size={16}
                    />
                  )}
                  Save
                </Button> */}

                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  style={{ boxShadow: 'none' }}
                  disabled={!dirty || isSubmitting}
                >
                  {isSubmitting && (
                    <CircularProgress
                      style={{
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-8px',
                      }}
                      size={16}
                    />
                  )}
                  Save
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
