import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';

import Collapse from '@material-ui/core/Collapse';
import ImageIcon from '@material-ui/icons/Image';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FolderIcon from '@material-ui/icons/Folder';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MyLocationIcon from '@material-ui/icons/MyLocation';

import CategoryIcon from '@material-ui/icons/Category';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {
  ShoppingCart,
  Event,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import { useSelector } from 'react-redux';

const logoImage = require('../../assets/images/vittle-logo-teal.svg');
const note = require('../../assets/images/sticky_note2.svg');

const useStyles = makeStyles({
  list: {
    width: 250,
  },
});

const navItemsDelivery = [
  {
    name: 'Dashboard',
    url: '/',
    icon: <HomeIcon />,
  },
  {
    name: 'Fulfillment',
    url: '/fulfillment',
    icon: <LocalShippingIcon />,
  },
  {
    name: 'Labels',
    url: '/labels',
    icon: <note />,
  },
];

const navItems = [
  {
    name: 'Dashboard',
    url: '/',
    icon: <HomeIcon />,
  },
  {
    name: 'Contacts',
    url: '/customers',
    icon: <PeopleIcon />,
  },
  {
    name: 'Orders',
    url: '/orders',
    icon: <ShoppingCart />,
  },
  {
    name: 'Plating',
    url: '/plating',
    icon: <Event />,
  },
  {
    name: 'Meal planner',
    url: '/meal-planner',
    icon: <Event />,
  },
  {
    name: 'Categories',
    url: '/categories',
    icon: <PeopleIcon />,
  },
  {
    name: 'Tags',
    url: '/tags',
    icon: <CategoryIcon />,
  },
  {
    name: 'Products',
    url: '/products',
    icon: <CategoryIcon />,
  },
  {
    name: 'Ingredients',
    url: '/ingredients',
    icon: <CategoryIcon />,
  },
  {
    name: 'Staff',
    url: '/staff',
    icon: <LocalShippingIcon />,
  },
  {
    name: 'Fulfillment',
    url: '/fulfillment',
    icon: <LocalShippingIcon />,
  },
  {
    name: 'Labels',
    url: '/labels',
    icon: <note />,
  },
  {
    name: 'Media',
    url: '/media',
    icon: <ImageIcon />,
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: <SettingsIcon />,
    hasSubMenu: true,

    subMenuItems: [
      {
        name: 'Taxes',
        url: '/settings/taxes',
        icon: <AttachMoneyIcon />,
      },

      {
        name: 'Meal plans',
        url: '/settings/meal-plans',
        icon: <FolderIcon />,
      },
      {
        name: 'Postal codes',
        url: '/settings/postal-codes',
        icon: <MyLocationIcon />,
      },
      {
        name: 'Delivery',
        url: '/settings/delivery',
        icon: <FolderIcon />,
      },
    ],
  },
];

function MainMenu(props) {
  const classes = useStyles();

  const navigate = (url) => {
    props.history.push(url);
  };

  const user = useSelector((state) => state.auth.user);

  console.log('user-Role', user, user.roles.indexOf('owner'));

  const [openSubMenu, setOpenSubMenu] = React.useState('');

  return (
    <SwipeableDrawer
      open={props.open}
      onOpen={props.toggleDrawer(true)}
      onClose={props.toggleDrawer(false)}
    >
      <div className={classes.list} role="presentation">
        <List>
          <ListItem style={{ marginBottom: '1em', paddingTop: '.75em' }}>
            <img src={logoImage} style={{ width: '90px' }} alt="" />
          </ListItem>
          {user.roles.indexOf('delivery') !== -1 &&
            navItemsDelivery.map((e, index) => (
              <>
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    if (e.hasSubMenu) {
                      setOpenSubMenu(e.name);
                    } else {
                      navigate(e.url);
                      props.changeMenuOpen(false);
                    }
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                  >
                    <ListItemIcon>
                      {e.name === 'Labels' ? <img src={note} alt="" /> : e.icon}
                    </ListItemIcon>
                    <ListItemText primary={e.name} />
                    {e.hasSubMenu && (
                      <Box>
                        {openSubMenu === e.name ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </Box>
                    )}
                  </Box>
                </ListItem>
                {e.hasSubMenu && (
                  <Collapse
                    in={openSubMenu === e.name}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      style={{ paddingLeft: '16px' }}
                    >
                      {e.subMenuItems.map((subMenuItem, subMenuIndex) => {
                        return (
                          <ListItem
                            onClick={() => {
                              navigate(subMenuItem.url);
                              props.changeMenuOpen(false);
                            }}
                            button
                          >
                            <ListItemIcon>{subMenuItem.icon}</ListItemIcon>
                            <ListItemText primary={subMenuItem.name} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                )}
              </>
            ))}

          {user.roles.indexOf('owner') !== -1 &&
            navItems.map((e, index) => (
              <>
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    if (e.hasSubMenu) {
                      setOpenSubMenu(e.name);
                    } else {
                      navigate(e.url);
                      props.changeMenuOpen(false);
                    }
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems="center"
                  >
                    <ListItemIcon>
                      {e.name === 'Labels' ? <img src={note} /> : e.icon}
                    </ListItemIcon>
                    <ListItemText primary={e.name} />
                    {e.hasSubMenu && (
                      <Box>
                        {openSubMenu === e.name ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </Box>
                    )}
                  </Box>
                </ListItem>
                {e.hasSubMenu && (
                  <Collapse
                    in={openSubMenu === e.name}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      style={{ paddingLeft: '16px' }}
                    >
                      {e.subMenuItems.map((subMenuItem, subMenuIndex) => {
                        return (
                          <ListItem
                            onClick={() => {
                              navigate(subMenuItem.url);
                              props.changeMenuOpen(false);
                            }}
                            button
                          >
                            <ListItemIcon>{subMenuItem.icon}</ListItemIcon>
                            <ListItemText primary={subMenuItem.name} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                )}
              </>
            ))}
        </List>
        {/* <Divider /> */}
      </div>
    </SwipeableDrawer>
  );
}

export default withRouter(MainMenu);
