import React, { Ref, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Breadcrumbs,
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Switch,
  Checkbox,
  FormControlLabel,
  Chip,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  InputAdornment,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import debounce from 'lodash/debounce';

import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { useStaticState, KeyboardDatePicker } from '@material-ui/pickers';

//@ts-ignore
import PDFDocument from 'pdfkit/js/pdfkit.standalone.js';
import blobStream from 'blob-stream';
import moment from 'moment';
import { Autocomplete } from '@material-ui/lab';

import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ImageIcon from '@material-ui/icons/Image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CropIcon from '@material-ui/icons/Crop';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';

import { lighten, makeStyles, useTheme, alpha } from '@material-ui/core/styles';

import { searchProducts } from '../../api/products';
import { searchIngredients } from '../../api/ingredients';
import { RootState } from '../../rootReducer';
import { BirthdayIcon, CustomIcon, AlertIcon } from '../../modules/label-icons';

import './NewLabel.scss';

const logoImage = require('../../assets/images/Vittle Wordmark (White).svg');

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    width: '100%',
  },
  tabs: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: '1px',
  },
  filterChips: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root2: {
    width: '100%',
  },
  media: {
    height: 200,
  },
}));

export default function (props: any) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [ingredientsSearchTerm, setIngredientsSearchTerm] = React.useState('');

  const [allergiesSearchTerm, setAllergiesSearchTerm] = React.useState('');
  const [dislikesSearchTerm, setDislikesSearchTerm] = React.useState('');

  const [dishesLoading, setDishesLoading] = React.useState(false);

  const [dishName, setDishName] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [dietaryRestrictions, setDietaryRestrictions] = useState([]);
  const [religiousRestrictions, setReligiousRestrictions] = useState([]);
  const [dislikes, setDislikes] = useState([]);
  const [preferences, setPreferences] = useState([]);

  const [customerName, setCustomerName] = useState('');
  const [hasBirthday, setHasBirthday] = useState(false);
  const [isCustom, setIsCustom] = useState(false);

  const [quantityStart, setQuantityStart] = useState('1');
  const [quantityTotal, setQuantityTotal] = useState('1');

  const [notes, setNotes] = useState('1');

  const [instruction, setInstruction] = useState('');
  const [madeOnDate, setMadeOnDate] = useState(moment().format('MMMM D'));

  const [calories, setCalories] = useState('0');
  const [protein, setProtein] = useState('0');
  const [carbs, setCarbs] = useState('0');
  const [fats, setFats] = useState('0');
  const [activeStep, setActiveStep] = React.useState(0);
  const [currentLabelPage, setCurrentLabelPage] = React.useState(1);

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepLabel = () => {
    if (activeStep == 1) {
      return 'Select contact';
    } else if (activeStep == 2) {
      return 'Select product';
    } else if (activeStep == 3) {
      return 'Review preferences';
    } else if (activeStep == 4) {
      return 'Review additional options';
    } else if (activeStep == 1) {
      return 'Select product';
    }
  };

  const searchDishes = async (searchTerm: string) => {
    if (searchTerm == '') {
      return;
    }

    try {
      setDishesLoading(true);
      //ADD DEBOUNCED
      const products = await searchProducts(searchTerm);
      console.log(products);
      setOptions(products);
    } catch (error) {
      setDishesLoading(false);
      console.log(error);
    }

    setDishesLoading(false);
  };

  // const debouncedSearchDishes = debounce(searchDishes, 1000);

  const printLabel = () => {
    const doc = new PDFDocument({ autoFirstPage: false });
    doc.addPage({
      size: [288, 216],
      margin: 0,
    });

    doc.font('Helvetica');
    doc.fontSize(7.5);
    const day = moment(madeOnDate).format('MMMM D');
    doc.text(`Made for you on ${day}`, 10.8, 204);

    doc.fontSize(18);
    doc.font('Helvetica-Bold');

    if (doc.widthOfString(`Made for ${customerName}`) <= 265.5) {
      doc.text(`Made for ${customerName}`, 10.8, 58, { width: 265.5 });
    } else if (doc.widthOfString(`${customerName}`) <= 265.5) {
      doc.text(customerName, 10.8, 58, { width: 265.5 });
    } else {
      doc.text(
        `${customerName.split(' ')[0]} ${customerName
          .split(' ')
          [customerName.split(' ').length - 1 || 1].charAt(0)}.`,
        10.8,
        58,
        { width: 265.5 }
      );
    }

    doc.font('Helvetica-Bold');
    doc.fontSize(13);
    doc.text(dishName, 10.8, 82, { width: 265.5 });

    let stream = doc.pipe(blobStream());

    doc.image(BirthdayIcon, 62, 34, {
      width: 50.2488,
      height: 15.74,
    });

    doc.fontSize(7.5);
    doc.font('Helvetica-Bold');

    doc
      .roundedRect(
        11.25,
        33.7464,
        doc.widthOfString(`Qty: ${quantityStart} of ${quantityTotal}`) + 7.2,
        15.75,
        1.8
      )
      .stroke();

    doc.text(`Qty: ${quantityStart} of ${quantityTotal}`, 15, 39);

    //Instructions

    doc.fontSize(0);
    doc.text(' ', 0, 0, { continued: false, strike: false });

    // instructions
    if (instruction !== '') {
      doc.font('Helvetica-Bold');
      doc.fontSize(7.5);
      doc.text(instruction, 10.8, 196);
    }

    // nutritional info
    doc.font('Helvetica');
    doc.fontSize(7.5);

    //calories
    doc.font('Helvetica');
    doc.text('Calories', 165.6, 204);

    // if (calories !== '0' && parseFloat(calories) > 0) {
    doc.font('Helvetica-Bold');
    doc.text(calories, 165.6, 196);
    // }

    //protein
    doc.font('Helvetica');
    doc.text('Protein', 203.7, 204);

    // if (protein !== '0' && parseFloat(protein) > 0) {
    doc.font('Helvetica-Bold');
    doc.text(`${protein}g`, 204, 196);
    // }

    //carbs
    doc.font('Helvetica');
    doc.text('Carbs', 235, 204);

    // if (carbs !== '0' && parseFloat(carbs) > 0) {
    doc.font('Helvetica-Bold');
    doc.text(`${carbs}g`, 235, 196);
    // }

    //fats
    doc.font('Helvetica');
    doc.text('Fats', 262.8, 204);

    // if (fats !== '0' && parseFloat(fats) > 0) {
    doc.font('Helvetica-Bold');
    doc.text(`${fats}g`, 262.8, 196);
    // }

    //ingredients
    // dish ingredients
    if (ingredients.length > 0) {
      doc.fontSize(9);
      doc.font('Helvetica');

      if (
        allergies.length > 0 ||
        religiousRestrictions.length > 0 ||
        dislikes.length > 0
      ) {
        ingredients.forEach((ingredient: string, index: number) => {
          let hasScratch = false;

          // COME BACK HERE TO SEE IF CUSTOMER HAS RESTRICTIONS ADDED TO THEIR ACCOUNT
          // if (
          //   userData.hasOwnProperty('restrictions') &&
          //   userData.restrictions != null
          // ) {
          //   // get the restriction
          //   userData.restrictions.forEach((rest) => {
          //     if (rest.types.findIndex((t) => t ingredient.typeId) !== -1) {
          //       hasScratch = true;
          //     } else if (rest.ingredients !== undefined) {
          //       if (
          //         rest.ingredients.findIndex(
          //           (ing) => ing === ingredient._id
          //         ) !== -1
          //       ) {
          //         hasScratch = true;
          //       }
          //     }
          //   });
          // }

          if (allergies.length > 0) {
            if (
              allergies.findIndex((srest: string) => srest === ingredient) !==
              -1
            ) {
              hasScratch = true;
            }
          }

          if (dislikes.length > 0) {
            if (
              dislikes.findIndex((pref: string) => pref === ingredient) !== -1
            ) {
              hasScratch = true;
            }
          }

          // console.log(hasScratch);
          if (index === 0) {
            doc.text(ingredient, 10.8, 108, {
              width: 265.5,
              continued: true,
              strike: hasScratch,
            });
            doc.text(', ', { continued: true, strike: false });
          } else if (index === ingredients.length - 1) {
            doc.text(ingredient, { continued: true, strike: hasScratch });
          } else {
            doc.text(ingredient, { continued: true, strike: hasScratch });
            doc.text(', ', { continued: true, strike: false });
          }
        });
      } else {
        doc.text(`${ingredients.join(', ')}`, 10.8, 108, { width: 265.5 });
      }
    }

    doc.text(' ', 0, 0, { continued: false });

    //restrictions
    if (
      allergies.length > 0 ||
      dietaryRestrictions.length > 0 ||
      dislikes.length > 0 ||
      preferences.length > 0
    ) {
      doc.roundedRect(10.8, 145, 265, 45, 1.8).stroke();

      doc.image(AlertIcon, 22.32, 154, { width: 15.84, height: 13.68 });

      doc.fontSize(7);

      // console.log('Restriction by type');

      let hasRestrictionAllergy = false;
      let hasRestrictionDietary = false;
      let hasRestrictionReligious = false;
      let hasDislikes = false;

      // console.log(restrictionsByType);
      // if (restrictionsByType) {
      if (allergies.length > 0) {
        hasRestrictionAllergy = true;
        doc.font('Helvetica-Bold');
        doc.text('Allergies: ', 50, 153, { width: 218.99, continued: true });

        doc.font('Helvetica');

        allergies.forEach((allergy: string, index: number) => {
          doc.text(allergy, { continued: true });

          if (index !== allergies.length - 1) {
            doc.text(', ', { continued: true });
          } else {
            doc.text('; ', { continued: true });
          }
        });
      }
      // }

      // COME BACK HERE FOR SPECIFIC RESTRICTIONS OR ALLERGY GROUPS
      // if (
      //   userData.hasOwnProperty('specificRestrictions') &&
      //   userData.specificRestrictions != null &&
      //   userData.specificRestrictions.length > 0
      // ) {
      //   doc.font('Helvetica-Bold');
      //   if (!hasRestrictionAllergy) {
      //     doc.text('Allergies: ', 50, 153, { width: 218.99, continued: true });
      //   }

      //   doc.font('Helvetica');
      //   userData.specificRestrictions.forEach((rest, index) => {
      //     doc.text(rest.title, { continued: true });
      //     if (index !== userData.specificRestrictions.length - 1) {
      //       doc.text(', ', { continued: true });
      //     } else {
      //       doc.text('; ', { continued: true });
      //     }
      //   });

      //   hasRestrictionAllergy = true;
      // }

      // if (restrictionsByType) {
      if (dietaryRestrictions.length > 0) {
        hasRestrictionDietary = true;

        doc.font('Helvetica-Bold');

        if (hasRestrictionAllergy) {
          doc.text('Dietary: ', { continued: true });
        } else {
          doc.text('Dietary: ', 50, 153, { width: 218.99, continued: true });
        }

        doc.font('Helvetica');
        dietaryRestrictions.forEach((dietary: string, index: number) => {
          doc.text(dietary, { continued: true });

          if (index !== dietaryRestrictions.length - 1) {
            doc.text(', ', { continued: true });
          } else {
            doc.text('; ', { continued: true });
          }
        });
      }

      if (religiousRestrictions.length > 0) {
        hasRestrictionReligious = true;
        doc.font('Helvetica-Bold');

        if (hasRestrictionDietary || hasRestrictionAllergy) {
          doc.text('Religious: ', { continued: true });
        } else {
          doc.text('Dietary: ', 50, 153, { width: 218.99, continued: true });
        }

        doc.font('Helvetica');

        religiousRestrictions.map((religious: string, index: number) => {
          doc.text(religious, { continued: true });

          if (index !== religiousRestrictions.length - 1) {
            doc.text(', ', { continued: true });
          } else {
            doc.text('; ', { continued: true });
          }
        });
      }
      // }

      if (dislikes.length > 0) {
        hasDislikes = true;
        doc.font('Helvetica-Bold');

        if (
          hasRestrictionDietary ||
          hasRestrictionAllergy ||
          hasRestrictionReligious
        ) {
          doc.text('Dislikes: ', { continued: true });
        } else {
          doc.text('Dislikes: ', 50, 153, { width: 218.99, continued: true });
        }

        doc.font('Helvetica');

        dislikes.map((dislike: string, index: number) => {
          doc.text(dislike, { continued: true });
          if (index !== dislikes.length - 1) {
            doc.text(', ', { continued: true });
          } else {
            doc.text('; ', { continued: true });
          }
        });
      }

      // if (restrictionsByType) {
      if (preferences.length > 0) {
        doc.font('Helvetica-Bold');

        if (
          hasRestrictionDietary ||
          hasRestrictionAllergy ||
          hasRestrictionReligious ||
          hasDislikes
        ) {
          doc.text('Preferences: ', { continued: true });
        } else {
          doc.text('Preferences: ', 50, 153, {
            width: 218.99,
            continued: true,
          });
        }

        doc.font('Helvetica');

        preferences.map((preference: string, index: number) => {
          doc.text(preference, { continued: true });
          if (index !== preferences.length - 1) {
            doc.text(', ', { continued: true });
          } else {
            doc.text('; ', { continued: true });
          }
        });
      }
      // }
    }

    doc.text(' ', 0, 0, { continued: false });

    stream.on('finish', () => {
      const blob = stream.toBlob('application/pdf');

      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = `Label_${new Date().toDateString()}.pdf`;

      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      window.addEventListener('focus', (e) => URL.revokeObjectURL(link.href), {
        once: true,
      });
    });

    doc.end();
  };

  const renderRestrictions = (type: string) => {
    let dataToReturn = '';

    let hasRestrictionAllergy = false;
    let hasRestrictionDietary = false;
    let hasRestrictionReligious = false;
    let hasDislikes = false;

    if (type === 'allergies') {
      if (allergies.length > 0) {
        hasRestrictionAllergy = true;

        dataToReturn += allergies.join(', ');
      }
    }

    if (type === 'dietary') {
      if (dietaryRestrictions.length > 0) {
        hasRestrictionAllergy = true;

        dataToReturn += dietaryRestrictions.join(', ');
      }
    }

    if (type === 'dislikes') {
      if (dislikes.length > 0) {
        hasRestrictionAllergy = true;
        dataToReturn += dislikes.join(', ');
      }
    }

    if (type === 'preferences') {
      if (preferences.length > 0) {
        hasRestrictionAllergy = true;
        dataToReturn += preferences.join(', ');
      }
    }

    return dataToReturn;
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <Link
              to="/"
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Button style={{ textTransform: 'capitalize' }}>
                <Typography>Dashboard</Typography>
              </Button>
            </Link>
            <Link
              to="/labels"
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Button style={{ textTransform: 'capitalize' }}>
                <Typography>Labels</Typography>
              </Button>
            </Link>
            <Typography variant="body1">New Label</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: '40px' }}
      >
        <Grid item>
          <Box fontWeight="fontWeightMedium" fontSize="h4.fontSize" m={1}>
            New Label
          </Box>
          <Box></Box>
        </Grid>

        <Grid item></Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginTop: '30px' }}>
        <Grid item md={7}>
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <div className="label">
              <img className="label__logo" src={logoImage} />

              <div className="label__print-area">
                <div className="label__print-element label__print-element--quantity-chip label__print-element--bold">
                  Qty: {quantityStart} of {quantityTotal}
                </div>
                {hasBirthday && (
                  <div className="label__print-element label__print-element--icon label__print-element--birthday-icon">
                    <img src={BirthdayIcon} />
                  </div>
                )}
                {isCustom && (
                  <div className="label__print-element label__print-element--icon label__print-element--birthday-icon">
                    <img src={CustomIcon} />
                  </div>
                )}
                <div className="label__print-element label__print-element--name label__print-element--bold">
                  Made for {customerName}
                </div>
                <div className="label__print-element label__print-element--dish-name label__print-element--bold">
                  {dishName}
                </div>
                <div className="label__print-element label__print-element--ingredients">
                  {ingredients.join(', ')}
                </div>

                {allergies.length > 0 ||
                  dietaryRestrictions.length > 0 ||
                  dislikes.length > 0 ||
                  (preferences.length > 0 && (
                    <div className="label__print-element label__print-element--rectangle"></div>
                  ))}
                {(allergies.length > 0 ||
                  dietaryRestrictions.length > 0 ||
                  dislikes.length > 0 ||
                  preferences.length > 0) && (
                  <div className="label__print-element label__print-element--rectangle-data">
                    {allergies.length > 0 && (
                      <span>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '7pt',
                            fontFamily: 'Helvetica',
                          }}
                        >
                          Allergies:{' '}
                        </span>
                        {renderRestrictions('allergies')}
                        <span>;</span>
                      </span>
                    )}

                    {dietaryRestrictions.length > 0 && (
                      <span>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '7pt',
                            fontFamily: 'Helvetica',
                          }}
                        >
                          {' '}
                          Dietary:{' '}
                        </span>
                        {renderRestrictions('dietary')}
                        <span>;</span>
                      </span>
                    )}

                    {dislikes.length > 0 && (
                      <span>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: '7pt',
                            fontFamily: 'Helvetica',
                          }}
                        >
                          {' '}
                          Dislikes:{' '}
                        </span>
                        {renderRestrictions('dislikes')}
                        <span>;</span>
                      </span>
                    )}
                  </div>
                )}

                <span className="label__print-element label__print-element--warning-icon">
                  <img src={AlertIcon} />
                </span>

                <div className="label__print-element label__print-element--rectangle">
                  {/* {restrictions.join(', ')} */}
                </div>

                <div className="label__print-element label__print-element--made-on">
                  Made for you on {madeOnDate}
                </div>

                <div className="label__print-element label__print-element--instruction">
                  {instruction}
                </div>

                <span className="label__print-element label__print-element--calories-heading">
                  Calories
                </span>
                <span className="label__print-element label__print-element--calories">
                  {calories}
                </span>

                <span className="label__print-element label__print-element--protein-heading">
                  Protein
                </span>
                <span className="label__print-element label__print-element--protein">
                  {protein}g
                </span>

                <span className="label__print-element label__print-element--carbs-heading">
                  Carbs
                </span>
                <span className="label__print-element label__print-element--carbs">
                  {carbs}g
                </span>

                <span className="label__print-element label__print-element--fats-heading">
                  Fats
                </span>
                <span className="label__print-element label__print-element--fats">
                  {fats}g
                </span>
              </div>
            </div>
          </Box>

          {/* <Box display="flex" maxWidth="400px">
            <Button
              variant="contained"
              color="primary"
              // startIcon={<DeleteIcon />}
            >
              Prev
            </Button>
            <div className="dots">
              <span></span>
            </div>
            <Button
              variant="contained"
              color="primary"
              // startIcon={<DeleteIcon />}
            >
              Next
            </Button>
          </Box> */}
        </Grid>
        <Grid item md={5}></Grid>
      </Grid>

      <Paper elevation={2} className="label-elements">
        <div className="label-elements__in">
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step key={activeStep}>
              <StepLabel style={{ fontSize: '2em' }}>
                <Box
                  fontWeight="fontWeightMedium"
                  fontSize="h6.fontSize"
                  mb={0}
                >
                  Select contact
                </Box>
              </StepLabel>
              <StepContent>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    autoFocus
                    id="name"
                    margin="normal"
                    label="Search contact"
                    type="text"
                    value={customerName}
                    onChange={(e: any) => setCustomerName(e.target.value)}
                    onBlur={(e: any) => setCustomerName(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Box>
              </StepContent>
            </Step>

            <Step key={activeStep}>
              <StepLabel>
                {' '}
                <Box
                  fontWeight="fontWeightMedium"
                  fontSize="h6.fontSize"
                  mb={0}
                >
                  Select product
                </Box>
              </StepLabel>
              <StepContent>
                {/* <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize="h6.fontSize"
                    mb={0}
                  >
                    Meal
                  </Box>
                </Grid> */}

                <Grid item xs={12}>
                  <Autocomplete
                    blurOnSelect
                    freeSolo
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    onChange={(ev: any, val: any) => {
                      if (typeof val === 'object') {
                        setDishName(val.title);

                        if (val.ingredients.length > 0) {
                        }
                      } else {
                        setDishName(val);
                      }
                    }}
                    options={options}
                    loading={dishesLoading}
                    getOptionLabel={(option: any) => option.title}
                    open={open}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        margin="normal"
                        label="Search product"
                        variant="outlined"
                        onChange={(e: any) => {
                          setSearchTerm(e.target.value);
                          setDishName(e.target.value);
                          // searchDishes(e.target.value);
                        }}
                        value={searchTerm}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),

                          endAdornment: (
                            <React.Fragment>
                              {dishesLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      fontWeight="fontWeightMedium"
                      fontSize="body1.fontSize"
                      mb={1}
                      mt={3}
                    >
                      Ingredients
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={[]}
                      freeSolo
                      onChange={(ev: any, val: any) => {
                        setIngredients(val);
                      }}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder={
                            ingredients.length > 0
                              ? ''
                              : 'Enter ingredient name'
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize="body1.fontSize"
                    mb={1}
                    mt={3}
                  >
                    Nutritional information
                  </Box>
                </Grid>

                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      autoFocus
                      id="name"
                      label="Calories"
                      type="text"
                      onChange={(e: any) => setCalories(e.target.value)}
                      onBlur={(e: any) => setCalories(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">cal</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      autoFocus
                      id="name"
                      label="Protein"
                      type="text"
                      onChange={(e: any) => setProtein(e.target.value)}
                      onBlur={(e: any) => setProtein(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">g</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} item xs={12}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      autoFocus
                      id="name"
                      label="Carbs"
                      type="text"
                      onChange={(e: any) => setCarbs(e.target.value)}
                      onBlur={(e: any) => setCarbs(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">g</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      autoFocus
                      id="name"
                      label="Fats"
                      type="text"
                      onChange={(e: any) => setFats(e.target.value)}
                      onBlur={(e: any) => setFats(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">g</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize="body1.fontSize"
                    mb={1}
                    mt={3}
                  >
                    Instructions
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={instruction}
                    onChange={(ev: any) => setInstruction(ev.target.value)}
                    variant="outlined"
                    placeholder="Enter instruction"
                    fullWidth
                    margin="normal"
                  />
                </Grid>

                <Box mt={3}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Box>
              </StepContent>
            </Step>

            <Step key={activeStep}>
              <StepLabel>
                <Box
                  fontWeight="fontWeightMedium"
                  fontSize="h6.fontSize"
                  mb={0}
                >
                  Review preferences
                </Box>
              </StepLabel>
              <StepContent>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    onChange={(ev: any, val: any) => {
                      setAllergies(val);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Enter allergies"
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />

                  {/* 
                
                <Autocomplete
                  blurOnSelect
                                    freeSolo
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onChange={(ev: any, val: any) => {
                    if (typeof val === 'object') {
                      setDishName(val.title);

                      if (val.ingredients.length > 0) {
                      }
                    } else {
                      setDishName(val);
                    }
                  }}
                  options={options}
                  loading={dishesLoading}
                  getOptionLabel={(option: any) => option.title}
                  open={open}
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="normal"
                      label="Enter allergies"
                      variant="outlined"
                      onChange={(e: any) => {
                        setSearchTerm(e.target.value);
                        searchIngredients(e.target.value);
                      }}
                      value={searchTerm}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {dishesLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                /> */}
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    onChange={(ev: any, val: any) => {
                      setDietaryRestrictions(val);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Enter dietary restrictions"
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    freeSolo
                    onChange={(ev: any, val: any) => {
                      setDislikes(val);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        placeholder="Enter dislikes"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  freeSolo
                  onChange={(ev: any, val: any) => {
                    setPreferences(val);
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Enter preferences"
                    />
                  )}
                /> 
                </Grid>*/}

                <Box mt={3}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Box>
              </StepContent>
            </Step>
            <Step key={activeStep}>
              <StepLabel>
                {' '}
                <Box
                  fontWeight="fontWeightMedium"
                  fontSize="h6.fontSize"
                  mb={0}
                >
                  Review additional options
                </Box>
              </StepLabel>
              <StepContent>
                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize="body1.fontSize"
                    mt={3}
                    mb={1}
                  >
                    Quantity
                  </Box>
                </Grid>
                <Grid item container xs={12} spacing={4}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      autoFocus
                      label="Total quantity"
                      value={quantityTotal}
                      type="number"
                      onChange={(e: any) => setQuantityTotal(e.target.value)}
                      onBlur={(e: any) => setQuantityTotal(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize="body1.fontSize"
                    mt={3}
                    mb={1}
                  >
                    Made on
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="startDate"
                    name="startDate"
                    label="Select a date"
                    format="dddd, MMMM D"
                    value={madeOnDate}
                    onChange={(date: any, value: any) => {
                      setMadeOnDate(moment(date).format('MMMM D'));
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize="body1.fontSize"
                    mt={3}
                    mb={1}
                  >
                    Notes
                  </Box>
                </Grid>
                <Grid item container xs={12} spacing={4}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      autoFocus
                      disabled
                      multiline
                      label="Notes"
                      type="text"
                      value={notes}
                      onChange={(e: any) => setNotes(e.target.value)}
                      onBlur={(e: any) => setNotes(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize="body1.fontSize"
                    mt={3}
                    mb={1}
                  >
                    Birthday
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasBirthday}
                          onChange={(event: any) =>
                            setHasBirthday(!hasBirthday)
                          }
                          name="checkedA"
                        />
                      }
                      label={'Birthday'}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    fontWeight="fontWeightMedium"
                    fontSize="body1.fontSize"
                    mt={3}
                    mb={1}
                  >
                    Custom
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isCustom}
                          onChange={(event: any) => setIsCustom(!isCustom)}
                          // name="checkedA"
                        />
                      }
                      label={'Custom'}
                    />
                  </Box>
                </Grid>

                <Box mt={3}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={handleNext}
                    onClick={printLabel}
                  >
                    <PrintIcon />
                    Print
                  </Button>
                </Box>
              </StepContent>
            </Step>
          </Stepper>

          {/* 
          <Box className="btn-container">
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={printLabel}
            >
              <PrintIcon /> Print
            </Button>
          </Box> */}
        </div>
      </Paper>
    </Container>
  );
}
