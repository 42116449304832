import { createSlice } from '@reduxjs/toolkit';
import OrdersService from '../../api/orders';

const initialState = {
  orders: {
    data: [],
    count: 0,
    total: 0,
  },
  ordersLoading: true,
  order: null,
  orderLoading: true,
  platingData: [],
  platingDataLoading: true,
  deliveryData: [],
  deliveryDataLoading: true,
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    ordersLoading: (state, action: any) => {
      //@ts-ignore
      state.ordersLoading = action.payload;
    },

    fetchOrdersSuccess: (state, action: any) => {
      //@ts-ignore
      state.orders = action.payload;
      state.ordersLoading = false;
    },

    unsetOrder: (state, action) => {
      state.order = null;
      state.orderLoading = true;
    },

    orderLoading: (state, action: any) => {
      //@ts-ignore
      state.orderLoading = action.payload;
    },

    fetchOrderSuccess: (state, action: any) => {
      //@ts-ignore
      state.order = action.payload;
      state.orderLoading = false;
    },

    updateOrderSuccess: (state, action) => {
      state.order = action.payload;
    },

    getPlatingDataSuccess: (state, action: any) => {
      //@ts-ignore
      state.platingData = action.payload;
      state.platingDataLoading = false;
    },

    setPlatingDataLoading: (state, action) => {
      state.platingDataLoading = action.payload;
    },

    getDeliveryDataSuccess: (state, action: any) => {
      //@ts-ignore
      state.deliveryData = action.payload;
      state.deliveryDataLoading = false;
    },

    setDeliveryDataLoading: (state, action) => {
      state.deliveryDataLoading = action.payload;
    },
  },
});

export const {
  ordersLoading,
  fetchOrdersSuccess,
  orderLoading,
  fetchOrderSuccess,
  updateOrderSuccess,
  getPlatingDataSuccess,
  setPlatingDataLoading,
  getDeliveryDataSuccess,
  setDeliveryDataLoading,
} = ordersSlice.actions;

export function fetchOrders(query: any) {
  return async function (dispatch: Function) {
    dispatch(ordersLoading(true));
    console.log('getting here', query);
    try {
      const org: Object = await OrdersService.find(query);
      console.log(org);
      //@ts-ignore
      dispatch(fetchOrdersSuccess(org));
    } catch (error) {
      dispatch(ordersLoading(false));
      console.log(error);
    }
  };
}

export function fetchOrder(id: string) {
  return async function (dispatch: Function) {
    dispatch(orderLoading(true));
    console.log('getting order single here');
    try {
      const org: Object = await OrdersService.get(id);
      console.log(org);
      //@ts-ignore
      dispatch(fetchOrderSuccess(org));
    } catch (error) {
      console.log(error);
    }
  };
}

export function updateOrder(
  id: string,
  order: any,
  setSubmitting: Function | null = null
) {
  return async function (dispatch: Function) {
    try {
      const i = await OrdersService.patch(id, order);
      console.log(i);
      dispatch(updateOrderSuccess(i));
    } catch (error) {
      console.log('Update order item failed');
      console.log(error);
    } finally {
      if (setSubmitting !== null) setSubmitting(false);
    }
  };
}

export const emaiLReceipt =
  (id: string, successFunc: Function) => async (dispatch: Function) => {
    try {
      await OrdersService.patch(id, {
        emailReceipt: true,
      });

      if (successFunc) {
        successFunc();
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getPlatingData =
  (selectedDate: string) => async (dispatch: Function) => {
    dispatch(setPlatingDataLoading(true));

    try {
      const orderData = await OrdersService.get(null, {
        query: {
          selectedDate,
          plating: true,
        },
      });

      console.log(orderData);
      dispatch(getPlatingDataSuccess(orderData));
    } catch (error) {
      console.log(error);
    }
  };

export const getDeliveryData =
  (weekStartDate: string, todaysDate: string) => async (dispatch: Function) => {
    dispatch(setPlatingDataLoading(true));

    console.log('test');

    try {
      const orderData = await OrdersService.get(null, {
        query: {
          weekStartDate,
          todaysDate: todaysDate,
          delivery: true,
        },
      });

      console.log(orderData);
      dispatch(getDeliveryDataSuccess(orderData));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setDeliveryDataLoading(false));
    }
  };

export default ordersSlice.reducer;
