import feathers from '../../modules/feathers';

import {
  requestSecondaryCustomers,
  fetchSecondaryCustomers,
  receivedSecondaryCustomers,
} from './fetch';
// import { createActivity } from '../activities/add';

export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const ADD_CUSTOMER_LOADING = 'ADD_CUSTOMER_LOADING';

export const UPDATE_CUSTOMER_CONTACT_DETAILS =
  'UPDATE_CUSTOMER_CONTACT_DETAILS';

export const UPDATE_CUSTOMER_NOTES = 'UPDATE_CUSTOMER_NOTES';

export function addCustomerLoading(loading: Boolean) {
  return {
    type: ADD_CUSTOMER_LOADING,
    payload: loading,
  };
}

export function addCustomer(customer: Object) {
  return {
    type: ADD_CUSTOMER,
    payload: customer,
  };
}

export function updateCustomerContactDetails(customer: Object) {
  return {
    type: UPDATE_CUSTOMER_CONTACT_DETAILS,
    payload: customer,
  };
}

export function updateCustomerNotes(note: String) {
  return {
    type: UPDATE_CUSTOMER_NOTES,
    payload: note,
  };
}

export function updateCustomerSecondaryAccountsId(
  primaryAccountId: String,
  idToPush: String
) {
  return async function (dispatch: Function) {
    await feathers.service('users').patch(primaryAccountId, {
      $push: {
        secondaryAccounts: idToPush,
      },
    });
  };
}

export function createCustomer(customer: Object, history: any) {
  return async function (dispatch: Function) {
    dispatch(addCustomerLoading(true));

    let createdCustomer;

    try {
      createdCustomer = await feathers.service('users').create(customer);
      console.log(createdCustomer);
      //@ts-ignore
      if (!customer.secondary) {
        await dispatch(addCustomer(createdCustomer));
        dispatch();
        // createActivity({
        //   actionOn: createdCustomer._id,
        //   actionBy: 'RANDOM_ID',
        //   action: 'create-primary-customer',
        //   data: {
        //     firstName: createdCustomer.firstName,
        //     lastName: createdCustomer.lastName,
        //   },
        // })
      } else {
        await dispatch(
          updateCustomerSecondaryAccountsId(
            createdCustomer.primaryAccountId,
            createdCustomer._id
          )
        );

        await dispatch(requestSecondaryCustomers());

        const secondaryCustomers = await fetchSecondaryCustomers(
          createdCustomer.primaryAccountId
        );

        await dispatch(receivedSecondaryCustomers(secondaryCustomers));

        dispatch();
        // createActivity({
        //   actionOn: createdCustomer._id,
        //   actionBy: 'RANDOM_ID',
        //   action: 'create-secondary-customer',
        //   data: {
        //     primaryAccountId: createdCustomer.primaryAccountId,
        //     firstName: createdCustomer.firstName,
        //     lastName: createdCustomer.lastName,
        //   },
        // })

        dispatch();
        // createActivity({
        //   actionOn: createdCustomer.primaryAccountId,
        //   actionBy: 'RANDOM_ID',
        //   action: 'add-secondary-contact',
        //   data: {
        //     firstName: createdCustomer.firstName,
        //     lastName: createdCustomer.lastName,
        //   },
        // })
      }

      await dispatch(addCustomerLoading(false));
      //@ts-ignore
      if (!customer.secondary) {
        history.push(`/customer/${createdCustomer._id}`);
      }
    } catch (err) {
      console.log('ADD CUSTOMER FAILED');
      console.log(err);
      dispatch(addCustomerLoading(false));
    }
  };
}

export function updateCustomerContact(
  customerId: String,
  contactDetails: Object
) {
  return function (dispatch: Function) {
    return feathers
      .service('users')
      .patch(customerId, {
        ...contactDetails,
      })
      .then((res: any) => {
        // dispatch(
        //   createActivity({
        //     actionOn: customerId,
        //     actionBy: 'RANDOM_ID',
        //     action: 'update-customer-contact',
        //   })
        // );
      });
  };
}

export function updateCustomerNotesActual(customerId: String, notes: String) {
  return function (dispatch: Function) {
    return feathers
      .service('users')
      .patch(customerId, {
        notes,
      })
      .then((res: any) => {
        dispatch();
        // createActivity({
        //   actionOn: customerId,
        //   actionBy: 'RANDOM_ID',
        //   action: 'update-customer-notes',
        //   data: {
        //     notes,
        //   },
        // })
      });
  };
}

export function updateCustomerAddress(customerId: String, address: [Object]) {
  return function (dispatch: Function) {
    return feathers.service('users').patch(customerId, {
      address,
    });
  };
}
