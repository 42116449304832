import React from 'react';

import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Paper,
  Button,
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

interface CustomerOrderProps {
  orders?: Array<object>;
}

export default function CustomerOrders(props: CustomerOrderProps) {
  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Order history</Typography>
              <Button>
                <AddIcon style={{ marginRight: '5px' }} /> New order
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Restrictions</Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
