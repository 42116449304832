import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import { useStaticState, KeyboardDatePicker } from '@material-ui/pickers';
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { Formik, Field, FieldArray, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

export default (props: {
  open: boolean;
  close: any;
  arrayHelpers?: object;
  values?: any; //formik
  selectedEditAvailabilityIndex: number;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // console.log(props.values.availability[props.selectedEditAvailabilityIndex]);
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <Formik
        initialValues={{
          startDate:
            props.selectedEditAvailabilityIndex !== -1
              ? props.values.availability[props.selectedEditAvailabilityIndex]
                  .startDate
              : moment().format('YYYY-MM-DD'),
          endDate:
            props.selectedEditAvailabilityIndex !== -1
              ? props.values.availability[props.selectedEditAvailabilityIndex]
                  .endDate
              : moment().add(1, 'd').format('YYYY-MM-DD'),
          noEndDate:
            props.selectedEditAvailabilityIndex !== -1
              ? props.values.availability[props.selectedEditAvailabilityIndex]
                  .noEndDate
              : false,
          frequency:
            props.selectedEditAvailabilityIndex !== -1
              ? props.values.availability[props.selectedEditAvailabilityIndex]
                  .frequency
              : 'This day only',
          frequencyValue:
            props.selectedEditAvailabilityIndex !== -1
              ? props.values.availability[props.selectedEditAvailabilityIndex]
                  .frequencyValue
              : '',
        }}
        validationSchema={Yup.object().shape({
          // name: '',
          // _id: Yup.object().shape
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log('Inside asd');
          console.log(values);

          //@ts-ignore
          props.arrayHelpers.push({ ...values });
          props.close();
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <div
              style={{
                padding: '15px 15px 15px 25px',
                backgroundColor: '#33474C',
                color: '#FFF',
                marginBottom: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              id="form-dialog-title"
            >
              <div>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  Manage availability
                </Typography>
              </div>
              <div>
                <IconButton onClick={props.close}>
                  <CloseIcon style={{ color: '#fff' }} />
                </IconButton>
              </div>
            </div>

            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                    Date
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <KeyboardDatePicker
                    disablePast
                    id="startDate"
                    name="startDate"
                    label="Start date"
                    format="dddd, MMMM D, YYYY"
                    value={values.startDate}
                    onChange={(date: any, value: any) => {
                      setFieldValue('startDate', moment(date).toDate());
                    }}
                    fullWidth
                    error={errors.startDate && touched.startDate ? true : false}
                    helperText={
                      errors.startDate && touched.startDate && errors.startDate
                    }
                    shouldDisableDate={(date: any) =>
                      date.isoWeekday() === 7 || date.isoWeekday() === 6
                    }
                  />
                  <FormControlLabel
                    style={{ marginTop: '8px' }}
                    control={
                      <Checkbox
                        name="noEndDate"
                        checked={values.noEndDate}
                        onChange={() => {
                          setFieldValue('noEndDate', !values.noEndDate);
                        }}
                        onBlur={handleBlur}
                      />
                    }
                    label="No end date"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <KeyboardDatePicker
                    fullWidth
                    disablePast
                    id="endDate"
                    name="endDate"
                    label="End date"
                    disabled={values.noEndDate}
                    format="dddd, MMMM D, YYYY"
                    value={values.endDate}
                    onChange={(date: any, value: any) => {
                      setFieldValue('endDate', moment(date).toDate());
                    }}
                    error={errors.endDate && touched.endDate ? true : false}
                    helperText={
                      errors.endDate && touched.endDate && errors.endDate
                    }
                    shouldDisableDate={(date: any) =>
                      date.isoWeekday() === 7 || date.isoWeekday() === 6
                    }
                    keyboardIcon={<EventIcon />}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{ marginTop: '16px', fontWeight: 'bold' }}
                  >
                    Frequency
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Select
                    fullWidth
                    displayEmpty
                    value={values.frequency}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="frequency"
                  >
                    <MenuItem value="This day only">This day only</MenuItem>
                    <MenuItem value="Every weekday">Every weekday</MenuItem>
                    <MenuItem value={'Weekly on a day'}>
                      Weekly on {moment(values.startDate).format('dddd')}
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: '24px 16px 16px' }}>
              <Button onClick={props.close} color="primary">
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                style={{ boxShadow: 'none' }}
              >
                Add
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
