import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
  CircularProgress,
  Box,
  Avatar,
  Chip,
  IconButton,
  Collapse,
} from '@material-ui/core';
import moment from 'moment';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';

const omarAvatar = require('../../assets/images/omar-avatar.png');
const vittleImage = require('../../assets/images/vittle-profile-admin.png');

// added address

// modififed address

// deleted address

// added card

// deleted card

// modified card

// changed status

// sent a reset password email

// linked a customer

// modified contact --- Done

// added a secondary profile

// removed a secondary profile

export function ActivityItem(props: any) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const getPrettyActionText = (action: String, data: any, author?: string) => {
    switch (action) {
      case 'send-verification-email':
        return `Verification email sent to ${data.firstName} ${data.lastName}.`;

      case 'create-primary-customer':
        return `created customer ${data.firstName} ${data.lastName}.`;

      case 'add-secondary-contact':
        return `added a secondary profile ${data.firstName} ${data.lastName}.`;

      case 'create-secondary-customer':
        return `added ${data.firstName} ${data.lastName}.`;

      case 'update-customer-notes':
        return `updated note.`;

      case 'created-transfer':
        return `created a transfer.`;

      case 'completed-transfer':
        return `completed the transfer.`;

      case 'sign-up':
        return `${data.firstName} ${data.lastName} signed up.`;

      case 'update-customer-address':
        return `updated address.`;

      case 'create-stripe-customer':
        return `Stripe customer ${data.stripeCustomerId} created.`;

      case 'add-card':
        return `${data.firstName} ${data.lastName} added a ${
          data.cardLast4
            ? `credit card ending with ${data.cardLast4}.`
            : 'credit card.'
        }`;

      case 'remove-card':
        return `${data.firstName} ${data.lastName} removed a ${
          data.cardLast4
            ? `credit card ending with ${data.cardLast4}.`
            : 'credit card.'
        }`;

      case 'change-password':
        return `${author} updated their password.`;

      case 'change-language':
        return `${author} updated their language to ${data.language}.`;

      case 'add-address':
        return `${author} added a new address.`;

      case 'edit-address':
        return `${author} edited an address.`;

      case 'delete-address':
        return `${author} removed an address.`;

      case 'remove-address':
        return `${author} removed an address.`;
    }
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Avatar
            style={{
              width: '42px',
              height: '42px',
              marginRight: '12px',
            }}
            alt={props.author}
            src={
              props.author === 'Vittle'
                ? vittleImage
                : props.author === 'RANDOM_ID'
                ? omarAvatar
                : ''
            }
          >
            {console.log('IN HERE')}
            {console.log(props.author)}
            {props.author[0]}
          </Avatar>
          <Box>
            <Typography variant="subtitle1" style={{ lineHeight: 1.25 }}>
              <strong>
                {props.author === 'RANDOM_ID' ? 'Omar Radwan' : ''}
              </strong>{' '}
              {getPrettyActionText(props.action, props.data, props.author)}
            </Typography>
            <Typography
              style={{ lineHeight: 1.25 }}
              color="textSecondary"
              variant="body2"
            >
              {moment(props.when).format('MMMM D, YYYY [at] HH:mm')}
            </Typography>
          </Box>
        </Box>
        {(props.action === 'send-welcome-email' ||
          props.action === 'send-verification-email') && (
          <Box>
            <Button>Resend</Button>
            <IconButton onClick={() => setCollapseOpen(!collapseOpen)}>
              <ExpandMoreIcon
                style={{
                  justifySelf: 'flex-end',
                }}
              />
            </IconButton>
          </Box>
        )}
      </Box>
      {(props.action === 'send-welcome-email' ||
        props.action === 'send-verification-email') && (
        <Collapse in={collapseOpen} timeout="auto">
          <Box
            paddingLeft="56px"
            display="inline-flex"
            flexDirection="column"
            paddingTop="1em"
          >
            {props.action === 'send-verification-email' && (
              <React.Fragment>
                <Typography variant="body2" color="textSecondary">
                  {props.data.emailOpen === 0
                    ? `${props.data.firstName} ${props.data.lastName} hasn't read the email yet.`
                    : 'Jivanysh Sohoni read the email.'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {' '}
                  {props.data.vittleLogoClick > 0 &&
                    `${props.data.firstName} ${props.data.lastName} clicked on the Vittle Logo.`}
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  {props.data.setPasswordButtonClick > 0 &&
                    `${props.data.firstName} ${props.data.lastName} clicked on the Set Password Button.`}
                </Typography>
              </React.Fragment>
            )}
          </Box>
        </Collapse>
      )}
    </React.Fragment>
  );
}

// function getModifiedItemPrettyName(modifiedItem: String) {}

export default function CustomerActivityList(props: {
  activity: Array<Object>;
}) {
  return (
    <div>
      {props.activity.length &&
        [...props.activity]
          .sort((a: any, b: any) => (a.createdAt > b.createdAt ? -1 : 1))
          .map((a: any, index: Number) => {
            return (
              <ActivityItem
                author={a.actionBy}
                action={a.action}
                when={a.createdAt}
                data={a.data}
              />
            );
          })}
    </div>
  );
}
