import feathers from '../../modules/feathers';

export default {
  find: (query: object | undefined) => {
    if (!query) {
      return feathers.service('media').find();
    }

    return feathers.service('media').find(query);
  },

  remove: (id: string) => {
    return feathers.service('media').remove(id);
  },
};
