import { createSlice } from '@reduxjs/toolkit';

import feathers from '../../modules/feathers';
import {
  getCustomersTable,
  fetchSecondaryCustomers,
} from '../../api/customers';
import { newActivity } from '../../slices/activities/activitiesSlice';

const initialState = {
  customers: [],
  error: null,
  addCustomerLoading: false,
  addCustomerContactDetailsLoading: false,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    addCustomerLoading: (state, action) => {
      state.addCustomerLoading = action.payload;
    },

    addCustomerSuccess: (state, action) => {
      //@ts-ignore
      state.customers.data.unshift(action.payload);
    },

    fetchCustomersSuccess: (state, action) => {
      console.log(action);
      state.customers = action.payload.data
        ? action.payload.data
        : action.payload;
    },

    fetchCustomersFailed: (state, action) => {
      console.log('Failed fetchCustomers');
      console.log(action);
      state.error = action.payload;
    },
  },
});

export const {
  fetchCustomersSuccess,
  fetchCustomersFailed,
  addCustomerLoading,
  addCustomerSuccess,
} = customersSlice.actions;

export default customersSlice.reducer;

export const fetchCustomers = (
  filters: [Object] | undefined,
  searchText: String | undefined
) => async (dispatch: Function) => {
  console.log('FetchCustomers');
  try {
    const customers = await getCustomersTable(filters, searchText);
    console.log(customers);
    dispatch(fetchCustomersSuccess(customers));
  } catch (error) {
    console.log('Catch failedCustomers');
    console.log(error);
    dispatch(fetchCustomersFailed(error));
  }
};

export function createCustomer(customer: Object, history: any) {
  return async function (dispatch: Function) {
    dispatch(addCustomerLoading(true));

    let createdCustomer;

    try {
      createdCustomer = await feathers.service('users').create(customer);
      console.log(createdCustomer);
      //@ts-ignore
      if (!customer.secondary) {
        await dispatch(addCustomerSuccess(createdCustomer));
        dispatch(
          newActivity({
            actionOn: createdCustomer._id,
            actionBy: 'RANDOM_ID',
            action: 'create-primary-customer',
            data: {
              firstName: createdCustomer.firstName,
              lastName: createdCustomer.lastName,
            },
          })
        );
      } else {
        // await dispatch(
        //   updateCustomerSecondaryAccountsId(
        //     createdCustomer.primaryAccountId,
        //     createdCustomer._id
        //   )
        // );

        // await dispatch(requestSecondaryCustomers());

        const secondaryCustomers = await fetchSecondaryCustomers(
          createdCustomer.primaryAccountId
        );

        // await dispatch(receivedSecondaryCustomers(secondaryCustomers));

        dispatch(
          newActivity({
            actionOn: createdCustomer._id,
            actionBy: 'RANDOM_ID',
            action: 'create-secondary-customer',
            data: {
              primaryAccountId: createdCustomer.primaryAccountId,
              firstName: createdCustomer.firstName,
              lastName: createdCustomer.lastName,
            },
          })
        );

        dispatch(
          newActivity({
            actionOn: createdCustomer.primaryAccountId,
            actionBy: 'RANDOM_ID',
            action: 'add-secondary-contact',
            data: {
              firstName: createdCustomer.firstName,
              lastName: createdCustomer.lastName,
            },
          })
        );
      }

      await dispatch(addCustomerLoading(false));
      //@ts-ignore
      if (!customer.secondary) {
        history.push(`/customer/${createdCustomer._id}`);
      }
    } catch (err) {
      console.log('ADD CUSTOMER FAILED');
      console.log(err);
      dispatch(addCustomerLoading(false));
    }
  };
}
