import React from 'react';

import {
  CircularProgress,
  Grid,
  Typography,
  Box,
  Paper,
  Button,
} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';

interface CustomerObject {
  settings: {
    language: string;
  };
}

interface CustomerSettingsProps {
  customer: CustomerObject;
}

export default function CustomerSettings(props: CustomerSettingsProps) {
  const handleEditArea = (area: String) => {};

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Typography variant="h6" style={{ paddingBottom: '1em' }}>
              Notifications
            </Typography>

            <Typography
              variant="h6"
              color="textSecondary"
              style={{ fontWeight: 'normal' }}
            >
              Account support
            </Typography>

            <Typography variant="body2" color="textSecondary">
              Customer will receive messages about their account, security and
              privacy matters, and customer support requests. For security
              reasons, you cannot disable email notifications or phone calls.
            </Typography>

            <Grid
              container
              className="settings-editable-area"
              onClick={() => handleEditArea('notifications')}
            >
              <Grid xs={12} md={3}>
                <Typography variant="body1">Email</Typography>

                <Typography variant="body2" color="textSecondary">
                  Yes
                </Typography>
              </Grid>
              <Grid xs={12} md={3}>
                <Typography variant="body1">Text messages</Typography>

                <Typography variant="body2" color="textSecondary">
                  No
                </Typography>
              </Grid>
              <Grid xs={12} md={3}>
                <Typography variant="body1">Phone calls</Typography>

                <Typography variant="body2" color="textSecondary">
                  Yes
                </Typography>
              </Grid>
              <Grid xs={12} md={3}>
                <Typography variant="body1">Push notifications</Typography>

                <Typography variant="body2" color="textSecondary">
                  No
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Typography variant="h6" style={{ paddingBottom: '1em' }}>
              Language
            </Typography>

            <Grid container className="settings-editable-area">
              <Grid item xs={12}>
                <Typography variant="body1">Language</Typography>

                <Typography variant="body2" color="textSecondary">
                  {props.customer.settings.language === 'FR'
                    ? 'French'
                    : 'English'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Typography variant="h6">Delete account</Typography>

            <Button>
              <BlockIcon style={{ fontSize: '18', marginRight: '5px' }} />{' '}
              Remove
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Account activity</Typography>
            </Grid>
            <Typography>Last login: Yesterday, 13:35</Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
