import { createSlice } from '@reduxjs/toolkit';
import { createActivity, findRecentByCustomer } from '../../api/activities';

const initialState = {
  dashboard: {
    activities: [],
    dashboardActivityLoading: false,
    error: null,
  },
  customer: {
    activities: [],
    customerActivityLoading: false,
    error: null,
  },
};

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    startCustomerActivityLoading: (state, action: any) => {
      //@ts-ignore
      state.customer.customerActivityLoading = true;
    },
    getCustomerActivitySuccess: (state, action: any) => {
      //@ts-ignore
      state.customer.activities = action.payload;
      state.customer.customerActivityLoading = false;
    },

    getCustomerActivityFailed: (state, action: any) => {
      state.customer.activities = [];
      state.customer.customerActivityLoading = false;
      state.customer.error = action.payload;
    },

    createCustomerActivitySuccess: (state, action: any) => {
      console.log(action);
      //@ts-ignore
      state.customer.activities.unshift(action.payload);
    },
    createCustomerActivityFailed: (state, action: any) => {
      console.log(action);
      state.customer.error = action;
    },
  },
});

export const {
  startCustomerActivityLoading,
  getCustomerActivitySuccess,
  getCustomerActivityFailed,
  createCustomerActivitySuccess,
  createCustomerActivityFailed,
} = activitiesSlice.actions;

export function newActivity(activity: Object) {
  return async function (dispatch: Function) {
    try {
      const createdActivity = await createActivity(activity);
      dispatch(createCustomerActivitySuccess(createdActivity));
    } catch (error) {
      console.log(error);
      createCustomerActivityFailed(error);
    }
  };
}

export function fetchCustomerActivity(customerId: String) {
  return async function (dispatch: Function) {
    dispatch(startCustomerActivityLoading({}));
    try {
      const activities: Object = await findRecentByCustomer(customerId);

      console.log(activities);
      //@ts-ignore
      dispatch(getCustomerActivitySuccess(activities.data));
    } catch (error) {
      console.log(error);
      dispatch(getCustomerActivityFailed(error));
    }
  };
}

// export function fetchRecentActivity() {
//   return function(dispatch: Function) {
//     return feathers
//       .service('activities')
//       .find()
//       .then((res: any) => {
//         console.log(res);
//         dispatch(receiveDashboardActivities(res.data));
//       })
//       .catch((err: any) => {
//         console.log(err);
//       });
//   };
// }

export default activitiesSlice.reducer;
