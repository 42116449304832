import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ReplayIcon from '@material-ui/icons/Replay';
import HomeIcon from '@material-ui/icons/Home';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Autocomplete from '@material-ui/lab/Autocomplete';

import IconButton from '@material-ui/core/IconButton';

import InputAdornment from '@material-ui/core/InputAdornment';
import ImageIcon from '@material-ui/icons/Image';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import EditIcon from '@material-ui/icons/Edit';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import LaunchIcon from '@material-ui/icons/Launch';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import PrintIcon from '@material-ui/icons/Print';
import EmailIcon from '@material-ui/icons/Email';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';

import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddIcon from '@material-ui/icons/Add';
import TodayIcon from '@material-ui/icons/Today';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Tooltip from '@material-ui/core/Tooltip';

import { CircularProgress, LinearProgress } from '@material-ui/core';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Radio from '@material-ui/core/Radio';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { useStaticState, Calendar } from '@material-ui/pickers';
import currency from 'currency.js';
import moment from 'moment';
import { VariantType, useSnackbar } from 'notistack';

import feathers from '../../modules/feathers';

import { RootState } from '../../rootReducer';

import calculateOrderLineItems from '../../modules/calculateOrderLineItems';

import {
  emaiLReceipt,
  fetchOrder,
  updateOrder,
} from '../../slices/orders/ordersSlice';

import RefundDialog from './RefundDialog';
import CancelDialog from './CancelDialog';

import CardBrandImage from '../../components/CardBrandImage/CardBrandImage';

import './EditOrder.scss';
import orders from '../../api/orders';

export default function EditOrder(props: any) {
  // const classes = useStyles();
  console.log('Edit Order Propsssss------', props);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const orderLoading = useSelector(
    (state: RootStateOrAny) => state.orders.orderLoading
  );

  const order: any = useSelector((state: RootStateOrAny) => {
    console.log('status', state);
    return state.orders.order;
  });

  const [value, handleDateChange] = React.useState(new Date());
  const [submittingEmailReceipt, setSubmittingEmailReceipt] =
    React.useState(false);

  const { pickerProps, wrapperProps } = useStaticState({
    value,
    //@ts-ignore
    onChange: handleDateChange,
  });

  const options = ['Mark as pending', 'Send invoice'];
  const options2 = ['Mark as paid', 'Paid with Cash', 'Paid by e-Transfer'];

  const [refundOrderDialogOpen, setRefundOrderDialogOpen] =
    React.useState(false);

  const [cancelOrderDialogOpen, setCancelOrderDialogOpen] =
    React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [openPaid, setOpenPaid] = React.useState(false);

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const anchorRefPaid = React.useRef<HTMLDivElement>(null);

  const [cardLast4, setCardLast4] = React.useState('');
  const [cardBrand, setCardBrand] = React.useState('');
  const [fraudDetails, setFraudDetails] = React.useState('');

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [selectedIndexPaid, setSelectedIndexPaid] = React.useState(1);
  const [confirmOrderSubmitting, setConfirmOrderSubmitting] =
    React.useState(false);
  const [orderCancelSubmitting, setOrderCancelSubmitting] =
    React.useState(false);

  const getPaymentIntent = async (paymentIntentId: string) => {
    const paymentIntent = await feathers
      .service('/stripe/payment-intent')
      .get(paymentIntentId);

    setCardLast4(
      //@ts-ignore
      paymentIntent?.charges.data[0].payment_method_details.card.last4
    );

    setCardBrand(
      //@ts-ignore
      paymentIntent?.charges.data[0].payment_method_details.card
    );

    console.log(paymentIntent);
    setFraudDetails(paymentIntent?.charges.data[0].fraudDetails);

    return paymentIntent;
  };

  useEffect(() => {
    if (!orderLoading && order) {
      if (order.paymentType === 'card') {
        // const paymentIntent = getPaymentIntent(order.paymentIntentId);
      }
    }
  }, [orderLoading, order]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    paid: boolean = false
  ) => {
    if (paid) {
      setSelectedIndexPaid(index);
      setOpenPaid(false);
      return;
    }

    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = (paid: boolean = false) => {
    if (paid) {
      setOpenPaid((prevOpen) => !prevOpen);
    } else {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const renderAddressIcon = (addressType: String) => {
    if (addressType === 'business' || addressType === 'work') {
      return (
        <WorkIcon
          style={{
            paddingLeft: '10px',
            paddingRight: '20px',
            color: '#999',
          }}
        />
      );
    }

    if (addressType === 'home') {
      return (
        <HomeIcon
          style={{
            paddingLeft: '10px',
            paddingRight: '20px',
            color: '#999',
          }}
        />
      );
    }

    if (addressType === 'apartment') {
      return (
        <BusinessIcon
          style={{
            paddingLeft: '10px',
            paddingRight: '20px',
            color: '#999',
          }}
        />
      );
    }
  };

  const handleClose = (
    paid: boolean = false,
    event: React.MouseEvent<Document, MouseEvent>
  ) => {
    if (paid) {
      if (
        anchorRefPaid.current &&
        anchorRefPaid.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpenPaid(false);
    } else {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
      setOpen(false);
    }
  };

  useEffect(() => {
    dispatch(fetchOrder(props.match.params.id));
  }, []);

  const acceptOrder = async () => {
    let paymentIntent;
    setConfirmOrderSubmitting(true);
    console.log('Payment Type', order);

    if (order.paymentType === 'card') {
      if (order.paymentIntentId) {
        try {
          console.log('Accept?', order.paymentIntentId);

          paymentIntent = await feathers
            .service('/stripe/payment-intent')
            .patch(order.paymentIntentId, {
              capture: true,
            });

          console.log('paymentIntent', paymentIntent);

          if (paymentIntent) {
            if (paymentIntent.status === 'succeeded') {
              dispatch(
                updateOrder(
                  order._id,
                  {
                    status: 'processing',
                    paymentStatus: 'paid',
                  },
                  setConfirmOrderSubmitting
                )
              );
            }
          }

          // const paymentIntent = await orders.confirmPayment({
          //   methodId: order.paymentIntentId,
          // });

          // console.log('result', paymentIntent);

          //pi_3NPLxTCaGbbvc96t1YQCq6mv
          // paymentIntent = await feathers
          //   .service('/stripe/payment-intent')
          //   .update(order.paymentIntentId, {
          //     total: order.total,
          //   });

          // console.log('pay---accept', paymentIntent);

          // if (paymentIntent) {
          //   if (paymentIntent.data.data.status === 'succeeded') {
          //     console.log('sussess');
          //     // dispatch(
          //     //   updateOrder(
          //     //     order._id,
          //     //     {
          //     //       status: 'processing',
          //     //       paymentStatus: 'paid',
          //     //     },
          //     //     setConfirmOrderSubmitting
          //     //   )
          //     // );
          //   }
          // }

          // console.log(paymentIntent);
        } catch (err) {
          console.log(err);
        }
      }
    } else if (order.paymentType === 'interac') {
    } else if (order.paymentType === 'cash') {
    }

    setConfirmOrderSubmitting(false);
  };

  const refundOrder = async () => {
    let paymentIntent;
    setOrderCancelSubmitting(true);

    if (order.paymentType === 'card') {
      if (order.paymentIntentId) {
        try {
          paymentIntent = await feathers.service('/stripe/refund').create({
            payment_intent: order.paymentIntentId,
          });

          console.log(paymentIntent);

          if (paymentIntent) {
            if (paymentIntent.status === 'succeeded') {
              dispatch(
                updateOrder(
                  order._id,
                  {
                    paymentStatus: 'refunded',
                  },
                  setOrderCancelSubmitting
                )
              );
            }
          }

          console.log(paymentIntent);
        } catch (err) {
          console.log(err);
        }
      }
    } else if (order.paymentType === 'interac') {
    } else if (order.paymentType === 'cash') {
    }
  };

  const cancelOrder = async (reason?: string, comments?: string) => {
    let paymentIntent;
    setOrderCancelSubmitting(true);

    if (order.paymentType === 'card') {
      if (order.paymentIntentId) {
        try {
          paymentIntent = await feathers
            .service('/stripe/payment-intent')
            .patch(order.paymentIntentId, {
              cancel: true,
            });

          console.log(paymentIntent);

          if (paymentIntent) {
            if (paymentIntent.status === 'canceled') {
              dispatch(
                updateOrder(
                  order._id,
                  {
                    status: 'cancelled',
                    paymentStatus: 'cancelled',
                  },
                  setOrderCancelSubmitting
                )
              );
            }
          }

          console.log(paymentIntent);
        } catch (err) {
          console.log(err);
        }
      }
    } else if (order.paymentType === 'interac') {
    } else if (order.paymentType === 'cash') {
    }
  };

  const sendReceipt = () => {
    setSubmittingEmailReceipt(true);

    dispatch(
      emaiLReceipt(order._id, () => {
        setSubmittingEmailReceipt(false);
        enqueueSnackbar('Receipt sent');
      })
    );
  };

  if (orderLoading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
            }}
          >
            <Link style={{ textDecoration: 'none' }} to="/">
              <Button style={{ textTransform: 'capitalize' }}>
                <KeyboardArrowLeftIcon /> <Typography>Dashboard</Typography>
              </Button>
            </Link>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: '20px 0 0' }}
      >
        <Box
          fontWeight="fontWeightMedium"
          fontSize="h4.fontSize"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <Box display="flex" flexDirection="column">
                Order no. {order._id}
                <Typography style={{ marginTop: '3px' }} color="textSecondary">
                  Order date:{' '}
                  {moment(order.createdAt).format('MMMM D, YYYY h[:]mm A')}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box>
            {order.status !== 'cancelled' && (
              <>
                <Chip
                  size="medium"
                  className={`status-chip status-chip--${
                    order.paymentStatus === 'paid'
                      ? 'paid'
                      : order.paymentStatus === 'refunded'
                      ? 'refunded'
                      : order.paymentStatus === 'canceled'
                      ? 'cancelled'
                      : 'pending'
                  }`}
                  label={
                    order.paymentStatus === 'paid'
                      ? 'Paid'
                      : order.paymentStatus === 'authorized'
                      ? 'Authorized'
                      : order.paymentStatus === 'refunded'
                      ? 'Refunded'
                      : ''
                  }
                  style={{ marginRight: '8px' }}
                />

                <Chip
                  size="medium"
                  className={`status-chip status-chip--${
                    order.fulfillmentStatus === 'unfulfilled'
                      ? 'unfulfilled'
                      : 'fulfilled'
                  }`}
                  label={
                    order.fulfillmentStatus === 'unfulfilled'
                      ? 'Unfulfilled'
                      : 'Fulfilled'
                  }
                  style={{ marginRight: '8px' }}
                />
              </>
            )}

            {order.paymentStatus !== 'refunded' && (
              <Chip
                size="medium"
                className={`status-chip status-chip--${
                  order.status === 'placed'
                    ? 'placed'
                    : order.status === 'processing'
                    ? 'processing'
                    : order.status === 'ready-for-delivery'
                    ? 'ready-for-delivery'
                    : order.status === 'delivered'
                    ? 'delivered'
                    : order.status === 'picked-up'
                    ? 'picked-up'
                    : order.status === 'cancelled'
                    ? 'cancelled'
                    : ''
                }`}
                label={
                  order.status === 'placed'
                    ? 'Order placed'
                    : order.status === 'processing'
                    ? 'Processing'
                    : order.status === 'ready-for-delivery'
                    ? 'Ready for delivery'
                    : order.status === 'delivered'
                    ? 'Delivered'
                    : order.status === 'picked-up'
                    ? 'Picked up'
                    : order.status === 'cancelled'
                    ? 'Cancelled'
                    : ''
                }
              />
            )}
          </Box>
        </Box>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: '0 0 40px' }}
      >
        <Box
          fontWeight="fontWeightMedium"
          fontSize="h4.fontSize"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <Box display="flex" flexDirection="column">
                <Box mt={1}>
                  <Button size="small" style={{ marginRight: '8px' }}>
                    <PrintIcon
                      style={{ color: '#9b9b9b', marginRight: '8px' }}
                    />{' '}
                    Print label
                  </Button>

                  <Button
                    size="small"
                    style={{ marginRight: '8px' }}
                    onClick={sendReceipt}
                    disabled={submittingEmailReceipt}
                  >
                    <EmailIcon
                      style={{ color: '#9b9b9b', marginRight: '8px' }}
                    />{' '}
                    Send receipt
                  </Button>
                  {order.paymentStatus === 'paid' &&
                    order.paymentType === 'card' && (
                      <Button
                        size="small"
                        style={{ marginRight: '8px' }}
                        onClick={() => setRefundOrderDialogOpen(true)}
                      >
                        <ReplayIcon
                          style={{
                            marginRight: '8px',
                            fontSize: '1.5em',
                            color: '#9b9b9b',
                          }}
                        />
                        Refund
                      </Button>
                    )}

                  {order.status === 'placed' &&
                    order.paymentStatus === 'authorized' &&
                    order.paymentType === 'card' && (
                      <Button
                        disabled={orderCancelSubmitting}
                        size="small"
                        onClick={() => setCancelOrderDialogOpen(true)}
                      >
                        {/* {orderCancelSubmitting && (
                          <CircularProgress
                            style={{
                              position: 'absolute',
                              left: '50%',
                              marginLeft: '-8px',
                            }}
                            size={16}
                          />
                        )} */}
                        <CloseIcon
                          style={{
                            marginRight: '8px',
                            fontSize: '1.5em',
                            color: '#9b9b9b',
                          }}
                        />
                        Cancel
                      </Button>
                    )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: '16px 24px 24px', marginBottom: '2em' }}>
            {order.lineItems.filter((e: any) => e.type === 'Meals').length >
              0 && (
              <Grid style={{ marginBottom: '1em', marginTop: '1em' }} container>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h6">Products</Typography>
                    <Typography color="textSecondary" variant="body2">
                      {
                        order.lineItems.filter((e: any) => e.type === 'Grocery')
                          .length
                      }{' '}
                      {`item${
                        order.lineItems.filter((e: any) => e.type === 'Grocery')
                          .length > 1
                          ? 's'
                          : ''
                      }`}
                    </Typography>
                  </Box>

                  <Button>Edit</Button>
                </Box>
              </Grid>
            )}

            {order.lineItems
              .filter((e: any) => e.type === 'Grocery')
              .map((e: any, i: any) => {
                return (
                  <>
                    <Grid
                      style={{ marginBottom: '1em' }}
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="space-between"
                      key={i}
                    >
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="start">
                          <img
                            src={
                              e.selectedVariant.thumbnailURL
                                ? `https://s3.amazonaws.com/vittle-new/${e.selectedVariant.thumbnailURL}`
                                : `https://placehold.it/75x75/ccc`
                            }
                            style={{ marginRight: '16px', width: '75px' }}
                          />
                          <Box display="flex" flexDirection="column">
                            <Typography>{e.title}</Typography>
                            <Typography color="textSecondary" variant="body2">
                              {e.selectedVariant.name
                                ? e.selectedVariant.name
                                : ''}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box
                          alignItems="flex-start"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Box>
                            <Typography
                              align="right"
                              variant="body1"
                              style={{ paddingRight: '8px' }}
                            >
                              {currency(e.total).format()}
                            </Typography>
                            <Typography
                              align="right"
                              color="textSecondary"
                              variant="body2"
                              style={{ paddingRight: '8px' }}
                            >
                              Qty: {e.quantity}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    {i !== order.lineItems.length - 1 && (
                      <Divider style={{ marginBottom: '24px' }} />
                    )}
                  </>
                );
              })}

            {order.lineItems.filter((e: any) => e.type === 'Meals').length >
              0 &&
              order.lineItems.filter((e: any) => e.type === 'Grocery').length >
                0 && (
                <div
                  style={{
                    borderTop: '1px solid rgba(0,0,0,0.12)',
                    margin: '32px -24px 24px',
                  }}
                ></div>
              )}

            {order.lineItems.filter((e: any) => e.type === 'Meals').length >
              0 && (
              <Grid style={{ marginBottom: '1em', marginTop: '1em' }} container>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box display="flex" flexDirection="column">
                    <Typography variant="h6">
                      {order.type === 'Grocery' ? 'Meals' : 'Meal plan'}
                    </Typography>
                    <Typography color="textSecondary" variant="body2">
                      {
                        order.lineItems.filter((e: any) => e.type === 'Meals')
                          .length
                      }{' '}
                      {`item${
                        order.lineItems.filter((e: any) => e.type === 'Meals')
                          .length > 1
                          ? 's'
                          : ''
                      }`}
                    </Typography>
                  </Box>

                  <Button>Edit</Button>
                </Box>
              </Grid>
            )}

            {order.lineItems
              .filter((e: any) => e.type === 'Meals')
              .map((e: any, i: any) => {
                return (
                  <>
                    <Grid
                      style={{ marginBottom: '1em' }}
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="space-between"
                      key={i}
                    >
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="start">
                          <img
                            src={
                              e.selectedVariant.thumbnailURL
                                ? `https://s3.amazonaws.com/vittle-new/${e.selectedVariant.thumbnailURL}`
                                : `https://placehold.it/75x75/ccc`
                            }
                            style={{ marginRight: '16px', width: '75px' }}
                          />
                          <Box display="flex" flexDirection="column">
                            <Typography>{e.title}</Typography>
                            <Typography color="textSecondary" variant="body2">
                              {e.selectedVariant.name
                                ? e.selectedVariant.name
                                : ''}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Box
                          alignItems="flex-start"
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <Box>
                            <Typography
                              align="right"
                              variant="body1"
                              style={{ paddingRight: '8px' }}
                            >
                              {currency(e.total).format()}
                            </Typography>
                            <Typography
                              align="right"
                              color="textSecondary"
                              variant="body2"
                              style={{ paddingRight: '8px' }}
                            >
                              Qty: {e.quantity}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>

                    {i !== order.lineItems.length - 1 && (
                      <Divider style={{ marginBottom: '24px' }} />
                    )}
                  </>
                );
              })}
          </Paper>

          <Paper style={{ padding: '24px 24px 0', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '2em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Payment</Typography>

              <Button>Edit</Button>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Subtotal</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px' }}
                    >
                      {currency(
                        calculateOrderLineItems({
                          lineItems: order.lineItems,
                          deliveryType: order.delivery.type,
                        }).subTotal
                      ).format()}
                    </Typography>
                  </Grid>
                </Grid>

                {order.delivery.type !== 'pickup' && (
                  <Grid container style={{ marginBottom: '8px' }}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body1">Delivery fee</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography
                        align="right"
                        variant="body1"
                        style={{ paddingRight: '8px' }}
                      >
                        {order.total > 100
                          ? 'Free'
                          : currency(
                              calculateOrderLineItems({
                                lineItems: order.lineItems,
                                deliveryType: order.delivery.type,
                              }).deliveryFee
                            ).format()}
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {/* <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">FREEDELIVERY2020</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Free delivery
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px', color: '#4caf50' }}
                    >
                      -{currency(10).format()}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">BLACKFRIDAY10</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Get $10.00 off
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px', color: '#4caf50' }}
                    >
                      -{currency(10).format()}
                    </Typography>
                  </Grid>
                </Grid> 

                <Divider style={{ marginBottom: '8px' }} />

                {/* <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Discount total</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px', color: '#4caf50' }}
                    >
                      -{currency(20).format()}
                    </Typography>
                  </Grid>
                </Grid> */}

                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Tax</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px' }}
                    >
                      {currency(
                        calculateOrderLineItems({
                          lineItems: order.lineItems,
                          deliveryType: order.delivery.type,
                        }).tax
                      ).format()}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider style={{ marginBottom: '8px' }} />

                {/* <Grid
                  container
                  alignItems="flex-start"
                  style={{ marginBottom: '8px' }}
                >
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Gift card</Typography>
                    <Typography variant="body2" color="textSecondary">
                      •••45AS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="right"
                      style={{ paddingRight: '8px', color: '#4caf50' }}
                      variant="body1"
                    >
                      -{currency(20).format()}
                    </Typography>
                  </Grid>
                </Grid> */}

                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Order total
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ fontWeight: 'bold', paddingRight: '8px' }}
                    >
                      {currency(order.total).format()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          <Paper style={{ padding: '24px' }}>
            <Typography variant="h6">Notes</Typography>

            <TextField
              // min="2"
              variant="outlined"
              minRows="1"
              maxRows="20"
              margin="normal"
              fullWidth
              multiline
              // defaultValue={this.props.customer.notes || ''}
              // onChange={(e: any) => {
              //   this.setState({
              //     note: e.target.value,
              //   });
              // }}
            />

            {/* {this.props.customer.notes !== this.state.note && ( */}
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                // onClick={() => this.handleNoteSave()}
                // disabled={Boolean(this.state.noteSaving)}
                style={{ justifySelf: 'flex-end' }}
              >
                {/* {this.state.noteSaving && (
                        <CircularProgress
                          style={{
                            position: 'absolute',
                            left: '50%',
                            marginLeft: '-8px',
                          }}
                          size={16}
                        />
                      )} */}
                Save
              </Button>
            </Box>
            {/* // )} */}

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px',
              }}
            ></div>

            <Typography variant="h6" style={{ marginBottom: '1em' }}>
              Activity
            </Typography>

            <div style={{ marginTop: '1em', marginBottom: '1.5em' }}>
              <Button
                size="small"
                style={{
                  textTransform: 'capitalize',
                  marginRight: '5px',
                  minWidth: '48px',
                }}
              >
                <Typography variant="body1" style={{ top: '3px' }}>
                  All
                </Typography>
              </Button>
              <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Account</Typography>
              </Button>
              <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Billing</Typography>
              </Button>
              <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Delivery</Typography>
              </Button>
            </div>

            {/* {this.props.customerActivityLoading &&
                  this.props.activity === [] && <Grid container></Grid>} */}

            {/* {!this.props.customerActivityLoading && this.props.activity && (
                  <CustomerActivityList activity={this.props.activity} />
                )} */}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Status</Typography>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Box className="order-status-progress-container">
                  <LinearProgress
                    className={`order-status-progress active-2-of-4 ${
                      order.status === 'cancelled'
                        ? 'order-status--cancelled'
                        : ''
                    }`}
                    style={{
                      height: '8px',
                    }}
                    variant="determinate"
                    value={
                      order.status === 'placed'
                        ? 6.25
                        : order.status === 'processing'
                        ? 37.5
                        : order.status === 'ready-for-delivery'
                        ? 75
                        : 100
                    }
                  />

                  <Box
                    position="relative"
                    width="100%"
                    className="order-status-progress-labels"
                  >
                    {order.status !== 'cancelled' && (
                      <>
                        <Typography
                          className={order.status === 'placed' ? 'active' : ''}
                        >
                          Order
                          <br />
                          placed
                        </Typography>
                        <Typography
                          className={
                            order.status === 'processing' ? 'active' : ''
                          }
                        >
                          Processing
                        </Typography>
                        <Typography
                          className={
                            order.status === 'ready-for-delivery'
                              ? 'active'
                              : ''
                          }
                        >
                          Ready
                          <br />
                          for delivery
                        </Typography>
                        <Typography
                          className={
                            order.status === 'delivered' ||
                            order.status === 'picked-up'
                              ? 'active'
                              : ''
                          }
                        >
                          {order.delivery.type === 'delivery'
                            ? 'Delivered'
                            : 'Picked up'}
                        </Typography>
                      </>
                    )}

                    {order.status === 'cancelled' && (
                      <Box display="flex" flexDirection="column">
                        <Typography color="error" className="error">
                          Cancelled
                        </Typography>

                        <Typography style={{ marginTop: '3px' }}>
                          Order was cancelled by Omar Radwan
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box display="flex" justifyContent="flex-end" mt={3}>
                  {order.status === 'placed' ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ boxShadow: 'none' }}
                      onClick={acceptOrder}
                      disabled={confirmOrderSubmitting}
                    >
                      {confirmOrderSubmitting && (
                        <CircularProgress
                          color="primary"
                          style={{
                            position: 'absolute',
                            width: '24px',
                            height: '24px',
                          }}
                        />
                      )}
                      <DoneIcon style={{ marginRight: '8px' }} />
                      Accept
                    </Button>
                  ) : order.status === 'processing' ? (
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ boxShadow: 'none' }}
                      onClick={acceptOrder}
                    >
                      <DoneIcon style={{ marginRight: '8px' }} />
                      Ready for delivery
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
              </Grid>
            </Grid>

            {/* <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Deliveries</Typography>
            </Grid>

            <Grid container>
              <Box width="100%" display="flex" alignItems="center">
                {order.delivery.type === 'delivery' ? (
                  <LocalShippingIcon />
                ) : (
                  <DirectionsWalkIcon />
                )}

                <Box width="100%" ml={1}>
                  <Typography
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {order.delivery.type === 'delivery' ? 'Delivery' : 'Pickup'}
                  </Typography>
                </Box>
              </Box>

              <Box width="100%" display="flex" alignItems="flex-start" mt={3}>
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <TodayIcon style={{ marginRight: '6px' }} />
                    <Typography
                      style={{
                        marginTop: '0',
                      }}
                    >
                      {moment(order.delivery.date).format('MMMM D, YYYY')}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box width="100%" display="flex" alignItems="flex-start" mt={3}>
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <AccessTimeIcon style={{ marginRight: '6px' }} />
                    <Typography
                      style={{
                        marginTop: '0',
                      }}
                    >
                      {order.delivery.type === 'delivery'
                        ? '2:30-6:30 PM'
                        : '2:30-4:00 PM'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid container>
              <Box width="100%" display="flex" alignItems="center">
                {order.delivery.type === 'delivery' ? (
                  <LocalShippingIcon />
                ) : (
                  <DirectionsWalkIcon />
                )}

                <Box width="100%" ml={1}>
                  <Typography
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {order.delivery.type === 'delivery' ? 'Delivery' : 'Pickup'}
                  </Typography>
                </Box>
              </Box>

              <Box width="100%" display="flex" alignItems="flex-start" mt={3}>
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <TodayIcon style={{ marginRight: '6px' }} />
                    <Typography
                      style={{
                        marginTop: '0',
                      }}
                    >
                      {moment(order.delivery.date)
                        .add(2, 'd')
                        .format('MMMM D, YYYY')}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box width="100%" display="flex" alignItems="flex-start" mt={3}>
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <AccessTimeIcon style={{ marginRight: '6px' }} />
                    <Typography
                      style={{
                        marginTop: '0',
                      }}
                    >
                      {order.delivery.type === 'delivery'
                        ? '2:30-6:30 PM'
                        : '2:30-4:00 PM'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid> */}
          </Paper>

          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Customer</Typography>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Avatar
                        style={{
                          alignSelf: 'flex-start',
                          width: '42px',
                          height: '42px',
                          marginRight: '10px',
                        }}
                        alt={`${order.customer.firstName} ${order.customer.lastName}`}
                      >
                        <Typography variant="body2">
                          {order.customer.firstName.charAt(0)}
                          {order.customer.lastName.charAt(0)}
                        </Typography>
                      </Avatar>
                      <div>
                        <Typography variant="body1">
                          <strong>
                            {order.customer.firstName} {order.customer.lastName}
                          </strong>
                        </Typography>
                        <Typography variant="body2">
                          <a href={`mailto:${order.customer.emailAddress}`}>
                            {order.customer.emailAddress}
                          </a>
                        </Typography>
                        <Typography variant="body2">
                          <a href={`tel:${order.customer.phone}`}>
                            {order.customer.phone}
                          </a>
                        </Typography>
                      </div>
                    </Box>

                    <IconButton
                    // onClick={() => {
                    //   this.setState({ customerContactEditDialogOpen: true });
                    // }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {order.delivery.type !== 'pickup' && (
              <>
                <div
                  style={{
                    borderTop: '1px solid rgba(0,0,0,0.12)',
                    margin: '32px -24px 24px',
                  }}
                ></div>

                <Grid
                  style={{ marginBottom: '1em' }}
                  container
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <div>
                    <Typography variant="h6">Address</Typography>
                  </div>
                </Grid>

                <Box
                  flexDirection="row"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box flexDirection="row" display="flex" alignItems="center">
                    <div>{renderAddressIcon(order.address.addressType)}</div>
                    <div>
                      <Typography style={{ textOverflow: 'ellipsis' }}>
                        {order.address.organization ? (
                          <React.Fragment>
                            {order.address.organization}
                            <br />
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                        {`${order.address.Line1}`} <br />
                        {`${order.address.City}, ${order.address.Province} ${order.address.PostalCode}`}
                      </Typography>
                      {order.address.buzzer && (
                        <Typography color="textSecondary">
                          Buzzer: {order.address.buzzer}
                        </Typography>
                      )}
                    </div>
                  </Box>
                  <div>
                    <IconButton
                    // onClick={() =>
                    //   props.openEditAddressDialog(
                    //     props.addressType,
                    //     props.address
                    //   )
                    // }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </div>
                </Box>
              </>
            )}

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6">Fulfillment</Typography>
              </div>

              <Button>Edit</Button>
            </Grid>

            <Grid container>
              <Box width="100%" display="flex" alignItems="center">
                {order.delivery.type === 'delivery' ? (
                  <LocalShippingIcon />
                ) : (
                  <DirectionsWalkIcon />
                )}

                <Box width="100%" ml={1}>
                  <Typography
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    {order.delivery.type === 'delivery' ? 'Delivery' : 'Pickup'}
                  </Typography>
                </Box>
              </Box>

              <Box width="100%" display="flex" alignItems="flex-start" mt={3}>
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <TodayIcon style={{ marginRight: '6px' }} />
                    <Typography
                      style={{
                        marginTop: '0',
                      }}
                    >
                      {moment(order.delivery.date).format('MMMM D, YYYY')}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box width="100%" display="flex" alignItems="flex-start" mt={3}>
                <Box width="100%" display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <AccessTimeIcon style={{ marginRight: '6px' }} />
                    <Typography
                      style={{
                        marginTop: '0',
                      }}
                    >
                      {order.delivery.type === 'delivery'
                        ? '2:30-6:30 PM'
                        : '2:30-4:00 PM'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Billing</Typography>
            </Grid>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box width="100%" display="flex" justifyContent="space-between">
                <Box width="100%" display="flex" flexDirection="column">
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <CardBrandImage brandName={cardBrand} />
                      <Typography variant="body2">•••• {cardLast4}</Typography>
                    </Box>
                    <Box>
                      <a
                        href={`https://dashboard.stripe.com/payments/${order.paymentIntentId}`}
                      >
                        <IconButton>
                          <LaunchIcon fontSize="small" />
                        </IconButton>
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box width="100%" display="flex" justifyContent="space-between">
                <Box width="100%" display="flex" flexDirection="column">
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      Fraud details
                    </Box>
                    <Box>
                      {fraudDetails}
                      <Typography></Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* 
            <Calendar
              disablePast
              shouldDisableDate={(day: any) => moment(day).day() !== 2}
              {...pickerProps}
              // onMonthChange={(date: any) => {
              //   console.log(date);
              //   return true;
              // }}
              maxDate={moment().endOf('month').add(1, 'month')}
            /> */}
          </Paper>
        </Grid>
      </Grid>

      <RefundDialog
        open={refundOrderDialogOpen}
        close={() => setRefundOrderDialogOpen(false)}
        refundOrder={refundOrder}
        name={order.customer.firstName}
      />

      <CancelDialog
        open={cancelOrderDialogOpen}
        close={() => setCancelOrderDialogOpen(false)}
        orderCancelSubmitting={orderCancelSubmitting}
        cancelOrder={cancelOrder}
        name={order.customer.firstName}
      />
    </Container>
  );
}
