import React, { Ref, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Breadcrumbs,
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Chip,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
//@ts-ignore
import PDFDocument from 'pdfkit/js/pdfkit.standalone.js';
import blobStream from 'blob-stream';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Skeleton from '@material-ui/lab/Skeleton';

// import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import { BirthdayIcon, CustomIcon, AlertIcon } from '../../modules/label-icons';

import { lighten, makeStyles, useTheme, alpha } from '@material-ui/core/styles';

import { getPlatingData } from '../../slices/orders/ordersSlice';
import { RootState } from '../../rootReducer';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    width: '100%',
  },
  tabs: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: '1px',
  },
  filterChips: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root2: {
    width: '100%',
  },
  media: {
    height: 200,
  },
}));

interface LabelDataProps {
  customerName: string;
  dishName: string;
  variant: string;
  instruction?: string;
  quantity: number;
  calories?: string | undefined;
  protein?: string | undefined;
  carbs?: string | undefined;
  fats?: string | undefined;
  quantityStart: number;
  quantityTotal: number;
  birthday?: boolean;
}

export default function (props: any) {
  const classes = useToolbarStyles();
  const dispatch = useDispatch();

  const media: any = useSelector((state: RootStateOrAny) => state.media.media);

  const [mediaToDelete, setMediaToDelete] = useState(null);

  const [selectedDate, setSelectedDate] = useState(
    moment().format('YYYY-MM-DD')
  );

  const onChangeSearchText = (event: any) => {
    const searchText = event.target.value;
  };

  const platingData: any = useSelector(
    (state: any) => state.orders.platingData
  );

  const platingDataLoading: boolean = useSelector(
    (state: any) => state.orders.platingDataLoading
  );

  const handlePrintLabel = () => {
    const doc = new PDFDocument({ autoFirstPage: false });
    const stream = doc.pipe(blobStream());

    platingData.map((e: any) => {
      console.log(e);

      for (let i = 1; i <= e.quantity; i++) {
        printLabel(doc, {
          customerName: e.name,
          dishName: e.title[0],
          instruction: '',
          quantity: e.quantity,
          quantityStart: i,
          quantityTotal: e.quantity,
          birthday: false,
          variant: e.variant[0],
        });
      }
    });

    stream.on('finish', () => {
      const blob = stream.toBlob('application/pdf');

      const link = document.createElement('a');
      // create a blobURI pointing to our Blob
      link.href = URL.createObjectURL(blob);
      link.download = `Label_${selectedDate}.pdf`;

      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      window.addEventListener('focus', (e) => URL.revokeObjectURL(link.href), {
        once: true,
      });
    });

    doc.end();
  };

  const printLabel = (doc: any, data: LabelDataProps) => {
    doc.addPage({
      size: [288, 216],
      margin: 0,
    });

    doc.font('Helvetica');
    doc.fontSize(7.5);
    const day = moment(selectedDate).subtract(1, 'd').format('MMMM D');
    doc.text(`Made for you on ${day}`, 10.8, 204);

    doc.fontSize(18);
    doc.font('Helvetica-Bold');

    if (doc.widthOfString(`Made for ${data.customerName}`) <= 265.5) {
      doc.text(`Made for ${data.customerName}`, 10.8, 58, { width: 265.5 });
    } else if (doc.widthOfString(`${data.customerName}`) <= 265.5) {
      doc.text(data.customerName, 10.8, 58, { width: 265.5 });
    } else {
      doc.text(
        `${data.customerName.split(' ')[0]} ${data.customerName
          .split(' ')
          [data.customerName.split(' ').length - 1 || 1].charAt(0)}.`,
        10.8,
        58,
        { width: 265.5 }
      );
    }

    doc.font('Helvetica-Bold');
    doc.fontSize(13);
    doc.text(
      data.dishName + `: ${data.variant === '-' ? 'Regular' : data.variant}`,
      10.8,
      82,
      { width: 265.5 }
    );

    if (data.birthday) {
      doc.image(BirthdayIcon, 62, 34, {
        width: 50.2488,
        height: 15.74,
      });
    }

    doc.fontSize(7.5);
    doc.font('Helvetica-Bold');

    doc
      .roundedRect(
        11.25,
        33.7464,
        doc.widthOfString(
          `Qty: ${data.quantityStart} of ${data.quantityTotal}`
        ) + 7.2,
        15.75,
        1.8
      )
      .stroke();

    doc.text(`Qty: ${data.quantityStart} of ${data.quantityTotal}`, 15, 39);

    //Instructions

    doc.fontSize(0);
    doc.text(' ', 0, 0, { continued: false, strike: false });

    // instructions
    if (data.instruction !== '') {
      doc.font('Helvetica-Bold');
      doc.fontSize(7.5);
      doc.text(data.instruction, 10.8, 196);
    }

    // nutritional info
    doc.font('Helvetica');
    doc.fontSize(7.5);

    //calories
    if (data.calories) {
      doc.font('Helvetica');
      doc.text('Calories', 165.6, 204);

      // if (calories !== '0' && parseFloat(calories) > 0) {
      doc.font('Helvetica-Bold');
      doc.text(data.calories, 165.6, 196);
      // }
    }

    //protein
    if (data.protein) {
      doc.font('Helvetica');
      doc.text('Protein', 203.7, 204);

      // if (protein !== '0' && parseFloat(protein) > 0) {
      doc.font('Helvetica-Bold');
      doc.text(`${data.protein}g`, 204, 196);
      // }
    }

    //carbs
    if (data.carbs) {
      doc.font('Helvetica');
      doc.text('Carbs', 235, 204);

      // if (carbs !== '0' && parseFloat(carbs) > 0) {
      doc.font('Helvetica-Bold');
      doc.text(`${data.carbs}g`, 235, 196);
      // }
    }

    if (data.fats) {
      //fats
      doc.font('Helvetica');
      doc.text('Fats', 262.8, 204);

      // if (fats !== '0' && parseFloat(fats) > 0) {
      doc.font('Helvetica-Bold');
      doc.text(`${data.fats}g`, 262.8, 196);
      // }
    }
    //ingredients
    // dish ingredients
    // if (ingredients.length > 0) {
    //   doc.fontSize(9);
    //   doc.font('Helvetica');

    //   if (
    //     allergies.length > 0 ||
    //     religiousRestrictions.length > 0 ||
    //     dislikes.length > 0
    //   ) {
    //     ingredients.forEach((ingredient: string, index: number) => {
    //       let hasScratch = false;

    //       // COME BACK HERE TO SEE IF CUSTOMER HAS RESTRICTIONS ADDED TO THEIR ACCOUNT
    //       // if (
    //       //   userData.hasOwnProperty('restrictions') &&
    //       //   userData.restrictions != null
    //       // ) {
    //       //   // get the restriction
    //       //   userData.restrictions.forEach((rest) => {
    //       //     if (rest.types.findIndex((t) => t ingredient.typeId) !== -1) {
    //       //       hasScratch = true;
    //       //     } else if (rest.ingredients !== undefined) {
    //       //       if (
    //       //         rest.ingredients.findIndex(
    //       //           (ing) => ing === ingredient._id
    //       //         ) !== -1
    //       //       ) {
    //       //         hasScratch = true;
    //       //       }
    //       //     }
    //       //   });
    //       // }

    //       if (allergies.length > 0) {
    //         if (
    //           allergies.findIndex((srest: string) => srest === ingredient) !==
    //           -1
    //         ) {
    //           hasScratch = true;
    //         }
    //       }

    //       if (dislikes.length > 0) {
    //         if (
    //           dislikes.findIndex((pref: string) => pref === ingredient) !== -1
    //         ) {
    //           hasScratch = true;
    //         }
    //       }

    //       // console.log(hasScratch);
    //       if (index === 0) {
    //         doc.text(ingredient, 10.8, 108, {
    //           width: 265.5,
    //           continued: true,
    //           strike: hasScratch,
    //         });
    //         doc.text(', ', { continued: true, strike: false });
    //       } else if (index === ingredients.length - 1) {
    //         doc.text(ingredient, { continued: true, strike: hasScratch });
    //       } else {
    //         doc.text(ingredient, { continued: true, strike: hasScratch });
    //         doc.text(', ', { continued: true, strike: false });
    //       }
    //     });
    //   } else {
    //     doc.text(`${ingredients.join(', ')}`, 10.8, 108, { width: 265.5 });
    //   }
    // }

    doc.text(' ', 0, 0, { continued: false });

    //restrictions
    // if (
    //   allergies.length > 0 ||
    //   dietaryRestrictions.length > 0 ||
    //   dislikes.length > 0 ||
    //   preferences.length > 0
    // ) {
    //   doc.roundedRect(10.8, 145, 265, 45, 1.8).stroke();

    //   doc.image(AlertIcon, 22.32, 154, { width: 15.84, height: 13.68 });

    //   doc.fontSize(7);

    //   // console.log('Restriction by type');

    //   let hasRestrictionAllergy = false;
    //   let hasRestrictionDietary = false;
    //   let hasRestrictionReligious = false;
    //   let hasDislikes = false;

    //   // console.log(restrictionsByType);
    //   // if (restrictionsByType) {
    //   // if (allergies.length > 0) {
    //   //   hasRestrictionAllergy = true;
    //   //   doc.font('Helvetica-Bold');
    //   //   doc.text('Allergies: ', 50, 153, { width: 218.99, continued: true });

    //   //   doc.font('Helvetica');

    //   //   allergies.forEach((allergy: string, index: number) => {
    //   //     doc.text(allergy, { continued: true });

    //   //     if (index !== allergies.length - 1) {
    //   //       doc.text(', ', { continued: true });
    //   //     } else {
    //   //       doc.text('; ', { continued: true });
    //   //     }
    //   //   });
    //   // }
    //   // }

    //   // COME BACK HERE FOR SPECIFIC RESTRICTIONS OR ALLERGY GROUPS
    //   // if (
    //   //   userData.hasOwnProperty('specificRestrictions') &&
    //   //   userData.specificRestrictions != null &&
    //   //   userData.specificRestrictions.length > 0
    //   // ) {
    //   //   doc.font('Helvetica-Bold');
    //   //   if (!hasRestrictionAllergy) {
    //   //     doc.text('Allergies: ', 50, 153, { width: 218.99, continued: true });
    //   //   }

    //   //   doc.font('Helvetica');
    //   //   userData.specificRestrictions.forEach((rest, index) => {
    //   //     doc.text(rest.title, { continued: true });
    //   //     if (index !== userData.specificRestrictions.length - 1) {
    //   //       doc.text(', ', { continued: true });
    //   //     } else {
    //   //       doc.text('; ', { continued: true });
    //   //     }
    //   //   });

    //   //   hasRestrictionAllergy = true;
    //   // }

    //   // if (restrictionsByType) {
    //   // if (dietaryRestrictions.length > 0) {
    //   //   hasRestrictionDietary = true;

    //   //   doc.font('Helvetica-Bold');

    //   //   if (hasRestrictionAllergy) {
    //   //     doc.text('Dietary: ', { continued: true });
    //   //   } else {
    //   //     doc.text('Dietary: ', 50, 153, { width: 218.99, continued: true });
    //   //   }

    //   //   doc.font('Helvetica');
    //   //   dietaryRestrictions.forEach((dietary: string, index: number) => {
    //   //     doc.text(dietary, { continued: true });

    //   //     if (index !== dietaryRestrictions.length - 1) {
    //   //       doc.text(', ', { continued: true });
    //   //     } else {
    //   //       doc.text('; ', { continued: true });
    //   //     }
    //   //   });
    //   // }

    //   // if (religiousRestrictions.length > 0) {
    //   //   hasRestrictionReligious = true;
    //   //   doc.font('Helvetica-Bold');

    //   //   if (hasRestrictionDietary || hasRestrictionAllergy) {
    //   //     doc.text('Religious: ', { continued: true });
    //   //   } else {
    //   //     doc.text('Dietary: ', 50, 153, { width: 218.99, continued: true });
    //   //   }

    //   //   doc.font('Helvetica');

    //   //   religiousRestrictions.map((religious: string, index: number) => {
    //   //     doc.text(religious, { continued: true });

    //   //     if (index !== religiousRestrictions.length - 1) {
    //   //       doc.text(', ', { continued: true });
    //   //     } else {
    //   //       doc.text('; ', { continued: true });
    //   //     }
    //   //   });
    //   // }
    //   // }

    //   // if (dislikes.length > 0) {
    //   //   hasDislikes = true;
    //   //   doc.font('Helvetica-Bold');

    //   //   if (
    //   //     hasRestrictionDietary ||
    //   //     hasRestrictionAllergy ||
    //   //     hasRestrictionReligious
    //   //   ) {
    //   //     doc.text('Dislikes: ', { continued: true });
    //   //   } else {
    //   //     doc.text('Dislikes: ', 50, 153, { width: 218.99, continued: true });
    //   //   }

    //   //   doc.font('Helvetica');

    //   //   dislikes.map((dislike: string, index: number) => {
    //   //     doc.text(dislike, { continued: true });
    //   //     if (index !== dislikes.length - 1) {
    //   //       doc.text(', ', { continued: true });
    //   //     } else {
    //   //       doc.text('; ', { continued: true });
    //   //     }
    //   //   });
    //   // }

    //   // if (restrictionsByType) {
    //   //Above line was already commented
    //   //  if (preferences.length > 0) {
    //   //    doc.font('Helvetica-Bold');

    //   //    if (
    //   //      hasRestrictionDietary ||
    //   //      hasRestrictionAllergy ||
    //   //      hasRestrictionReligious ||
    //   //      hasDislikes
    //   //    ) {
    //   //      doc.text('Preferences: ', { continued: true });
    //   //    } else {
    //   //      doc.text('Preferences: ', 50, 153, {
    //   //        width: 218.99,
    //   //        continued: true,
    //   //      });
    //   //    }

    //   //    doc.font('Helvetica');

    //   //  preferences.map((preference: string, index: number) => {
    //   //    doc.text(preference, { continued: true });
    //   //    if (index !== preferences.length - 1) {
    //   //      doc.text(', ', { continued: true });
    //   //    } else {
    //   //      doc.text('; ', { continued: true });
    //   //    }
    //   //  });
    //   //  }
    //   // }
    // }

    doc.text(' ', 0, 0, { continued: false });
  };

  useEffect(() => {
    console.log(moment(selectedDate).format('YYYY-MM-DD'));
    dispatch(getPlatingData(moment(selectedDate).format('YYYY-MM-DD')));
  }, []);

  useEffect(() => {
    console.log(moment(selectedDate).format('YYYY-MM-DD'));
    dispatch(getPlatingData(moment(selectedDate).format('YYYY-MM-DD')));
  }, [selectedDate]);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <Link
              to="/"
              style={{
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <Button style={{ textTransform: 'capitalize' }}>
                <Typography>Dashboard</Typography>
              </Button>
            </Link>
            <Typography variant="body1">Plating</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: '30px' }}
      >
        <Grid item>
          <Box fontWeight="fontWeightMedium" fontSize="h4.fontSize" m={1}>
            Plating for {moment(selectedDate).format('dddd, MMMM D')}
          </Box>
          <Box></Box>
        </Grid>

        <Grid item>
          <Button
            style={{
              boxShadow: 'none',
            }}
            variant="text"
            disabled={!platingDataLoading && platingData.length === 0}
            onClick={handlePrintLabel}
          >
            Print labels
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ marginBottom: '40px' }}
      >
        <Grid item>
          <Button
            style={{
              boxShadow: 'none',
            }}
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={() =>
              setSelectedDate(
                moment(selectedDate).subtract(1, 'd').format('YYYY-MM-DD')
              )
            }
          >
            Yesterday
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              boxShadow: 'none',
            }}
            variant="text"
            endIcon={<ArrowForwardIcon />}
            onClick={() =>
              setSelectedDate(
                moment(selectedDate).add(1, 'd').format('YYYY-MM-DD')
              )
            }
          >
            Tomorrow
          </Button>
        </Grid>
      </Grid>

      {/* <Paper>
        <Box padding={2}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              onChange={onChangeSearchText}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{
                width: '100%',
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </Box>
      </Paper> */}

      <Grid container spacing={3}>
        {platingDataLoading &&
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((e: any) => (
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Skeleton animation="wave" height="400px" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" width="60%" />
            </Grid>
          ))}

        {!platingDataLoading && platingData.length === 0 && (
          <Grid item xs={12}>
            <Typography variant="h6">No meals to prepare for today</Typography>
          </Grid>
        )}

        {!platingDataLoading &&
          platingData.map((e: any) => (
            <Grid item xs={6} sm={6} md={4} lg={4}>
              <Card className={classes.root2}>
                <CardMedia
                  className={classes.media}
                  image={`https://via.placeholder.com/600x400?text=`}
                  title="Caesar Salad"
                />
                <CardContent style={{ padding: '16px 8px' }}>
                  <Box width="100%" display="flex" flexDirection="column">
                    <Box display="flex" alignItems="center" overflow="hidden">
                      <Typography
                        variant="h6"
                        component="h2"
                        style={{
                          fontWeight: 500,
                        }}
                      >
                        {e.title[0]}
                        <span
                          style={{
                            width: '30px',
                            height: '30px',
                            background: '#ddd',
                            color: '#000',
                            borderRadius: '50%',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '16px',
                            marginLeft: '8px',
                          }}
                        >
                          {e.quantity}
                        </span>
                      </Typography>
                    </Box>

                    {/* <Box marginTop={3} justifyContent="space-evenly">
                      <Chip
                        label="No restrictions: 10"
                        style={{ marginRight: '8px', marginBottom: '8px' }}
                      />

                      <Chip
                        label="Matching allergens: 5"
                        style={{ marginRight: '8px', marginBottom: '8px' }}
                      />
                    </Box> */}

                    {/* <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState: any) => (
                    <React.Fragment>
                      <IconButton
                        style={{ padding: '8px', marginRight: '-8px' }}
                        {...bindTrigger(popupState)}
                      >
                        <MoreVertIcon />
                      </IconButton>

                      <Menu {...bindMenu(popupState)}>
                        <MenuItem onClick={popupState.close}>
                          <CropIcon
                            fontSize="small"
                            style={{ marginRight: '4px' }}
                          />{' '}
                          Crop
                        </MenuItem>
                        <MenuItem onClick={popupState.close}>
                          <EditIcon
                            fontSize="small"
                            style={{ marginRight: '4px' }}
                          />
                          Rename
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            popupState.close();
                          }}
                        >
                          <DeleteIcon
                            fontSize="small"
                            style={{ marginRight: '4px' }}
                          />
                          Delete
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState> */}
                  </Box>
                </CardContent>

                <CardActions>
                  <Button size="small" color="primary">
                    Breakdown
                  </Button>
                  <Button size="small" color="primary">
                    See Customers
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
}
