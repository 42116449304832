import feathers from '../../modules/feathers';
import { Product as ProductInterface } from '../../slices/products/productsSlice';

export function createProduct(Product: ProductInterface) {
  return feathers.service('products').create(Product);
}

export function patchProduct(id: String, Product: ProductInterface | any) {
  return feathers.service('products').patch(id, Product);
}

export function removeProduct(id: String) {
  console.log('remove---------');
  return feathers.service('products').remove(id);
}

export function setProductAvailability(id: String, available: boolean) {
  return feathers.service('products').patch(id, {
    available: available,
  });
}

export function getProduct(id: string, query: object | undefined = undefined) {
  if (query) {
    return feathers.service('products').get(id, query);
  }

  return feathers.service('products').get(id);
}

export function getProducts(query: object | undefined = undefined) {
  if (query) {
    return feathers.service('products').find(query);
  }
  return feathers.service('products').find();
}

export function getExtras() {
  return feathers.service('products').find({
    query: {
      type: 'Extras',
    },
  });
}

export function searchProducts(searchTerm: string) {
  return feathers.service('products').find({
    query: {
      searchTerm,
    },
  });
}
