import React from 'react';
import { useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';
import { Formik, Field, FieldArray, Form } from 'formik';
import { VariantType, useSnackbar } from 'notistack';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import CircularProgress from '@material-ui/core/CircularProgress';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import './Dropzone.scss';
import { uploadMedia, removeMedia } from '../../slices/media/mediaSlice';

function AddCategoryDialog(props: any) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const mediaToDelete: any = props.media;

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">
        Delete {mediaToDelete.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to permanently delete {mediaToDelete.name}? It
          will also be unassigned from any products.
        </DialogContentText>
      </DialogContent>

      <DialogContent>
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);

            try {
              dispatch(
                removeMedia(mediaToDelete._id, () => {
                  props.handleClose();
                  enqueueSnackbar(mediaToDelete.name + ' deleted');
                })
              );
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div
                style={{
                  padding: '0 20px 16px',
                  margin: '0 -24px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  background: '#fff',
                }}
              >
                <Button
                  style={{ marginRight: '10px' }}
                  onClick={() => props.handleClose()}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  style={{ boxShadow: 'none' }}
                  disabled={isSubmitting}
                >
                  {isSubmitting && (
                    <CircularProgress
                      style={{
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-8px',
                      }}
                      size={16}
                    />
                  )}
                  Delete
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(AddCategoryDialog);
