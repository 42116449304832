import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import config from 'config';
// import Helmet from 'react-helmet';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Avatar from '@material-ui/core/Avatar';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';

import clsx from 'clsx';
import { lighten, makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import PostAddIcon from '@material-ui/icons/PostAdd';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import CircularProgress from '@material-ui/core/CircularProgress';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { connect } from 'react-redux';
import { fetchCategories } from '../../slices/categories/categoriesSlice';

import AddCategoryDialog from './AddCategoryDialog';
import EditCategoryDialog from './EditCategoryDialog';
import AddSubCategoryDialog from './AddSubCategoryDialog';
import EditSubCategoryDialog from './EditSubCategoryDialog';

// import StatusIcon from '../../components/StatusIcon/StatusIcon';

import './CategoriesTable.scss';

class CategoriesTable extends React.Component {
  constructor(props) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    openAddCategoryDialog: false,
    openAddSubCategoryDialog: false,
    openEditCategoryDialog: false,
    openEditSubCategoryDialog: false,
    selectedCategoryName: '',
    selectedCategoryId: '',
    selectedCategoryType: '',
    selectedSubCategoryName: '',
    selectedSubCategoryIndex: 0,
    selectedCatSubCats: [],
    selectedCategorySalesChannels: [],
    filter: {
      firstName: 1,
      lastName: 1,
      status: '',
      totalSpent: 0,
    },
    activeFilters: [],
    items: [],
    value: 0,
  };

  componentDidMount() {
    this.props.fetchCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevProps);
    // console.log('Going in update');
    console.log(this.props);
    if (this.state.items.length == 0 && !this.props.categoriesLoading) {
      // console.log(this.props.organization.categories);

      let cats = this.props.categories.data.slice();

      // console.log('in here');
      this.setState({
        items: cats.map((e) => ({
          ...e,
          collapseOpen: true,
        })),
      });
    }
  }

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  onDragEnd(result) {
    // dropped outside the list
    console.log(result);
    console.log('innner drag');
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === 'droppableItem') {
      console.log('going in here 1');
      const items = this.reorder(this.state.items, sourceIndex, destIndex);

      this.setState({
        items,
      });
    } else if (result.type === 'droppableSubItem') {
      const itemSubItemMap = this.state.items.reduce((acc, item) => {
        acc[item._id] = item.subCats;
        return acc;
      }, {});

      console.log(itemSubItemMap);

      const sourceParentId = parseInt(result.source.droppableId);
      const destParentId = parseInt(result.destination.droppableId);

      const sourceSubItems = itemSubItemMap[sourceParentId];
      const destSubItems = itemSubItemMap[destParentId];

      let newItems = [...this.state.items];

      /** In this case subItems are reOrdered inside same Parent */
      if (sourceParentId === destParentId) {
        const reorderedSubItems = this.reorder(
          sourceSubItems,
          sourceIndex,
          destIndex
        );

        newItems = newItems.map((item) => {
          if (item._id === sourceParentId) {
            item.subCats = reorderedSubItems;
          }
          return item;
        });
        this.setState({
          items: newItems,
        });
      } else {
        console.log(sourceSubItems);
        let newSourceSubItems = [...sourceSubItems];
        const [draggedItem] = newSourceSubItems.splice(sourceIndex, 1);

        let newDestSubItems = [...destSubItems];
        newDestSubItems.splice(destIndex, 0, draggedItem);
        newItems = newItems.map((item) => {
          if (item._id === sourceParentId) {
            item.subCats = newSourceSubItems;
          } else if (item._id === destParentId) {
            item.subCats = newDestSubItems;
          }
          return item;
        });
        this.setState({
          items: newItems,
        });
      }
    }
  }

  getItemStyle(isDragging, draggableStyle) {
    return {
      background: isDragging ? '#2196f3' : 'white',
      // styles we need to apply on draggables
      ...draggableStyle,
    };
  }

  getListStyle(isDraggingOver) {
    return {
      background: isDraggingOver ? '#e0e0e0' : '#e0e0e0',
    };
  }

  handleChange(event, newValue) {
    console.log(newValue);
    this.setState({ value: newValue });
  }

  openCategoryCollapse(index) {
    let newItems = [...this.state.items];

    newItems[index].collapseOpen = !newItems[index].collapseOpen;
    console.log(newItems[index]);
    console.log(newItems[index].collapseOpen);
    console.log(!newItems[index].collapseOpen);

    this.setState({
      items: newItems,
    });
  }

  render() {
    if (this.props.organizationLoading) {
      return <CircularProgress />;
    }

    return (
      <div>
        <AddCategoryDialog
          open={this.state.openAddCategoryDialog}
          handleClose={() =>
            this.setState({
              openAddCategoryDialog: false,
            })
          }
          selectedType={
            this.state.value === 0
              ? 'Meals'
              : this.state.value === 1
              ? 'Grocery'
              : 'Ingredients'
          }
        />
        <AddSubCategoryDialog
          selectedCategoryName={this.state.selectedCategoryName}
          selectedCategoryId={this.state.selectedCategoryId}
          selectedType={
            this.state.value === 0
              ? 'Meals'
              : this.state.value === 1
              ? 'Grocery'
              : 'Ingredients'
          }
          open={this.state.openAddSubCategoryDialog}
          handleClose={() =>
            this.setState({
              openAddSubCategoryDialog: false,
            })
          }
        />

        <EditCategoryDialog
          open={this.state.openEditCategoryDialog}
          selectedCategoryId={this.state.selectedCategoryId}
          selectedCategorySalesChannels={
            this.state.selectedCategorySalesChannels
          }
          selectedCategoryName={this.state.selectedCategoryName}
          selectedCategoryNameFR={this.state.selectedCategoryNameFR}
          selectedCategoryType={this.state.selectedCategoryType}
          selectedType={
            this.state.value === 0
              ? 'Meals'
              : this.state.value === 1
              ? 'Grocery'
              : 'Ingredients'
          }
          handleClose={() =>
            this.setState({
              openEditCategoryDialog: false,
            })
          }
        />

        <EditSubCategoryDialog
          selectedSubCategory={this.state.selectedSubCategory}
          selectedCategoryName={this.state.selectedCategoryName}
          selectedCategoryId={this.state.selectedCategoryId}
          selectedSubCategoryName={this.state.selectedSubCategoryName}
          selectedSubCategoryNameFR={this.state.selectedSubCategoryNameFR}
          selectedSubCategoryIndex={this.state.selectedSubCategoryIndex}
          selectedCatSubCats={this.state.selectedCatSubCats}
          open={this.state.openEditSubCategoryDialog}
          handleClose={() =>
            this.setState({
              openEditSubCategoryDialog: false,
            })
          }
        />

        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                style={{
                  marginTop: '1em',
                  marginBottom: '1em',
                }}
              >
                <Link
                  to="/"
                  style={{
                    color: 'inherit',
                    textDecoration: 'none',
                    '&:hover': { textDecoration: 'none' },
                  }}
                >
                  <Button style={{ textTransform: 'capitalize' }}>
                    <Typography>Dashboard</Typography>
                  </Button>
                </Link>
                <Typography variant="body1">Categories</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Box fontWeight="fontWeightMedium" fontSize="h4.fontSize" m={1}>
                Categories
              </Box>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.setState({ openAddCategoryDialog: true })}
                style={{
                  boxShadow: 'none',
                  '&:hover': { boxShadow: 'none' },
                }}
                className="add-class-button"
              >
                <CreateNewFolderIcon
                  style={{ fontSize: '1.3em', marginRight: '8px' }}
                />
                Add category
              </Button>
            </Grid>
          </Grid>

          {this.props.categoriesLoading && <CircularProgress />}

          {!this.props.categoriesLoading && (
            <Grid container>
              <Paper
                elevation={1}
                style={{ width: '100%', marginTop: '2.1em' }}
              >
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChange.bind(this)}
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label="Meals" />
                  <Tab label="Groceries" />
                  <Tab label="Ingredients" />
                </Tabs>

                <Grid className="categories-table-head" container>
                  <Grid
                    item
                    className="categories-table-head__cell"
                    xs={1}
                  ></Grid>
                  <Grid item className="categories-table-head__cell" xs={4}>
                    Category
                  </Grid>
                  <Grid item className="categories-table-head__cell" xs={4}>
                    Subcategory
                  </Grid>
                  <Grid
                    item
                    className="categories-table-head__cell"
                    xs={3}
                  ></Grid>
                </Grid>

                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="droppable" type="droppableItem">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={this.getListStyle(snapshot.isDraggingOver)}
                      >
                        {this.state.items
                          .filter((e) =>
                            this.state.value === 0
                              ? e.type === 'Meals'
                              : this.state.value === 1
                              ? e.type === 'Grocery'
                              : e.type === 'Ingredients'
                          )
                          .map((item, index) => {
                            return (
                              <Draggable
                                key={item._id}
                                draggableId={item._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className={
                                      snapshot.isDragging &&
                                      'item-being-dragged'
                                    }
                                    style={this.getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <Grid
                                      container
                                      alignItems="center"
                                      className="categories-table-row categories-table-row--parent"
                                    >
                                      <Grid
                                        item
                                        className="categories-table-row__cell"
                                        xs={1}
                                      >
                                        <IconButton
                                          {...provided.dragHandleProps}
                                        >
                                          <DragHandleIcon size="large" />
                                        </IconButton>
                                      </Grid>
                                      <Grid
                                        item
                                        className="categories-table-row__cell"
                                        xs={4}
                                      >
                                        {item.name}
                                      </Grid>
                                      <Grid
                                        item
                                        className="categories-table-row__cell"
                                        xs={4}
                                      ></Grid>

                                      <Grid
                                        item
                                        className="categories-table-row__cell"
                                        xs={3}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'flex-end',
                                        }}
                                        justifyContent="flex-end"
                                      >
                                        <IconButton
                                          onClick={() => {
                                            this.setState({
                                              openEditCategoryDialog: true,
                                              selectedCategoryName: item.name,
                                              selectedCategoryNameFR:
                                                item.nameFR || '',

                                              selectedCategoryId: item._id,
                                              selectedCategorySalesChannels:
                                                item.salesChannels,
                                              selectedCategoryType: item.type,
                                            });
                                          }}
                                        >
                                          <EditIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            this.setState({
                                              openAddSubCategoryDialog: true,
                                              selectedCategoryName: item.name,
                                              selectedCategoryId: item._id,
                                            })
                                          }
                                        >
                                          <PostAddIcon />
                                        </IconButton>
                                        <IconButton
                                          disabled={item.subCats.length === 0}
                                          onClick={() =>
                                            this.openCategoryCollapse(index)
                                          }
                                        >
                                          {item.collapseOpen ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )}
                                        </IconButton>
                                      </Grid>
                                    </Grid>

                                    {item.subCats && (
                                      <Collapse in={item.collapseOpen}>
                                        <Droppable
                                          droppableId={item._id}
                                          type="droppableSubItem"
                                        >
                                          {(provided2, snapshot2) => (
                                            <div
                                              {...provided2.droppableProps}
                                              ref={provided2.innerRef}
                                              style={this.getListStyle(
                                                snapshot2.isDraggingOver
                                              )}
                                            >
                                              {item.subCats.map(
                                                (subItem, index2) => (
                                                  <Draggable
                                                    key={subItem._id}
                                                    draggableId={subItem._id}
                                                    index={index2}
                                                  >
                                                    {(provided2, snapshot2) => (
                                                      <div
                                                        ref={provided2.innerRef}
                                                        {...provided2.draggableProps}
                                                        style={this.getItemStyle(
                                                          snapshot2.isDragging,
                                                          provided2
                                                            .draggableProps
                                                            .style
                                                        )}
                                                      >
                                                        <Grid
                                                          container
                                                          alignItems="center"
                                                          className="categories-table-row categories-table-row--parent"
                                                        >
                                                          <Grid
                                                            item
                                                            className="categories-table-row__cell"
                                                            xs={1}
                                                          >
                                                            <IconButton
                                                              {...provided2.dragHandleProps}
                                                            >
                                                              <DragHandleIcon size="large" />
                                                            </IconButton>
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            className="categories-table-row__cell"
                                                            xs={3}
                                                          ></Grid>
                                                          <Grid
                                                            item
                                                            className="categories-table-row__cell"
                                                            xs={3}
                                                          >
                                                            {subItem.name}
                                                          </Grid>
                                                          <Grid
                                                            item
                                                            className="categories-table-row__cell"
                                                            xs={3}
                                                          ></Grid>
                                                          <Grid
                                                            item
                                                            className="categories-table-row__cell"
                                                            xs={2}
                                                            style={{
                                                              display: 'flex',
                                                              justifyContent:
                                                                'flex-end',
                                                            }}
                                                            justifyContent="flex-end"
                                                          >
                                                            <IconButton
                                                              onClick={() => {
                                                                this.setState({
                                                                  selectedSubCategory:
                                                                    subItem,
                                                                  openEditSubCategoryDialog: true,
                                                                  selectedCategoryId:
                                                                    item._id,
                                                                  selectedSubCategoryName:
                                                                    subItem.name,
                                                                  selectedSubCategoryNameFR:
                                                                    subItem.nameFR ||
                                                                    '',
                                                                  selectedSubCategoryIndex:
                                                                    index2,
                                                                  selectedCatSubCats:
                                                                    item.subCats,
                                                                });
                                                              }}
                                                            >
                                                              <EditIcon fontSize="small" />
                                                            </IconButton>
                                                          </Grid>
                                                        </Grid>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                )
                                              )}
                                              {provided2.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </Collapse>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Paper>
            </Grid>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categoriesLoading: state.categories.categoriesLoading,
  categories: state.categories.categories,
});

const mapDispatchToProps = { fetchCategories };

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesTable);
