import React, { Ref, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Breadcrumbs,
  Grid,
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Fab,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import ImageIcon from '@material-ui/icons/Image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CropIcon from '@material-ui/icons/Crop';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { lighten, makeStyles, useTheme, alpha } from '@material-ui/core/styles';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  DayView,
  MonthView,
  WeekView,
  Toolbar,
  ViewSwitcher,
  Appointments,
  AppointmentForm,
  DateNavigator,
  TodayButton,
  AllDayPanel,
} from '@devexpress/dx-react-scheduler-material-ui';

import feathers from '../../modules/feathers';
import AddProductDialog from './AddProductDialog';
import { findMedia } from '../../slices/media/mediaSlice';
import { RootState } from '../../rootReducer';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    width: '100%',
  },
  tabs: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: '1px',
  },
  filterChips: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  root2: {
    width: '100%',
  },
  media: {
    height: 200,
  },
}));

export default function (props: any) {
  const classes = useToolbarStyles();
  const dispatch = useDispatch();

  const media: any = useSelector((state: RootStateOrAny) => state.media.media);

  const [addProductDialogOpen, setAddProductDialogOpen] = useState(false);

  return (
    <div>
      <Container>
        {/* <Grid container>
          <Grid item xs={12}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              style={{
                marginTop: '1em',
                marginBottom: '1em',
              }}
            >
              <Link
                to="/"
                style={{
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                <Button style={{ textTransform: 'capitalize' }}>
                  <Typography>Dashboard</Typography>
                </Button>
              </Link>
              <Typography variant="body1">Media</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginBottom: '40px' }}
        >
          <Grid item>
            <Box fontWeight="fontWeightMedium" fontSize="h4.fontSize" m={1}>
              Meal Planner
            </Box>
            <Box></Box>
          </Grid>
        </Grid> */}
      </Container>
      {/* <Paper>
        <Box padding={2}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              onChange={onChangeSearchText}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              style={{
                width: '100%',
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </Box>
      </Paper> */}

      {/* <Grid container maxW spacing={3} style={{ marginTop: '30px' }}> */}
      <Scheduler
        data={[
          {
            startDate: '2018-07-25T00:00',
            endDate: '2018-07-25T23:59',
            title: 'Grocery (70), Breakfast (6), Lunch and Dinner (8)',
            allDay: true,
          },
        ]}
      >
        <ViewState
          defaultCurrentDate="2018-07-25"
          defaultCurrentViewName="Week"
        />
        <Toolbar />

        <DateNavigator />
        <TodayButton />
        <ViewSwitcher />

        <DayView />
        <WeekView />
        <MonthView />

        <AllDayPanel />

        <Appointments />
        <AppointmentForm />
      </Scheduler>

      <Box mt={4}></Box>

      <Fab
        color="secondary"
        aria-label="add"
        style={{ position: 'fixed', bottom: '16px', right: '16px' }}
        onClick={() => setAddProductDialogOpen(true)}
      >
        <AddIcon />
      </Fab>

      <AddProductDialog
        open={addProductDialogOpen}
        close={() => setAddProductDialogOpen(false)}
      />
      {/* </Grid> */}
    </div>
  );
}
