import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import { Provider, RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Wrapper } from '@googlemaps/react-wrapper';
import store from './store/store';
import feathers from './modules/feathers';

import SignIn from './pages/Login/Login';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import MainMenu from './components/MainMenu/MainMenu';
import NavBar from './components/NavBar/NavBar';
import Customers from './pages/Customers/Customers';
import Dashboard from './pages/Dashboard/Dashboard';
import NewIngredient from './pages/Ingredients/NewIngredient';
import EditIngredient from './pages/Ingredients/EditIngredient';

import Products from './pages/Products/Products';
import NewProduct from './pages/Products/NewProduct';
import ProductsTable from './pages/Products/ProductsTable';
import EditProduct from './pages/Products/EditProduct';

import DiscountsTable from './pages/Discounts/DiscountsTable';
import Discounts from './pages/Discounts/Discounts';

import Orders from './pages/Orders/Orders';
import NewOrder from './pages/Orders/NewOrder';
import EditOrder from './pages/Orders/EditOrder';

import Customer from './pages/Customer/Customer';
import CategoriesTable from './pages/Categories/CategoriesTable';
import IngredientsTable from './pages/Ingredients/IngredientsTable';
import InventoryTable from './pages/Inventory/InventoryTable';

import TransfersTable from './pages/Transfers/TransfersTable';
import AddTransfer from './pages/Transfers/AddTransfer';
import EditTransfer from './pages/Transfers/EditTransfer';
import Media from './pages/Media/Media';
import MealPlanner from './pages/MealPlanner/MealPlanner';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import Plating from './pages/Plating/Plating';
import NewPlan from './pages/NewPlan/NewPlan';
import EditPlan from './pages/EditPlan/EditPlan';

import Taxes from './pages/Taxes/Taxes';

import Labels from './pages/Labels/Labels';
import NewLabel from './pages/NewLabel/NewLabel';
import Fulfillment from './pages/Fulfillment/Fulfillment';
import PostalCodesTable from './pages/PostalCodes/PostalCodesTable';
import NewPostalCode from './pages/PostalCodes/NewPostalCode';
import EditPostalCode from './pages/PostalCodes/EditPostalCode';
import DeliverySurcharges from './pages/DeliverySurcharges/DeliverySurcharges';

import NewStaff from './pages/Staff/NewStaff';
import EditStaff from './pages/Staff/EditStaff';
import StaffTable from './pages/Staff/StaffTable';
import {
  loginFailed,
  loginSuccess,
  setCheckingAuth,
} from './slices/auth/authReducer';
import { RootState } from './rootReducer';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#33474C' },
    secondary: { main: '#FFE173' },
    error: {
      500: '#f44336',
    },
  },
});

function App(props: any) {
  const dispatch = useDispatch();
  const history = useHistory();

  const checkingAuth: boolean = useSelector(
    (state: RootStateOrAny) => state.auth.checkingAuth
  );

  const isAuthenticated: boolean = useSelector(
    (state: RootStateOrAny) => state.auth.isAuthenticated
  );

  const [menuOpen, changeMenuOpen] = React.useState(false);

  const toggleDrawer = (open: any) => (event: any) => {
    console.log('asd');

    if (event) {
      if (
        event.type === 'keydown' &&
        (event.key === 'Tab' || event.key === 'Shift')
      ) {
        return;
      }
    }
    changeMenuOpen(open);
  };

  useEffect(() => {
    // dispatch(setCheckingAuth(true));

    feathers
      .reAuthenticate()
      .then((res) => {
        if (!res.user.hasOwnProperty('roles')) {
          dispatch(loginFailed());
        }

        if (res.user.roles.indexOf('admin') === -1) {
          dispatch(loginFailed());
        }

        dispatch(loginSuccess(res));
        // history.push('/');
      })
      .catch((err) => {
        console.log(err);
        dispatch(loginFailed());
        history.push('/sign-in');
      })
      .finally(() => {
        // dispatch(setCh);
      });
  }, []);

  return (
    // <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Wrapper apiKey={'AIzaSyBVJzk-BCm6eiiOso1yuJ73Fn75JUtDRwE'}>
        <SnackbarProvider maxSnack={4}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            {isAuthenticated && (
              <MainMenu
                open={menuOpen}
                changeMenuOpen={changeMenuOpen}
                toggleDrawer={(o: any) => toggleDrawer(o)}
              />
            )}

            {isAuthenticated && (
              <NavBar toggleDrawer={(o: any) => toggleDrawer(o)} />
            )}

            <Switch>
              <div style={{ margin: '16px 0', position: 'relative' }}>
                <Route exact path="/sign-in" component={SignIn} />

                <ProtectedRoute exact path="/" component={Dashboard} />

                <ProtectedRoute
                  exact
                  path="/categories"
                  component={CategoriesTable}
                />
                <ProtectedRoute exact path="/customers" component={Customers} />
                <ProtectedRoute
                  exact
                  path="/customer/:id"
                  component={Customer}
                />

                <ProtectedRoute
                  exact
                  path="/discounts"
                  component={DiscountsTable}
                />
                <ProtectedRoute
                  exact
                  path="/discounts/new"
                  component={Discounts}
                />

                <ProtectedRoute
                  exact
                  path="/fulfillment"
                  component={Fulfillment}
                />

                <ProtectedRoute
                  exact
                  path="/ingredients"
                  component={IngredientsTable}
                />

                <ProtectedRoute exact path="/orders" component={Orders} />
                <ProtectedRoute exact path="/orders/new" component={NewOrder} />
                <ProtectedRoute
                  exact
                  path="/order/:id/edit"
                  component={EditOrder}
                />

                <ProtectedRoute exact path="/plating/" component={Plating} />

                <ProtectedRoute
                  exact
                  path="/ingredients/new"
                  component={NewIngredient}
                />

                <ProtectedRoute
                  exact
                  path="/ingredient/:id"
                  component={EditIngredient}
                />

                <ProtectedRoute
                  exact
                  path="/inventory"
                  component={InventoryTable}
                />

                <ProtectedRoute
                  exact
                  path="/products"
                  component={ProductsTable}
                />

                <ProtectedRoute
                  exact
                  path="/products/new"
                  component={NewProduct}
                />

                <ProtectedRoute
                  exact
                  path="/product/:id/edit"
                  component={EditProduct}
                />

                <ProtectedRoute
                  exact
                  path="/transfers"
                  component={TransfersTable}
                />

                <ProtectedRoute
                  exact
                  path="/transfers/new"
                  component={AddTransfer}
                />

                <ProtectedRoute
                  exact
                  path="/transfers/:id"
                  component={EditTransfer}
                />

                <ProtectedRoute exact path="/media" component={Media} />

                <ProtectedRoute exact path="/labels" component={Labels} />

                <ProtectedRoute exact path="/labels/new" component={NewLabel} />

                <ProtectedRoute
                  exact
                  path="/meal-planner"
                  component={MealPlanner}
                />

                {/* <ProtectedRoute exact path="/settings" component={Settings} /> */}

                <ProtectedRoute exact path="/staff" component={StaffTable} />

                <ProtectedRoute exact path="/staff/new" component={NewStaff} />

                <ProtectedRoute
                  exact
                  path="/staff/:id/edit"
                  component={EditStaff}
                />

                <ProtectedRoute
                  exact
                  path="/settings/taxes"
                  component={Taxes}
                />
                <ProtectedRoute
                  exact
                  path="/settings/delivery"
                  component={DeliverySurcharges}
                />

                <ProtectedRoute
                  exact
                  path="/settings/postal-codes"
                  component={PostalCodesTable}
                />

                <ProtectedRoute
                  exact
                  path="/settings/postal-codes/new"
                  component={NewPostalCode}
                />

                <ProtectedRoute
                  exact
                  path="/settings/postal-codes/:id/edit"
                  component={EditPostalCode}
                />

                {/* <ProtectedRoute exact path="/settings/plans" component={Plans} /> */}

                <ProtectedRoute
                  exact
                  path="/settings/meal-plans/new"
                  component={NewPlan}
                />

                <ProtectedRoute
                  exact
                  path="/settings/meal-plans/:id/edit"
                  component={EditPlan}
                />

                <ProtectedRoute
                  exact
                  path="/settings/delivery"
                  component={DeliverySurcharges}
                />
              </div>
            </Switch>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </Wrapper>
    </ThemeProvider>
    // </Provider>
  );
}

export default () => {
  return (
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  );
};
