import React, { useEffect, useState, useCallback } from 'react';

import {
  Grid,
  Box,
  Button,
  TextField,
  InputAdornment,
  MenuItem,
  ListSubheader,
  IconButton,
  Typography,
  Chip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import AutoComplete from '@material-ui/lab/Autocomplete';
import { debounce } from 'lodash';

import { FieldArray } from 'formik';
import { search } from '../../api/ingredients';

export default (props: any) => {
  const {
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    index,
    arrayHelpers,
    ingredient,
  } = props;

  const [substitutionIngredientOpen, setSubstitutionIngredientOpen] =
    useState(false);

  const [ingredientsSearchResults, setIngredientsSearchResults] = useState([]);

  const [ingredientsLoading, setIngredientsLoading] = useState(true);

  const searchHandler = debounce(async (searchText: string) => {
    console.log('going in here');
    console.log(searchText);
    setIngredientsLoading(true);

    const results = await search(searchText);
    console.log(results);

    //@ts-ignorew
    setIngredientsSearchResults(results);
    setIngredientsLoading(false);
  }, 500);

  return (
    <Grid container spacing={3} style={{ marginBottom: '1em' }}>
      <Grid item xs={12} md={5} sm={5}>
        <Box display="flex" alignItems="start" flexDirection="column">
          <AutoComplete
            id={index + 'autocomplete' + index}
            autoComplete
            includeInputInList
            filterSelectedOptions
            selectOnFocus
            noOptionsText={'No ingredients found'}
            onChange={(event: any, newValue: any) => {
              if (newValue === undefined) {
                return;
              }

              if (!newValue) {
                return;
              }

              console.log('going autocompelte onChange');
              console.log(newValue);

              setFieldValue(`subingredients.${index}.title`, newValue.title);
              setFieldValue(
                `subingredients.${index}.FoodDescription`,
                newValue.FoodDescription
              );

              setFieldValue(
                `subingredients.${index}.ingredientId`,
                newValue._id
              );
              setFieldValue(`subingredients.${index}.type`, newValue.type);
              setFieldValue(`subingredients.${index}._id`, newValue._id);

              setFieldValue(
                `subingredients.${index}.ingredientObject`,
                newValue
              );

              // setFieldValue(`subingredients.${index}`, {
              //   title: newValue.title,
              //   FoodDescription: newValue.FoodDescription,
              //   ingredientId: newValue.ingredientId,
              //   ingredientObject: newValue.ingredientObject,
              //   value: ingredient.value,
              //   unit: ingredient.unit,
              // });
            }}
            onInputChange={(event: any, newInputValue: any) =>
              searchHandler(newInputValue)
            }
            loading={ingredientsLoading}
            filterOptions={(x: any) => x}
            options={ingredientsSearchResults}
            renderOption={(option: any) => {
              return (
                <Box width="100%" display="flex" flexDirection="column">
                  <Box display="flex" ml={3} flexDirection="column">
                    <Typography color="textPrimary">
                      {option.title !== ''
                        ? option.title
                        : option.FoodDescription}{' '}
                      <Chip size="small" label={option.type} />
                    </Typography>
                    <Typography color="textSecondary">
                      {option.category}
                    </Typography>
                  </Box>
                </Box>
              );
            }}
            //@ts-ignore
            getOptionLabel={(option: {
              title: string;
              FoodDescription: string;
            }) => (option.title === '' ? option.FoodDescription : option.title)}
            value={
              //@ts-ignore
              values.subingredients[
                index
                //@ts-ignore
              ].ingredientId === ''
                ? {
                    ingredientId: '',
                    title: '',
                    FoodDescription: '',
                  }
                : values.subingredients[
                    index
                    //@ts-ignore
                  ].ingredientObject
            }
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField fullWidth {...params} label="Ingredient" />
            )}
          />
          {values.ingredients.length > 0 && (
            <>
              {values.ingredients[index].hasOwnProperty('type') && (
                <Chip
                  style={{ marginTop: '4px' }}
                  size="small"
                  label={values.ingredients[index].type}
                />
              )}
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={3} sm={3}>
        <TextField
          fullWidth
          select
          label="Select unit"
          placeholder="Volume"
          value={
            values.subingredients[
              index
              //@ts-ignore
            ].unit
          }
          onChange={(ev: any) => {
            console.log(ev.target.value);
            setFieldValue(`subingredients.${index}.unit`, ev.target.value);
          }}
        >
          <ListSubheader>Volume</ListSubheader>
          <MenuItem value="tsp">Teaspoon (tsp)</MenuItem>
          <MenuItem value="tbsp">Tablespoon (tbsp)</MenuItem>
          <MenuItem value="floz">Fluid ounce (fl oz)</MenuItem>
          <MenuItem value="mL">Milliliter (mL)</MenuItem>
          <MenuItem value="L">Liter (L)</MenuItem>
          <ListSubheader>Mass and weight</ListSubheader>
          <MenuItem value="lb">Pound (lb)</MenuItem>
          <MenuItem value="oz">Ounce (oz)</MenuItem>
          <MenuItem value="mg">Milligram (mg)</MenuItem>
          <MenuItem value="g">Gram (g)</MenuItem>
          <MenuItem value="kg">Kilogram (kg)</MenuItem>
        </TextField>
      </Grid>

      <Grid item xs={12} md={3} sm={3}>
        <TextField
          onChange={(e: any) => {
            console.log(e.currentTarget.value);
            setFieldValue(
              `subingredients.${index}.value`,
              e.currentTarget.value
            );
          }}
          name={`subingredients.${index}.value`}
          value={values.subingredients[index].value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {values.subingredients[index].unit}
              </InputAdornment>
            ),
          }}
          fullWidth
          label="Value"
        />
      </Grid>

      <Grid item xs={12} md={1} sm={1}>
        <Box display="flex" alignItems="flex-end">
          <IconButton
            style={{
              position: 'relative',
              right: '-8px',
            }}
            onClick={() => arrayHelpers.remove(index)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};
