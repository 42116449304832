import React from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

export default (props: {
  open: boolean;
  close: any;
  orderCancelSubmitting: boolean;
  name: string;
  cancelOrder: Function;
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="form-dialog-title"
    >
      <div
        style={{
          padding: '15px 15px 15px 25px',
          backgroundColor: '#33474C',
          color: '#FFF',
          marginBottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        id="form-dialog-title"
      >
        <div>
          <Typography
            variant="h6"
            style={{
              fontWeight: 400,
            }}
          >
            Cancel Order
          </Typography>
        </div>
        <div>
          <IconButton onClick={props.close}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
        </div>
      </div>
      <DialogContent>
        <Typography variant="body1">
          What is the reason for cancelling {props.name}'s order?
        </Typography>

        <Box width="100%" mt={3}>
          <Box>
            <RadioGroup aria-label="gender" name="customized-radios">
              <FormControlLabel
                value="I changed my mind"
                control={<Radio color="primary" />}
                label="Changed their mind"
              />
              <FormControlLabel
                value="I bought the wrong item(s)"
                control={<Radio color="primary" />}
                label="Bought wrong item(s)"
              />
              <FormControlLabel
                value="I found a cheaper alternative"
                control={<Radio color="primary" />}
                label="Found a cheaper alternative"
              />
              <FormControlLabel
                value="The delivery or pickup options are too long"
                control={<Radio color="primary" />}
                label="Delivery or pickup options are too long"
              />
              <FormControlLabel
                value="I prefer not to say"
                control={<Radio color="primary" />}
                label="Preferred not to say"
              />
              <FormControlLabel
                value="Other"
                control={<Radio color="primary" />}
                label="Other"
              />
            </RadioGroup>
          </Box>
        </Box>

        <Box width="100%" mt={3}>
          <TextField
            autoFocus
            multiline
            id="comments"
            label="Note"
            type="text"
            fullWidth
          />
        </Box>
      </DialogContent>
      <DialogActions style={{ padding: '16px 24px' }}>
        <Button onClick={props.close} color="primary">
          Cancel
        </Button>

        <Button
          disabled={props.orderCancelSubmitting}
          variant="contained"
          color="secondary"
          style={{ boxShadow: 'none' }}
          onClick={() => props.cancelOrder()}
        >
          {props.orderCancelSubmitting && (
            <CircularProgress
              style={{
                position: 'absolute',
                left: '50%',
                marginLeft: '-8px',
              }}
              size={16}
            />
          )}
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
