import { createSlice } from '@reduxjs/toolkit';

import UsersService from '../../api/users';

const initialState = {
  staffLoading: true,
  staff: {
    data: [],
    total: 0,
    limit: 0,
    skip: 0,
  },
  staffError: null,

  staffSingle: null,
  staffSingleLoading: true,
  staffSingleError: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setStaffLoading: (state, action) => {
      state.staffLoading = action.payload;
    },

    fetchStaffSuccess: (state, action) => {
      state.staffLoading = false;
      state.staff = action.payload;
    },

    setStaffSingleLoading: (state, action) => {
      state.staffSingleLoading = action.payload;
    },

    fetchSingleStaffSuccess: (state, action) => {
      state.staffSingleLoading = false;
      state.staffSingle = action.payload;
    },

    unsetDeliveryPersonnel: (state) => {
      state.staffLoading = false;
      state.staff = {
        data: [],
        total: 0,
        limit: 0,
        skip: 0,
      };
    },
    unsetStaffSingle: (state) => {
      state.staffSingle = null;
      state.staffSingleLoading = true;
      state.staffSingleError = null;
    },
  },
});

export const {
  setStaffLoading,
  fetchStaffSuccess,
  unsetDeliveryPersonnel,

  setStaffSingleLoading,
  fetchSingleStaffSuccess,
  unsetStaffSingle,
} = usersSlice.actions;

export default usersSlice.reducer;

export const fetchStaff = () => async (dispatch: Function) => {
  try {
    setStaffLoading(true);

    const deliveryGuys = await UsersService.find({
      roles: { $in: ['admin', 'delivery'] },
    });

    console.log('in fetch staff');
    console.log(deliveryGuys);
    dispatch(fetchStaffSuccess(deliveryGuys));
  } catch (error) {
    //   setStaffLoading(false);
    console.log('Error: fetchStaff');
    console.log(error);
  }
};

export const fetchSingleStaff = (id: string) => async (dispatch: Function) => {
  console.log('fetchSingleStaff');
  try {
    dispatch(setStaffSingleLoading(true));
    const i = await UsersService.get(id);
    console.log(i);
    dispatch(fetchSingleStaffSuccess(i));
  } catch (error) {
    console.log('Catch faild fetchSingleStaff Code');
    console.log(error);
    // dispatch(fetchSingleStaffFailed(error));
  } finally {
    dispatch(setStaffSingleLoading(false));
  }
};

export function createStaff(staff: any, setSubmitting: Function, history: any) {
  return async function (dispatch: Function) {
    try {
      const p = await UsersService.create(staff);

      console.log(p);

      history.push('/staff');
    } catch (error) {
      console.log(error);
      // dispatch(getProductsFailed(error));
    } finally {
      setSubmitting(false);
    }
  };
}

export const patchStaff =
  (id: string, staff: any, setSubmitting: Function, history: any) =>
  async (dispatch: Function) => {
    console.log('patchStaff');
    try {
      //   dispatch(sert(true));
      const i = await UsersService.patch(id, staff);
      console.log(i);
      dispatch(fetchSingleStaffSuccess(i));
      history.push('/staff');
    } catch (error) {
      console.log('Catch faild Postal Code');
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };
