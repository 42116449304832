import { createSlice } from '@reduxjs/toolkit';
import MediaService from '../../api/media';
import FileUploadService from '../../api/upload';

const initialState = {
  media: {
    data: [],
  },
  mediaLoading: true,
};

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    mediaLoading: (state, action: any) => {
      //@ts-ignore
      state.mediaLoading = action.payload;
    },

    findMediaSuccess: (state, action: any) => {
      //@ts-ignore
      console.log('asd');
      state.media = action.payload;
      state.mediaLoading = false;
    },

    uploadMediaSuccess: (state, action: any) => {
      //@ts-ignore
      state.media.data.push(action.payload);
      state.mediaLoading = false;
    },

    removeMediaSuccess: (state, action: any) => {
      console.log(state.media);
      const index = state.media.data.findIndex(
        //@ts-ignore
        (e) => e._id === action.payload._id
      );

      console.log(index);

      if (index >= 0) {
        //@ts-ignore
        console.log(index);
        state.media.data.splice(index, 1);
      }
    },
  },
});

export const {
  mediaLoading,
  findMediaSuccess,
  removeMediaSuccess,
  uploadMediaSuccess,
} = mediaSlice.actions;

export function findMedia(query: object | undefined = undefined) {
  return async function (dispatch: Function) {
    dispatch(mediaLoading(true));
    try {
      const media: Object = await MediaService.find(query);
      console.log(media);
      //@ts-ignore
      dispatch(findMediaSuccess(media));
    } catch (error) {
      console.log(error);
    }
  };
}

export function uploadMedia(
  file: object,
  croppedData: object,
  successFunction: Function | undefined
) {
  return async function (dispatch: Function) {
    dispatch(mediaLoading(true));
    try {
      const media: any = await FileUploadService.create(file, croppedData);
      console.log(media);
      //@ts-ignore
      dispatch(uploadMediaSuccess(media));

      if (successFunction) {
        successFunction();
      }
    } catch (error) {
      console.log(error);
    }
  };
}

export function removeMedia(id: string, successFunction: Function) {
  return async function (dispatch: Function) {
    try {
      const i = await MediaService.remove(id);
      console.log(i);
      dispatch(removeMediaSuccess(i));
      successFunction();
    } catch (error) {
      console.log('Update organization failed');
      console.log(error);
    }
  };
}

export default mediaSlice.reducer;
