import React from "react";

type StatusIcon = { status: String; styles: Object | undefined };

export default function StatusIcon(props: StatusIcon) {
  const getColorFromStatus = (status: any) => {
    switch (status) {
      case "abandoned":
        return "#9e9e9e";

      case "active":
        return "#4caf50";

      case "cancelled":
        return "#f44336";

      case "paused":
        return "#ffc107";

      default:
        return "#9e9e9e";
    }
  };

  return (
    <span
      style={{
        ...props.styles,
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        border: "1px solid white",
        backgroundColor: getColorFromStatus(props.status)
      }}
    ></span>
  );
}
