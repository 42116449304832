import { createSlice } from '@reduxjs/toolkit';

import {
  createCategory,
  getCategories,
  patchCategory,
  removeCategory,
  createSubCategory,
  removeSubCategory,
} from '../../api/categories';

const initialState = {
  categories: {
    data: [],
    total: 0,
    limit: 0,
    skip: 0,
  },
  categoriesLoading: true,
  error: null,
};

export interface Category {
  type: String;
  name: String;
  nameFR?: String;
  SubCats?: any;
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categoriesLoading: (state, action: any) => {
      //@ts-ignore
      state.categoriesLoading = action.payload;
    },

    getCategoriesSuccess: (state, action: any) => {
      action.payload.data.forEach((e: any) => {
        e.collapseOpen = false;
      });

      //@ts-ignore
      state.categories = action.payload;
      state.categoriesLoading = false;
    },

    getCategoriesFailed: (state, action: any) => {
      state.categories.data = [];
      state.categoriesLoading = false;
      state.error = action.payload;
    },

    createCategorySuccess: (state, action: any) => {
      //@ts-ignore
      console.log(action.payload);
      //@ts-ignore
      state.categories.data.push(action.payload);
    },

    updateCategoryAvailabilitySuccess: (state, action: any) => {
      const index = state.categories.data.findIndex(
        //@ts-ignore
        (e) => e._id === action.payload._id
      );

      if (index >= 0) {
        //@ts-ignore
        state.categories.data[index].available = action.payload.available;
      }
    },

    updateCategorySuccess: (state, action: any) => {
      const index = state.categories.data.findIndex(
        //@ts-ignore
        (e) => e._id === action.payload._id
      );

      if (index >= 0) {
        //@ts-ignore
        state.categories.data[index] = action.payload;
      }
    },

    addSubCategorySuccess: (state, action: any) => {
      const index = state.categories.data.findIndex(
        //@ts-ignore
        (e) => e._id === action.payload._id
      );

      if (index >= 0) {
        //@ts-ignore
        state.categories.data[index] = action.payload;
      }
    },

    deleteCategorySuccess: (state, action: any) => {
      const index = state.categories.data.findIndex(
        //@ts-ignore
        (e) => e._id === action.payload._id
      );

      if (index >= 0) {
        //@ts-ignore
        state.categories.data.splice(index, 1);
      }
    },

    openCategoryCollapse: (state, action: any) => {
      console.log('open cat colalpse');
      const index = state.categories.data.findIndex(
        //@ts-ignore
        (e, i) => i === action.payload
      );

      console.log(state.categories.data[index]);

      if (index >= 0) {
        //@ts-ignore
        state.categories.data[index].collapseOpen = !state.categories.data[
          index
          //@ts-ignore
        ].collapseOpen;
      }
    },

    updateSubCategorySuccess: (state, action: any) => {},
  },
});

export const {
  categoriesLoading,
  getCategoriesSuccess,
  getCategoriesFailed,
  createCategorySuccess,
  addSubCategorySuccess,
  updateCategorySuccess,
  deleteCategorySuccess,
  openCategoryCollapse,
  updateSubCategorySuccess,
} = categoriesSlice.actions;

export function fetchCategories() {
  return async function (dispatch: Function) {
    dispatch(categoriesLoading(true));
    try {
      const categories: Object = await getCategories();

      console.log(categories);
      //@ts-ignore
      dispatch(getCategoriesSuccess(categories));
    } catch (error) {
      console.log('Fetch Categories Error');
      console.log(error);
      // dispatch(getCategoriesFailed(error));
    }
  };
}

export function addCategory(
  category: Category,
  setSubmitting: Function,
  closeDialog: Function
) {
  return async function (dispatch: Function) {
    try {
      const c = await createCategory(category);
      console.log(c);

      dispatch(createCategorySuccess(c));
      dispatch(fetchCategories());
      closeDialog();
    } catch (error) {
      console.log(error);

      dispatch(getCategoriesFailed(error));
    } finally {
      setSubmitting(false);
    }
  };
}

export function updateCategory(
  id: String,
  category: Category | any,
  setSubmitting: Function,
  closeDialog: Function
) {
  return async function (dispatch: Function) {
    try {
      const i = await patchCategory(id, category);
      console.log(i);
      dispatch(updateCategorySuccess(i));
      dispatch(fetchCategories());
      closeDialog();
    } catch (error) {
      console.log('Update category item failed');
      console.log(error);

      //   dispatch(getCategoriesFailed(error));
    } finally {
      setSubmitting(false);
    }
  };
}

export function updateSubCategory(
  id: String,
  subCats: any,
  setSubmitting: Function,
  closeDialog: Function
) {
  return async function (dispatch: Function) {
    try {
      console.log('Going update sub category');
      const i = await patchCategory(id, {
        subCats,
      });
      console.log(i);
      dispatch(updateCategorySuccess(i));
      dispatch(fetchCategories());
      closeDialog();
    } catch (error) {
      console.log('Update category item failed');
      console.log(error);

      //   dispatch(getCategoriesFailed(error));
    } finally {
      setSubmitting(false);
    }
  };
}

export function deleteSubCategory(
  id: String,
  subCat: Object,
  setSubmitting: Function,
  closeDialog: Function
) {
  return async function (dispatch: Function) {
    try {
      const i = await removeSubCategory(id, subCat);
      console.log(i);
      dispatch(updateCategorySuccess(i));
      dispatch(fetchCategories());
      closeDialog();
    } catch (error) {
      console.log('Update category item failed');
      console.log(error);

      //   dispatch(getCategoriesFailed(error));
    } finally {
      setSubmitting(false);
    }
  };
}

export function addSubCategory(
  id: String,
  subCategory: Object,
  setSubmitting: Function,
  closeDialog: Function
) {
  return async function (dispatch: Function) {
    try {
      const i = await createSubCategory(id, subCategory);
      console.log(i);
      dispatch(updateCategorySuccess(i));
      dispatch(fetchCategories());

      closeDialog();
    } catch (error) {
      console.log('Update category item failed');
      console.log(error);

      //   dispatch(getCategoriesFailed(error));
    } finally {
      setSubmitting(false);
    }
  };
}

export function deleteCategory(
  CategoryId: String,
  setSubmitting: Function,
  closeDialog: Function
) {
  return async function (dispatch: Function) {
    try {
      const category = await removeCategory(CategoryId);

      console.log(category);
      dispatch(deleteCategorySuccess(category));

      closeDialog();
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };
}

export default categoriesSlice.reducer;
