import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Box, Typography, CircularProgress } from '@material-ui/core';
import { VariantType, useSnackbar } from 'notistack';

import { fetchSubscription } from '../../slices/subscription/subscriptionSlice';
import stripePromise from '../../modules/stripe';
import feathers from '../../modules/feathers';

const CheckoutForm = (props: any) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: any) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  const handleSubmit = async (event: any) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setSubmitting(true);

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // // Use your card Element with other Stripe.js APIs
    // //@ts-ignore

    if (props.add) {
      //@ts-ignore
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.log('[error]', error);
      }

      feathers
        .service('/stripe/payment-method')
        .patch(paymentMethod.id, {
          attach: true,
          customer: props.stripeCustomerId,
        })
        .then((res: any) => {
          console.log('success');
          console.log(res);

          setSubmitting(false);
          props.toggleOpen(false);
          props.fetchSubscription(props.customerId);
          enqueueSnackbar('Card added successfully');
        })
        .catch((err: any) => {
          console.log('err');
          console.log(err);
          enqueueSnackbar('There was a problem adding card', {
            variant: 'error',
          });
          setSubmitting(false);
        });

      return;
    }

    const setupIntent = await feathers.service('/stripe/setup-intent').create({
      payment_method_types: ['card'],
    });

    console.log(setupIntent);

    // @ts-ignore
    const cardSetupResult = await stripe.confirmCardSetup(
      //@ts-ignore
      setupIntent.client_secret,
      //@ts-ignore
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: props.email,
            name: props.firstName + ' ' + props.lastName,
          },
        },
      }
    );

    if (cardSetupResult.error) {
      enqueueSnackbar('There was a problem adding card', {
        variant: 'error',
      });
      console.log(cardSetupResult.error);
      return;
      // Display cardSetupResult.error.message in your UI.
    }

    // The setup has succeeded. Display a success message and send
    // result.setupIntent.payment_method to your server to save the
    // card to a Customer

    console.log(cardSetupResult);
    console.log(cardSetupResult.setupIntent.payment_method);

    const customerCreate = await feathers.service('/stripe/customer').create({
      name: props.firstName + ' ' + props.lastName,
      email: props.email,
      payment_method: cardSetupResult.setupIntent.payment_method,
      metadata: {
        subscriptionId: props.subscriptionId,
        customerId: props.customerId,
      },
    });

    setSubmitting(false);
    props.toggleOpen(false);
    props.fetchSubscription(props.customerId);

    console.log(customerCreate);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h6" style={{ marginBottom: '1em' }}>
        Add a credit card
      </Typography>
      <CardElement
        options={{
          hidePostalCode: false,
          style: {
            base: {
              fontFamily: 'Roboto, sans-serif',
              fontSize: '16px',
              color: 'rgba(0, 0, 0, 0.87)',
              '::placeholder': {
                color: 'rgba(0, 0, 0, 0.87)',
              },
            },
            invalid: {
              color: '#9e2146',
              iconColor: '#fa755a',
            },
          },
        }}
        onChange={handleChange}
      />
      <Typography
        style={{ marginTop: '10px', color: '#9e2146' }}
        variant="body1"
      >
        {error}
      </Typography>

      <Box paddingY={2}>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            style={{
              boxShadow: 'none',
            }}
            disabled={!stripe || error !== null || !elements || submitting}
          >
            {submitting && (
              <CircularProgress
                style={{
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-8px',
                }}
                size={16}
              />
            )}
            Add
          </Button>
        </DialogActions>
      </Box>
    </form>
  );
};

// interface CardSaveFormProps {
//   an;
// }

class CardSaveForm extends React.Component<any> {
  // console.log(props);

  render() {
    return (
      <Elements stripe={stripePromise}>
        <CheckoutForm
          add={this.props.paymentMethod == 'card'}
          save={this.props.paymentMethod == ''}
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          customerId={this.props.customerId}
          subscriptionId={this.props.subscriptionId}
          email={this.props.email}
          toggleOpen={this.props.toggleOpen}
          stripeCustomerId={this.props.stripeCustomerId || null}
          fetchSubscription={this.props.fetchSubscription}
        />
      </Elements>
    );
  }
}

const mapDispatchToProps = {
  fetchSubscription,
};

export default connect(null, mapDispatchToProps)(CardSaveForm);
