import feathers from '../../modules/feathers';

export default {
  create: (file: object, croppedData: object) => {
    return feathers.service('file-upload').create({
      file,
      croppedData,
    });
  },
};
