import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import {
  Avatar,
  CircularProgress,
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  TextField,
  Chip,
  IconButton,
  Container,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  updateCustomerNote,
  updateCustomerAddress,
  fetchCustomer,
  createCustomer,
  updateCustomerContact,
} from '../../slices/customer/customerSlice';

import CustomerCardPaymentMethods from './CustomerCardPaymentMethods';
import CustomerAddressPreview from './CustomerAddressPreview';
import CustomerActivityList from './CustomerActivityList';
import { useSnackbar } from 'notistack';
import AddCustomerDialog from '../Customers/AddCustomerDialog';
import CustomerContactEditDialog from './CustomerContactEditDialog';
import CustomerEditAddressDialog from './CustomerEditAddressDialog';
import CustomerAddAddressDialog from './CustomerAddAddressDialog';
import { switchPaymentMethod } from '../../slices/subscription/subscriptionSlice';
import CustomerBillingDialog from './CustomerBillingDialog';
import CustomerEditFulfillmentDialog from './CustomerEditFulfillmentDialog';

interface CustomerObject {
  _id: string;
  settings: {
    language: string;
  };
  address?: any;
  notes?: string;
  secondary: boolean;
  secondaryAccounts: Array<string>;

  phone: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface CustomerOverviewProps {
  customer: CustomerObject;
  subscription: any;
  customerActivityLoading: Boolean;
  activity: Array<object> | undefined;
  paymentMethodsLoading: Boolean;
  fetchSubscriptionLoading: Boolean;
  secondaryCustomersFetchLoading: Boolean;
  secondaryCustomers: any;

  fetchCustomerLoading: Boolean;

  primaryCustomerFetchLoading: Boolean;
  primaryCustomer: any;

  paymentMethods: any;
}

function CustomerLoading() {
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton />
          <Skeleton animation={false} />
          <Skeleton animation="wave" />
        </Grid>
      </Grid>
    </Container>
  );
}

export default function CustomerOverview(props: CustomerOverviewProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [noteValue, setNoteValue] = useState(props.customer.notes || '');
  const [noteSaving, setNoteSaving] = useState(false);
  const [customerContactEditDialogOpen, setCustomerContactEditDialogOpen] =
    useState(false);
  const [addCustomerDialogOpen, setAddCustomerDialogOpen] = useState(false);

  const [customerAddAddressDialogOpen, setCustomerAddAddressDialogOpen] =
    useState(false);

  const [customerEditAddressDialogOpen, setCustomerEditAddressDialogOpen] =
    useState(true);

  const [billingDialogOpen, setBillingDialogOpen] = useState(false);
  const [fulfillmentDialogOpen, setFulfillmentDialogOpen] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAddressType, setSelectedAddressType] = useState(null);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);

  const handleNoteSave = () => {
    updateCustomerNote(
      props.customer._id,
      noteValue,
      enqueueSnackbar,
      setNoteSaving
    );
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: '24px' }}>
            <Typography variant="h6">Notes</Typography>

            <TextField
              variant="outlined"
              rows="1"
              rowsMax="20"
              margin="normal"
              fullWidth
              multiline
              value={noteValue}
              onChange={(e: any) => {
                setNoteValue(e.target.value);
              }}
            />

            {props.customer.notes !== noteValue && (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  onClick={() => handleNoteSave()}
                  disabled={noteSaving}
                  style={{ justifySelf: 'flex-end' }}
                >
                  {noteSaving && (
                    <CircularProgress
                      style={{
                        position: 'absolute',
                        left: '50%',
                        marginLeft: '-8px',
                      }}
                      size={16}
                    />
                  )}
                  Save
                </Button>
              </Box>
            )}

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px',
              }}
            ></div>

            <Typography variant="h6" style={{ marginBottom: '1em' }}>
              Activity
            </Typography>

            <div style={{ marginTop: '1em', marginBottom: '1.5em' }}>
              <Button
                size="small"
                style={{
                  textTransform: 'capitalize',
                  marginRight: '5px',
                  minWidth: '48px',
                  backgroundColor: 'rgba(0,0,0,0.09)',
                }}
              >
                <Typography variant="body1" style={{ top: '3px' }}>
                  All
                </Typography>
              </Button>
              <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Account</Typography>
              </Button>
              <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Billing</Typography>
              </Button>
              <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Delivery</Typography>
              </Button>
              <Button
                size="small"
                style={{ textTransform: 'capitalize', marginRight: '5px' }}
              >
                <Typography variant="body1">Notifications</Typography>
              </Button>
            </div>

            {props.customerActivityLoading && props.activity === [] && (
              <Grid container></Grid>
            )}

            {!props.customerActivityLoading && props.activity && (
              <CustomerActivityList activity={props.activity} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Contact</Typography>

              {!props.customer.secondary && (
                <Button
                  size="small"
                  onClick={() => setAddCustomerDialogOpen(true)}
                >
                  Add
                </Button>
              )}
            </Grid>

            <Box display="flex" flexDirection="column">
              {props.customer.secondaryAccounts.length > 0 && (
                <Box>
                  <Chip
                    size="small"
                    label="Primary"
                    style={{
                      flex: '1',
                      marginBottom: '10px',
                      marginLeft: '50px',
                    }}
                  />
                </Box>
              )}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <Avatar
                    style={{
                      alignSelf: 'flex-start',
                      width: '42px',
                      height: '42px',
                      marginRight: '10px',
                    }}
                    alt={`${props.customer.firstName} ${props.customer.lastName}`}
                  >
                    <Typography variant="body2">
                      {props.customer.firstName.charAt(0)}
                      {props.customer.lastName.charAt(0)}
                    </Typography>
                  </Avatar>
                  <div>
                    <Typography variant="body1">
                      <strong>
                        {props.customer.firstName} {props.customer.lastName}
                      </strong>
                    </Typography>
                    <Typography variant="body2">
                      <a href={`mailto:${props.customer.email}`}>
                        {props.customer.email}
                      </a>
                    </Typography>
                    <Typography variant="body2">
                      <a href={`tel:${props.customer.phone}`}>
                        {props.customer.phone}
                      </a>
                    </Typography>
                  </div>
                </Box>

                <IconButton
                  onClick={() => {
                    setCustomerContactEditDialogOpen(true);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Box>
              {props.secondaryCustomersFetchLoading && <CustomerLoading />}
            </Box>

            {!props.customer.secondary &&
              !props.secondaryCustomersFetchLoading &&
              props.secondaryCustomers &&
              props.secondaryCustomers.map((customer: any, index: Number) => (
                <Box display="flex" mt="1em" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Avatar
                        style={{
                          alignSelf: 'flex-start',
                          width: '42px',
                          height: '42px',
                          marginRight: '10px',
                        }}
                        alt={`${customer.firstName} ${customer.lastName}`}
                      >
                        <Typography variant="body2">
                          {customer.firstName.charAt(0).toUpperCase()}
                          {customer.lastName.charAt(0).toUpperCase()}
                        </Typography>
                      </Avatar>
                      <div>
                        <Typography variant="body1">
                          <strong>
                            {customer.firstName} {customer.lastName}
                          </strong>
                        </Typography>
                        <Typography variant="body2">
                          <a href={`mailto:${customer.email}`}>
                            {customer.email}
                          </a>
                        </Typography>
                        <Typography variant="body2">
                          <a href={`tel:${customer.phone}`}>{customer.phone}</a>
                        </Typography>
                      </div>
                    </Box>

                    <Box>
                      <Link
                        //@ts-ignore
                        //@ts-ignore
                        to={`/customer/${customer._id}`}
                        //@ts-ignore
                        //@ts-ignore
                        target="_blank"
                        // style={{
                        //   textDecoration: 'none',
                        //   color: 'inherit',
                        // }}
                      >
                        <IconButton
                          style={{
                            fontSize: 'inherit',
                          }}
                        >
                          <OpenInNewIcon fontSize="small" />
                        </IconButton>
                      </Link>
                      <IconButton
                        onClick={() => {
                          setCustomerContactEditDialogOpen(true);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              ))}

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px',
              }}
            ></div>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Address</Typography>
              <Button
                size="small"
                onClick={() => setCustomerAddAddressDialogOpen(true)}
              >
                Add
              </Button>
            </Grid>

            {props.customer.address.map((address: object, index: Number) => (
              <CustomerAddressPreview
                address={address}
                selectedAddressIndex={index}
                openEditAddressDialog={(
                  addressType: String,
                  address: Object
                ) => {
                  //@ts-ignore
                  setSelectedAddressIndex(index);
                  //@ts-ignore
                  setSelectedAddress(address);
                  //@ts-ignore
                  setSelectedAddressType(address.addressType);
                  setCustomerEditAddressDialogOpen(true);
                }}
              />
            ))}

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px',
              }}
            ></div>

            {props.paymentMethodsLoading || props.fetchSubscriptionLoading ? (
              <CustomerLoading />
            ) : (
              <React.Fragment>
                <Grid
                  style={{ marginBottom: '1em' }}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6">Billing</Typography>
                  {!props.customer.secondary && (
                    <Button
                      size="small"
                      onClick={() => {
                        setBillingDialogOpen(true);
                      }}
                    >
                      {props.subscription.paymentMethod === '' ? 'Add' : 'Edit'}
                    </Button>
                  )}
                </Grid>

                {!props.customer.secondary && (
                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {props.subscription.paymentMethod === '' && (
                      <Typography>Customer has no payment method</Typography>
                    )}

                    {props.subscription.paymentMethod === 'cash' && (
                      <Typography
                        variant="body1"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <img
                          style={{
                            width: '30px',
                            marginLeft: '10px',
                            marginRight: '15px',
                          }}
                          src={require('../../assets/images/cash.png')}
                        />
                        Customer pays cash
                      </Typography>
                    )}

                    {props.subscription.paymentMethod === 'interac' && (
                      <Typography
                        variant="body1"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <img
                          style={{
                            width: '30px',
                            marginLeft: '10px',
                            marginRight: '15px',
                          }}
                          src={require('../../assets/images/cash.png')}
                        />
                        Customer pays via Interac
                      </Typography>
                    )}

                    {props.subscription.paymentMethod == 'card' && (
                      <React.Fragment>
                        <CustomerCardPaymentMethods
                          paymentMethods={props.paymentMethods}
                        />
                      </React.Fragment>
                    )}
                  </Grid>
                )}

                {props.customer.secondary &&
                  (props.primaryCustomer == null ||
                    props.fetchCustomerLoading) && <CustomerLoading />}

                {props.customer.secondary &&
                  props.fetchCustomerLoading !== null &&
                  !props.fetchCustomerLoading && (
                    <Chip
                      label={`Managed by ${props.primaryCustomer.firstName} ${props.primaryCustomer.lastName}`}
                      clickable
                      onClick={() => {
                        history.push('/customer/' + props.primaryCustomer._id);
                      }}
                    />
                  )}
              </React.Fragment>
            )}

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Fulfillment</Typography>
              <Button
                size="small"
                onClick={() => setFulfillmentDialogOpen(true)}
              >
                Edit
              </Button>
            </Grid>

            <Typography></Typography>

            {/* <Box mt={2} display="flex" alignItems="center">
              <Box display="flex" alignItems="center" paddingRight="8px">
                {subscription.deliveryTime === 'nightBefore' ? (
                  <NightsStayIcon
                    style={{ width: '32px', height: '32px' }}
                    color="secondary"
                  />
                ) : (
                  <WbSunnyIcon
                    style={{ width: '32px', height: '32px' }}
                    color="secondary"
                  />
                )}
              </Box>
              <Box>
                <p
                  className="font-weight-500 font-weight-medium"
                  style={{
                    fontFamily: 'Inter-Regular, sans-serif',
                    margin: 0,
                  }}
                >
                  {subscription.deliveryTime === 'nightBefore'
                    ? `Night before between ${
                        subscription.deliveryType === 'delivery'
                          ? '2:30-6:30 p.m.'
                          : '2:30-4:00 p.m.'
                      }`
                    : `Day of between 7:00-11:00 a.m.`}
                </p>
              </Box>
            </Box> */}

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '24px -24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Credits</Typography>
              <Button
                size="small"
                onClick={() => setCustomerAddAddressDialogOpen(true)}
              >
                Add
              </Button>
            </Grid>

            <Typography>Customer has no credits</Typography>

            {/* <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <LinearProgress variant="determinate" value={30} />
                  </Box>
                  <Box minWidth={35}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >{`$${Math.round(30)}`}</Typography>
                  </Box>
                </Box> */}
          </Paper>
        </Grid>
      </Grid>

      <AddCustomerDialog
        primaryAccount={props.customer}
        secondary={true}
        open={addCustomerDialogOpen}
        handleClose={() => setAddCustomerDialogOpen(false)}
        createCustomer={createCustomer}
      />

      <CustomerContactEditDialog
        updateCustomerContact={updateCustomerContact}
        customer={props.customer}
        open={customerContactEditDialogOpen}
        toggleOpen={(open: any) => setCustomerContactEditDialogOpen(open)}
      />

      {selectedAddress !== null && (
        <CustomerEditAddressDialog
          address={selectedAddress}
          addressType={selectedAddressType}
          selectedAddressIndex={selectedAddressIndex}
          customer={props.customer}
          open={customerEditAddressDialogOpen}
          updateCustomerAddress={updateCustomerAddress}
          fetchCustomer={fetchCustomer}
          toggleOpen={(open: any) => {
            setCustomerEditAddressDialogOpen(open);
            setSelectedAddress(null);
            setSelectedAddressType(null);
            setSelectedAddressIndex(null);
          }}
        />
      )}

      <CustomerAddAddressDialog
        updateCustomerAddress={updateCustomerAddress}
        fetchCustomer={fetchCustomer}
        customer={props.customer}
        open={customerAddAddressDialogOpen}
        toggleOpen={(open: any) => {
          setCustomerAddAddressDialogOpen(open);
        }}
      />

      <CustomerBillingDialog
        open={billingDialogOpen}
        toggleOpen={(open: any) => {
          setBillingDialogOpen(open);
        }}
        subscription={props.subscription}
        subscriptionId={props.subscription._id}
        customerId={props.customer._id}
        firstName={props.customer.firstName}
        lastName={props.customer.lastName}
        email={props.customer.email}
        paymentMethod={props.subscription.paymentMethod}
        stripeCustomerId={props.subscription.stripe_customer_id || null}
        changePaymentMethod={switchPaymentMethod}
      />

      <CustomerEditFulfillmentDialog
        open={fulfillmentDialogOpen}
        toggleOpen={(open: any) => {
          setFulfillmentDialogOpen(open);
        }}
        subscription={props.subscription}
        subscriptionId={props.subscription._id}
        customerId={props.customer._id}
        firstName={props.customer.firstName}
        lastName={props.customer.lastName}
        email={props.customer.email}
      />
    </div>
  );
}
