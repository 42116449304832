import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { Formik, FieldArray, FastField, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import InputAdornment from '@material-ui/core/InputAdornment';
import ImageIcon from '@material-ui/icons/Image';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import CloudIcon from '@material-ui/icons/Cloud';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';

import { addProduct, fetchExtras } from '../../slices/products/productsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../slices/categories/categoriesSlice';
import { RootState } from '../../rootReducer';

import './NewPlan.css';
import { Radio } from '@material-ui/core';

export default function NewPlan(props: any) {
  // const classes = useStyles();
  const dispatch = useDispatch();

  const [categoryDialogOpen, setCategoryDialogOpen] = useState(0);

  const [variants, setVariants] = useState([{ name: '' }]);

  const reorder = (
    list: Array<object>,
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 8 * 2,
    margin: `0 0 ${8}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: 8,
    width: '100%',
    display: 'flex',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      variants,
      result.source.index,
      result.destination.index
    );

    //@ts-ignore
    setVariants(items);
  };

  const categories: any = useSelector(
    (state: RootStateOrAny) => state.categories.categories
  );

  const categoriesLoading: any = useSelector(
    (state: RootStateOrAny) => state.categories.categoriesLoading
  );

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchExtras());
  }, []);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
            }}
          >
            <Link style={{ textDecoration: 'none' }} to="/">
              <Button style={{ textTransform: 'capitalize' }}>
                <Typography>Dashboard</Typography>
              </Button>
            </Link>
            <Button style={{ textTransform: 'capitalize' }}>
              <Typography>Settings</Typography>
            </Button>
            <Typography>Plan</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          title: '',
          titleFR: '',
          description: '',
          descriptionFR: '',
          categories: [],
          availability: [true, true, true, true, true, false, false],
          availabilityPreset: 'weekdays',
          pricingPerDish: false,
          price: 0,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Title is required'),
          description: Yup.string(),
          categories: Yup.array(),
          availability: Yup.array().of(Yup.boolean()),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          console.log(values);

          // dispatch(addProduct(values, setSubmitting, props.history));
        }}
      >
        {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: '20px 0 40px' }}
            >
              <Box
                fontWeight="fontWeightMedium"
                fontSize="h4.fontSize"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                      {values.categories.length > 0
                        ? `
                      ${values.categories.length}${' '} ${
                            values.categories.length > 1 ? 'meals' : 'meal'
                          } per day`
                        : 'New plan'}
                    </Box>
                  </Box>
                </Box>

                <Box>
                  <Button
                    style={{ marginRight: '1em' }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                    <CloudIcon
                      style={{ fill: '#9b9b9b', marginLeft: '10px' }}
                    />
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <Typography variant="h6">Categories</Typography>

                  <Box mt={1}>
                    <FieldArray
                      name="categories"
                      render={(arrayHelpers) =>
                        !categoriesLoading &&
                        categories.data
                          .filter((e: any) => e.type === 'Meals')
                          .map((e: any, i: number) => (
                            <Box
                              paddingY={1}
                              display="flex"
                              alignItems="center"
                              width="100%"
                              // justifyContent="space-between"
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <Box
                                display="flex"
                                alignItems="center"
                                flex="1"
                                onClick={() => {
                                  const index = values.categories.findIndex(
                                    (cat: any) => {
                                      return cat.name === e.name;
                                    }
                                  );

                                  if (index === -1) {
                                    arrayHelpers.push({
                                      name: e.name,
                                      minimumQuantity: 1,
                                    });

                                    return;
                                  }

                                  arrayHelpers.remove(index);
                                }}
                              >
                                <Checkbox
                                  checked={
                                    values.categories.findIndex((cat: any) => {
                                      return cat.name === e.name;
                                    }) !== -1
                                  }
                                />

                                <Typography variant="body1">
                                  {e.name}
                                </Typography>
                              </Box>
                              <Box display="flex" flex="1">
                                <TextField
                                  label="Minimum qty"
                                  margin="none"
                                  placeholder="Min qty"
                                  defaultValue="1"
                                  name={`categories.${i}.minimumQuantity`}
                                  disabled={
                                    values.categories.findIndex((cat: any) => {
                                      return cat.name === e.name;
                                    }) == -1
                                  }
                                />
                              </Box>
                            </Box>
                          ))
                      }
                    />
                  </Box>
                </Paper>

                <Paper style={{ padding: '0 24px 0', marginBottom: '2em' }}>
                  <>
                    <Grid
                      style={{ marginBottom: '1em', paddingTop: '24px' }}
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h6">Pricing</Typography>
                    </Grid>
                  </>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label={`Enter price`}
                        name={`price`}
                        value={values.price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={Boolean(touched.price && errors.price)}
                        style={{
                          paddingTop: '1px',
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              component="div"
                              style={{ paddingLeft: '-14px' }}
                              disablePointerEvents
                            >
                              $
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              component="div"
                              style={{ paddingLeft: '-14px' }}
                              disablePointerEvents
                            >
                              {values.pricingPerDish ? '/dish' : '/week'}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormControlLabel
                        control={<Checkbox name="pricingPerDish" />}
                        label="Pricing per dish"
                        onChange={(ev: any) => {
                          console.log(ev.target.value);
                          setFieldValue(
                            'pricingPerDish',
                            !values.pricingPerDish
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}></Grid>
                  </Grid>
                  {/* 
                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '32px -24px 24px',
                    }}
                  ></div> */}
                </Paper>

                <Paper style={{ padding: '0 24px 0', marginBottom: '2em' }}>
                  <>
                    <Grid
                      style={{ marginBottom: '1em', paddingTop: '24px' }}
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography variant="h6">Availability</Typography>
                    </Grid>
                  </>

                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Radio color="primary" name="weekdays" />}
                        label="Weekdays"
                        checked={values.availabilityPreset === 'weekdays'}
                        onChange={(ev: any) => {
                          setFieldValue('availabilityPreset', 'weekdays');

                          setFieldValue(`availability.0`, true);
                          setFieldValue(`availability.1`, true);
                          setFieldValue(`availability.2`, true);
                          setFieldValue(`availability.3`, true);
                          setFieldValue(`availability.4`, true);
                          setFieldValue(`availability.5`, false);
                          setFieldValue(`availability.6`, false);
                        }}
                        onBlur={handleBlur}
                      />

                      <FormControlLabel
                        control={<Radio color="primary" name="weekends" />}
                        label="Weekend"
                        checked={values.availabilityPreset === 'weekends'}
                        onChange={(ev: any) => {
                          setFieldValue('availabilityPreset', 'weekends');
                          setFieldValue(`availability.0`, false);
                          setFieldValue(`availability.1`, false);
                          setFieldValue(`availability.2`, false);
                          setFieldValue(`availability.3`, false);
                          setFieldValue(`availability.4`, false);
                          setFieldValue(`availability.5`, true);
                          setFieldValue(`availability.6`, true);
                        }}
                        onBlur={handleBlur}
                      />

                      <FormControlLabel
                        control={<Radio color="primary" name="custom" />}
                        label="Custom"
                        checked={values.availabilityPreset === 'custom'}
                        onChange={(ev: any) => {
                          setFieldValue('availabilityPreset', 'custom');
                          setFieldValue(`availability.0`, true);
                          setFieldValue(`availability.1`, true);
                          setFieldValue(`availability.2`, true);
                          setFieldValue(`availability.3`, true);
                          setFieldValue(`availability.4`, true);
                          setFieldValue(`availability.5`, false);
                          setFieldValue(`availability.6`, false);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="availability"
                        render={(arrayHelpers) => (
                          <div>
                            <Grid
                              style={{ marginBottom: '1em' }}
                              container
                              direction="row"
                              alignItems="flex-start"
                              justifyContent="space-between"
                            >
                              {values.availability.map(
                                (av: any, ind: number) => {
                                  return (
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={moment()
                                        .isoWeekday(ind + 1)
                                        .format('dddd')}
                                      onChange={(ev: any) => {
                                        console.log(ev.target.value);
                                        setFieldValue(
                                          `availability.${ind}`,
                                          !values.availability[ind]
                                        );
                                      }}
                                      checked={values.availability[ind]}
                                      onBlur={handleBlur}
                                    />
                                  );
                                }
                              )}
                            </Grid>
                          </div>
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}></Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4}>
                <Paper style={{ padding: '24px', marginBottom: '2em' }}>
                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <div>
                      <Typography variant="h6">Preview</Typography>
                    </div>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ paddingBottom: 0 }}></Grid>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '24px -24px 24px',
                    }}
                  ></div>

                  <Grid
                    style={{ marginBottom: '1em' }}
                    container
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <div>
                      <Typography variant="h6">Test</Typography>
                    </div>
                  </Grid>

                  <div
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      margin: '24px -24px 24px',
                    }}
                  ></div>
                </Paper>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Container>
  );
}
