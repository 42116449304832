import feathers from '../../modules/feathers';

export function getSubscription(customerId: String) {
  return feathers.service('subscriptions').find({
    query: {
      customerId,
    },
  });
}

export function changePaymentMethod(
  subscriptionid: String,
  paymentMethod: String
) {
  return feathers.service('subscriptions').patch(subscriptionid, {
    paymentMethod: paymentMethod,
  });
}
