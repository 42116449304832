import { createSlice } from '@reduxjs/toolkit';
import {
  getPostalCodesTable,
  getPostalCode,
  createPostalCode,
  patch,
} from '../../api/postal-codes';

const initialState = {
  error: null,

  postalCode: null,
  postalCodeLoading: true,
  postalCodeError: null,

  postalCodeUpdating: false,
  postalCodeUpdateError: false,

  postalCodesLoading: true,
  postalCodes: {
    data: [],
    total: 0,
    limit: 0,
    skip: 0,
  },
};

const postalCodesSlice = createSlice({
  name: 'postalCodes',
  initialState,
  reducers: {
    fetchPostalCodesSuccess: (state, action) => {
      console.log(action);

      state.postalCodes = action.payload.data
        ? action.payload.data
        : action.payload;

      state.postalCodesLoading = false;
    },
    fetchPostalCodesFailed: (state, action) => {
      console.log('Failed fetchPostalCodes');
      console.log(action);
      state.error = action.payload;
    },

    setPostalCodeLoading: (state, action) => {
      state.postalCodeLoading = action.payload;
    },

    setPostalCodesLoading: (state, action) => {
      state.postalCodesLoading = action.payload;
    },

    setPostalCodeUpdating: (state, action) => {
      state.postalCodeUpdating = action.payload;
    },

    fetchPostalCodeSuccess: (state, action) => {
      state.postalCode = action.payload;
      state.postalCodeLoading = false;
    },

    unsetPostalCode: (state) => {
      state.postalCode = null;
      state.postalCodeLoading = true;
      state.postalCodeError = null;
    },
  },
});

export const {
  fetchPostalCodesSuccess,
  fetchPostalCodesFailed,
  setPostalCodeLoading,
  fetchPostalCodeSuccess,
  unsetPostalCode,
  setPostalCodesLoading,
} = postalCodesSlice.actions;

export default postalCodesSlice.reducer;

export const fetchPostalCodes =
  (filters: Object | undefined = undefined, searchText: String | undefined) =>
  async (dispatch: Function) => {
    console.log('fetchPostalCodes');
    try {
      dispatch(setPostalCodesLoading(true));
      const i = await getPostalCodesTable(filters, searchText);
      console.log(i);
      dispatch(fetchPostalCodesSuccess(i));
    } catch (error) {
      console.log('Catch faild Ingredients');
      console.log(error);
      dispatch(fetchPostalCodesFailed(error));
      dispatch(setPostalCodesLoading(false));
    }
  };

export const fetchPostalCode = (id: string) => async (dispatch: Function) => {
  console.log('fetchPostalCodes');
  try {
    dispatch(setPostalCodeLoading(true));
    const i = await getPostalCode(id);
    console.log(i);
    dispatch(fetchPostalCodeSuccess(i));
  } catch (error) {
    console.log('Catch faild Postal Code');
    console.log(error);
    // dispatch(fetchPostalCodesFailed(error));
  } finally {
    dispatch(setPostalCodeLoading(false));
  }
};

export function addPostalCode(
  postalCode: any,
  setSubmitting: Function,
  history: any
) {
  return async function (dispatch: Function) {
    try {
      const p = await createPostalCode(postalCode);

      console.log(p);

      history.push('/settings/postal-codes');
    } catch (error) {
      console.log(error);
      // dispatch(getProductsFailed(error));
    } finally {
      setSubmitting(false);
    }
  };
}

export const patchPostalCode =
  (id: string, postalCode: any, setSubmitting: Function, history: any) =>
  async (dispatch: Function) => {
    console.log('patchPostalCode');
    try {
      dispatch(setPostalCodeLoading(true));
      const i = await patch(id, postalCode);
      console.log(i);
      dispatch(fetchPostalCodesSuccess(i));
      history.push('/settings/postal-codes');
    } catch (error) {
      console.log('Catch faild Postal Code');
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };
