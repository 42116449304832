import feathers from '../../modules/feathers';

export function createPostalCode(postalCode: Object) {
  return feathers.service('postal-codes').create(postalCode);
}

export function getPostalCode(id?: String) {
  console.log('IN');
  return feathers.service('postal-codes').get(id);
}

export function getPostalCodesTable(
  filters: Object | undefined,
  searchText: String | undefined
) {
  let query: any = {};
  // asd
  if (searchText) {
    //@ts-ignore
    query = {
      search: searchText,
    };
  }

  if (filters) {
    query['$sort'] = filters;
  }

  console.log(query);

  return feathers.service('postal-codes').find({ query });
}

export function patch(id: string, data: any) {
  console.log('Going in patch');
  return feathers.service('postal-codes').patch(id, data);
}

export function searchPostalCodes(search: string) {
  return feathers.service('postal-codes').find({
    query: {
      search,
    },
  });
}
