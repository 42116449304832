import React, { useEffect, useRef, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';

import {
  Grid,
  Box,
  Button,
  Typography,
  Paper,
  IconButton,
  TextField,
  Checkbox,
  InputAdornment,
  Divider,
  Avatar,
  Menu,
  MenuItem,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withMobileDialog,
} from '@material-ui/core';
import NestedMenuItem from 'material-ui-nested-menu-item';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { VariantType, useSnackbar } from 'notistack';

import NightsStayIcon from '@material-ui/icons/NightsStay';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NoteIcon from '@material-ui/icons/Note';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsIcon from '@material-ui/icons/Directions';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import SearchIcon from '@material-ui/icons/Search';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import './Fulfillment.scss';
import { getDeliveryData } from '../../slices/orders/ordersSlice';
import moment from 'moment';
import feathers from '../../modules/feathers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import _, { groupBy } from 'lodash';
import { fetchStaff } from '../../slices/users/usersSlice';
import StatusChangeDialog from './StatusChangeDialog';
import getDeliveryStatusNiceName from '../../modules/getDeliveryStatusNiceName';

const MultipleStopIcon = require('../../assets/images/multiple_stop.svg');
const vittleImage = require('../../assets/images/vittle-profile-admin.png');
const FilterIcon = require('../../assets/images/filter_icon.svg');

//@ts-ignore
//@ts-ignore
function DeliveryMap(props: {
  selectedDelivery: Object | null;
  strokeColor: string | null;
}) {
  const ref = useRef();

  const deliveryData: Array<any> = useSelector(
    (state: RootStateOrAny) => state.orders.deliveryData
  );

  useEffect(() => {
    try {
      //@ts-ignore
      //@ts-ignore
      const map = new window.google.maps.Map(ref.current, {
        zoom: 17,
        center: { lat: 45.3780708, lng: -75.672641 },
      });

      //@ts-ignore
      //@ts-ignore
      const directionsService = new window.google.maps.DirectionsService();

      //@ts-ignore
      //@ts-ignore
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        polylineOptions: { strokeColor: props.strokeColor },
      });

      directionsRenderer.setMap(map);

      directionsService
        .route({
          //@ts-ignore
          origin: new google.maps.LatLng(45.3780708, -75.6705891),
          //@ts-ignore
          destination: new google.maps.LatLng(45.3780708, -75.6905891),
          // waypoints: [
          //   {
          //     //@ts-ignore
          //     location: new google.maps.LatLng(45.380854, -75.673754),
          //     stopover: true,
          //   },
          //   {
          //     //@ts-ignore
          //     location: new google.maps.LatLng(45.3777657, -75.6786357),
          //     stopover: true,
          //   },
          // ],
          optimizeWaypoints: true,
          //@ts-ignore
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((response: any) => {
          directionsRenderer.setDirections(response);
        })
        .catch((e: any) =>
          window.alert('Directions request failed due to ' + e.status)
        );
    } catch (error) {
      console.log('In here');
      console.log(error);
    }
  }, []);

  //@ts-ignore
  return <div ref={ref} id="map" />;
}

export default function (props: any) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const user: any = useSelector((state: RootStateOrAny) => state.auth.user);

  const [menuPosition, setMenuPosition] = useState<any>(null);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [viewOrderDialogOpen, setViewOrderDialogOpen] = useState(false);
  const [selectedStatusChangeToType, setSelectedStatusChangeToType] =
    useState('');
  const [selectedDriverId, setSelectedDriverId] = useState('');
  const [selectedOrderData, setSelectedOrderData] = useState<any>(null);

  const handleMoreIconClick = (
    event: React.MouseEvent,
    order: any,
    driverId: string
  ) => {
    if (menuPosition) {
      return;
    }

    event.preventDefault();

    setSelectedDriverId(driverId);

    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });

    setSelectedOrderData({
      orderId: order._id,
      customer: order.customer,
      delivery: order.delivery,
      address: order.address,
      lineItems:
        order.lineItemsByDay[
          moment().subtract(1, 'd').isoWeekday() === 7
            ? 0
            : moment().subtract(1, 'd').isoWeekday() - 1
        ],
      totalLineItems: order.lineItemsForTheDayTotalQuantity,
    });
  };

  const handleItemClick = (event: React.MouseEvent) => {
    console.log(event.target);
  };

  const handleStatusChangeDialogOpen = async (
    event: React.MouseEvent,
    type: string
  ) => {
    console.log(event.target);

    console.log('IN MENU ITEM');
    console.log(type);

    setSelectedStatusChangeToType(type);
    // setSelectedOrderData(orderData);
    setStatusDialogOpen(true);
  };

  const handleStatusChange = async (
    message: string | undefined,
    image: any | undefined = undefined
  ) => {
    console.log('In handle status change');

    let data: any = {
      type: selectedStatusChangeToType,
      orderItemsTotal: selectedOrderData.totalLineItems,
      customer: selectedOrderData.customer,
      delivery: selectedOrderData.delivery,
      lineItems: selectedOrderData.lineItems,
      address: selectedOrderData.address,
      deliveryDriver: user.firstName,
      dayIndex:
        moment('2022-09-05').subtract(1, 'd').isoWeekday() === 7
          ? 0
          : moment('2022-09-05').subtract(1, 'd').isoWeekday() - 1,
      message,
    };

    if (image) {
      data.image = image;
    }

    try {
      const orderPatched = await feathers
        .service('orders')
        .patch(selectedOrderData.orderId, data, {
          query: {
            deliveryStatusChange: true,
          },
        });
    } catch (error) {
      console.log(error);
    }

    setStatusDialogOpen(false);
    setMenuPosition(null);
    enqueueSnackbar(
      'Status updated to ' +
        getDeliveryStatusNiceName(selectedStatusChangeToType),
      {
        variant: 'success',
      }
    );
  };

  const deliveryData: Array<any> = useSelector(
    (state: RootStateOrAny) => state.orders.deliveryData
  );

  const deliveryDataGroupedBySubscription: any = useSelector(
    (state: RootStateOrAny) => {
      console.log('here');
      if (state.orders.deliveryData.length == 0) {
        return [];
      }

      if (state.orders.deliveryDataLoading) {
        return [];
      }

      console.log(
        groupBy(state.orders.deliveryData, (e: any) => e.subscriptionId)
      );

      return groupBy(state.orders.deliveryData, (e: any) => e.subscriptionId);
    }
  );

  const deliveryDataGroupedByDeliveryDriver: any = useSelector(
    (state: RootStateOrAny) => {
      console.log('here');
      if (state.orders.deliveryData.length == 0) {
        return [];
      }

      if (state.orders.deliveryDataLoading) {
        return [];
      }

      console.log('Delivery data');
      console.log(state.orders.deliveryData);

      console.log(
        groupBy(state.orders.deliveryData, (e: any) => {
          return e.assignedTo;
        })
      );

      return groupBy(state.orders.deliveryData, (e: any) => e.assignedTo);
    }
  );

  const deliveryDataLoading: boolean = useSelector(
    (state: RootStateOrAny) => state.orders.deliveryDataLoading
  );

  const staffLoading: boolean = useSelector(
    (state: RootStateOrAny) => state.users.staffLoading
  );

  const staff: any = useSelector((state: RootStateOrAny) => state.users.staff);

  useEffect(() => {
    dispatch(fetchStaff());

    dispatch(
      getDeliveryData(
        moment('2022-09-05').startOf('isoWeek').format('YYYY-MM-DD'), // weekStartDate
        moment('2022-09-05').subtract(1, 'd').format('YYYY-MM-DD') // today's date
      )
    );
  }, []);

  useEffect(() => {
    if (!staffLoading) {
      console.log(staff);
    }
  }, [staffLoading]);

  const reorder = (list: any, startIndex: any, endIndex: any): Array<any> => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);

    return list;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? 'lightblue' : 'inherit',
  });

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder([], result.source.index, result.destination.index);
  };

  const [checkboxSelectedNumber, setCheckboxSelectedNumber] = useState(0);
  const [massNotifyDialogOpen, setMassNotifyDialogOpen] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);

  const [currentListBeingEdited, setCurrentListBeingEdited] = useState('glenn');
  const [currentListOpen, setCurrentListOpen] = useState('glenn');
  const [selectedDelivery, setSelectedDelivery] = useState(null);

  return (
    <div style={{ position: 'relative' }}>
      <DeliveryMap strokeColor="#8b0013" selectedDelivery={selectedDelivery} />

      <Paper elevation={2} className="fulfillment-sidebar">
        {!deliveryDataLoading && !staffLoading && (
          <Box paddingX={2} paddingY={3}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Box fontWeight="fontWeightMedium" fontSize="h5.fontSize">
                Fulfillment
              </Box>
              <IconButton onClick={() => {}}>
                <img src={FilterIcon} />
              </IconButton>
            </Box>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ fontWeight: 'normal' }}
            >
              {moment().format('dddd, MMMM D, YYYY')}
            </Typography>

            <Box display="flex" width="100%" mt={2} flexWrap={'wrap'}>
              {/* <Box
              display="flex"
              alignItems="center"
              mr={1}
              style={{ fontSize: '14px' }}
            >
              <DirectionsIcon
                color="primary"
                style={{ marginRight: '4px', fontSize: '18px' }}
              />
              3 routes
            </Box> */}
              <Box
                display="flex"
                alignItems="center"
                mr={1}
                style={{ fontSize: '14px' }}
              >
                <img
                  src={MultipleStopIcon}
                  className="icon-primary"
                  style={{ marginRight: '4px', fontSize: '18px' }}
                />{' '}
                {
                  deliveryData.filter(
                    (e: any) => e.delivery.type === 'delivery'
                  ).length
                }
                {deliveryData.filter((e: any) => e.delivery.type === 'delivery')
                  .length > 1
                  ? ' deliveries'
                  : ' delivery'}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ fontSize: '14px' }}
                mr={1}
              >
                <DirectionsCarIcon
                  color="primary"
                  style={{ marginRight: '4px', fontSize: '18px' }}
                />{' '}
                3 drivers
              </Box>
              <Box
                display="flex"
                alignItems="center"
                mr={1}
                style={{ fontSize: '14px' }}
              >
                <DirectionsWalkIcon
                  color="primary"
                  style={{ marginRight: '4px', fontSize: '18px' }}
                />
                {console.log(deliveryDataGroupedBySubscription)}
                {
                  deliveryData.filter((e: any) => e.delivery.type === 'pickup')
                    .length
                }
                {deliveryData.filter((e: any) => e.delivery.type === 'pickup')
                  .length > 1
                  ? ' pick ups'
                  : ' pick up'}
              </Box>
            </Box>
          </Box>
        )}

        <Divider />

        <Box paddingX={2} paddingY={2}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                autoFocus
                id="name"
                margin="normal"
                disabled
                label="Search fulfillment"
                type="text"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {deliveryData.findIndex((e: any) => e.delivery.type === 'pickup') >=
          0 && (
          <ExpansionPanel
            defaultExpanded={false}
            expanded={currentListOpen === 'vittle'}
            onClick={(e: any) => setCurrentListOpen('vittle')}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="fulfillment-delivery-driver"
            >
              <Box className="fulfillment-item">
                <div className="fulfillment-item__info">
                  <Avatar
                    src={vittleImage}
                    className="fulfillment-item__avatar color-1"
                  >
                    V
                  </Avatar>

                  <div className="fulfillment-item__text">
                    <Typography>Vittle</Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>1</strong> of <strong>1</strong> pickup
                    </Typography>
                  </div>
                </div>
                <IconButton
                  onClick={(e: any) => {
                    setCurrentListOpen('vittle');
                    setCurrentListBeingEdited('vittle');
                    e.stopPropagation();
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Box>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ul className="fulfillment-route-list">
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {deliveryData
                          .filter((e: any) => e.delivery.type === 'pickup')
                          .map((order: any, index: number) => (
                            <Draggable
                              key={`item-${order._id}`}
                              draggableId={`item-${order._id}`}
                              index={order._id}
                            >
                              {(provided, snapshot) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  className={`fulfillment-route-item ${
                                    order.status === 'complete'
                                      ? 'fulfillment-route-item--complete'
                                      : 'fulfillment-route-item--incomplete'
                                  }`}
                                >
                                  <div>
                                    <div className="fulfillment-route-item__icon color-pickup">
                                      {currentListBeingEdited === 'vittle' ? (
                                        <div className="fulfillment-route-item__checkbox-area">
                                          <DragIndicatorIcon className="fulfillment-route-item__drag-handle" />
                                          <Checkbox
                                            onChange={(event: any) => {
                                              console.log(event.target.checked);

                                              if (
                                                checkboxSelectedNumber === 1
                                              ) {
                                                setCheckboxSelectedNumber(0);
                                              } else if (1) {
                                              }

                                              setCheckboxSelectedNumber(
                                                (prevState) => prevState + 1
                                              );
                                            }}
                                          />
                                        </div>
                                      ) : order.status === 'complete' ? (
                                        <CheckIcon />
                                      ) : index + 1 < 10 ? (
                                        <span>{'0' + (index + 1)}</span>
                                      ) : (
                                        <span>{index + 1}</span>
                                      )}
                                    </div>

                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      width="100%"
                                    >
                                      <Typography variant="body1">{`${order.customer.firstName} ${order.customer.lastName}`}</Typography>

                                      <Box
                                        display="flex"
                                        flexDirection="row"
                                        mt={1}
                                        width="100%"
                                      >
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          mr={1}
                                        >
                                          {order.delivery.time ===
                                          'nightBefore' ? (
                                            <>
                                              <NightsStayIcon
                                                style={{
                                                  color: '#bbb',
                                                  marginRight: '6px',
                                                }}
                                              />
                                              <Typography>
                                                Night before
                                              </Typography>
                                            </>
                                          ) : (
                                            <>
                                              <WbSunnyIcon
                                                fontSize="small"
                                                style={{
                                                  marginRight: '6px',
                                                  color: '#bbb',
                                                }}
                                                color="disabled"
                                              />
                                              <Typography color="textSecondary">
                                                Day of
                                              </Typography>
                                            </>
                                          )}
                                        </Box>
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          mr={1}
                                        >
                                          •
                                        </Box>

                                        <Typography
                                          variant="body1"
                                          color="textSecondary"
                                        >
                                          {
                                            order.lineItemsForTheDayTotalQuantity
                                          }{' '}
                                          item
                                          {order.lineItemsForTheDayTotalQuantity >
                                          1
                                            ? 's'
                                            : ''}
                                        </Typography>
                                      </Box>

                                      <Box></Box>
                                    </Box>
                                  </div>

                                  <div className="fulfillment-route-item__more-options-area">
                                    <>
                                      {order.address.notes !== '' && (
                                        <IconButton>
                                          <NoteIcon />
                                        </IconButton>
                                      )}
                                      <IconButton
                                        onClick={(e: any) =>
                                          handleMoreIconClick(e, order, '')
                                        }
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        open={!!menuPosition}
                                        onClose={() => setMenuPosition(null)}
                                        anchorReference="anchorPosition"
                                        anchorPosition={menuPosition}
                                      >
                                        <NestedMenuItem
                                          label="Status"
                                          parentMenuOpen={!!menuPosition}
                                          onClick={handleItemClick}
                                        >
                                          <MenuItem
                                            onClick={(e) =>
                                              handleStatusChangeDialogOpen(
                                                e,
                                                'ready-for-pickup'
                                              )
                                            }
                                          >
                                            Ready for pickup
                                          </MenuItem>
                                          <MenuItem
                                            onClick={(e) =>
                                              handleStatusChangeDialogOpen(
                                                e,
                                                'picked-up'
                                              )
                                            }
                                          >
                                            Picked up
                                          </MenuItem>
                                          <MenuItem
                                            onClick={(e) =>
                                              handleStatusChangeDialogOpen(
                                                e,
                                                'not-picked-up'
                                              )
                                            }
                                          >
                                            Not picked up
                                          </MenuItem>
                                          <MenuItem
                                            onClick={(e) =>
                                              handleStatusChangeDialogOpen(
                                                e,
                                                'delayed'
                                              )
                                            }
                                          >
                                            Delayed
                                          </MenuItem>
                                        </NestedMenuItem>

                                        <NestedMenuItem
                                          label="Re-assign"
                                          parentMenuOpen={!!menuPosition}
                                          onClick={handleItemClick}
                                        >
                                          <MenuItem onClick={handleItemClick}>
                                            Alistair
                                          </MenuItem>
                                          <MenuItem onClick={handleItemClick}>
                                            Test
                                          </MenuItem>
                                        </NestedMenuItem>
                                      </Menu>
                                    </>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        {Object.keys(deliveryDataGroupedByDeliveryDriver).map(
          (key: string, index: number) => {
            const driver: any = staff.data.find((e: any) => e._id === key);

            return (
              <ExpansionPanel
                defaultExpanded={false}
                expanded={currentListOpen === driver._id}
              >
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="fulfillment-delivery-driver"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setCurrentListOpen((prevState) =>
                      prevState === driver._id ? '' : driver._id
                    );
                  }}
                >
                  <Box className="fulfillment-item">
                    <div className="fulfillment-item__info">
                      <Avatar className="fulfillment-item__avatar color-1">
                        {driver.firstName.charAt(0)}
                        {driver.lastName.charAt(0)}
                      </Avatar>

                      <div className="fulfillment-item__text">
                        <Typography>
                          {driver.firstName} {driver.lastName}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {/* <strong>2</strong> of{' '} */}
                          <strong>
                            {
                              deliveryDataGroupedByDeliveryDriver[key].filter(
                                (e: any) => e.delivery.type === 'delivery'
                              ).length
                            }
                          </strong>{' '}
                          deliveries
                        </Typography>
                      </div>
                    </div>
                    <IconButton
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setCurrentListBeingEdited(driver._id);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ul className="fulfillment-route-list">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided: any, snapshot: any) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                          >
                            {deliveryDataGroupedByDeliveryDriver[key]
                              .filter(
                                (e: any) => e.delivery.type === 'delivery'
                              )
                              .map((order: any, index: number) => {
                                return (
                                  <Draggable
                                    key={`item-${order._id}`}
                                    draggableId={`item-${order._id}`}
                                    index={order._id}
                                  >
                                    {(provided, snapshot) => (
                                      <li
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                        className={`fulfillment-route-item ${
                                          order.status === 'complete'
                                            ? 'fulfillment-route-item--complete'
                                            : 'fulfillment-route-item--incomplete'
                                        }`}
                                      >
                                        <div>
                                          <div className="fulfillment-route-item__icon color-1">
                                            {currentListBeingEdited ===
                                            'vittle' ? (
                                              <div className="fulfillment-route-item__checkbox-area">
                                                <DragIndicatorIcon className="fulfillment-route-item__drag-handle" />
                                                <Checkbox
                                                  onChange={(event: any) => {
                                                    console.log(
                                                      event.target.checked
                                                    );

                                                    if (
                                                      checkboxSelectedNumber ===
                                                      1
                                                    ) {
                                                      setCheckboxSelectedNumber(
                                                        0
                                                      );
                                                    } else if (1) {
                                                    }

                                                    setCheckboxSelectedNumber(
                                                      (prevState) =>
                                                        prevState + 1
                                                    );
                                                  }}
                                                />
                                              </div>
                                            ) : order.status === 'complete' ? (
                                              <CheckIcon />
                                            ) : index + 1 < 10 ? (
                                              <span>{'0' + (index + 1)}</span>
                                            ) : (
                                              <span>{index + 1}</span>
                                            )}
                                          </div>

                                          <Box
                                            display="flex"
                                            flexDirection="column"
                                            width="100%"
                                          >
                                            <Typography variant="body1">{`${order.customer.firstName} ${order.customer.lastName}`}</Typography>
                                            <Typography
                                              variant="body1"
                                              className="fulfillment-route__name"
                                            >
                                              {order.address.organization
                                                .length > 0 && (
                                                <>
                                                  {order.address.organization}{' '}
                                                  <br />
                                                </>
                                              )}
                                              {order.address.Line1},{' '}
                                              {order.address.Line2}
                                              {order.address.Line1 ||
                                              order.address.Line2 ? (
                                                <br />
                                              ) : (
                                                ''
                                              )}
                                              {order.address.City},{' '}
                                              {order.address.Province}{' '}
                                              {order.address.PostalCode}
                                              {order.address.buzzer !== '' && (
                                                <>
                                                  <span>
                                                    <br />
                                                    Buzzer:{' '}
                                                    {order.address.buzzer}
                                                  </span>
                                                </>
                                              )}
                                            </Typography>
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              mt={1}
                                              width="100%"
                                            >
                                              {/* <a href="#">
                                        <Typography variant="body2">
                                          Order No. {order._id}
                                        </Typography>
                                      </a> */}
                                              {/* <span style={{ margin: '0 5px' }}>•</span> */}
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                mr={1}
                                              >
                                                {order.delivery.time ===
                                                'nightBefore' ? (
                                                  <>
                                                    <NightsStayIcon
                                                      className="fulfillment-item__delivery-time-icon"
                                                      style={{
                                                        marginRight: '8px',
                                                      }}
                                                    />
                                                    <Typography>
                                                      Night before
                                                    </Typography>
                                                  </>
                                                ) : (
                                                  <>
                                                    <WbSunnyIcon
                                                      fontSize="small"
                                                      style={{
                                                        marginRight: '8px',
                                                      }}
                                                      className="fulfillment-item__delivery-time-icon"
                                                    />
                                                    <Typography color="textSecondary">
                                                      Day of
                                                    </Typography>
                                                  </>
                                                )}
                                              </Box>
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                                mr={1}
                                              >
                                                •
                                              </Box>

                                              <Typography
                                                variant="body1"
                                                color="textSecondary"
                                              >
                                                {
                                                  order.lineItemsForTheDayTotalQuantity
                                                }{' '}
                                                item
                                                {order.lineItemsForTheDayTotalQuantity >
                                                1
                                                  ? 's'
                                                  : ''}
                                              </Typography>
                                            </Box>

                                            <Box></Box>
                                          </Box>
                                        </div>

                                        <div className="fulfillment-route-item__more-options-area">
                                          <>
                                            {order.address.notes !== '' && (
                                              <IconButton>
                                                <NoteIcon />
                                              </IconButton>
                                            )}
                                            <IconButton
                                              onClick={(e: any) =>
                                                handleMoreIconClick(
                                                  e,
                                                  order,
                                                  key
                                                )
                                              }
                                            >
                                              <MoreVertIcon />
                                            </IconButton>
                                          </>
                                        </div>
                                      </li>
                                    )}
                                  </Draggable>
                                );
                              })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </ul>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          }
        )}
      </Paper>

      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        style={{
          minWidth: '200px !important',
        }}
      >
        <Box pl={2} pb={1}>
          <Typography variant="button">Status</Typography>
        </Box>

        <Divider />

        {selectedOrderData?.delivery.type === 'delivery' ? (
          <>
            {' '}
            <MenuItem
              onClick={(e) => handleStatusChangeDialogOpen(e, 'in-transit')}
            >
              <span className="status-circle status--in-transit"></span>{' '}
              In-transit
            </MenuItem>
            <MenuItem
              onClick={(e) => handleStatusChangeDialogOpen(e, 'delivered')}
            >
              <span className="status-circle status--delivered"></span>{' '}
              Delivered
            </MenuItem>
            <MenuItem
              onClick={(e) => handleStatusChangeDialogOpen(e, 'not-delivered')}
            >
              <span className="status-circle status--not-delivered"></span>
              Not delivered
            </MenuItem>
          </>
        ) : (
          <>
            {' '}
            <MenuItem
              onClick={(e) =>
                handleStatusChangeDialogOpen(e, 'ready-for-delivery')
              }
            >
              <span className="status-circle status--in-transit"></span> Ready
              for delivery
            </MenuItem>
            <MenuItem
              onClick={(e) => handleStatusChangeDialogOpen(e, 'delivered')}
            >
              <span className="status-circle status--delivered"></span> Picked
              up
            </MenuItem>
            <MenuItem
              onClick={(e) => handleStatusChangeDialogOpen(e, 'not-delivered')}
            >
              <span className="status-circle status--not-delivered"></span>
              Not picked up
            </MenuItem>
          </>
        )}

        <MenuItem onClick={(e) => handleStatusChangeDialogOpen(e, 'delayed')}>
          <span className="status-circle status--delayed"></span> Delayed
        </MenuItem>

        <Divider />

        <Box pl={2} pt={1} pb={1}>
          <Typography variant="button">Order</Typography>
        </Box>

        <Divider />

        <MenuItem onClick={(e: any) => setViewOrderDialogOpen(true)}>
          View order
        </MenuItem>

        {selectedOrderData?.delivery.type !== 'pickup' && (
          <>
            <Divider />

            <Box pl={2} pb={1} pt={1}>
              <Typography variant="button">Fulfillment</Typography>
            </Box>

            <Divider />

            <NestedMenuItem
              label="Re-assign"
              parentMenuOpen={!!menuPosition}
              onClick={handleItemClick}
            >
              {staff.data
                .filter((e: any) => e._id !== selectedDriverId)
                .map((e: any) => (
                  <MenuItem onClick={(e) => console.log('Test')}>
                    {e.firstName} {e.lastName}
                  </MenuItem>
                ))}
            </NestedMenuItem>
          </>
        )}
      </Menu>

      {checkboxSelectedNumber > 0 && (
        <div className="fulfillment-items-selected">
          <p>3 items selected</p>

          <Button>Edit</Button>
        </div>
      )}

      <StatusChangeDialog
        open={statusDialogOpen}
        handleClose={() => setStatusDialogOpen(false)}
        handleStatusChange={handleStatusChange}
        name={
          selectedOrderData !== null
            ? selectedOrderData.customer.firstName +
              ' ' +
              selectedOrderData.customer.lastName
            : ''
        }
        statusChangeTo={selectedStatusChangeToType}
      />

      <Dialog
        fullScreen={false}
        open={massNotifyDialogOpen}
        onClose={() => setMassNotifyDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Mass notify deliveries
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {checkboxSelectedNumber} deliver
            {checkboxSelectedNumber > 1 ? 'ies' : 'y'} selected
          </DialogContentText>
          <List style={{ marginTop: '1em' }}>
            <ListItem
              style={{ cursor: 'pointer' }}
              className="status--in-transit"
              button
              // onClick={() => this.handleStatusChangeDialogOpen('In-Transit', null, true)}
            >
              <span className="status-circle" />
              <ListItemText primary="In-Transit" />
            </ListItem>
            <Divider />
            <ListItem
              style={{ cursor: 'pointer' }}
              className="status--delayed"
              button
            >
              <span className="status-circle " />
              <ListItemText primary="Delayed" />
            </ListItem>
            <Divider />
            <ListItem
              style={{ cursor: 'pointer' }}
              className="status--not-delivered"
              button
            >
              <span className="status-circle" />
              <ListItemText primary="Not delivered" />
            </ListItem>
            <Divider />
            <ListItem
              style={{ cursor: 'pointer' }}
              className="status--delivered"
              button
            >
              <span className="status-circle" />
              <ListItemText primary="Delivered" />
            </ListItem>
            <Divider />
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setMassNotifyDialogOpen(false)}
            color="default"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={false}
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Assign selected delivery
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {checkboxSelectedNumber} deliver
            {checkboxSelectedNumber > 1 ? 'ies' : 'y'} selected
          </DialogContentText>
          <List style={{ marginTop: '1em' }}>
            <ListItem style={{ cursor: 'pointer' }} button key="2137">
              <ListItemText primary={'Unassigned'} />
            </ListItem>
            <Divider />

            <ListItem
              style={{ cursor: 'pointer' }}
              // key={e._id}
              button
              // onClick={() => this.handleDeliveryAssign(e._id)}
            >
              <ListItemText primary={'Glenn'} />
            </ListItem>
            <Divider />

            <ListItem
              style={{ cursor: 'pointer' }}
              // key={e._id}
              button
              // onClick={() => this.handleDeliveryAssign(e._id)}
            >
              <ListItemText primary={'Tyler'} />
            </ListItem>
            <Divider />

            <ListItem
              style={{ cursor: 'pointer' }}
              // key={e._id}
              button
              // onClick={() => this.handleDeliveryAssign(e._id)}
            >
              <ListItemText primary={'Chris'} />
            </ListItem>
            <Divider />
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssignDialogOpen(false)} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={false}
        maxWidth="sm"
        fullWidth
        open={viewOrderDialogOpen}
        onClose={() => setViewOrderDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {selectedOrderData !== null && (
            <>
              Order for{' '}
              {selectedOrderData.customer.firstName +
                ' ' +
                selectedOrderData.customer.lastName}{' '}
            </>
          )}
        </DialogTitle>
        <DialogContent>
          {selectedOrderData !== null && (
            <List>
              {selectedOrderData.lineItems.map((item: any, index: number) => (
                <ListItem>{item.title}</ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewOrderDialogOpen(false)} color="default">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
