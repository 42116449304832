import feathers from '../../modules/feathers';

export function getPaymentMethods(stripeCustomerId: String) {
  return feathers.service('/stripe/payment-method').find({
    query: {
      customer: stripeCustomerId,
      type: 'card'
    }
  });
}

export function deletePaymentMethod(paymentMethodId: String) {
  return feathers.service('/stripe/payment-method').remove(paymentMethodId);
}
