import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import socketio from '@feathersjs/socketio-client';
import authentication from '@feathersjs/authentication-client';

//@ts-ignore
const socket = io(process.env.REACT_APP_API_URL, {
  transports: ['websocket'],
  timeout: 20000,
});

const app = feathers();
app.configure(
  socketio(socket, {
    timeout: 40000,
  })
);

app.configure(authentication());

socket.on('connected', () => {
  console.log('Connected with SERVER');
});

export default app;
