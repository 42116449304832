import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from '../../rootReducer';

export default (props: any) => {
  const isAuthenticated: boolean = useSelector(
    (state: RootStateOrAny) => state.auth.isAuthenticated
  );

  const user: any = useSelector((state: RootStateOrAny) => state.auth.user);

  return (
    <Route
      {...props}
      render={(renderProps: any) => {
        console.log(renderProps);
        return isAuthenticated && user.roles.indexOf('admin') !== -1 ? (
          React.createElement(props.component, { ...props })
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              //@ts-ignore
              state: { referrer: currentLocation },
            }}
          />
        );
      }}
    />
  );
};
