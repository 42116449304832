import React from 'react';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import EditIcon from '@material-ui/icons/Edit';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import CloudIcon from '@material-ui/icons/Cloud';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Tooltip from '@material-ui/core/Tooltip';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Radio from '@material-ui/core/Radio';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { useStaticState, Calendar } from '@material-ui/pickers';
import currency from 'currency.js';

import './NewOrder.css';
import moment from 'moment';

export default function NewOrder(props: any) {
  // const classes = useStyles();

  const [value, handleDateChange] = React.useState(new Date());

  const { pickerProps, wrapperProps } = useStaticState({
    value,
    //@ts-ignore
    onChange: handleDateChange,
  });

  const options = ['Mark as pending', 'Send invoice'];
  const options2 = ['Mark as paid', 'Paid with Cash', 'Paid by e-Transfer'];

  const [open, setOpen] = React.useState(false);
  const [openPaid, setOpenPaid] = React.useState(false);

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const anchorRefPaid = React.useRef<HTMLDivElement>(null);

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [selectedIndexPaid, setSelectedIndexPaid] = React.useState(1);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    paid: boolean = false
  ) => {
    if (paid) {
      setSelectedIndexPaid(index);
      setOpenPaid(false);
      return;
    }

    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = (paid: boolean = false) => {
    if (paid) {
      setOpenPaid((prevOpen) => !prevOpen);
    } else {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose = (
    paid: boolean = false,
    event: React.MouseEvent<Document, MouseEvent>
  ) => {
    if (paid) {
      if (
        anchorRefPaid.current &&
        anchorRefPaid.current.contains(event.target as HTMLElement)
      ) {
        return;
      }

      setOpenPaid(false);
    } else {
      if (
        anchorRef.current &&
        anchorRef.current.contains(event.target as HTMLElement)
      ) {
        return;
      }
      setOpen(false);
    }
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            style={{
              marginTop: '1em',
            }}
          >
            <Link style={{ textDecoration: 'none' }} to="/">
              <Button style={{ textTransform: 'capitalize' }}>
                <KeyboardArrowLeftIcon /> <Typography>Dashboard</Typography>
              </Button>
            </Link>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ margin: '20px 0 40px' }}
      >
        <Box
          fontWeight="fontWeightMedium"
          fontSize="h4.fontSize"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            mb={2}
          >
            <Box display="flex" alignItems="center" flexDirection="row" mb={2}>
              <Box display="flex" flexDirection="column">
                Create order
              </Box>
            </Box>
          </Box>

          <Box>
            <Button style={{ marginRight: '1em' }}>
              Save as draft
              <CloudIcon style={{ fill: '#9b9b9b', marginLeft: '10px' }} />
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper style={{ padding: '24px 24px 0', marginBottom: '2em' }}>
            <Typography variant="h6">Order details</Typography>
            <Grid container alignItems="center" justifyContent="space-between">
              <Box display="flex" width="100%" alignItems="center">
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchIcon style={{ fill: '#9b9b9b' }} />,
                    endAdornment: (
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ backgroundColor: '#e0e0e0', border: 0 }}
                      >
                        Browse
                      </Button>
                    ),
                  }}
                  placeholder="Search products"
                />
              </Box>
            </Grid>
            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>
            <Grid style={{ marginBottom: '1em' }} container>
              <Box display="flex" flexDirection="column">
                <Typography variant="h6">Products</Typography>
                <Typography color="textSecondary" variant="body2">
                  2 items
                </Typography>
              </Box>
            </Grid>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <Box display="flex" alignItems="start">
                  <img
                    src="https://placehold.it/75x75/ccc"
                    style={{ marginRight: '16px' }}
                  />
                  <Box display="flex" flexDirection="column">
                    <Typography>Potato</Typography>
                    <Typography color="textSecondary" variant="body2">
                      1 bag
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  alignItems="flex-start"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Box>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px' }}
                    >
                      {currency(20).format()}
                    </Typography>
                    <Typography
                      align="right"
                      color="textSecondary"
                      variant="body2"
                      style={{ paddingRight: '8px' }}
                    >
                      Qty: 1
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Tooltip title="Edit">
                      <IconButton size="small">
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                      <IconButton size="small" style={{ marginTop: '6px' }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Divider style={{ marginBottom: '24px' }} />

            <Grid
              style={{ marginBottom: '1em' }}
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item xs={6}>
                <Box display="flex" alignItems="start">
                  <img
                    src="https://placehold.it/75x75/ccc"
                    style={{ marginRight: '16px' }}
                  />
                  <Box display="flex" flexDirection="column">
                    <Typography>Potato</Typography>
                    <Typography color="textSecondary" variant="body2">
                      1 bag
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  alignItems="flex-start"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Box>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px' }}
                    >
                      {currency(20).format()}
                    </Typography>
                    <Typography
                      align="right"
                      color="textSecondary"
                      variant="body2"
                      style={{ paddingRight: '8px' }}
                    >
                      Qty: 1
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Tooltip title="Edit">
                      <IconButton size="small">
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove">
                      <IconButton size="small" style={{ marginTop: '6px' }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>
            <Grid
              style={{ marginBottom: '2em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Summary</Typography>

              <Button>
                <CardGiftcardIcon style={{ marginRight: '8px' }} />
                Add gift card or discount
              </Button>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{ fontWeight: 'bold', paddingBottom: '4px' }}
                >
                  Notes
                </Typography>
                <TextField variant="outlined" multiline fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Subtotal</Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px' }}
                    >
                      {currency(20).format()}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Delivery fee</Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px' }}
                    >
                      {currency(20).format()}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">FREEDELIVERY2020</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Free delivery
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px', color: '#4caf50' }}
                    >
                      -{currency(10).format()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Tooltip title="Remove">
                      <IconButton size="small" style={{ marginTop: '-4px' }}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">BLACKFRIDAY10</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Get $10.00 off
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px', color: '#4caf50' }}
                    >
                      -{currency(10).format()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Tooltip title="Remove">
                      <IconButton size="small" style={{ marginTop: '-4px' }}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Divider style={{ marginBottom: '8px' }} />

                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Discount total</Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px', color: '#4caf50' }}
                    >
                      -{currency(20).format()}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Tax</Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ paddingRight: '8px' }}
                    >
                      {currency(20).format()}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider style={{ marginBottom: '8px' }} />

                <Grid
                  container
                  alignItems="flex-start"
                  style={{ marginBottom: '8px' }}
                >
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Gift card</Typography>
                    <Typography variant="body2" color="textSecondary">
                      •••45AS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      align="right"
                      style={{ paddingRight: '8px', color: '#4caf50' }}
                      variant="body1"
                    >
                      -{currency(20).format()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Tooltip title="Remove">
                      <IconButton size="small" style={{ marginTop: '-4px' }}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: '8px' }}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                      Order total
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography
                      align="right"
                      variant="body1"
                      style={{ fontWeight: 'bold', paddingRight: '8px' }}
                    >
                      {currency(20).format()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Payment</Typography>
            </Grid>

            <Box display="flex" justifyContent="flex-end">
              <ButtonGroup
                style={{ marginRight: '8px', boxShadow: 'none' }}
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
              >
                <Button>{options[selectedIndex]}</Button>
                <Button
                  size="small"
                  aria-controls={open ? 'split-button-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={(ev: any) => handleToggle()}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={(ev: any) => handleClose(false, ev)}
                      >
                        <MenuList id="split-button-menu">
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={index === selectedIndex}
                              onClick={(event) =>
                                handleMenuItemClick(event, index)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>

              <ButtonGroup
                style={{ marginRight: '8px', boxShadow: 'none' }}
                variant="contained"
                ref={anchorRefPaid}
                aria-label="split button"
              >
                <Button>{options2[selectedIndexPaid]}</Button>
                <Button
                  size="small"
                  aria-controls={openPaid ? 'split-button-menu' : undefined}
                  aria-expanded={openPaid ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={(ev: any) => handleToggle(true)}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                open={openPaid}
                anchorEl={anchorRefPaid.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={(ev: any) => handleClose(true, ev)}
                      >
                        <MenuList id="split-button-menu">
                          {options2.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={index === selectedIndexPaid}
                              onClick={(event) =>
                                handleMenuItemClick(event, index, true)
                              }
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>

              <Button
                color="secondary"
                variant="contained"
                style={{
                  boxShadow: 'none',
                }}
              >
                <CreditCardIcon style={{ marginRight: '8px' }} />
                Pay with credit card
              </Button>
            </Box>
            <div
              style={{
                borderTop: '1px solid transparent',
                margin: '24px -24px 24px',
              }}
            ></div>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper style={{ padding: '24px', marginBottom: '2em' }}>
            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Customer</Typography>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchIcon style={{ fill: '#9b9b9b' }} />,
                    endAdornment: (
                      <Button
                        variant="outlined"
                        size="small"
                        style={{ backgroundColor: '#e0e0e0', border: 0 }}
                      >
                        Add
                      </Button>
                    ),
                  }}
                  label="Search customer"
                />
              </Grid>
            </Grid>

            <div
              style={{
                borderTop: '1px solid rgba(0,0,0,0.12)',
                margin: '32px -24px 24px',
              }}
            ></div>

            <Grid
              style={{ marginBottom: '1em' }}
              container
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <div>
                <Typography variant="h6">Fulfillment</Typography>
              </div>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                Delivery date
              </Grid>
            </Grid>

            <Calendar
              disablePast
              shouldDisableDate={(day: any) => moment(day).day() !== 2}
              {...pickerProps}
              // onMonthChange={(date: any) => {
              //   console.log(date);
              //   return true;
              // }}
              maxDate={moment().endOf('month').add(1, 'month')}
            />

            <Grid container>
              <Grid item xs={12}>
                Delivery time
              </Grid>
            </Grid>

            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
              >
                <Box width="100%" display="flex" alignItems="center">
                  <LocalShippingIcon />

                  <Box width="100%" ml={1}>
                    <Typography
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      Delivery
                    </Typography>
                    <Typography
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        fontSize: '14px',
                        color: '#b9b2b6',
                      }}
                    >
                      2:30-6:30 p.m.
                    </Typography>
                  </Box>
                </Box>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Box width="100%" display="flex" alignItems="flex-start">
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <AccessTimeIcon style={{ marginRight: '6px' }} />
                      <Typography
                        style={{
                          marginTop: '0',
                        }}
                      >
                        2:30-4:00 p.m.
                      </Typography>
                    </Box>

                    <Box>
                      <span
                        className="color-primary-dark"
                        style={{ color: 'rgb(76, 175, 80)' }}
                      >
                        Free
                      </span>
                      <Radio
                        className="radio-green"
                        color="primary"
                        name="asd"
                      />
                    </Box>
                  </Box>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#000' }} />}
              >
                <Box width="100%" display="flex" alignItems="center">
                  <DirectionsWalkIcon />

                  <Box width="100%" ml={1}>
                    <Typography
                      style={{
                        marginTop: '0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingRight: '36px',
                      }}
                    >
                      <span>Pick up</span>
                      {/* <span>Free</span> */}
                    </Typography>
                    <Typography
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                      variant="body2"
                      color="textSecondary"
                    >
                      Vittle
                    </Typography>
                  </Box>
                </Box>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
                <Box width="100%" display="flex" alignItems="flex-start">
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <AccessTimeIcon style={{ marginRight: '6px' }} />
                      <Typography
                        style={{
                          marginTop: '0',
                        }}
                      >
                        <span>2:30-4:00 p.m.</span>

                        <Typography
                          style={{
                            marginTop: 0,
                            marginBottom: 0,
                          }}
                          variant="body2"
                          color="textSecondary"
                        >
                          Vittle
                        </Typography>
                      </Typography>
                    </Box>

                    <Box>
                      <span className="color-primary-dark">Free</span>
                      <Radio color="primary" name="asd" />
                    </Box>
                  </Box>
                </Box>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
