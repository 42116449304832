import feathers from '../../modules/feathers';
import { Category } from '../../slices/categories/categoriesSlice';

export function createCategory(Category: Category) {
  return feathers.service('categories').create(Category);
}

export function patchCategory(id: String, data: any) {
  return feathers.service('categories').patch(id, {
    ...data,
  });
}

export function removeCategory(id: String) {
  return feathers.service('categories').remove(id);
}

export function removeSubCategory(id: String, subCat: Object) {
  return feathers.service('categories').patch(id, {
    $pull: {
      subCats: subCat,
    },
  });
}

export function createSubCategory(id: String, subCat: Object) {
  return feathers.service('categories').patch(id, {
    $push: {
      subCats: subCat,
    },
  });
}

export function setCategoryAvailability(id: String, available: boolean) {
  return feathers.service('categories').patch(id, {
    available: available,
  });
}

export function getCategories() {
  return feathers.service('categories').find();
}
