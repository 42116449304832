import feathers from '../../modules/feathers';

export default {
  create: (data: any) => {
    return feathers.service('users').create(data);
  },

  find: (query: object | null = null) => {
    if (query) {
      console.log('going in query');
      return feathers.service('users').find({
        query: query,
      });
    }

    return feathers.service('users').find();
  },

  get: (id: string | null, params: any = undefined) => {
    if (params) {
      return feathers.service('users').get(id, params);
    }

    return feathers.service('users').get(id);
  },

  patch: (id: string, data: any) => {
    return feathers.service('users').patch(id, data);
  },
};
