import { createSlice } from '@reduxjs/toolkit';

import AuthService from '../../api/auth';
import getGreeting from '../../utils/getGreeting';
// import { getSecondaryAccounts } from './userReducer';
// import { getSubscription } from './subscriptionReducer';
// import { open } from './snackbarReducer';

// import { AppThunk, AppDispatch } from '../../store/store';

const initialState = {
  isAuthenticated: false,
  checkingAuth: true,
  user: {},
  roles: [],
  loginError: false,
};

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCheckingAuth: (state, action) => {
      state.checkingAuth = action.payload;
    },
    loginStart: (state) => {
      state.user = {};
      state.checkingAuth = true;
      state.isAuthenticated = false;
      state.loginError = false;
    },
    loginSuccess: (state, action) => {
      state.checkingAuth = false;
      state.user = action.payload.user;
      state.roles = action.payload.user.roles;
      state.isAuthenticated = true;
      state.loginError = false;
    },
    loginFailed: (state) => {
      state.user = {};
      state.checkingAuth = false;
      state.loginError = true;
      state.isAuthenticated = false;
    },
    logoutSuccess: (state) => {
      state.user = {};
      state.roles = [];

      state.loginError = false;
      state.isAuthenticated = false;
      state.checkingAuth = false;
    },
    alreadyLoggedIn: (state, action) => {
      state.user = action.payload;
      state.loginError = false;
      state.isAuthenticated = true;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailed,
  logoutSuccess,
  alreadyLoggedIn,
  setCheckingAuth,
} = authSlice.actions;

export default authSlice.reducer;

export const login =
  (
    email: string,
    password: string,
    setSubmitting: Function,
    enqueueSnackbar: Function,
    history: any
  ) =>
  async (dispatch: any) => {
    console.log('Calling');

    dispatch(loginStart());

    try {
      const res = await AuthService.login({
        email,
        password,
      });

      // console.log(res);

      dispatch(loginSuccess(res));

      if (setSubmitting) {
        setSubmitting(false);
        history.push('/');
        enqueueSnackbar(getGreeting() + ' ' + res.user.firstName, {
          variant: 'success',
        });
      }
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      dispatch(loginFailed());
      enqueueSnackbar('There was a problem logging in', {
        variant: 'error',
      });
    }

    console.log('yes');
  };

export const logout = (history: any) => async (dispatch: any) => {
  console.log('Calling logout');
  try {
    await AuthService.logout();
    dispatch(logoutSuccess());
    history.push('/sign-in');
  } catch (error) {
    console.log(error);
  }
};
