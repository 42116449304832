import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button';

import getGreeting from '../../utils/getGreeting';

import { Line, Bar } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 18,
    marginBottom: 25,
  },
  pos: {
    marginBottom: 12,
  },
  graphNumber: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  graphArrowPositive: {
    color: '#66bb6a',
  },
  graphArrowNegative: {
    color: '#ef5350',
  },
  graphArrow: {
    fontSize: 16,
    marginLeft: 10,
  },
  graphChange: {
    fontSize: 16,
  },
  graphChangePositive: {
    color: '#66bb6a',
  },
  graphChangeNegative: {},
});

export default function Dashboard(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const user: any = useSelector((state: RootStateOrAny) => state.auth.user);

  const negative = {
    borderColor: '#ef5350',
    backgroundColor: '#ffcdd2',
  };

  const positive = {
    backgroundColor: '#c8e6c9',
    borderColor: '#66bb6a',
  };

  const customersData = () => {
    return {
      labels: ['1', '2', '3', '4'],
      options: {
        axes: {
          display: false,
        },
      },
      datasets: [
        {
          // label: 'My First dataset',
          fill: true,
          backgroundColor: positive.backgroundColor,
          borderColor: positive.borderColor,
          // backgroundColor: negative.backgroundColor,
          // borderColor: negative.borderColor,
          pointBorderWidth: 0,
          pointRadius: 0,
          data: [0, 59, 10, 20],
        },
      ],
    };
  };

  const salesData = () => {
    return {
      labels: ['1', '2', '3', '4'],
      options: {
        axes: {
          display: false,
        },
      },
      datasets: [
        {
          // label: 'My First dataset',
          fill: true,
          backgroundColor: positive.backgroundColor,
          borderColor: positive.borderColor,
          // backgroundColor: negative.backgroundColor,
          // borderColor: negative.borderColor,
          pointBorderWidth: 0,
          pointRadius: 0,
          data: [0, 5, 15, 25],
        },
      ],
    };
  };

  const signupsData = () => {
    return {
      labels: ['1', '2', '3', '4'],
      options: {
        axes: {
          display: false,
        },
      },
      datasets: [
        {
          // label: 'My First dataset',
          fill: true,
          // backgroundColor: positive.backgroundColor,
          // borderColor: positive.borderColor,
          backgroundColor: negative.backgroundColor,
          borderColor: negative.borderColor,
          pointBorderWidth: 0,
          pointRadius: 0,
          data: [0, 2, 7, 1],
        },
      ],
    };
  };

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Box marginTop={2}>
            <Typography variant="h4">
              {getGreeting()}, {user.firstName}.
            </Typography>
          </Box>
          <Box marginBottom={4}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ fontWeight: 'normal' }}
            >
              Here's what's happening with Vittle today.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Paper>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box padding={2}>
              <Typography className={classes.title}>Customers</Typography>

              <Line
                data={() => customersData()}
                height={60}
                options={{
                  legend: {
                    display: false,
                  },
                  scales: {
                    yAxes: [
                      {
                        display: false,
                      },
                    ],
                    xAxes: [
                      {
                        display: false,
                      },
                    ],
                  },
                }}
              />
            </Box>
            <Box padding={2} display="flex" flexDirection="column">
              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography variant="h5" component="h2">
                  120
                </Typography>
                <ArrowUpwardIcon
                  className={`${classes.graphArrow} ${classes.graphArrowPositive} `}
                />
                <Typography
                  className={`${classes.graphChange} ${classes.graphChangePositive}`}
                >
                  5
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  // style={{ textTransform: 'uppercase' }}
                  color="textSecondary"
                >
                  Weekly
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box padding={2}>
              <Typography className={classes.title}>Sales</Typography>
              <Line
                data={() => salesData()}
                height={60}
                options={{
                  legend: {
                    display: false,
                  },
                  scales: {
                    yAxes: [
                      {
                        display: false,
                      },
                    ],
                    xAxes: [
                      {
                        display: false,
                      },
                    ],
                  },
                }}
              />
            </Box>
            <Box padding={2} display="flex" flexDirection="column">
              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography variant="h5" component="h2">
                  $12,000
                </Typography>
                <ArrowUpwardIcon
                  className={`${classes.graphArrow} ${classes.graphArrowPositive} `}
                />
                <Typography
                  className={`${classes.graphChange} ${classes.graphChangePositive}`}
                >
                  5%
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  // style={{ textTransform: 'uppercase' }}
                  color="textSecondary"
                >
                  Weekly
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box padding={2}>
              <Typography className={classes.title}>Sign ups</Typography>
              <Line
                height={60}
                data={() => signupsData()}
                options={{
                  legend: {
                    display: false,
                  },
                  scales: {
                    yAxes: [
                      {
                        display: false,
                      },
                    ],
                    xAxes: [
                      {
                        display: false,
                      },
                    ],
                  },
                }}
              />
            </Box>
            <Box padding={2} display="flex" flexDirection="column">
              <Box display="flex" alignItems="center" flexDirection="row">
                <Typography variant="h5" component="h2">
                  5
                </Typography>

                <Box
                  display="flex"
                  alignItems="center"
                  className={`${classes.graphChange} ${classes.graphChangeNegative}`}
                >
                  <ArrowDownwardIcon
                    className={`${classes.graphArrow} ${classes.graphArrowNegative} `}
                  />
                  2
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="body2"
                  // style={{ textTransform: 'uppercase' }}
                  color="textSecondary"
                >
                  Daily
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box mt={2}></Box>
        <Divider />
        <Box padding={1} display="flex" justifyContent="center">
          <Button style={{ fontSize: 12 }} color="primary" size="small">
            See reports
          </Button>
        </Box>
      </Paper>

      <Paper elevation={1} style={{ marginTop: '30px' }} variant="elevation">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box padding={2}>
              <Typography className={classes.title}>Recent activity</Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        <Box padding={1} display="flex" justifyContent="center">
          <Button style={{ fontSize: 12 }} color="primary" size="small">
            See all Activity
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
